import { Injectable } from '@angular/core';
import { protectedResources } from '../auth-config';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { AuthTokenService } from './auth-token.service';
import { BehaviorSubject, Subject, debounceTime, skip } from 'rxjs';
import ErrorHandling from '../shared/Utility/ErrorHandling';
import { ToastrService } from 'ngx-toastr';
import { NetworkConnectionService } from './network-connection.service';

export enum HubMessageType {
  NotificationRecieved = 'notificationRecieved',

  NotebookGenerated = 'NotebookGenerated',
  NotebookUpdated = 'notebookUpdated',

  NoteCreated = 'noteCreated',
  NoteUpdated = 'noteUpdated',

  BookmarkUpdated = 'bookmarkUpdated',
  BookmarkDeleted = 'bookmarkDeleted',

  TimeZoneUpdated = 'TimeZoneUpdated',
  FileChunkUploaded = 'fileChunkUploaded',
}

export interface HubMessage {
  type: HubMessageType;
  content: {
    [s: string]: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class HubService {
  private hubConnection: HubConnection;
  private retryCount = 0;
  private retryConnectionAwaits = [0, 2000, 10000, 30000, 60000, 60000, 60000];
  private noteBooksRegisted = new Map<string, { count: number }>();
  private noteBooksUnRegisted = new Set<string>();
  private invokeQueue: { action: string; callback: (value) => void; errorCallback: (value) => void; args: any[] }[] = [];

  private hubSource = new Subject<HubMessage>();
  public hub$ = this.hubSource.asObservable();

  public connected$ = new BehaviorSubject<boolean>(false);

  readonly hubUrl = `${protectedResources.apiSignalRSocketUrl.endpoint}/hubs/dataupdate`;

  constructor(private authTokenService: AuthTokenService, private errorHandling: ErrorHandling, private toastr: ToastrService, private networkConnectionService: NetworkConnectionService) {
    // Test
    this.hub$.subscribe((message) => {
      console.log('HubMessage', message);
    });
    // this.networkConnectionService.networkStatus$.pipe(skip(1), debounceTime(1000)).subscribe((value) => {
    //   if (value && !this.isHubActive()) {
    //     this.reactiveConnection();
    //   }
    // });
  }

  public async setupConnection(): Promise<void> {
    this.onConnected(false);
    if(!this.networkConnectionService.isOnline) {
      return;
    }
    console.log('SignalRService Load()');
    this.retryCount = 0;
    while (!this.authTokenService.token) {
      console.log('SignalRService Load() --> Waiting for token...');
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    console.log('SignalRService Load() --> Token received, setting up SignalR');

    if (!!this.hubConnection) {
      this.disconnect();
    }

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => this.authTokenService.token,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .build();

    // subscribe all messages
    this.onListenAllMessages();

    this.hubConnection.onclose(() => {
      this.start();
    });

    this.start();
  }

  public async reactiveConnection() {
    if (this.isHubActive()) {
      return;
    }
    await this.setupConnection();
  }

  public registerNotebookUpdate(notebookId: string) {
    if (this.noteBooksUnRegisted.has(notebookId)) {
      this.noteBooksUnRegisted.delete(notebookId);
    }
    if (this.noteBooksRegisted.has(notebookId)) {
      const { count } = this.noteBooksRegisted.get(notebookId);
      this.noteBooksRegisted.set(notebookId, { count: count + 1 });
      return;
    }
    // Support 1 notebook regrister at 1 time
    const keys = Array.from(this.noteBooksRegisted.keys());
    if (keys.length > 1) {
      keys.forEach((notebookId) => {
        this.noteBooksRegisted.delete(notebookId);
        const callback = (value) => {};
        const errorCallback = (err) => {
          this.noteBooksUnRegisted.add(notebookId);
          return console.error(err.toString());
        };
        this.invokeMessage('unRegisterForNoteUpdates', callback, errorCallback, notebookId);
      });
    }

    this.noteBooksRegisted.set(notebookId, { count: 1 });
    const callback = (value) => {};
    const errorCallback = (err) => {
      return console.error(err.toString());
    };
    this.invokeMessage('registerforNoteUpdates', callback, errorCallback, notebookId);
  }

  public unRegisterNotebookUpdate(notebookId: string) {
    if (!this.noteBooksRegisted.has(notebookId)) {
      return;
    }
    const { count } = this.noteBooksRegisted.get(notebookId);
    if (count > 1) {
      // more than 1 subcription
      this.noteBooksRegisted.set(notebookId, { count: count - 1 });
      return;
    }
    this.noteBooksRegisted.delete(notebookId);
    const callback = (value) => {};
    const errorCallback = (err) => {
      this.noteBooksUnRegisted.add(notebookId);
      return console.error(err.toString());
    };
    this.invokeMessage('unRegisterForNoteUpdates', callback, errorCallback, notebookId);
  }

  public isHubActive() {
    return this.connected$.value;
  }

  private retryRegisterNoteBooksUpdate() {
    this.noteBooksRegisted.forEach(({ count }, key) => {
      this.hubConnection.invoke('registerforNoteUpdates', key).catch((err) => {
        return console.error(err.toString());
      });
    });
  }

  private retryUnRegisterNoteBooksUpdate() {
    this.noteBooksUnRegisted.forEach((key) => {
      this.hubConnection
        .invoke('unRegisterForNoteUpdates', key)
        .then(() => {})
        .catch((err) => {
          this.noteBooksUnRegisted.add(key);
          return console.error(err.toString());
        });
    });
  }

  private async start() {
    if(this.hubConnection.state !== HubConnectionState.Disconnected){
      return;
    }
    try {
      await this.hubConnection.start().then(() => {
        this.onConnected(true);
        this.retryCount = 0;
        this.retryRegisterNoteBooksUpdate();
        this.retryUnRegisterNoteBooksUpdate();
      });
    } catch (err) {
      this.retryCount++;
      if (this.networkConnectionService.isOnline) {
        this.errorHandling.LogErrorToServer(
          'SIGNALR Connection Failed with Error = ' + err.toString() + ' -- Connection will be Retried by Refreshing the App -- Token = ' + this.authTokenService.token,
          err
        );
      }

      if (err && err.statusCode && err.statusCode === 401) {
        console.error('Unauthorize');
        await this.authTokenService.RefreshToken();
        await this.setupConnection();
        return;
      }
      if (!this.networkConnectionService.isOnline) {
        this.onConnected(false);
        console.error('Fail to connect signalR via Internet problem');
        // this.errorHandling.LogErrorToServer('SIGNALR Connection Failed with Error = ' + err.toString() + ' -- Due to Internet Problem', err, null);
        return;
      }
      if (this.retryCount > this.retryConnectionAwaits.length) {
        // Show error
        this.toastr.error(
          '<p>Application did NOT Load Properly</p><p>If you experience any issues with data updating in the UI after changes, please re-load the application.</p><p>If you continue to experience this issue, please contact support</p>',
          'ERROR',
          {
            enableHtml: true,
            closeButton: true,
            timeOut: 0,
            tapToDismiss: true,
          }
        );

        return;
      }

      this.onConnected(false);
      console.error('Fail to connect signalR');

      setTimeout(() => this.start(), this.retryConnectionAwaits[this.retryCount - 1]);
    }
  }

  private disconnect() {
    this.onConnected(false);
    this.hubConnection.stop();
  }

  private onConnected(value: boolean) {
    if (value != this.connected$.value) {
      this.connected$.next(value);
    }
  }

  private onListenAllMessages() {
    Object.keys(HubMessageType).forEach((key) => {
      const handler = this.getHandlerSignalRArgs(HubMessageType[key]);

      this.hubConnection.on(HubMessageType[key], handler);
    });
    this.invokeQueue.forEach(({action, args, callback, errorCallback})=>{
      this.invokeMessage(action, callback, errorCallback, ...args);
    })
  }

  private getHandlerSignalRArgs(type: HubMessageType) {
    switch (type) {
      case HubMessageType.NotificationRecieved:
        return (signalRjson) => this.emitHubConent(type, { signalRjson });

      case HubMessageType.NotebookUpdated:
      case HubMessageType.NoteCreated:
      case HubMessageType.NoteUpdated:
        return (signalRnotebookID, signalRnoteID, signalRjson) =>
          this.emitHubConent(type, {
            signalRnotebookID,
            signalRnoteID,
            signalRjson,
          });

      case HubMessageType.BookmarkUpdated:
        return (signalrNoteID, signalrBookmarkText, signalrIsBookmakred, signalrDueDate) =>
          this.emitHubConent(type, {
            signalrNoteID,
            signalrBookmarkText,
            signalrIsBookmakred,
            signalrDueDate,
          });

      case HubMessageType.BookmarkDeleted:
        return (signalrNoteID) => this.emitHubConent(type, { signalrNoteID });

      case HubMessageType.NotebookGenerated:
        return (generationID, notebookId, fileType, filesize) =>
          this.emitHubConent(type, {
            generationID,
            notebookId,
            fileType,
            filesize,
          });

      case HubMessageType.TimeZoneUpdated:
        return (signalRTimeZoneID, ianaTimeZoneID) => this.emitHubConent(type, { signalRTimeZoneID, ianaTimeZoneID });

      case HubMessageType.FileChunkUploaded:
        return (signalRnotebookID, signalRfileName, signalRchunkIndex, signalRtotalChunks, signalRpercUploaded) =>
          this.emitHubConent(type, {
            signalRnotebookID,
            signalRfileName,
            signalRchunkIndex,
            signalRtotalChunks,
            signalRpercUploaded,
          });

      default:
        throw new Error('Not Implemented');
    }
  }

  private emitHubConent(type: HubMessageType, content: any) {
    this.hubSource.next({
      type,
      content,
    });
  }

  private invokeMessage(action: string, callback: (value) => void, errorCallback: (value) => void, ...args: any[]) {
    if (!(this.hubConnection && this.hubConnection.state === HubConnectionState.Connected)) {
      this.invokeQueue.push({ action, callback, errorCallback, args });
      return;
    }
    this.hubConnection
      .invoke(action, ...args)
      .then(callback)
      .catch(errorCallback);
  }
}

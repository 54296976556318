import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementWorkRequestDocumentTypeRoutingModule } from './case-management-work-request-document-type-routing.module';
import { CaseManagementWorkRequestDocumentTypeComponent } from './case-management-work-request-document-type.component';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementWorkRequestDocumentTypeAddModule } from '../case-management-work-request-document-type-add/case-management-work-request-document-type-add.module';


@NgModule({
  declarations: [
    CaseManagementWorkRequestDocumentTypeComponent
  ],
  imports: [
    CommonModule,
    CaseManagementWorkRequestDocumentTypeRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementWorkRequestDocumentTypeAddModule
  ],
  exports: [
    CaseManagementWorkRequestDocumentTypeComponent
  ]
})
export class CaseManagementWorkRequestDocumentTypeModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementReceivedMethodRoutingModule } from './case-management-received-method-routing.module';
import { CaseManagementReceivedMethodComponent } from './case-management-received-method.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementReceivedMethodAddModule } from '../case-management-received-method-add/case-management-received-method-add.module';

@NgModule({
  declarations: [
    CaseManagementReceivedMethodComponent
  ],
  imports: [
    CommonModule,
    CaseManagementReceivedMethodRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementReceivedMethodAddModule
  ],
  exports: [
    CaseManagementReceivedMethodComponent
  ]
})
export class CaseManagementReceivedMethodModule { }

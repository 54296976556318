import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogRoutingModule } from './dialog-routing.module';
import { BaseDialogComponent } from './dialog.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';


@NgModule({
  declarations: [
    BaseDialogComponent
  ],
  imports: [
    CommonModule,
    DialogRoutingModule,
    DialogModule
  ],
  exports: [
    BaseDialogComponent
  ]
})
export class BaseDialogModule { }

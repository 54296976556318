import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ToastrService } from 'ngx-toastr';
import { BaseDialogComponent } from 'src/app/shared/base/dialog/dialog.component';
import { EmitType } from '@syncfusion/ej2-base';
import { protectedResources } from 'src/app/auth-config';
import ConvertUtils from 'src/app/shared/Utility/ConvertUtils';
import DateUtils from 'src/app/shared/Utility/DateUtils';
import { highlightSearch } from '@syncfusion/ej2-angular-dropdowns';


enum DownloadFileType {
  PDF,
  ZIP
}

@Component({
  selector: 'app-download-confirmation-dialog',
  templateUrl: './download-confirmation-dialog.component.html',
  styleUrls: ['./download-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadConfirmationDialogComponent extends BaseDialogComponent implements OnInit {

  downloadRelatedNodeID: string = "";
  downloadNotebookbookID: string = "";
  downloadNotebookName: string = "";
  downloadFileType: string = "application/pdf";   // default 20
  downloadFileExtension: string = ".pdf";
  downloadType: string = "PDF";
  downloadFileSize: number = 0;
  downloadFileSizeDisplayMB: string = "";
  downloadURL: string = "";
  showSizeWarning: boolean = false;
  fileName: string = "";
  downloadButtonLabel: string = "Download ...";   // Download PDF, Download ZIP, Download Started (depending on situation)

  urlBase = protectedResources.apiBaseUrl.endpoint;



  constructor(
    private toastr: ToastrService,
    public readonly cdRef: ChangeDetectorRef
  ) {
    super(cdRef)
  }

  ngOnInit(): void {

  }

  LoadControlFromNotification(downloadGenerationID: string, categoryID: number, messageHtml: string, 
    dateGeneratedStr: string) {

    var doc = new DOMParser().parseFromString(messageHtml, 'text/html');

    console.log("messageHtml", messageHtml);

    let hiddenSpans = doc.querySelectorAll('span');

    for (let i = 0; i < hiddenSpans.length; i++) {

      this.downloadFileSize = Number(hiddenSpans[i].attributes.getNamedItem('fileSize').nodeValue);
      this.downloadNotebookbookID = hiddenSpans[i].attributes.getNamedItem('notebookID').nodeValue;
      this.downloadNotebookName = hiddenSpans[i].attributes.getNamedItem('notebookName').nodeValue;

      this.downloadFileSizeDisplayMB = ConvertUtils.getMBfromBytes(this.downloadFileSize).toString() + "MB";

      this.CheckIfSizeWarningShouldBeDisplayed();


    }

    console.log("downloadFileSize", this.downloadFileSize);
    console.log("downloadNotebookbookID", this.downloadNotebookbookID);
    console.log("downloadNotebookName", this.downloadNotebookName);
    console.log("downloadFileSizeDisplayMB", this.downloadFileSizeDisplayMB);
    console.log("categoryID", categoryID);



    if (categoryID == 20) {
      this.LoadControl(downloadGenerationID, DownloadFileType.PDF, this.downloadFileSize, this.downloadNotebookbookID, this.downloadNotebookName, dateGeneratedStr);
    }
    else if (categoryID == 21) {
      this.LoadControl(downloadGenerationID, DownloadFileType.ZIP, this.downloadFileSize, this.downloadNotebookbookID, this.downloadNotebookName, dateGeneratedStr);
    }
    else {
      console.error("UNKNOWN CATEGORY ID = " + categoryID);
    }
  }

  CheckIfSizeWarningShouldBeDisplayed() {
    if (this.downloadFileSize > 50000000) { // 50MB
      this.showSizeWarning = true;
    }

  }

  LoadControlFromSignalR(downloadGenerationID: string, notebookID: string, fileType: string, fileSize: number) {

    this.downloadFileSize = fileSize;
    this.downloadNotebookbookID = notebookID;
    this.downloadNotebookName = "";

    console.log("LoadControlFromSignalR");
    console.log("fileSize", fileSize);
    console.log("downloadFileSize", this.downloadFileSize);


    this.downloadFileSizeDisplayMB = ConvertUtils.getMBfromBytes(this.downloadFileSize).toString() + "MB";


    let downloadFileType: DownloadFileType;

    if (fileType.toUpperCase() == "PDF") {
      downloadFileType = DownloadFileType.PDF;
    }
    else if (fileType.toUpperCase() == "ZIP") {
      downloadFileType = DownloadFileType.ZIP;
    }
    else {
      console.error("Unknown File Type = " + fileType.toUpperCase());
    }

    this.CheckIfSizeWarningShouldBeDisplayed();



    this.LoadControl(downloadGenerationID, downloadFileType, fileSize, notebookID, "", "DATE");
  }

  LoadControl(downloadGenerationID: string, downloadFileType: DownloadFileType, fileSize: number, notebookID: string, notebookName: string, dateGeneratedStr: string) {

    this.downloadRelatedNodeID = downloadGenerationID;


    // Make Date valid for filename
    dateGeneratedStr = dateGeneratedStr.replace(" @ ", "-").replace(":", "-");

    console.log("dateGeneratedStr", dateGeneratedStr);



    switch (downloadFileType) {
      case DownloadFileType.PDF:
        this.downloadFileType = "application/pdf";
        this.downloadFileExtension = ".pdf";
        this.downloadType = "PDF";
        this.downloadButtonLabel = "Download PDF";
        break;

      case DownloadFileType.ZIP:
        this.downloadFileType = "application/zip";
        this.downloadFileExtension = ".zip";
        this.downloadType = "ZIP Achive";
        this.downloadButtonLabel = "Download ZIP Archive";

        break;

      default:
        console.error("UNKNOWN DownloadFileType = " + downloadFileType);
    }


    this.downloadURL = this.urlBase + "/api/Notebooks/DownloadNotebook/" + this.downloadRelatedNodeID;

    console.log("downloadURL", this.downloadURL);

    // Create a Good FileName

    if (this.downloadNotebookName) {
      this.fileName = this.downloadNotebookName + "-NotebookID-" + this.downloadNotebookbookID + "-Generated-" + dateGeneratedStr;
    }
    else {
      // Notebook Name NOT Known
      this.fileName = "NotebookID-" + this.downloadNotebookbookID + "-Generated-" + dateGeneratedStr;
    }

    console.log("fileName", this.fileName)

    this.cdRef.detectChanges();
  }


  onDialogClose(event){
    this.ResetDialog();
    super.onDialogClose(event);
  }


  ResetDialog(){
    this.isDisabled = false;
    this.isSpinnerActive = false;
    this.showDownloadProgressNote = false;
  }


  isDisabled: boolean = false;
  isSpinnerActive: boolean = false;
  showDownloadProgressNote: boolean = false;

  onClickDownload() {
    console.log("onClickDownload");
    this.downloadButtonLabel = "Download Started...";
    this.isDisabled = true;
    this.isSpinnerActive = true;


    setTimeout(() => {
      // -------------------------------------------------------------
      // Provide update to user that Download could be done.
      // Turn off spinner
      // Show message
      // -------------------------------------------------------------

      console.log("download timer completed");
      this.isSpinnerActive = false;

    this.downloadButtonLabel = "Check if Download Completed *";

    this.showDownloadProgressNote = true;

      this.cdRef.detectChanges();



    }, 2000);

  }

}

import { Injectable } from '@angular/core';
import { SyncQueueItem } from './models/sync';
import { NetworkConnectionService } from '../network-connection.service';
import { EMPTY, TimeoutError, combineLatest, debounceTime, filter } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthTokenService } from '../auth-token.service';
import { HubService } from '../hub.service';

@Injectable({
  providedIn: 'root',
})
export class SyncQueueService {
  queues: SyncQueueItem[] = [];
  constructor(
    private networkConnectionService: NetworkConnectionService,
    private authTokenService: AuthTokenService,
    private hubService: HubService
  ) {
    const token$ = this.authTokenService.token$;
    const hubConnection$ = this.hubService.connected$;
    const network$ = this.networkConnectionService.networkStatus$;
    combineLatest(token$, network$, hubConnection$).pipe(debounceTime(500)).subscribe(([token, value, connected]) => {
      if (!(value && token && connected) || this.queues.length === 0) {
        return;
      }
      this.handleQueue();
    });
  }

  handleQueue() {
    if(this.queues.length == 0 || !this.networkConnectionService.isOnline) {
      return;
    }
    const queueItem = this.dequeue();
    const { request$, onError, onSuccess} = queueItem;
    const subscription = request$.subscribe((res)=> {
      if(onSuccess) {
        onSuccess(res);
      }
    }, (err) => {
      if(onError) {
        onError(err);
      };
      return EMPTY;
    })
    subscription.add(() => {
      this.handleQueue();
    })
  }

  dequeue(): SyncQueueItem {
    if (this.queues.length == 0) return null;

    const item: SyncQueueItem = this.queues[0];

    this.queues.splice(0, 1);
    return item;
  }

  enqueue(syncQueueItems: SyncQueueItem[]): void {
    this.queues.push(...syncQueueItems);
    this.handleQueue();
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      !this.networkConnectionService.isOnline // A helper service that delegates to window.navigator.onLine
    );
  }
}

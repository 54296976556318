import { ApplicationRef, Injectable, Renderer2 } from '@angular/core';
import { NoNewVersionDetectedEvent, SwUpdate, UnrecoverableStateEvent, VersionDetectedEvent, VersionInstallationFailedEvent, VersionReadyEvent } from '@angular/service-worker';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, concat, first, interval, map, skip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  readonly initVersion = '4.2.5';
  appData$ = new BehaviorSubject<any | null>(null);
  version$: Observable<string> = this.appData$.asObservable().pipe(map((data) => data?.version));

  constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef, private toastr: ToastrService) {
    console.log('SwUpdateService', this.swUpdate.isEnabled);
    if (swUpdate.isEnabled) {
      swUpdate.versionUpdates.subscribe((evt) => {
        console.log('SwUpdateService Update', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            this.onVersionDetected(evt);
            break;
          case 'VERSION_READY':
            this.onVersionReady(evt);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.onVersionInstallationFail(evt);
            break;
          case 'NO_NEW_VERSION_DETECTED':
            this.onNoNewVersionDetected(evt);
            break;
        }
      });
      swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
        this.openConfirmDialog('Version Unrecoverable', 'The Version is unrecoverable, Please click on "Reload" to fix or please contact support');
      });
      this.swUpdate
        .checkForUpdate()
        .then(() => {
          console.log('Checking update');
        })
        .catch((err) => {
          console.error('Failed to check for updates:', err);
        });
      // this.checkUpdate(); // remove due to App never Stable somehow
    }
  }

  checkUpdate() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.swUpdate.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  private onVersionDetected(evt: VersionDetectedEvent) {
    console.log(`Downloading new app version: ${evt.version.hash}`, evt.version.appData);
  }

  private onVersionReady(evt: VersionReadyEvent) {
    console.log(`New app version Ready: ${evt.currentVersion}`);
    console.log(`Current version:`, evt.currentVersion);
    console.log(`Latest version:`, evt.latestVersion);
    const _currentVersion: string = evt.currentVersion.appData ? evt.currentVersion.appData['version'] : this.initVersion;
    const _latestVersion: string = evt.latestVersion.appData ? evt.latestVersion.appData['version'] : this.initVersion;
    const majorCurrentVersion = this.getMajorVersion(_currentVersion);
    const majorLatestVersion = this.getMajorVersion(_latestVersion);

    const isMajorUpdate = this.compareVersion(majorCurrentVersion, majorLatestVersion) !== 0;
    if (isMajorUpdate) {
      this.openConfirmDialog('Version Update', 'The Major updated is released, Please click on "Reload" to get the latest changes.');
    }
    this.appData$.next(evt.currentVersion.appData);
  }

  private onVersionInstallationFail(evt: VersionInstallationFailedEvent) {
    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`, evt.version.appData);
    this.toastr.error(
      '<p>Application did NOT Load Properly</p><p>If you experience any issues with data updating in the UI after changes, please re-load the application.</p><p>If you continue to experience this issue, please contact support</p>',
      'ERROR',
      {
        enableHtml: true,
        closeButton: true,
        timeOut: 0,
        tapToDismiss: true,
      }
    );
  }

  private onNoNewVersionDetected(evt: NoNewVersionDetectedEvent) {
    console.log(`No new Version updated '${evt.version.hash}`, evt.version);
    this.appData$.next(evt.version.appData);
  }

  private openConfirmDialog(title: string, content: string) {
    DialogUtility.confirm({
      title,
      content,
      okButton: { text: 'Reload', click: this.onReload.bind(this) },
      cancelButton: { text: 'Cancel' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  private onReload() {
    document?.location?.reload();
  }

  private getMajorVersion(v: string) {
    const version = v.split('.');
    version.pop();
    return version.join('.');
  }

  private compareVersion(v1: string, v2: string) {
    let v1parts = v1.split('.'),
      v2parts = v2.split('.');

    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }

      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }
}

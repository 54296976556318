// import { time } from "console";
const { DateTime } = require("luxon");


import { DatePipe } from "@angular/common";
import { TforensicDateString } from "src/app/domain/tforensicDateString";
import { TforensicNote } from "src/app/domain/tforensicNote";


export default class DateUtils {

  // ==============================================================================
  // Console Log
  // Can easily comment out below to turn off all consoles output
  // ==============================================================================
  static ShowConsoleMsg(msg: string) {
    // console.log("DateUtils", msg);
  }

  static ShowConsoleMsgObj(msg: string, AnyObj: any) {
    // console.log("DateUtils" + " _ " + msg, AnyObj);
  }



  // ===============================================================================
  // .Net C# TICKS Conversion
  // This allows Notes temporarily made on angular client match 
  // the Date/Times Ticks coming from C# .Net API
  // ===============================================================================
  static getDotNetDateTimeNowTicks() {
    return this.getDotNetDateTimeTicks(new Date().getTime());
  }

  static getAngularDateTimeNowTicks() {

    return this.getAngularTicksFromDotNetTicks(this.getDotNetDateTimeNowTicks());
  }

  // // ===============================================================================
  // // The DateTime Control will add the computer OffSet instead of the Users
  // // So this ensures we get the proper time in Ticks for storing in DB.
  // // ===============================================================================
  // static getDotNetDatTimeTicksFromDateStringAfterStrippingOffsetFromDisplayControl(theDate: string, timeZoneID: string) {

  //   this.ShowConsoleMsgObj("getDotNetDatTimeTicksFromDateStringAfterStrippingOffsetFromDisplayControl", theDate);


  // }


  // ===============================================================================
  //   
  // ===============================================================================
  static isLocalTimeOffSetSameAsProfileTimezoneOffset(IanaTimeZoneID: string): boolean {


    let currSystemDateTime: Date = DateTime.local().toFormat("ff");
    let currUserDateTime: Date = DateTime.local().setZone(IanaTimeZoneID).toFormat("ff");

    this.ShowConsoleMsg("+++++++++++++++++++");
    this.ShowConsoleMsg("User IanaTimeZoneID = " + IanaTimeZoneID);
    this.ShowConsoleMsg(currSystemDateTime.toString());
    this.ShowConsoleMsg(currUserDateTime.toString());
    this.ShowConsoleMsg(currSystemDateTime.toString());
    this.ShowConsoleMsg(currUserDateTime.toString());


    if (currSystemDateTime.toString() == currUserDateTime.toString()) {
      this.ShowConsoleMsg("MATCH");
      return true;
    }
    else {
      this.ShowConsoleMsg("No Match");
      return false;
    }

    this.ShowConsoleMsg("+++++++++++++++++++");

  }



  // ===============================================================================
  //   
  // ===============================================================================
  static getDotNetDateTimeTicks(dateTicksFromGetTimeCall: number) {

    // the number of .net ticks at the unix epoch
    var epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    var ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    var dotNetTicks = epochTicks + (dateTicksFromGetTimeCall * ticksPerMillisecond);

    // -------------------------------------------------------------------------------
    // The date MUST NOT include milliseconds 
    // I think milliseconds, or whatever this partial second value is. :)
    // which is is the last 7 digits.
    // eg: 637624141310000000 (is what we want)
    //     637624126520630000 (is what is returned)
    // So replace last 7 spots with 0's.
    // As this levefl of precision not required and makes matching records harder
    // -------------------------------------------------------------------------------
    let dotNetTicksStr: string = dotNetTicks.toString().substring(0, 11) + "0000000";
    dotNetTicks = +dotNetTicksStr;

    this.ShowConsoleMsgObj("getDotNetDateTimeTicks", dotNetTicks);

    return dotNetTicks;

  }



  // ===============================================================================
  //   
  // ===============================================================================
  static getAngularTicksFromDotNetTicks(dotNetUTCTicks: number) {


    // the number of .net ticks at the unix epoch
    var epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    var ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    var angularTicks = (dotNetUTCTicks - epochTicks) / ticksPerMillisecond;

    // strip off any decimal values.
    angularTicks = Math.trunc(angularTicks);

    return angularTicks;

  }

  // static setupAllForensicNoteObjectDatesFromDotNetTicks(currNoteObj : TforensicNote, dotNetTicks: number, timeZoneID: string) : TforensicNote{
  static setupAllForensicNoteObjectDatesFromDotNetTicks(currNoteObj: TforensicNote, timeZoneID: string): TforensicNote {


    // currNoteObj.dateDotNetUTCinTicks = dotNetTicks;
    currNoteObj.noteDate_String = this.getNoteDateStringsFromDotNetTicks(currNoteObj.dateDotNetUTCinTicks, timeZoneID);
    currNoteObj.noteDate = this.ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(
      this.getAngularTicksFromDotNetTicks(currNoteObj.dateDotNetUTCinTicks), timeZoneID);

    return currNoteObj;
  }

  // static isValidDate(stringDate: string): boolean{
  //   console.log("isValidDate", stringDate);
  //   let date: DateTime.Date(stringDate, "YYYY/MM/DD")
  // }

  static isValidDate(dateString: string) {

    // Make sure string has - and NOT /
    if (dateString.indexOf("/") > -1) {
      console.error("isValidDate(...) is using / instead of -", dateString);
      return false;
    }


    // First check for the pattern
    var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

    if (!regex_date.test(dateString)) {
      console.error("isValidDate(...) invalid format", dateString);
      return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split("-");
    var day = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }




  // ===============================================================================
  //   
  // ===============================================================================
  static setupAllForensicNoteObjectDatesFromCurrentTime(currNoteObj: TforensicNote, timeZoneID: string): TforensicNote {

    currNoteObj.dateDotNetUTCinTicks = this.getDotNetDateTimeNowTicks();

    currNoteObj = this.setupAllForensicNoteObjectDatesFromDotNetTicks(currNoteObj, timeZoneID);

    return currNoteObj;
  }



  // ===============================================================================
  //   
  // ===============================================================================
  static getFormattedDateTimeInUsersTimezone(dateInAngularTicks: number, timeZoneID: string, dateTimeFormat: string): Date {
    // return moment(dateInAngularTicks).locale("en").tz(timeZoneID).format(dateTimeFormat);

    this.ShowConsoleMsgObj("getFormattedDateTimeInUsersTimezone -- Timezone", timeZoneID);

    var options = { zone: timeZoneID }

    if (dateTimeFormat) {
      var formattedDateTime = DateTime.fromMillis(dateInAngularTicks, options).toFormat(dateTimeFormat);
      this.ShowConsoleMsgObj("formattedDateTime", formattedDateTime);

      return formattedDateTime;

    }
    else {
      var noFormatDateTime = DateTime.fromMillis(dateInAngularTicks, options);
      this.ShowConsoleMsgObj("noFormatDateTime", noFormatDateTime);

      return noFormatDateTime;
    }
  }


  // ===============================================================================
  //   
  // ===============================================================================
  static getNoteDateStringsFromDotNetTicks(dotNetTicks: number, timeZoneID: string) {

    let angularTicks = this.getAngularTicksFromDotNetTicks(dotNetTicks);

    this.ShowConsoleMsgObj("getNoteDateStringsFromDotNetTicks --> angularTicks", angularTicks);

    return this.getNoteDateStrings(angularTicks, timeZoneID);
  }


  // ===============================================================================
  //   
  // ===============================================================================
  static getNoteDateStrings(dateInAngularTicksFromGetTime: number, timeZoneID: string) {
    // -------------------------------------------------
    // Setup Date Strings for Display Purposes
    // -------------------------------------------------

    this.ShowConsoleMsgObj("getNoteDateStrings", dateInAngularTicksFromGetTime + " - " + timeZoneID);

    var noteDate_string = new TforensicDateString();

    // -------------------------------------------------------------------------------------------------------
    // List of Abbreviations to use at: https://github.com/moment/luxon/blob/master/docs/formatting.md
    // Under "Table of tokens"
    // -------------------------------------------------------------------------------------------------------
    noteDate_string.shortDate = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "LLL dd, yyyy").toString();
    noteDate_string.time_24Hour = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "HH:mm").toString();
    noteDate_string.time_12Hour = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "h:mm a").toString();
    noteDate_string.mediumDate = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "DDDD").toString();

    // noteDate_string.shortDate = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "MMM DD, y");
    // noteDate_string.time_24Hour = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "HH:mm");
    // noteDate_string.time_12Hour = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "h: mm a");
    // noteDate_string.mediumDate = this.getFormattedDateTimeInUsersTimezone(dateInAngularTicksFromGetTime, timeZoneID, "fulldate");

    this.ShowConsoleMsgObj("noteDate_string", noteDate_string);
    return noteDate_string;
  }


  // ===============================================================================
  //   
  // ===============================================================================
  static ConverUTCtDateToUsersTimeZoneFromDotNetTicksForDateTimeDisplayControl(dotNetTicks: number, timeZoneID: string) {

    this.ShowConsoleMsgObj("dotNetTicks", dotNetTicks);

    let angularTicks: number = this.getAngularTicksFromDotNetTicks(dotNetTicks);

    return this.ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(angularTicks, timeZoneID);
  }



  // ===============================================================================
  //   
  // ===============================================================================     
  static getCurrentUTCDateTimeForUsersTimeZoneForDateTimeDisplayControl(timeZoneID: string): Date {
    return this.ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(this.getAngularDateTimeNowTicks(), timeZoneID);
  }



  // ===============================================================================
  //   
  // ===============================================================================
  static ConverUTCtDateToUsersTimeZoneFromAngularTicksForDateTimeDisplayControl(angularTicksData: number, timeZoneID: string): Date {

    // var dateFormat = "yyyy-MM-dd hh:mm:ss a";  // to show AM PM - Especially for the Advanced Note Date/Time
    // var dateFormat = "yyyy-MM-dd hh:mm:ss a";  // to show AM PM - Especially for the Advanced Note Date/Time


    console.log("angularTicksData", angularTicksData);
    console.log("timeZoneID", timeZoneID);


    var usersPreferredLocalDateTime = this.getFormattedDateTimeInUsersTimezone(angularTicksData, timeZoneID, null);


    console.log("ConverUTCtDateToUsersTimeZoneFromAngularTicks", usersPreferredLocalDateTime.toString());


    return usersPreferredLocalDateTime;


  }

  static pipe = new DatePipe('en-US');

  
  // =====================================================================
  //
  // =====================================================================
  static FormatDateString(theDateString: Date) {
    return this.pipe.transform(theDateString, 'yyyy-MM-dd');
  }


  
  // =====================================================================
  //
  // =====================================================================
  static FormatDateTimeString(theDateTimeString) {

    if(isNaN(theDateTimeString.valueOf())){
      console.log("Date is NaN");
      return "";
    }

    return this.pipe.transform(theDateTimeString, 'yyyy-MM-dd hh:mm:ss a');
  }

  // =====================================================================
  //
  // =====================================================================
  static FormatDateTimeStringNoSeconds(theDateTimeString: Date) {
    console.log("FormatDateTimeStringNoSeconds", theDateTimeString);

    console.log("isNaN(theDateTimeString.getTime())", isNaN(theDateTimeString.valueOf()));

    if(isNaN(theDateTimeString.valueOf())){
      console.log("Date is NaN");
      return "";
    }

    return this.pipe.transform(theDateTimeString, 'yyyy-MM-dd hh:mm a');
  }

}


<ejs-dialog id="ejDialogCaseManagementStructure" #ejDialogCaseManagementStructure showCloseIcon='true'
    header='Case Management Custom Structure' (beforeClose)="onDialogClose($event)" width="800" height="95%" [visible]="isVisible"
    [animationSettings]='animationSettings' [target]="dialogDragTarget" [allowDragging]='allowDialogDragging'
    [enableResize]='false' isModal="true">
    <div>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">

            <div class="twoCol">
                <div class="colOne">
                    <p>The Case Management Structure is customizable to fit your organization's specific needs.</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Side Menu (Cases, Projects)</mat-label>
                        <input matInput  [(ngModel)]="projectLevelNaming.masterName" />
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 1 (Clients, Projects)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level1Name" maxlength="30"/>
                    </mat-form-field>
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level1AllowNotebooks" [disabled]="projectLevelNaming.level1Name == ''">{{showNotebooksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level1AllowResources" [disabled]="projectLevelNaming.level1Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level1AllowDiaryTasks" [disabled]="projectLevelNaming.level1Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level1AllowFinalReports" [disabled]="projectLevelNaming.level1Name == ''">{{showReportsTab}}</mat-checkbox><br>
                     -->
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 2 (Files, Matters, Incident #s)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level2Name" />
                    </mat-form-field>
                    <mat-checkbox class="checkbox checkboxSpacing" color="primary" [(ngModel)]="projectLevelNaming.level2AllowNotebooks" [disabled]="projectLevelNaming.level2Name == ''">{{showNotebooksTab}}</mat-checkbox>
                    
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level2AllowResources" [disabled]="projectLevelNaming.level2Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level2AllowDiaryTasks" [disabled]="projectLevelNaming.level2Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level2AllowFinalReports" [disabled]="projectLevelNaming.level2Name == ''">{{showReportsTab}}</mat-checkbox><br> -->
                    
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 3 (Sections, Departments, Units)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level3Name" />
                    </mat-form-field>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level3AllowNotebooks" [disabled]="projectLevelNaming.level3Name == ''">{{showNotebooksTab}}</mat-checkbox><br>
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level3AllowResources" [disabled]="projectLevelNaming.level3Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level3AllowDiaryTasks" [disabled]="projectLevelNaming.level3Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level3AllowFinalReports" [disabled]="projectLevelNaming.level3Name == ''">{{showReportsTab}}</mat-checkbox><br> -->
                    
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 4 (Work Requests)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level4Name" />
                    </mat-form-field>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level4AllowNotebooks" [disabled]="projectLevelNaming.level4Name == ''">{{showNotebooksTab}}</mat-checkbox><br>
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level4AllowResources" [disabled]="projectLevelNaming.level4Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level4AllowDiaryTasks" [disabled]="projectLevelNaming.level4Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level4AllowFinalReports" [disabled]="projectLevelNaming.level4Name == ''">{{showReportsTab}}</mat-checkbox><br>
                     -->
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 5 (Exhibits)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level5Name" />
                    </mat-form-field>
                    <mat-checkbox class="checkbox checkboxSpacing" color="primary" [(ngModel)]="projectLevelNaming.level5AllowNotebooks" [disabled]="projectLevelNaming.level5Name == ''">{{showNotebooksTab}}</mat-checkbox><br>
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level5AllowResources" [disabled]="projectLevelNaming.level5Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level5AllowDiaryTasks" [disabled]="projectLevelNaming.level5Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level5AllowFinalReports" [disabled]="projectLevelNaming.level5Name == ''">{{showReportsTab}}</mat-checkbox><br>
                     -->
                    <mat-form-field appearance="outline" class="subLevel">
                        <mat-label>Level 6 (Exhibit Details)</mat-label>
                        <input  matInput [(ngModel)]="projectLevelNaming.level6Name" />
                    </mat-form-field>
                    <br>
                    <mat-checkbox class="checkbox checkboxSpacing" color="primary" [(ngModel)]="projectLevelNaming.level6AllowNotebooks" [disabled]="projectLevelNaming.level6Name == ''">{{showNotebooksTab}}</mat-checkbox><br>
                    <!-- <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level6AllowResources" [disabled]="projectLevelNaming.level6Name == ''">{{showResourcesTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level6AllowDiaryTasks" [disabled]="projectLevelNaming.level6Name == ''">{{showDiaryTasksTab}}</mat-checkbox><br>
                    <mat-checkbox class="checkbox" color="primary" [(ngModel)]="projectLevelNaming.level6AllowFinalReports" [disabled]="projectLevelNaming.level6Name == ''">{{showReportsTab}}</mat-checkbox><br>
                     -->

                    <div class="buttonSection center">
                        <button ejs-button id="btnAddUpdate" #btnAddUpdate class="success"
                          (click)="SaveAllProjectLevels()"><img src="assets/images/progress-spinner.gif" class="button-spinner" *ngIf="isUpdatingLevels" />Update Level Names & Tabs</button>
                      </div>

<hr>
                  

                </div>
                <!-- 2nd COL Additional Information -->
                <div class="colTwoInfo">
                    <h2>Additional Information:</h2>
                    <h3>Side Menu:</h3>
                    <p>Name displayed in the side menu under 'Team Notebooks'</p>
                    <h3>Level 1 - 6:</h3>
                    <p>Name for each level of the Case Management Structure. Although each level has a specific purpose, you are free to rename the levels.</p>
                    <p>NOTE: An 's' will be added to the name provided when appropriate in the User Interface.
                        Do not include an s at the end of any levels you rename.
                    </p>
                    <h3>Private Industry:</h3>
                    <ul>
                        <li>Case</li>
                        <ol>
                            <li>Client</li>
                            <li>File</li>
                            <li>Section</li>
                            <li>Work Request</li>
                            <li>Exhibit</li>
                            <li>Exhibit Details</li>
                        </ol>
                    </ul>
                  
                    <h4>Law Enforcement Agency</h4>
                    <ul>
                        <li>Project</li>
                        <ol>
                            <li>Project</li>
                            <li>Incident #</li>
                            <li>Department</li>
                            <li>Work Request</li>
                            <li>Exhibit</li>
                            <li>Exhibit Details</li>
                        </ol>
                    </ul>
                    <hr>
                    <h4>Show XYZ tab</h4>
                    <p>In each section, you can choose to show/hide relevant tabs of information.</p>
                    <p>It may not make sense to have specific notebooks, diary tasks, due dates, resources or reports at some levels, 
                        but this will depend on how your organization documents investigations.</p>
                    <p>Un-check to hide the appropriate tab.</p>
                    <p>Extra options will be made available as they are completed.</p>
                    <hr>
                    <h4>Future Updates</h4>
                    <p>Our CMS is in Active Development to bring new features and options. If you have an idea on how the CMS can be improved to meet your requirements, please let us know.</p>
                    
                </div>
            </div>
        </div>
    </div>
</ejs-dialog>
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { TforensicProjectItem } from '../domain/tforensicProjectItem';
// import { TforensicNoteBook } from '../domain/tforensicNoteBook';
import { TforensicProject } from '../domain/tforensicProject';
import { tNumber } from '../domain/tNumber';
import { SuccessModel } from '../domain/Success';
import { TprojectNotebook } from '../domain/projectNotebooks';
import { TforensicNoteBook } from '../domain/tforensicNoteBook';
import { tString } from '../domain/tString';
import { TforensicProjectLevels } from '../domain/tforensicProjectLevels';
import { ProjectLevelNaming } from '../shared/customObjects/ProjectLevelNaming';
import { ProjectLevelOptions } from '../shared/customObjects/ProjectLevelOptions';
// import { tString } from '../domain/tString';
import { protectedResources } from '../auth-config';
import { ProjectNotebookListModel } from '../shared/customObjects/projectNotebookListModel';
import URLUtils from '../shared/Utility/urlUtils';


@Injectable({
  providedIn: 'root',
})
export class ForensicProjectServiceV2 {

  url = protectedResources.apiBaseUrl.endpoint;
  
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) 
  {

  }

  // ============================================================================
  // Project Levels
  // ============================================================================


  


  UpdateAllProjectLevels(
    projectLevelNaming : ProjectLevelNaming
  ): Observable<SuccessModel> {

    console.log("UpdateAllProjectLevels", projectLevelNaming);

    // ---------------------------------------------------------------------------------
    // Level Names MUST NOT have a trailing 's' at the end as an 's' will be added
    // where needed within the UI. So remove if it exists.
    // ---------------------------------------------------------------------------------

    if(projectLevelNaming.masterName.endsWith('s')){
      projectLevelNaming.masterName = projectLevelNaming.masterName.slice(0, -1);
    }
    if(projectLevelNaming.level1Name.endsWith('s')){
      projectLevelNaming.level1Name = projectLevelNaming.level1Name.slice(0, -1);
    }
    if(projectLevelNaming.level2Name.endsWith('s')){
      projectLevelNaming.level2Name = projectLevelNaming.level2Name.slice(0, -1);
    }
    if(projectLevelNaming.level3Name.endsWith('s')){
      projectLevelNaming.level3Name = projectLevelNaming.level3Name.slice(0, -1);
    }
    if(projectLevelNaming.level4Name.endsWith('s')){
      projectLevelNaming.level4Name = projectLevelNaming.level4Name.slice(0, -1);
    }
    if(projectLevelNaming.level5Name.endsWith('s')){
      projectLevelNaming.level5Name = projectLevelNaming.level5Name.slice(0, -1);
    }
    if(projectLevelNaming.level6Name.endsWith('s')){
      projectLevelNaming.level6Name = projectLevelNaming.level6Name.slice(0, -1);
    }


    const httpsOptions = {
      headers: new HttpHeaders({accept: "application/json-patch+json "}),
      withCredentials: true,
    };

    // NOTE: Master Menu has no reason to show Notebooks, resources, etc. All set to false.
    const body = [
      {
        "levelNum": 0,
        "levelLabel": projectLevelNaming.masterName,
        "allowNotebooks": false,
        "allowResources": false,
        "allowDiaryTasks": false,
        "allowFinalReports": false
      },
      {
        "levelNum": 1,
        "levelLabel": projectLevelNaming.level1Name,
        "allowNotebooks": projectLevelNaming.level1AllowNotebooks,
        "allowResources": projectLevelNaming.level1AllowResources,
        "allowDiaryTasks": projectLevelNaming.level1AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level1AllowFinalReports
      },
      {
        "levelNum": 2,
        "levelLabel": projectLevelNaming.level2Name,
        "allowNotebooks": projectLevelNaming.level2AllowNotebooks,
        "allowResources": projectLevelNaming.level2AllowResources,
        "allowDiaryTasks": projectLevelNaming.level2AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level2AllowFinalReports
      },
      {
        "levelNum": 3,
        "levelLabel": projectLevelNaming.level3Name,
        "allowNotebooks": projectLevelNaming.level3AllowNotebooks,
        "allowResources": projectLevelNaming.level3AllowResources,
        "allowDiaryTasks": projectLevelNaming.level3AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level3AllowFinalReports
      },
      {
        "levelNum": 4,
        "levelLabel": projectLevelNaming.level4Name,
        "allowNotebooks": projectLevelNaming.level4AllowNotebooks,
        "allowResources": projectLevelNaming.level4AllowResources,
        "allowDiaryTasks": projectLevelNaming.level4AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level4AllowFinalReports
      },
      {
        "levelNum": 5,
        "levelLabel": projectLevelNaming.level5Name,
        "allowNotebooks": projectLevelNaming.level5AllowNotebooks,
        "allowResources": projectLevelNaming.level5AllowResources,
        "allowDiaryTasks": projectLevelNaming.level5AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level5AllowFinalReports
      },
      {
        "levelNum": 6,
        "levelLabel": projectLevelNaming.level6Name,
        "allowNotebooks": projectLevelNaming.level6AllowNotebooks,
        "allowResources": projectLevelNaming.level6AllowResources,
        "allowDiaryTasks": projectLevelNaming.level6AllowDiaryTasks,
        "allowFinalReports": projectLevelNaming.level6AllowFinalReports
      }
    ];

    // console.log("body", body);
    // console.log("body-JSON", JSON.stringify(body));


    return this.http.put<any>(
      this.url + "/api/ProjectLevel",
      body,
      httpsOptions
    );
  }

  getProjectLevels(): Observable<TforensicProjectLevels> {
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    }

    console.log("getProjectLevels-url", this.url);

    return this.http.get<TforensicProjectLevels>(this.url + '/api/ProjectLevel', httpPostOptions);
  }


  getProjectAdminSettings(enableServiceWorkerCache: boolean, userID: string): Observable<SuccessModel> {
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    }

    let fullURL: string = this.url + '/api/ProjectAdminSettings';
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<SuccessModel>(fullURL, httpPostOptions);
  }


  UpdateAllProjectAdminSettings(
    projectLevelOptions : ProjectLevelOptions
  ): Observable<SuccessModel> {

    console.log("UpdateAllProjectLevels", projectLevelOptions);

    const httpsOptions = {
      headers: new HttpHeaders({accept: "application/json-patch+json "}),
      withCredentials: true,
    };

    // NOTE: Master Menu has no reason to show Notebooks, resources, etc. All set to false.
    const body = [
      {
        "showFileEntryNumberAtLevel": projectLevelOptions.showFileEntryNumberAtLevel,
        "showAddDigitalForensicsFileRequestAtlevel": projectLevelOptions.showAddDigitalForensicsFileRequestAtlevel,
        "onlyShowAddDigitalForensicsFileRequestIf": projectLevelOptions.onlyShowAddDigitalForensicsFileRequestIf,
        "onlyShowAddDigitalForensicsFileRequestIfValue": projectLevelOptions.onlyShowAddDigitalForensicsFileRequestIfValue,
        "showAddDigitalForensicsExhibitAtLevel": projectLevelOptions.showAddDigitalForensicsExhibitAtLevel,
        "onlyShowAddDigitalForensicsExhibitIf": projectLevelOptions.onlyShowAddDigitalForensicsExhibitIf,
        "onlyShowAddDigitalForensicsExhibitIfValue": projectLevelOptions.onlyShowAddDigitalForensicsExhibitIfValue,
        "showAddVideoForensicsFileRequest": projectLevelOptions.showAddVideoForensicsFileRequest,
        "showAddVideoForensicsExhibitAtLevel": projectLevelOptions.showAddVideoForensicsExhibitAtLevel,
        "onlyShowAddVideoForensicsExhibitIf": projectLevelOptions.onlyShowAddVideoForensicsExhibitIf,
        "onlyShowAddVideoForensicsExhibitIfValue": projectLevelOptions.onlyShowAddVideoForensicsExhibitIfValue,
        "showAddCyberFileRequest": projectLevelOptions.showAddCyberFileRequest,
        "showAddCyberExhibitAtLevel": projectLevelOptions.showAddCyberExhibitAtLevel,
        "onlyShowAddCyberExhibitIf": projectLevelOptions.onlyShowAddCyberExhibitIf,
        "onlyShowAddCyberExhibitIfValue": projectLevelOptions.onlyShowAddCyberExhibitIfValue,
        "showAddOSINTFileRequest": projectLevelOptions.showAddOSINTFileRequest,
        "showAddPrivateInvestigatorFileRequest": projectLevelOptions.showAddPrivateInvestigatorFileRequest
      }
    ];

    console.log("body", body)


    return this.http.put<any>(
      this.url + "/api/ProjectAdminSettings",
      body[0],
      httpsOptions
    );
  }


  
  // ============================================================================
  //
  // ============================================================================
  getAllForensicProjects(
    projectID: string,
    showArchivedProjects: boolean,
    startIndex: string,
    numberToDisplay: string,
    searchText: string,
    ownerType: string,
    enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<TforensicProject> {
    
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      
      // observe: 'response' as 'response',
      params: {
        "projectID": projectID,
        "includeInActive": showArchivedProjects.toString(),
        "startIndex": startIndex,
        "numberToDisplay": numberToDisplay,
        "searchText": searchText,
        "ownerType": ownerType
      }
    }

    let fullURL: string = this.url + '/api/Project/List';
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<TforensicProject>(fullURL, httpPostOptions);
  }




  
  // ============================================================================
  //
  // ============================================================================
  RenameProject(
    projectID: string,
    newProjectName: string,
  ): Observable<SuccessModel> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("newProjectName", newProjectName);

    return this.http.patch<SuccessModel>(
      this.url + "/api/Project/" + projectID + "/Rename",
      formData,
      httpPostOptions
    );
  }


  UpdateSynopsis(
    projectID: string,
    newSynopsis: string,
  ): Observable<SuccessModel> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("newSynopsis", newSynopsis);

    return this.http.patch<SuccessModel>(
      this.url + "/api/Project/" + projectID + "/UpdateSynopsis",
      formData,
      httpPostOptions
    );
  }



  
  // ============================================================================
  //
  // ============================================================================
  MarkProjectAsInactive(
    projectID: string, 
    makeInActive: boolean
  ): Observable<SuccessModel> {

    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("makeInActive", makeInActive.toString());

    return this.http.patch<SuccessModel>(
      this.url + "/api/Project/" + projectID + "/MarkProjectAsInactive",
      formData,
      httpPostOptions
    );
  }


  
  // ============================================================================
  //
  // ============================================================================
  MarkProjectAsDeleted(
    projectID: string
  ): Observable<SuccessModel> {

    const httpPostOptions = {
      withCredentials: true,
    };
    
    const formData = new FormData();

    return this.http.patch<SuccessModel>(
      this.url + "/api/Project/" + projectID + "/DeleteProject",
      formData,
      httpPostOptions
    );
  }



  
  


  // ============================================================================
  //
  // ============================================================================
  getAllForensicProjectsForTreeView(
    notebookID: string, enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<JSON> {
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      params: {
        notebookID: notebookID,
      },
    };

    let fullURL: string = this.url + '/api/Project/GetAllForTree';
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<JSON>(fullURL, httpPostOptions);
  }



  
  // // ============================================================================
  // //
  // // ============================================================================
  // getProjectById(projectID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {
  //   const httpPostOptions = {
  //     withCredentials: true,
  //   };

  //   console.log("getProjectById - projectID = ", projectID);

  //   let fullURL: string = this.url + '/api/Project/List/' + projectID;
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   return this.http.get<any>(fullURL, httpPostOptions);
  // }

  
  // ============================================================================
  //
  //
  // OwnerType: 
  // - My = 0
  // - Team = 1
  // ============================================================================
  getProjectNotebooks(notebookList: ProjectNotebookListModel[], searchText: string, ownerType: number, enableServiceWorkerCache: boolean, userID: string): Observable<any> {
    
    // ------------------------------------------------------------------------------
    // Need to get ONLY the notebookID into an array to send to the API Method.
    // remove the isChild from the NotebookList object array
    // ------------------------------------------------------------------------------
    console.log("getProjectNotebooks", notebookList);
    const notebookListOnly: Array<string> = notebookList.map(datum => datum.notebookId);
    console.log("notebookListOnly", notebookListOnly);
    
    const httpPostOptions = {
      withCredentials: true,

       // observe: 'response' as 'response',
       params: {
        "notebooks": notebookListOnly,
        "searchText": searchText,
        "ownerType": ownerType.toString()
      }
    };

    console.log("getProjectNotebooks -- ", notebookListOnly);

    let fullURL: string = this.url + "/api/Project/GetProjectNotebooks";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<any>(fullURL, httpPostOptions);
  }



  
  // ============================================================================
  //
  // ============================================================================
  getProjectIDIfExists(notebookID: string, enableServiceWorkerCache: boolean, userID: string): Observable<tNumber> {
    const httpPostOptions = {
      withCredentials: true,
      params: {
        notebookID: notebookID
      },
    };

    let fullURL: string = this.url + "/api/Project/GetProjectIDIfExists";
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<tNumber>(fullURL, httpPostOptions);
  }



  
  // ============================================================================
  //
  // ============================================================================
  getProjectName(projectID: string, enableServiceWorkerCache: boolean, userID: string): Observable<tString> {
    const httpPostOptions = {
      withCredentials: true,
      params: {
        projectID: projectID
      },
    };


let fullURL: string = this.url + "/api/Project/GetProjectName";
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<tString>(fullURL, httpPostOptions);
  }



  
  // ============================================================================
  //
  // ============================================================================
  getAvailableNotebooksNotPartOfAProject(searchText: string, ownerType: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {

    console.log("ownerType", ownerType);


    const httpPostOptions =
    {
      headers:
        new HttpHeaders(
          {
            "Content-Type": "application/json"
          }),
      withCredentials: true,

      // observe: 'response' as 'response',
      params: {
        "searchText": searchText,
        "ownerType": ownerType
      }
    }


    let fullURL: string = this.url + '/api/Project/GetAvailableNotebooksNotPartOfAProject';
fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<any>(fullURL, httpPostOptions);
  }

  


  


  // ============================================================================
  //
  // ============================================================================
  AddNotebookToProject(
    projectID: string,
    notebookID: string
  ) {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append('notebookID', notebookID);
    
    return this.http.post(this.url + '/api/Project/' + projectID + '/AddNotebookToProject', formData, httpPostOptions);
  }

  
}


<ejs-dialog showCloseIcon='true' header="{{customProjectNaming.level2Name}} Options" (beforeClose)="onDialogClose($event)" width="400"
  [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
  [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
  <ng-template #content>
    <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
    <div *ngIf="!isLoading">
      <!-- Content -->

      <ejs-radiobutton label='Auto-Increment (padded to 7 digits)' (click)="click('AutoIncrement')" value="AutoIncrement" name="option" [(ngModel)]="appComponent.adminCmsSettingsModel.fileNumberOption" color="primary"></ejs-radiobutton>
      <ejs-radiobutton label='Numeric (freeform entry - padded to 7 digits)' (click)="click('Numeric')" value="Numeric" name="option" [(ngModel)]="appComponent.adminCmsSettingsModel.fileNumberOption" color="primary"></ejs-radiobutton>
      <ejs-radiobutton label='Freeform Text' (click)="click('FreeFormText')" value="FreeFormText" name="option" [(ngModel)]="appComponent.adminCmsSettingsModel.fileNumberOption" color="primary"></ejs-radiobutton>
<div *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'AutoIncrement'">
    <mat-form-field appearance="outline" class="autoIncrement">
        <mat-label>Auto-Increment Starting Value</mat-label>
        <input matInput (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"
        [(ngModel)]="appComponent.adminCmsSettingsModel.fileNumberStartingValue" minlength="1" maxlength="7"/>
      </mat-form-field>
</div>
<div class="buttonSection center">
    <button ejs-button class="cancel" type="button" (click)="Cancel()">Cancel</button>
    <button class="success" id="btnSubmit" #btnSubmit (click)="Save()"><img
            src="/assets/images/progress-spinner.gif" class="button-spinner"
            *ngIf="showUpdatingSpinner" />Save</button>
</div>

<p class="note" *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'AutoIncrement'">
    AUTO-INCREMENT<br>
    Each {{customProjectNaming.level2Name}} entered will be provided with a value that auto-increments.<br>
    Adjust the starting value if existing records exist in the CMS to avoid duplication.
  </p>
  <p class="note" *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'Numeric'">
    NUMERIC<br>
    Often used in Law Enforcement where {{customProjectNaming.level2Name}}'s are created by external system as incidents are reported.
  </p>

  <p class="note" *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'FreeFormText'">
    FREEFORM<br>
    Use of combination of text and numeric values.
  </p>

      <!-- (end of) Content-->
    </div>
  </ng-template>
</ejs-dialog>
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseDialogComponent } from 'src/app/shared/base/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';
import { ProjectLevelOptions } from 'src/app/shared/customObjects/ProjectLevelOptions';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { OrganizationService } from 'src/app/service/organization.service';
import { ForensicProjectServiceV2 } from 'src/app/service/project-v2.service';

@Component({
  selector: 'app-settings-cms-structure',
  templateUrl: './cms-structure.component.html',
  styleUrls: ['./cms-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CmsStructureComponent extends BaseDialogComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = false;
  
  showNotebooksTab: string = "Show Notebooks tab";
  showResourcesTab: string = "Show Resources tab";
  showDiaryTasksTab: string = "Show Diary / Task tab";
  showReportsTab: string = "Show Reports tab";

  // spinner for progress button
  isUpdatingLevels: boolean = false;
  isUpdatingProjectSettings: boolean = false;

  cmsSystemLevel: number = -1;


  constructor(
    private toastr: ToastrService,
    private projectLevelNaming: ProjectLevelNaming,
    private forensicProjectService: ForensicProjectServiceV2,
    private projectLevelOptions: ProjectLevelOptions,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    private organizationService: OrganizationService,
    public readonly cdRef: ChangeDetectorRef

  ) { 
    super(cdRef);
  }

  ngOnInit(): void {
    console.log("ngOnInit - CmsStructureComponent - projectLevelNaming", this.projectLevelNaming)


  }

  LoadControl(userID: string){

    console.log("CmsStructureComponent - LoadControl(...)", userID)
    this.projectLevelOptions.Load(userID);
    this.show();
  }

  SaveAllProjectLevels() {

    this.isUpdatingLevels = true;

    this.forensicProjectService.UpdateAllProjectLevels(this.projectLevelNaming)
      .subscribe((result) => {
        console.log("SaveALL", result.result);
        this.isUpdatingLevels = false;
        this.toastr.success("Custom Case Management Structure Updated");
      },
        (error) => {
          console.error(error);
          this.toastr.error(error, "ERROR")
          this.isUpdatingLevels = false;
        });


  }

  SaveAllProjectAdminSettings() {

    console.log("SaveAllProjectAdminSettings() called...")


    // this.projectLevelOptions.save(this.projectLevelOptions);


    this.isUpdatingProjectSettings = true;


    // ----------------------------------------------------------------------------------------------
    // NOTE: Currently, we are saving a value for each type of CMS...
    // but I think all will be at the same level.
    // for now, set all, but in future, we may move to a single setting and remove the others.
    // Currently, front end just using this.projectLevelOptions.showAddDigitalForensicsExhibitAtLevel = this.cmsSystemLevel;
    // ----------------------------------------------------------------------------------------------

    this.projectLevelOptions.showAddVideoForensicsExhibitAtLevel = this.projectLevelOptions.showAddDigitalForensicsExhibitAtLevel;
    this.projectLevelOptions.showAddCyberExhibitAtLevel = this.projectLevelOptions.showAddDigitalForensicsExhibitAtLevel;




    this.forensicProjectService.UpdateAllProjectAdminSettings(this.projectLevelOptions)
      .subscribe((result) => {
        console.log("SaveALL", result.result);
        this.isUpdatingProjectSettings = false;
        this.toastr.success("Project Settings Updated");
      },
        (error) => {
          console.error(error);
          this.toastr.error(error, "ERROR")
          this.isUpdatingProjectSettings = false;
        });


  }


}

import { ExhibitType } from "./ExhibitType";

export class ExhibitModel {

  // ID's
  id: string = "";
  parentExhibitId: string = "";
  incidentId: string = "";

  // Reference #'s
  poTag: string = "";
  referenceNumber: string = "";
  originalInvestigatorExhibitNum: string = "";

  // Exhibit Info
  serialNumber: string = "";

  // Priority & Return To
  priority: string = "";
  exhibitDispostionTypeId: string = "";

  // Notes
  physicalDescription: string = "";
  additionalInformation: string = "";
  notes: string = "";

  // Derived Values (after Load used)
  makeLabel: string = "";
  exhibitTypeStr: string = "";
  exhibitType: ExhibitType = null;            // NOT returned from API - but if required, set by code.
  yearOfIncident: string = "";
  incidentNumber: string = "";
  subject: string = "";
  childExhibits: string[] = [];
  exhibitDispostionTypeLabel: string = "";
  numberOfChildExhibits: number = 0;

  // Helper for UI
  isUpdate: boolean = false;    // set to true so UI knows to update existing object.
  workRequestTaskID: string = "";


  LoadFromApiResult(result: any) {

    // ID's
    this.id = result.base.id;
    this.parentExhibitId = result.base.parentExhibitId;
    this.incidentId = result.base.incidentId;

    // Reference #'s
    this.poTag = result.base.poTag;
    this.referenceNumber = result.base.referenceNumber;
    this.originalInvestigatorExhibitNum = result.base.originalInvestigatorExhibitNum;

    // Exhibit Info
    this.serialNumber = result.base.serialNumber;

    // Priority & Return To
    this.priority = result.base.priority;
    this.exhibitDispostionTypeId = result.base.exhibitDispostionTypeId;

    // Notes
    this.physicalDescription = result.base.physicalDescription;
    this.additionalInformation = result.base.additionalInformation;
    this.notes = result.base.notes;

    // Derived
    this.makeLabel = result.derived.makeLabel;
    this.exhibitDispostionTypeLabel = result.derived.exhibitDispostionTypeLabel;
    this.yearOfIncident = result.derived.yearOfIncident;
    this.incidentNumber = result.derived.incidentNumber;
    this.subject = result.derived.subject;
    this.numberOfChildExhibits = result.derived.numberOfChildExhibits;
    this.exhibitDispostionTypeLabel = result.derived.exhibitDispostionTypeLabel;
    this.childExhibits = result.derived.childExhibits;
    this.exhibitTypeStr = result.derived.exhibitType;

    // Extra's
    this.exhibitType = ExhibitModel.ConvertStringToExhibitTypeObject(this.exhibitTypeStr);

  }


  // ==========================================================
  // Used in various places to convert the stored String
  // ExhibitType value to an actual enum.
  // ==========================================================

  static ConvertStringToExhibitTypeObject(exhibitTypeStr: string): ExhibitType {

    console.log("ConvertStringToExhibitTypeObject(...)", exhibitTypeStr);

    // UPPER Case for easier comparison
    if(!exhibitTypeStr){
      return null;
    }

    if(!exhibitTypeStr.endsWith("Exhibit")){
      // Does NOT end with Exhibit, so already a proper Exhibit
      console.log("Exhibit is OF ExhibitType", exhibitTypeStr);
      return ExhibitType[exhibitTypeStr.replace('EXHIBIT', '')]; 
    }



    // else...
    exhibitTypeStr = exhibitTypeStr.toUpperCase();

    console.log("exhibitTypeStr", exhibitTypeStr);

    switch (exhibitTypeStr) {

      case "NASEXHIBIT":
        return ExhibitType.NAS;

      case "COMPUTEREXHIBIT":
        return ExhibitType.Computer;

      case "SIMCARDEXHIBIT":
        return ExhibitType.SIM;

      case "HARDDRIVEEXHIBIT":
        return ExhibitType.HDD;

      case "USBFLASHDRIVEEXHIBIT":
        return ExhibitType.USB;

      case "SDCARDEXHIBIT":
        return ExhibitType.SD;

      case "MOBILEDEVICEEXHIBIT":
        return ExhibitType.Cell;

      case "VEHICLEEXHIBIT":
        return ExhibitType.Vehicle;

      case "CLOUDDATAEXHIBIT":
        return ExhibitType.Cloud;

      case "OTHERDATAEXHIBIT":
        return ExhibitType.Other;
      // ----------------------
      // VIDEO Exhibits
      // ----------------------
      case "VIDEOOTHEREXHIBIT":
        return ExhibitType.VideoOther;

      case "VIDEODVREXHIBIT":
        return ExhibitType.VideoDVR;

      case "VIDEOELECTRONICFILEEXHIBIT":
        return ExhibitType.VideoElectronicFile;

      case "VIDEOHDDEXHIBIT":
        return ExhibitType.VideoHDD;

      case "VIDEOOPTICALDISKEXHIBIT":
        return ExhibitType.VideoOpticalDisk;

      case "VIDEOSDEXHIBIT":
        return ExhibitType.VideoSD;

      case "VIDEOTAPEEXHIBIT":
        return ExhibitType.VideoTape;

      case "VIDEOUSBEXHIBIT":
        return ExhibitType.VideoUSB;

      default:
        console.error("Unknown Exhibit Type = ", exhibitTypeStr);
        return null;
    }


  }


}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';

import { IncidentNumbersRoutingModule } from './incident-numbers-routing.module';
import { IncidentNumbersComponent } from './incident-numbers.component';
import { SharedModule } from '../shared/shared.module';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { CaseManagementIncidentNumbersAddv2Module } from '../setting/components/case-management-incident-numbers-addv2/case-management-incident-numbers-addv2.module';
import { BreadCrumbTrailModule } from '../bread-crumb-trail/bread-crumb-trail.module';


@NgModule({
  declarations: [
    IncidentNumbersComponent
  ],
  imports: [
    CommonModule,
    IncidentNumbersRoutingModule,
    DialogModule,
    SharedModule,
    GridModule,
    CaseManagementIncidentNumbersAddv2Module,
    BreadCrumbTrailModule,
  ],
  providers: [
    PageService, 
    SortService, 
    FilterService, 
    GroupService,
  ],
  exports: [
    IncidentNumbersComponent
  ]
})
export class IncidentNumbersModule { }

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { TforensicNote } from "../domain/tforensicNote";
import { TforensicNoteBookForm } from "../domain/tforensicNoteBookForm";
import { TforensicPersons } from "../domain/tforensicPersons";
import { Tforensic } from "../domain/tforensic";
import { TforensicPreviewImage } from "../domain/tforensicPreviewImage";
import { APP_CONFIG, AppConfig } from "../app-config.module";
// import { TforensicNoteAttchments } from "../domain/tforensicNoteAttchments";
import { protectedResources } from '../auth-config';
import { PersonEntityModel } from "../shared/customObjects/personEntityModel";
import URLUtils from "../shared/Utility/urlUtils";

@Injectable({
  providedIn: "root",
})
export class ForensicNoteService {
  title = new BehaviorSubject("none");

  setTitle(title: string) {
    this.title.next(title);
  }

  url = protectedResources.apiBaseUrl.endpoint;


  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }



  getNoteDetailById(notelD: string): Observable<any> {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get<TforensicNote>(
      this.url + "/api/Notes/" + notelD,
      httpPostOptions
    );
  }

  getNoteAttachmentsDetailById(
    notelD: string
  ): Observable<any> {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get<any>(
      this.url + "/api/Notes/" + notelD,
      httpPostOptions
    );
  }

  deleteNoteById(
    notelD: string,
    reasonForDeletion: string
  ): Observable<TforensicNoteBookForm> {

    const formData = new FormData();
    formData.append("reasonForDeletion", reasonForDeletion);

    const httpPostOptions = {
      withCredentials: true,
      headers: new HttpHeaders({ accept: "text/plain" }),
      body: formData,
    };

    return this.http.delete<TforensicNoteBookForm>(
      this.url + "/api/Notes/" + notelD + "/Delete",
      httpPostOptions
    );

  }

  createforensicnotebook(
    notebookID: string,
    noteText: string,
    noteContentType: string,
    dateCreated: string
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("noteText", noteText);
    formData.append("noteContentType", noteContentType);
    formData.append("dateCreated", dateCreated);

    return this.http.post<TforensicNote>(
      this.url + "/api/Notebooks" + notebookID + "/Notes/CreateTextNoteAndTimestamp",
      formData,
      httpPostOptions
    );
  }

  // CreatePersonEntityNote(
  //   tforensicPersons: TforensicPersons,
  //   nootbookId: string
  // ) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json-patch+json ",
  //     }),
  //     withCredentials: true,
  //   };
  //   let now = new Date();
  //   var date = now.toUTCString();
  //   return this.http.post<TforensicPersons>(
  //     this.url + "/api/Notebooks/" + nootbookId + "/PostPerson",
  //     JSON.stringify({
  //       personType: tforensicPersons.personType,
  //       phone: tforensicPersons.phone,
  //       email: tforensicPersons.email,
  //       Dob: tforensicPersons.dob,
  //       identification: tforensicPersons.identification,
  //       streetName: tforensicPersons.streetName,
  //       city: tforensicPersons.city,
  //       state: tforensicPersons.state,
  //       country: tforensicPersons.country,
  //       notes: tforensicPersons.notes,
  //       FirstName: tforensicPersons.firstName,
  //       LastName: tforensicPersons.lastName,
  //       dateCreated: date,
  //     }),
  //     httpOptions
  //   );
  // }


  CreateTextNoteInTicksAndTimestamp(
    notebookID: string,
    noteID: string,
    noteText: string,
    noteContentType: string,
    dotNetTicksDate: number
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("notebookID", notebookID);
    formData.append("noteID", noteID);
    formData.append("noteText", noteText);
    formData.append("noteContentType", noteContentType);
    formData.append("dateCreatedinTicks", dotNetTicksDate.toString());
    return this.http.post<TforensicNote>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateTextNoteInTicksAndTimestamp",
      formData,
      httpPostOptions
    );
  }

  CreateAttachmentNote(
    notebookID: string,
    noteID: string,
    file: File,
    noteDate: string
  ) {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("UploadFiles", file);
    formData.append("noteID", noteID);
    formData.append("dateCreated", noteDate);
    return this.http.post<TforensicNote>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateAttachmentNote_SyncFusionV3",
      formData,
      httpPostOptions
    );
  }
  
  addAttachmentToNote(
    noteID: string,
    file: File,
  ) {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("AttachmentUpload", file);
    formData.append("totalFileSize", file.size.toString());
    return this.http.post<TforensicNote>(
      this.url + "/api/notes/" + noteID + "/AddAttachment_SyncFusion",
      formData,
      httpPostOptions
    );
  }


  CreateTextNoteAndTimestamp(
    notebookID: string,
    noteText: string,
    noteContentType: string
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };
    let now = new Date();
    var date = now.toUTCString();
    const formData = new FormData();
    formData.append("notebookID", notebookID);
    formData.append("noteText", noteText);
    formData.append("noteContentType", noteContentType);
    formData.append("dateCreated", date);
    return this.http.post<TforensicNote>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateTextNoteAndTimestamp",
      formData,
      httpPostOptions
    );
  }

  CreateTextNoteWithDate(
    notebookID: string,
    noteText: string,
    noteContentType: string,
    dateCreated: string
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };

    if (dateCreated == "") {
      let now = new Date();
      dateCreated = now.toUTCString();
    }

    console.log("DateTime being Sent is: ", dateCreated);

    const formData = new FormData();
    formData.append("notebookID", notebookID);
    formData.append("noteText", noteText);
    formData.append("noteContentType", noteContentType);
    formData.append("dateCreated", dateCreated);
    return this.http.post<TforensicNote>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateTextNoteAndTimestamp",
      formData,
      httpPostOptions
    );
  }

  createAdvanceNote(
    notebookID: string,
    dateCreated: string
  ): Observable<Tforensic> {
    const httpPostOptions = {
      withCredentials: true,
    };
    if (dateCreated == "") {
      let now = new Date();
      dateCreated = now.toUTCString();
    }
    //console.log(dateCreated);
    const formData = new FormData();
    formData.append("dateCreated", dateCreated);

    return this.http.post<Tforensic>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateBlankNote",
      formData,
      httpPostOptions
    );
  }


  // DEPRECATED!!!
  createAdvanceBlankNote(
    notebookID: string,
    dateCreated: string,
    noteContentType: string
  ): Observable<Tforensic> {
    const httpPostOptions = {
      withCredentials: true,
    };
    // if (dateCreated == "") {
    //   // let now = new Date();
    //   // dateCreated = now.toUTCString();
    //   dateCreated = null;
    // }
    //console.log(dateCreated);
    const formData = new FormData();
    // formData.append("dateCreated", dateCreated);
    formData.append("noteContentType", noteContentType);

    return this.http.post<Tforensic>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateBlankNote",
      formData,
      httpPostOptions
    );
  }


  CreateAdvanceBlankNoteInTicks(
    notebookID: string,
    dotNetTicksDate: number,
    noteContentType: string
  ): Observable<Tforensic> {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("dateofNoteInTicks", dotNetTicksDate.toString());
    formData.append("noteContentType", noteContentType);

    return this.http.post<Tforensic>(
      this.url + "/api/Notebooks/" + notebookID + "/Notes/CreateBlankNoteInTicks",
      formData,
      httpPostOptions
    );
  }


  UpdateNoteTextInTicks(
    noteID: string,
    text: string,
    dotNetTicksDate: number
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("UpdateNoteTextInTicks");
    const formData = new FormData();
    formData.append("dateofNoteInTicks", dotNetTicksDate.toString());
    formData.append("text", text);

    return this.http.patch<TforensicNote>(
      this.url + "/api/Notes/" + noteID + "/UpdateNoteTextInTicks",
      formData,
      httpPostOptions
    );
  }


  UpdateNoteInTicksAndTimestamp(
    noteID: string,
    text: string,
    revisionComments: string,
    dotNetTicksDate: number
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("dateofNoteInTicks", dotNetTicksDate.toString());
    formData.append("text", text);
    formData.append("revisionComments", revisionComments);


    return this.http.patch<TforensicNote>(
      this.url + "/api/Notes/" + noteID + "/UpdateNoteInTicksAndTimestamp",
      formData,
      httpPostOptions
    );
  }

  UpdateNoteText(
    noteID: string,
    text: string,
    contentDate: string
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("contentDate", contentDate);
    formData.append("text", text);

    return this.http.patch<TforensicNote>(
      this.url + "/api/Notes/" + noteID + "/UpdateNoteText",
      formData,
      httpPostOptions
    );
  }

  UpdatePersonNoteText(
    noteID: string,
    text: string,
    entity: any
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("contentDate", new Date().toUTCString());
    formData.append("text", text);
    formData.append("entity", entity);

    return this.http.patch<TforensicNote>(
      this.url + "/api/Notes/" + noteID + "/UpdateNoteText",
      formData,
      httpPostOptions
    );
  }


  UpdateWitnessNoteText(
    noteID: string,
    tforensicWitness: TforensicPersons,
    contactMethod: number
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json",
      }),
      withCredentials: true,
    };
    var witness = JSON.stringify({
      incidentNumber: "A",
      witness: {
        personType: 1,
        identification: tforensicWitness.identification,
        firstName: tforensicWitness.firstName,
        lastName: tforensicWitness.lastName,
        dob: tforensicWitness.dob,
        estimatedAge: tforensicWitness.EstimatedAge,
        streetName: tforensicWitness.streetName,
        streetNumber: "",
        city: tforensicWitness.city,
        state: tforensicWitness.state,
        country: tforensicWitness.country,
        zipCode: tforensicWitness.zipCode,
        notes: tforensicWitness.notes,
        phone: tforensicWitness.phone,
        homePhone: tforensicWitness.homePhone,
        email: tforensicWitness.email,
        dateCreated: new Date().toUTCString(),
      },
      contactMethod: contactMethod,
    });
    ////console.log(witness);
    return this.http.patch<TforensicNote>(
      this.url + "/api/Witnesses/" + noteID + "/UpdateWitnessNote",
      witness,
      httpPostOptions
    );
  }

  // UpdatePersonNote(
  //   noteID: string,
  //   text: string,
  //   entity: any
  // ): Observable<TforensicNote> {
  //   const httpPostOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json-patch+json ",
  //     }),
  //     withCredentials: true,
  //   };
  //   // const formData = new FormData();
  //   // formData.append('contentDate', new Date().toUTCString());
  //   // formData.append('text', text);
  //   // formData.append('entity', entity);

  //   return this.http.patch<TforensicNote>(
  //     this.url + "/api/Persons/" + noteID + "/UpdatePersonNote",
  //     entity,
  //     httpPostOptions
  //   );
  // }


  AddUpdatePersonNote(
    notebookID: string,
    noteID: string,
    text: string,
    person: PersonEntityModel
  ): Observable<any> {

    const httpPostOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json ",
      }),
      withCredentials: true,
    };


    person.contactMethod = "Text Message";



    var entity = this.getPersonEntityJson(person);


    console.log("body", entity);


    return this.http.patch<any>(
      this.url + "/api/Persons/" + notebookID + "/" + noteID + "/UpdatePersonNote",
      entity,
      httpPostOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetPersonHTML(
    person: PersonEntityModel): Observable<any> {

    var entity = this.getPersonEntityJson(person);
    console.log("body", entity);


    const httpPostOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json ",
      }),
      withCredentials: true,
    };

    return this.http.post<any>(
      this.url + "/api/Persons/" + person.notebookId + "/" + person.id + "/GetPersonHtml",
      entity,
      httpPostOptions
    );
  }

  getPersonEntityJson(person: PersonEntityModel) {

    const entity =
    {
      "personType": person.personType,
      "id": person.id,
      "notebookId": person.notebookId,
      "identification": person.identification,
      "firstName": person.firstName,
      "lastName": person.lastName,
      "dob": person.dob,
      // "dobYear": person,
      // "dobMonth": person,
      // "dobDay": person,
      "estimatedAge": person.estimatedAge,
      "streetName": person.streetName,
      "streetNumber": person.streetNumber,
      "city": person.city,
      "state": person.state,
      "country": person.country,
      "zipCode": person.zipCode,
      "contactMethod": person.contactMethod,
      "cellPhone": person.cellPhone,
      "cellPhoneCountryCode": person.cellPhoneCountryCode,
      "homePhone": person.homePhone,
      "homePhoneCountryCode": person.homePhoneCountryCode,
      "email": person.email,
      "notes": person.notes,
      // "levelNum": 0,
      // "levelLabel": projectLevelNaming.masterName,
      // "allowNotebooks": false,
      // "allowResources": false,
      // "allowDiaryTasks": false,
      // "allowFinalReports": false
      "images": [],
      "associatedNoteID": person.associatedNoteID,
    };

    return entity;
  }




// ===============================================================================
//
// ===============================================================================
  timeStampNote(
    noteID: string,
    revisionComments: string
  ): Observable<TforensicNote> {
    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("revisionComments", revisionComments);

    return this.http.patch<TforensicNote>(
      this.url + "/api/Notes/" + noteID + "/TimeStampNote",
      formData,
      httpPostOptions
    );
  }


  
// ===============================================================================
//
// ===============================================================================
  latitude: number = 0;
  longitude: number = 0;
  getforensicLocation(): Observable<Tforensic> {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          ////console.log("location");
          this.latitude = resp.coords.latitude;
          this.longitude = resp.coords.longitude;
          const httpPostOptions = {
            withCredentials: true,
            params: {
              latitude: this.latitude.toString(),
              longitude: this.longitude.toString(),
            },
          };
          this.http
            .get<Tforensic>(this.url + "/api/LocationMap", httpPostOptions)
            .subscribe((r) => {
              ////console.log(r);
              this.show();
            });
        },
        this.showError,
        // Options. See MDN for details.
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
        //  err => {
        //   //console.log("jj");
        //    return "Geolocation is not supported by this browser.";
        //}
      );
      //}
      //else {
      ////console.log("Geolocation is not supported by this browser.");
      //}
      var t = new Observable<Tforensic>();
      return t;
    } else {
      var t = new Observable<Tforensic>();
      return t;
    }
  }



// ===============================================================================
//
// ===============================================================================
  show(): Observable<Tforensic> {
    var t = new Observable<Tforensic>();
    return t;
  }

  
// ===============================================================================
//
// ===============================================================================
  showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        //console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        //console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        //console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        //console.log("An unknown error occurred.");
        break;
    }
  }

  RemoveAttachment(
    noteID: string,
    attachmentName: string,
    fileSize: string
  ): Observable<Tforensic> {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();

    formData.append("sizes[0]", fileSize);
    formData.append("names[0]", attachmentName);

    return this.http.post<Tforensic>(
      this.url + "/api/Notes/" + noteID + "/RemoveAttachment",
      formData,
      httpPostOptions
    );
  }

  previewAttachedImageFullSize(fileId: string, enableServiceWorkerCache: boolean, userID: string) {
    const httpOptions = {
      withCredentials: true,
    };

    let fullURL: string = this.url +
      "/api/Attachments/GetImage/" +
      fileId +
      "?imageOptions=fullsize";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<TforensicPreviewImage>(fullURL, httpOptions);
  }

  previewAttachedImageSmallThumbnail(fileId: string, enableServiceWorkerCache: boolean, userID: string) {
    const httpOptions = {
      withCredentials: true,
    };

    let fullURL: string = this.url +
      "/api/Attachments/GetImage/" +
      fileId +
      "?imageOptions=250,250";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get<TforensicPreviewImage>(fullURL, httpOptions);
  }

  previewDownloadPdf(fileID: string) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<TforensicPreviewImage>(
      this.url + "/api/Attachments/DownloadAttachment/" + fileID,
      httpOptions
    );
  }

  GetWeather(latitude: string, longitude: string) {
    const httpOptions = {
      params: {
        "latitude": latitude,
        "longitude": longitude
      },
      withCredentials: true,
    };
    return this.http.get<any>(
      this.url + "/api/Weather",
      httpOptions
    );
  }

  GetLocation(latitude: string, longitude: string) {
    const httpOptions = {
      params: {
        "latitude": latitude,
        "longitude": longitude
      },
      withCredentials: true,
    };
    return this.http.get<any>(
      this.url + "/api/LocationMap",
      httpOptions
    );
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementWorkRequestExhibitTypeRoutingModule } from './case-management-work-request-exhibit-type-routing.module';
import { CaseManagementWorkRequestExhibitTypeComponent } from './case-management-work-request-exhibit-type.component';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementWorkRequestExhibitTypeAddModule } from '../case-management-work-request-exhibit-type-add/case-management-work-request-exhibit-type-add.module';


@NgModule({
  declarations: [
    CaseManagementWorkRequestExhibitTypeComponent
  ],
  imports: [
    CommonModule,
    CaseManagementWorkRequestExhibitTypeRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementWorkRequestExhibitTypeAddModule
  ],
  exports: [
    CaseManagementWorkRequestExhibitTypeComponent
  ]
})
export class CaseManagementWorkRequestExhibitTypeModule { }

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ToastrService } from 'ngx-toastr';
import { ClientEncryptionPasswordService } from 'src/app/service/client-encryption-password.service';
import { IndexDBService } from 'src/app/service/indexDB/index-db.service';
import { PasswordType, UserProfileService } from 'src/app/service/user-profile.service';
import SecurityUtils from '../../Utility/securityUtils';
export function confirmPasswordValidator(
  passwordField: string = 'password',
  confirmPasswordField: string = 'confirmPassword'
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(passwordField);
    const matchingControl = formGroup.get(confirmPasswordField);
    if (control?.value !== matchingControl?.value) {
      matchingControl?.setErrors({ confirmedValidator: true });
    } else {
      matchingControl?.setErrors(null);
    }
    return null;
  };
}
@Component({
  selector: 'app-encryption-password',
  templateUrl: './encryption-password.component.html',
  styleUrls: ['./encryption-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncryptionPasswordComponent {
  public readonly pinMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public hidePassword = true;
  public readonly animationSettings: Object = {
    effect: 'Zoom',
    duration: 600,
  };
  isVisible = false;
  allowClosing = false;
  passwordControl = new FormControl('', [Validators.required,  Validators.minLength(6)]);
  setupPassword = new FormGroup(
    {
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    [confirmPasswordValidator('password', 'confirmPassword')]
  );
  isPasswordSetup = false;
  type: PasswordType = 'Password';
  state: 'FillPassword' | 'DeleteConfirmation' = 'FillPassword';
  selectedIndex = 0;
  showError = false;
  numberOffline: {notebooks: number; notes: number} = null;
  constructor(
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    private indexDBService: IndexDBService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private userProfileService: UserProfileService,
    private securityUtils: SecurityUtils,
  ) {
   
  }

  // ===================================
  // SHOW
  // ===================================
  async show() {
    this.isVisible = true;
    this.hidePassword = true;
    this.allowClosing = false;
    this.setupData();
    this.cd.detectChanges();
  }

  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.allowClosing = true;
    this.cd.detectChanges();
  }

  validation(event: any) {
    if (!this.allowClosing) {
      event.cancel = true;
    }
  }

  onTypeChange(event: MatRadioChange) {
    const { value } = event;
    this.type = value;
    this.setupFormAfterType();
    this.cd.detectChanges();
  }

  onBack() {
    this.selectedIndex = 0;
    this.setupPassword.reset();
    this.showError = false;
    this.cd.detectChanges();
  }

  onChangeNumpadSetupPassword(password: string, control: string) {
    this.setupPassword.get(control).setValue(password);
    this.cd.detectChanges();
  }

  onChangeNumpad(password: string) {
    this.passwordControl.setValue(password)
    this.cd.detectChanges();
  }

  onSetupPIN() {
    if(this.setupPassword.invalid) {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.onSetupPassword();
  }

  onSetupPassword() {
    const { password } = this.setupPassword.getRawValue();
    this.setupPassword.reset();
    this.clientEncryptionPasswordService.setupCustomKEK(password, this.type);
    this.hide();
    this.toastr.success('Password has been setup successfully');
    this.cd.detectChanges();
  }

  onSavePassword() {
    const password = this.passwordControl.getRawValue();
    this.passwordControl.reset();
    const success = this.clientEncryptionPasswordService.setCustomKEK(password);
    if (success) {
      this.hide();
      const successMessage = this.isPasswordSetup ? 'Password Confirmed' : 'Password has been setup successfully';
      this.toastr.success(successMessage);
      this.cd.detectChanges();
      return;
    }
    this.state = 'DeleteConfirmation';
    this.cd.detectChanges();
  }

  onDeleteCancel() {
    this.state = 'FillPassword';
    this.cd.detectChanges();
  }

  onDeleteOldEncriptionPassword() {
    this.clientEncryptionPasswordService.clearCustomKEK();
    this.indexDBService.DeleteAllDatabases();
    this.toastr.success('Client Data Deleted');
    this.state = 'FillPassword';
    this.setupData();
    this.cd.detectChanges();
  }
  

  decorateMessageError(error: any, ...arg) {
    const messages: any = {
      required: 'Field is required',
      lowercase: 'Password must contain a lower case letter',
      uppercase: 'Password must contain an upper case letter',
      number: 'Password must contain a number',
      minlength: (error) => `Password must contain at least ${error.requiredLength} Characters`,
      special: 'Password must contain a special character',
      confirmedValidator: 'Passwords do not match',
      email: 'Invalid email address',
      emailValidator: 'Please double check your email address',
    };
    const key = Object.keys(error).find((key) => !!error[key]);
    if (!key) {
      return '';
    }
    if(key === 'minlength') {
      return messages['minlength'](error['minlength']);
    }
    const message = messages[key];
    return message;
  }

  private async setupData() {
    this.isPasswordSetup = !!(this.userProfileService.isCachedUser() && this.securityUtils.isServiceWorkerCacheEnabled());
    if (this.isPasswordSetup) {
      this.type = this.userProfileService.getPasswordType();
    }else {
      this.type = 'Password';
    }
    this.setupFormAfterType();
   
    this.numberOffline = await this.indexDBService.getNumberOfflineNotes();
    this.cd.detectChanges();
  }

  private setupFormAfterType() {
    this.setupPassword.reset();
    this.selectedIndex = 0;
    this.showError = false;
    if (this.type === 'PIN') {
      this.setupPassword.get('password').setValidators([Validators.required, Validators.minLength(6)]);
    }
    if (this.type === 'Password') {
      this.setupPassword.get('password').setValidators([Validators.required, Validators.minLength(8)]);
    }
  }
}

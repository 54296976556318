<!-- Loading GIF Panel -->
<div #loadingDiv class="loadingDiv" *ngIf="isLoading">
    <img src="/assets/images/Forensic_Notes_Logo.png" class="loadingLogo" tooltip="Loading Forensic Notes" />
    <img src="/assets/images/loading-gif-png-5.gif" class="loadingAppGif" tooltip="Loading Forensic Notes" />
</div>

<!-- Main content - Hide till fully loaded -->
<div [ngClass]="{'hidden' : isLoading}">
    <h1>Case Management System (CMS)</h1>
    <!--
    CMS Levels
    0 = not set
    1 = Law Enforcement
    2 = Private Industry
-->
    <p class="matLabelWithNoOutline">CMS Structure? </p>

    <div class="row" *ngFor='let curr of cmsTypeOptions'>
        <ejs-radiobutton [label]='curr.text' (click)="clickLevel(curr.value)" [value]="curr.value" name="curr.value"
            [(ngModel)]="cmsType" color="primary"></ejs-radiobutton>
    </div>

    <!-- Additional Options -->
    <div style="padding-top:10px;">
        <hr class="light">
        <div style="padding-top:10px;">
            <!-- Show Different Options if CMS == 1 or 2 (Law Enforcement or Private Industry) -->
            <div *ngIf="adminCmsSettingsModel.cmsType != 0">
                <!-- Digital Forensics -->
                <mat-checkbox class="checkbox" color="primary" (change)="EnableDigitalForensicsCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.digitalForensicsCmsActivated">Enable Digital Forensics CMS
                </mat-checkbox><br>
                <!-- Video Forensics -->
                <mat-checkbox class="checkbox" color="primary" (change)="EnableVideoForensicsCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.videoForensicsCmsActivated">Enable Video Forensics
                    CMS</mat-checkbox>
                <br>
                <!-- Private Investigators -->
                <mat-checkbox class="checkbox" color="primary" disabled="true"
                    (change)="EnablePrivateInvestigatorsCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.privateInvesgigatorsCmsActivated">Enable Private Investigators
                    CMS
                    (coming
                    soon)
                </mat-checkbox><br>
                <!-- Cyber -->
                <mat-checkbox class="checkbox" color="primary" disabled="true" (change)="EnableCyberCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.cyberCmsActivated">Enable Cyber CMS (coming
                    soon)</mat-checkbox><br>
                <!-- OSINT -->
                <mat-checkbox class="checkbox" color="primary" disabled="true" (change)="EnableOsintCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.osintCmsActivated">Enable OSINT CMS (coming
                    soon)</mat-checkbox><br>
                <!-- Workplace -->
                <mat-checkbox class="checkbox" color="primary" disabled="true"
                    (change)="EnableWorkplaceCMSClick($event)"
                    [(ngModel)]="adminCmsSettingsModel.workplaceInvestigationsCmsActivated">Enable Workplace CMS (coming
                    soon)
                </mat-checkbox><br>
                <div style="padding-top:10px;">
                    <hr class="light">
                    <h2>CMS General Settings</h2>

                    <div class="rowFullWrap">
                        <div class="column50">
                            <button class="SiteColor buttonWidth" (click)="EditCmsStructure()">Custom
                                Structure</button>
                        </div>
                        <div class="column50">
                            Customizable Case/Project structure to match how your organization conducts business.
                        </div>
                    </div>

                    <!-- General Settings (if at least one CMS type selected)-->
                    <div *ngIf="true">

                        <div class="rowFullWrap">
                            <div class="column50">
                                <button class="SiteColor buttonWidth"
                                    (click)="componentFileFormatOptions.show()">{{customProjectNaming.level2Name}}
                                    Format</button>
                            </div>
                            <div class="column50">
                                Set the {{customProjectNaming.level2Name}}s format.<br>
                                eg: Auto-Increment, Numeric or Freeform Text
                            </div>
                        </div>



                        <div class="rowFullWrap">
                            <div class="column50">
                                <button class="SiteColor buttonWidth"
                                    (click)="componentIncidentType.show()">{{customProjectNaming.level2Name}}
                                    Types</button>
                            </div>
                            <div class="column50">
                                eg: Homicide, Theft, Theft of Intellectual Property
                            </div>
                        </div>

                        <div class="rowFullWrap">
                            <div class="column50">
                                <button class="SiteColor buttonWidth"
                                    (click)="componentIncidentNumbers.show()">{{customProjectNaming.level2Name}}
                                    (Current List)</button>
                            </div>
                            <div class="column50">
                                Add/Edit/Delete {{customProjectNaming.level2Name}}s that you investigate.
                            </div>
                        </div>

                        <!-- <div class="rowFullWrap">
                    <div class="column50">
                        <button class="SiteColor buttonWidth" (click)="componentReceivedMethod.show()">Exhibit Receiving
                            Methods</button>
                    </div>
                    <div class="column50">
                        eg: by Hand, via Email, via Cloud Storage
                    </div>
                </div>

                <div class="rowFullWrap">
                    <div class="column50">
                        <button class="SiteColor buttonWidth" (click)="componentWorkRequestStatus.show()">Work Request
                            Status</button>
                    </div>
                    <div class="column50">
                        eg: 
                    </div>
                </div>

                <div class="rowFullWrap">
                    <div class="column50">
                        <button class="SiteColor buttonWidth" (click)="componentWorkRequestDocumentType.show()">File
                            Upload Document Types</button>
                    </div>
                    <div class="column50">
                        eg: Warrant, Digital Forensics Request Form, etc.
                    </div>
                </div> -->
                    </div>
                    <!-- (end of) General Settings -->

                    <!-- PRIVATE INDUSTRY Settings - Show if Level 2 -->
                    <!-- <div *ngIf="adminCmsSettingsModel.cmsType == 2">
                    <h3>Private Industry</h3>

                </div> -->

                    <!-- LAW ENFORECEMENT Settings - Show if Level 1 -->
                    <div *ngIf="adminCmsSettingsModel.cmsType == 1">
                        <h3>Law Enforcement</h3>
                        <div class="rowFullWrap">
                            <div class="column50">
                                <button class="SiteColor buttonWidth" (click)="componentOrgTeam.show()">Org Units /
                                    Teams</button>
                            </div>
                            <div class="column50">
                                eg: Homicide, Robbery, Patrol, etc.
                            </div>
                        </div>


                        <br>
                    </div>

                    <!-- DIGITAL FORENSICS -->
                    <!-- <div *ngIf="adminCmsSettingsModel.digitalForensicsCmsActivated">
                    <h3>Digital Forensics</h3>
                    <div class="rowFullWrap">
                        <div class="column50">
                            button...
                        </div>
                        <div class="column50">
                            eg: ...
                        </div>
                    </div>
                </div> -->


                    <!-- VIDEO FORENSICS -->
                    <div *ngIf="adminCmsSettingsModel.videoForensicsCmsActivated">
                        <h2>Video Forensics</h2>

                        <div class="rowFullWrap">
                            <div class="column50">
                                <button class="SiteColor buttonWidth" (click)="componentBINType.show()">BIN
                                    Types</button>

                            </div>
                            <div class="column50">
                                eg:
                            </div>
                        </div>

                        <!-- <div class="rowFullWrap">
                        <div class="column50">
                            <button class="SiteColor buttonWidth" (click)="componentWorkRequestExhibitType.show()">Media
                                Types</button>
                        </div>
                        <div class="column50">
                            eg: USB Flash Drive, DVD, etc. 
                        </div>
                    </div> -->

                        <br>


                    </div>




                    <app-case-management-incident-numbers
                        #componentIncidentNumbers></app-case-management-incident-numbers>
                    <app-case-management-org-team #componentOrgTeam></app-case-management-org-team>
                    <app-case-management-incident-type #componentIncidentType></app-case-management-incident-type>
                    <app-case-management-bin-type #componentBINType></app-case-management-bin-type>
                    <app-case-management-received-method #componentReceivedMethod></app-case-management-received-method>
                    <app-case-management-work-request-document-type #componentWorkRequestDocumentType>
                    </app-case-management-work-request-document-type>
                    <app-case-management-work-request-status #componentWorkRequestStatus>
                    </app-case-management-work-request-status>
                    <app-case-management-work-request-exhibit-type #componentWorkRequestExhibitType>
                    </app-case-management-work-request-exhibit-type>
                    <app-file-format-options #componentFileFormatOptions></app-file-format-options>
                    <!-- <app-case-management-structure #componentCaseManagementStructure></app-case-management-structure> -->
                    <app-settings-cms-structure #componentCaseManagementStructure></app-settings-cms-structure>

                </div>

            </div>
        </div>
    </div>
</div>
import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import SecurityUtils from '../shared/Utility/securityUtils';
import { ClientEncryptionPasswordService } from './client-encryption-password.service';
import { IndexDBService } from './indexDB/index-db.service';


@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public toastr: ToastrService,
    public authService: MsalService,
    public securityUtils: SecurityUtils,
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private indexDBService: IndexDBService,

  ) { }



  // ============================================================================
  // Called if user is NOT currently logged in to force them to login page.
  // ============================================================================
  async ForceLogin() {

    const redirectPromise = new Promise<void>(async (resolve) => {
      if (this.msalGuardConfig.authRequest) {
        // ------------------
        // Logged In
        // ------------------
        console.log("ForceLogin() initiated...");

        // -------------------------------------
        // Make sure Data Cache is cleared
        // -------------------------------------
        this.ClearDataCaches();

        // this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);

        await this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);



      } else {
        console.log("ForceLogin() --> LoginRedirect");
        this.authService.loginRedirect();
      }

      resolve();
    });
  }

  // ============================================================================
  //
  // ============================================================================
  async logout() {

    // ------------------------------------
    // Browser Cache
    // ------------------------------------
    try {
      await this.ClearDataCaches();
      console.log("Cache Cleared");
    }
    catch (error) {
      console.error("ClearDataCaches Error", error);
    }
    // Turn OFF Offline Features for next login
    await this.securityUtils.turnOffOfflineCapabilties();

    // ------------------------------------
    // Clear Password for Encryption
    // ------------------------------------
    try {
      await this.clientEncryptionPasswordService.clearUserKEK();
      console.log("Client-Side Encryption Password Cleared");
    }
    catch (error) {
      console.error("clearPassword Error", error);
    }

    // --------------------------------------------
    // Clear IndexDBs and Then Delete
    // --------------------------------------------
    try {
      const result = await this.indexDBService.DeleteAllDatabases()
        .then((result) => {
          console.log("IndexDB's Deleted");

          // ------------------------------------
          // Logout and Redirect
          // ------------------------------------
          this.authService.logoutRedirect({
            postLogoutRedirectUri: this.config.postLogoutRedirectUri
          });

        });
    }
    catch (error) {
      console.error("Delete IndexDB's Error", error);
    }



  }


  // ================================================================
  //
  // ================================================================
  async ClearBrowserAndIndexDbCaches() {

    this.indexDBService.DeleteAllDatabases();
    this.ClearDataCaches();
  }

  async ClearKEK() {
    // ------------------------------------
    // Clear Password for Encryption
    // ------------------------------------
    try {
      await this.clientEncryptionPasswordService.clearUserKEK();
      this.clientEncryptionPasswordService.clearCustomKEK();
      console.log("Client-Side Encryption Password Cleared");
    }
    catch (error) {
      console.error("clearPassword Error", error);
    }

  }


  // ================================================================
  //
  // ================================================================
  private ClearDataCaches(showMessage: boolean = false) {
    // -----------------------------------------
    // Clear Caches related to API Data
    // -----------------------------------------
    console.log("Clearing Data Caches");
    caches.delete("ngsw:/:1:data:api:cache");
    caches.delete("ngsw:/:db:1:data:api:lru");
    caches.delete("ngsw:/:db:1:data:api:age");

    if (showMessage) {
      this.toastr.success("Cache Data Cleared");
    }

  }



}

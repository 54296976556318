
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA, } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './service/HttpRequestInterceptor';
import { AppConfigModule } from './app-config.module';
import { SettingComponent } from './setting/setting.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { SettingModule } from './setting/setting.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// Import MSAL and MSAL browser libraries. 
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
// Import the Azure AD B2C configuration 
import { msalConfig, protectedResources } from './auth-config';
// Import the Angular HTTP interceptor. 
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AccountSetupModule } from './account-setup/account-setup.module';
import { InsightsModule } from './shared/insights/insights.module';
import { DownloadModule } from './download/download.module';
import { IndexDBService } from './service/indexDB/index-db.service';
import { AuthTokenService } from './service/auth-token.service';
import { UserProfileService } from './service/user-profile.service';
import { HubService } from './service/hub.service';
import { EncryptionPasswordComponent } from './shared/components/encryption-password/encryption-password.component';


// -----------------------------------------------------------------------------------
// APP_INITIALIZER Services
//
// APP_INITIALIZER is an Angular provider that allows you to run code 
// during the application initialization process. It is a way to perform 
// some logic or configuration before the application starts and the 
// omponent tree is created
// -----------------------------------------------------------------------------------

// --------------------------------------------------------------------------
// IndexDBService
// We do NOT NEED to wait for it to finish as it is not causing issues
// if we don't
// --------------------------------------------------------------------------
export function initMyService(myService: IndexDBService) {
  return () => {
    myService.load();
    myService.InitializeDBs();
  }
}

// =============================================================================================
// AuthToken Service, UserProfile Service, SignalR Service
// Both the UserProfile and SignalR require that the Token Service Complete Successfully
// before they can be run.
// So the following ensures it all completes in the correct order.
// =============================================================================================
// export function initAuthTokenService(authTokenService: AuthTokenService,
//   userProfileService: UserProfileService, hubService: HubService) {
//   return () => {

//     // AuthToken
//     return authTokenService.RefreshToken().then(() => {
//       console.log('AuthTokenService - RefreshToken() initialization complete');

//       // UserProfile
//       return userProfileService.Initialize().then(() => {
//         console.log('UserProfileService - getUserProfile() initialization complete');
        
//         // SignalR
//         return hubService.setupConnection().then(()=> {
//           console.log('SignalRService --> SignalR Service - initialization complete');

//         });
//       });
//     });
//   };
// }


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppConfigModule,
    AppRoutingModule,
    SettingModule,
    NgxPaginationModule,
    SharedModule,
    HttpClientModule,
    ServiceWorkerModule.register('custom-ngsw-worker.js', {
      enabled: true,
      registrationStrategy: "registerImmediately",
    }),
    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration. 
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.assetsApi.scopes,
        }
      },
      {
        // -----------------------------------------------------------------------------------------
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. 
        // If your code needs to call another web API, add the URI mapping here.
        // -----------------------------------------------------------------------------------------
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [protectedResources.assetsApi.endpoint, protectedResources.assetsApi.scopes],
          [protectedResources.caseManagementApi.endpoint, protectedResources.caseManagementApi.scopes],
          [protectedResources.organizationApi.endpoint, protectedResources.organizationApi.scopes],
          [protectedResources.apiBaseUrl.endpoint, protectedResources.organizationApi.scopes]
        ])
      }),
    RouterModule,
    AccountSetupModule,
    InsightsModule,
    DownloadModule,
    // MatGoogleMapsAutocompleteModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBQKty2hx8SNecUR2vej8tHLQRkwgizgpQ',
    //   libraries: ['places'],
    //   language: 'en',
    // }),
  ],
  declarations: [
    // CUSTOM Components go here...
    AppComponent,
    SettingComponent,
    EncryptionPasswordComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initAuthTokenService,
    //   deps: [AuthTokenService, UserProfileService, HubService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    IndexDBService,
    {
      provide: APP_INITIALIZER,
      useFactory: initMyService,
      deps: [IndexDBService],
      multi: true
    },
    UserProfileService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initUserProfileService,
    //   deps: [UserProfileService],
    //   multi: true
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initSignalRService,
    //   deps: [SignalRService],
    //   multi: true
    // },
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class AppModule { }

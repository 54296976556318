// PROD
export const environment = {
  production: true, 
  hideConsoleLog: true,
  AdvanceMode : "https://legacy.forensicnotes.com",

  LoginUrl: "https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_UserSignIn&client_id=2b68b226-6cf7-4ed7-b89c-61655458865b",
  // LoginUrl: "https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_UserSignIn&client_id=92d1949b-1841-4498-b118-fd931dc5f5ca",

  FAQ: 'http://forensicnotes.com/faqs',
  RequireAuthentication: true,      // Change to false if local and AUTH not required.
  ChunkSize: 1048576,
  notebookPageSize: 5, //,
  // App Insights
  insightsInstrumentationKey: '2b1aff0d-4879-4ef9-b3e4-1ea719c1f2ac',
  // auth-config settings...
  clientID: '2b68b226-6cf7-4ed7-b89c-61655458865b',       // NOTE - Make sure ID matches ID in LoginURL above
  // clientID: '92d1949b-1841-4498-b118-fd931dc5f5ca',       // NOTE - Make sure ID matches ID in LoginURL above

  authorityDomain: 'osintfn.b2clogin.com',
  signUpSignIn: 'B2C_1_UserSignIn',
  editProfile: 'B2C_1_edit_profile',

  authorityLogin: 'https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/B2C_1_UserSignIn',
  authorityEdit: 'https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/B2C_1_edit_profile',
  
  
  postLogoutRedirectUri: "https://www.forensicnotes.com",
  // API's
  apiBaseUrlEndpoint: 'https://fn-notesapi-prod-b2c2.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
  assetsApiEndPoint: 'https://crmapi-prd-b2c2.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
  caseManagementApiEndpoint: 'https://crmapi-prd-b2c2.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
  organizationApiEndpoint: 'https://crmapi-prd-b2c2.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
  // WebSocket - SignalR - Does not work via Front Door
  apiSignalRSocketApiEndPoint: 'https://fn-notesapi-prod-b2c2.thankfulforest-f6f12e33.eastus.azurecontainerapps.io',
  // Scope - Copied from the 'Expose an API' section for the Notes API App Registration
  scopes: 'https://osintfn.onmicrosoft.com/92d1949b-1841-4498-b118-fd931dc5f5ca/access_as_user',
};


// // DEV
// export const environment = {
//   production: false, 
//   hideConsoleLog: false,
//   AdvanceMode : "https://legacy.forensicnotes.com",

//   LoginUrl: "https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_UserSignIn&client_id=2b68b226-6cf7-4ed7-b89c-61655458865b",
//   // LoginUrl: "https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_UserSignIn&client_id=92d1949b-1841-4498-b118-fd931dc5f5ca",

//   FAQ: 'http://forensicnotes.com/faqs',
//   RequireAuthentication: true,      // Change to false if local and AUTH not required.
//   ChunkSize: 1048576,
//   notebookPageSize: 5, //,
//   // App Insights
//   insightsInstrumentationKey: '2b1aff0d-4879-4ef9-b3e4-1ea719c1f2ac',
//   // auth-config settings...
//   clientID: '2b68b226-6cf7-4ed7-b89c-61655458865b',       // NOTE - Make sure ID matches ID in LoginURL above
//   // clientID: '92d1949b-1841-4498-b118-fd931dc5f5ca',       // NOTE - Make sure ID matches ID in LoginURL above

//   authorityDomain: 'osintfn.b2clogin.com',
//   signUpSignIn: 'B2C_1_UserSignIn',
//   editProfile: 'B2C_1_edit_profile',

//   authorityLogin: 'https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/B2C_1_testMFA',
//   authorityEdit: 'https://osintfn.b2clogin.com/osintfn.onmicrosoft.com/B2C_1_edit_profile',
  
  
//   postLogoutRedirectUri: "https://www.forensicnotes.com",
//   // API's
//   apiBaseUrlEndpoint: 'https://fn-notesapi-2.mangoforest-769e62fe.eastus.azurecontainerapps.io',
//   assetsApiEndPoint: 'https://crm-api-dev-2.mangoforest-769e62fe.eastus.azurecontainerapps.io',
//   caseManagementApiEndpoint: 'https://crm-api-dev-2.mangoforest-769e62fe.eastus.azurecontainerapps.io',
//   organizationApiEndpoint: 'https://crm-api-dev-2.mangoforest-769e62fe.eastus.azurecontainerapps.io',
//   // WebSocket - SignalR - Does not work via Front Door
//   apiSignalRSocketApiEndPoint: 'https://fn-notesapi-2.mangoforest-769e62fe.eastus.azurecontainerapps.io',
//   // Scope - Copied from the 'Expose an API' section for the Notes API App Registration
//   scopes: 'https://osintfn.onmicrosoft.com/92d1949b-1841-4498-b118-fd931dc5f5ca/access_as_user',
// };

<div class="sub-content4">
  <h1>Templates</h1>
  <a class="template-faq" href="{{FAQ}}" target="_blank">
    <img src="../../../../assets/images/FAQ.svg">
    <span class="tooltip">FAQ's Template </span>
  </a>
  <div class="single-team">
    <button ejs-button class="single-user" id="single-temp" (click)="singleuser()">Personal<span class="tooltip">View
        Personal Templates</span></button>
    <button ejs-button *ngIf="isGroupAdmin" class="team-user" id="temp-temp" (click)="teamuser()">Team<span
        class="tooltip">View Team Templates</span></button>
    <!-- <a href="javascript:void(0)" id="single-temp" class="single-user" (click)="singleuser()">Personal</a>
    <a *ngIf="isGroupAdmin" href="javascript:void(0)" id="temp-temp" class="team-user" (click)="teamuser()">Team</a> -->
  </div>
  <div class="template-content">
    <ul class="template-list">
      <li *ngFor="let PersonalTemplate of allPersonalTemplates">
        <a id="edit-temp_{{PersonalTemplate.templateId}}">
          <img *ngIf="PersonalTemplate.nodeType=='25'" style="height: inherit;" src="../../../../assets/images/note.png">
          <img *ngIf="PersonalTemplate.nodeType=='0'" style="height: inherit;" src="../../../../assets/images/Folder.png">
          <img *ngIf="PersonalTemplate.nodeType=='20'" style="height: inherit;" src="../../../../assets/images/Notebook.png">
          <span
            (click)="previewTempPopup($event,PersonalTemplate.templateId,PersonalTemplate.title)">{{PersonalTemplate.title}}<span
              class="tooltip">View Template Design</span></span>
        </a>
        <span>
          <a class="edit">
            <i class="fas fa-pencil-alt" (click)="editTempPopup($event,PersonalTemplate.templateId)"><span
                class="tooltip">Edit Template Name</span></i>
            <div class="show-edit">
              <div class="edit-div" id="rename-temp_{{PersonalTemplate.templateId}}">
                <ejs-textbox #tempName placeholder="Template Name" value="{{PersonalTemplate.title}}"
                  cssClass="e-outline" floatLabelType="Auto"></ejs-textbox>
                <button ejs-button (click)="closeTempEdit(PersonalTemplate.templateId)"><i
                    class="fas fa-times"></i></button>
                <button ejs-button (click)="renameTemplate(PersonalTemplate.templateId , tempName.value)"><i
                    class="fas fa-check"></i></button>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="personal" (click)="moveToTeam($event,PersonalTemplate.templateId)">
            <i class="fas fa-users"><span class="tooltip">Convert Personal Template To Team Template</span></i>
          </a>

          <a href="javascript:void(0)" class="show"
            (click)="previewTempPopup($event,PersonalTemplate.templateId,PersonalTemplate.title)">
            <i class="fas fa-eye"><span class="tooltip">Rename Notebook</span></i>
          </a>
          <a href="javascript:void(0)" class="delete" (click)="deleteTempPopup($event,PersonalTemplate.templateId)">
            <i class="far fa-times-circle"><span class="tooltip">Delete Template</span></i>
          </a>
        </span>
      </li>
    </ul>
  </div>
  <div class="template-content2">
    <ul class="template-list">
      <li *ngFor="let TeamTemplate of allTeamTemplates">
        <a id="edit-temp_{{TeamTemplate.templateId}}">
          <img *ngIf="TeamTemplate.nodeType=='25'" style="height: inherit;" src="../../../../assets/images/note.png">
          <img *ngIf="TeamTemplate.nodeType=='0'" style="height: inherit;" src="../../../../assets/images/Folder.png">
          <img *ngIf="TeamTemplate.nodeType=='20'" style="height: inherit;" src="../../../../assets/images/Notebook.png">
          <span
            (click)="previewTempPopup($event,TeamTemplate.templateId,TeamTemplate.title)">{{TeamTemplate.title}}<span
              class="tooltip">View Template Design</span></span>
        </a>
        <span class=tabs>
          <a class="edit">
            <i class="fas fa-pencil-alt" (click)="editTempPopup($event,TeamTemplate.templateId)"><span
                class="tooltip">Edit Template Name</span></i>
            <div class="show-edit">
              <div class="edit-div" id="rename-temp_{{TeamTemplate.templateId}}">
                <ejs-textbox #tempName placeholder="Template Name" value="{{TeamTemplate.title}}" cssClass="e-outline"
                  floatLabelType="Auto"></ejs-textbox>
                <button ejs-button (click)="closeTempEdit(TeamTemplate.templateId)"><i
                    class="fas fa-times"></i></button>
                <button ejs-button (click)="renameTemplate(TeamTemplate.templateId , tempName.value)"><i
                    class="fas fa-check"></i></button>
              </div>
            </div>
          </a>
          <a href="javascript:void(0)" class="show"
            (click)="previewTempPopup($event,TeamTemplate.templateId,TeamTemplate.title)">
            <i class="fas fa-eye"><span class="tooltip">View Template Design</span></i>
          </a>
          <a href="javascript:void(0)" class="delete" (click)="deleteTempPopup($event,TeamTemplate.templateId)">
            <i class="far fa-times-circle"><span class="tooltip">Delete Template</span></i>
          </a>
        </span>
      </li>
    </ul>
  </div>
</div>
<!-- ,PersonalTemplate.templateId,PersonalTemplate.nodeType,PersonalTemplate.subType,PersonalTemplate.title -->

<div #containerMoveToTeam></div>
<ejs-dialog id='dialog' #ejDialogMoveToTeam header='Dialog' showCloseIcon='true'
  header='Are you sure you want to convert this template to Team Template?' isModal='true'
  (overlayClick)="onOverlayClickMoveToTeam()" [target]='targetElementMoveToTeam' width='700px' [visible]='false'>
  <div style="text-align: center;">
    <button ejs-button (click)="CloseMoveToTeam()">No</button>
    <button ejs-button (click)="YesMoveToTeam()">Yes</button>
  </div>
</ejs-dialog>
<div #containerTempPreview></div>
<ejs-dialog id='PreviewDialog' #ejDialogTempPreview isModal='true' (overlayClick)="onOverlayClickTempPreview()"
  [target]='targetElementTempPreview' width='250px' [visible]='false'>
  <div class="pop-close"><span>{{TemplateName}}</span><a (click)="closePrePopup()"><i class="fas fa-times"></i></a>
  </div>
  <div [innerHTML]=content>

  </div>
</ejs-dialog>
<div #containerTempDelete></div>
<ejs-dialog id='DeletDialog' #ejDialogTempDelete header='Dialog' showCloseIcon='true'
  header='Are you sure you want to delete this Template?' isModal='true' (overlayClick)="onOverlayClickTempDelete()"
  [target]='targetElementTempDelete' width='700px' [visible]='false'>
  <div style="text-align: center;">
    <button ejs-button (click)="NoDelete()">No</button>
    <button ejs-button (click)="YesDelete()">Yes</button>
  </div>
</ejs-dialog>

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { BrandingModel } from "../domain/Branding";
import { UpdateBrandModel } from "../domain/UpdateBrand";
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { protectedResources } from '../auth-config';
import URLUtils from "../shared/Utility/urlUtils";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient
  ) { }

  url = protectedResources.apiBaseUrl.endpoint;

  // ===========================================================================
  //
  // ===========================================================================
  DownloadNote(noteID: string, filetype: string, password: string, packageType: string, userID: string): Observable<any> {

    let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

    let fullURL: string = this.url + "/api/Notes/" + noteID + "/DownloadForensicNote";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get(fullURL
      , {
        responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }), params: {
          "password": password,
          "packageType": packageType
        }, withCredentials: true
      }
    );

  }

  // // ===========================================================================
  // // DEPRECATED - OLD
  // // ===========================================================================
  // DownloadNotebook(notebookID: string, filetype: string, password: string, packageType: string): Observable<any> {

  //   console.log("filetype", filetype);
  //   console.log("password", password);
  //   console.log("packageType", packageType);

  //   let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

  //   let fullURL: string = this.url + "/api/Notebooks/" + notebookID + "/DownloadForensicNotebook";
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   return this.http.get(fullURL
  //     , {
  //       responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }),
  //       params: {
  //         "password": password,
  //         "packageType": packageType
  //       }, withCredentials: true
  //     }
  //   );

  // }

  // ===========================================================================
  //
  // ===========================================================================
  GenerateDownloadNotebook(notebookID: string, filetype: string, password: string, packageType: string, userID: string): Observable<any> {

    console.log("filetype", filetype);
    console.log("password", password);
    console.log("packageType", packageType);

    let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

    let fullURL: string = this.url + "/api/Notebooks/" + notebookID + "/RequestForensicNotebook";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get(fullURL
      , {
        responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }),
        params: {
          "password": password,
          "packageType": packageType
        }, withCredentials: true
      }
    );

  }


//   async DownloadNotebook(generationID: string, filetype: string): Promise<ArrayBuffer> {
//     const httpPostOptions =
//     {
//       withCredentials: true
//     }

//     let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

//     let fullURL: string = this.url + "/api/Notebooks/DownloadNotebook/" + generationID;
//     // fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

//     console.log("filetype", filetype);
//     // return this.http.get<any>(fullURL, httpPostOptions);

//     return await this.http.post(fullURL, httpPostOptions, {
//       responseType: "arraybuffer",
//     }).pipe(map((file: ArrayBuffer) => {
//       return file;
//     })).toPromise
//   }




// }

  // DownloadNotebook(generationID: string, filetype: string){

  //   let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

  //   let fullURL: string = this.url + "/api/Notebooks/DownloadNotebook/" + generationID;
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   console.log("filetype", filetype);


  //   return this.http.get(fullURL
  //     , {
  //       responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }),
  //       withCredentials: true
  //     }
  //   );
  // }

  DownloadNotebook(generationID: string, filetype: string, userID: string){

    let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

    let fullURL: string = this.url + "/api/Notebooks/DownloadNotebook/" + generationID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    console.log("filetype", filetype);


    return this.http.get(fullURL
      , {
        responseType: 'blob', headers: new HttpHeaders({ 'Content-Type': filetype }),
        withCredentials: true
      }
    );
  }



  // ===========================================================================
  //
  // ===========================================================================
  DownloadAttachment(fileID: string, userID: string): Observable<any> {


    let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

    let fullURL: string = this.url + "/api/Attachments/DownloadAttachment/" + fileID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.http.get(fullURL
      , {
        responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
        withCredentials: true
      }
    );

  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsRoutingModule } from './terms-routing.module';
import { TermsComponent } from './terms.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { BaseDialogModule } from 'src/app/shared/base/dialog/dialog.module';


@NgModule({
  declarations: [
    TermsComponent
  ],
  imports: [
    CommonModule,
    TermsRoutingModule,
    BaseDialogModule,
    DialogModule
  ],
  exports: [
    TermsComponent
  ]
})
export class TermsModule { }

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { BrandingModel } from "../domain/Branding";
import { UpdateBrandModel } from "../domain/UpdateBrand";
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { protectedResources } from '../auth-config';
import URLUtils from "../shared/Utility/urlUtils";


@Injectable({
  providedIn: "root",
})

export class BrandingService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient
  ) { }

  url = protectedResources.apiBaseUrl.endpoint;

  // ===========================================================================
  //
  // ===========================================================================
  GetBrandingDetails(enableServiceWorkerCache: boolean, userID: string): Observable<BrandingModel> {

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/AccountAdmin/GetOrganizationInfo";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<BrandingModel>(fullURL, httpsOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  UpdateBrandingDetails(data: UpdateBrandModel): Observable<UpdateBrandModel> {

    console.log("UpdateBrandingDetails", data);

    const httpsOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    };
    return this.httpClient.patch<UpdateBrandModel>(
      this.url + "/api/AccountAdmin/UpdateOrganizationInfo",
      JSON.stringify({
        organizationName: data.organizationName,
        logoPath: data.logoPath,
        bannerPath: data.bannerPath,
        notebookDisclaimer: data.notebookDisclaimer,
      }),
      httpsOptions
    );
  }

  // ===========================================================================
  //
  // ===========================================================================
  SaveBrandLogo(data: UpdateBrandModel): Observable<UpdateBrandModel> {
    const httpsOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json ",
      }),
      withCredentials: true,
    };
    const brangupdate = new FormData();
    brangupdate.append("organizationName", data.organizationName);
    brangupdate.append("logoPath", data.logoPath);
    brangupdate.append("bannerPath", data.bannerPath);
    brangupdate.append("notebookDisclaimer", data.notebookDisclaimer);
    return this.httpClient.patch<UpdateBrandModel>(
      this.url + "/api/AccountAdmin/SaveLogo",
      brangupdate,
      httpsOptions
    );
  }

  // ===========================================================================
  //
  // ===========================================================================
  SaveBrandBanner(data: UpdateBrandModel): Observable<UpdateBrandModel> {

    const httpsOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json ",
      }),
      withCredentials: true,
    };

    const brangupdate = new FormData();
    brangupdate.append("organizationName", data.organizationName);
    brangupdate.append("logoPath", data.logoPath);
    brangupdate.append("bannerPath", data.bannerPath);
    brangupdate.append("notebookDisclaimer", data.notebookDisclaimer);
    return this.httpClient.patch<UpdateBrandModel>(
      this.url + "/api/AccountAdmin/SaveBanner",
      brangupdate,
      httpsOptions
    );
  }


  // // ===========================================================================
  // //
  // // ===========================================================================
  // DownloadNote(noteID: string, filetype: string, password: string, packageType: string): Observable<any> {

  //   let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

  //   let fullURL: string = this.url + "/api/Notes/" + noteID + "/DownloadForensicNote";
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   return this.http.get(fullURL
  //     , {
  //       responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }), params: {
  //         "password": password,
  //         "packageType": packageType
  //       }, withCredentials: true
  //     }
  //   );

  // }

  // // ===========================================================================
  // //
  // // ===========================================================================
  // DownloadNotebook(notebookID: string, filetype: string, password: string, packageType: string): Observable<any> {

  //   console.log("filetype", filetype);
  //   console.log("password", password);
  //   console.log("packageType", packageType);

  //   let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

  //   let fullURL: string = this.url + "/api/Notebooks/" + notebookID + "/DownloadForensicNotebook";
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   return this.http.get(fullURL
  //     , {
  //       responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': filetype }),
  //       params: {
  //         "password": password,
  //         "packageType": packageType
  //       }, withCredentials: true
  //     }
  //   );

  // }

  // // ===========================================================================
  // //
  // // ===========================================================================
  // DownloadAttachment(fileID: string): Observable<any> {


  //   let enableServiceWorkerCache: boolean = true;  // NEVER Save PDF to CACHE - No Reason

  //   let fullURL: string = this.url + "/api/Attachments/DownloadAttachment/" + fileID;
  //   fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

  //   return this.http.get(fullURL
  //     , {
  //       responseType: 'arraybuffer', headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
  //       withCredentials: true
  //     }
  //   );

  // }


}

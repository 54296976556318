<ejs-dialog showCloseIcon='true' header='Time Zone' (beforeClose)="onDialogClose($event)" width="450"
    [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
    [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
    <ng-template #content>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">
            <!-- Content -->
            <p class="matLabelWithNoOutline">Select your Time Zone...</p>
            <select #timeZone id="time" class="inputEffect">
                <option *ngFor="let alltimeZone of alltimeZones; let i = index"
                    [selected]="alltimeZone.timeZoneId == this.TimeZone" [value]="alltimeZones[i].timeZoneId">
                    {{alltimeZones[i].display}}
                </option>
            </select>
            <div class="buttonSectionOneCol">
                <button class="success" id="btnSubmit" #btnSubmit (click)="SaveTimeZone(timeZone.value)">Save</button>
            </div>
            <!-- (end of) Content-->
        </div>
    </ng-template>
</ejs-dialog>
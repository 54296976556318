import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementBinTypeAddRoutingModule } from './case-management-bin-type-add-routing.module';
import { CaseManagementBinTypeAddComponent } from './case-management-bin-type-add.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CaseManagementBinTypeAddComponent
  ],
  imports: [
    CommonModule,
    CaseManagementBinTypeAddRoutingModule,
    DialogModule,
    SharedModule,
  ],
  exports:[
    CaseManagementBinTypeAddComponent
  ]
})
export class CaseManagementBinTypeAddModule { }

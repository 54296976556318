import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementOrgTeamRoutingModule } from './case-management-org-team-routing.module';
import { CaseManagementOrgTeamComponent } from './case-management-org-team.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementOrgTeamAddModule } from '../case-management-org-team-add/case-management-org-team-add.module';



@NgModule({
  declarations: [
    CaseManagementOrgTeamComponent
  ],
  imports: [
    CommonModule,
    CaseManagementOrgTeamRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementOrgTeamAddModule
  ],
  exports: [
    CaseManagementOrgTeamComponent
  ]
})
export class CaseManagementOrgTeamModule { }

<p>Enter in the list of team members below.</p>
<div>
  <button (click)="addInvitee()" ejs-button cssClass="e-success" type="button" class="add-button">Add</button>
  <form [formGroup]="form" (submit)="submit(form)">
    <div formArrayName="invitee">
      <div *ngFor="let address of form.get('invitee')['controls']; let i=index" [formGroupName]="i">
        <!--here the fields of the array-->
        <div class="step-form form-input">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" placeholder="Email" autocomplete="email">
          </mat-form-field>
        </div>
        <div class="step-form form-input">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Name" autocomplete="name">
          </mat-form-field>
        </div>
        <div class="step-from form-icon">
          <button class="float-left" mat-icon-button color="primary" aria-label="Remove/clear"
            (click)="removeOrClearEmail(i)" matTooltip="Remove" *ngIf="i != 0">
            <mat-icon>highlight_off</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-comment">
      <mat-form-field class="example-full-width">
        <mat-label>Message</mat-label>
        <textarea matInput placeholder="Enter in a message to the invitees."></textarea>
      </mat-form-field>
    </div>

    <div class="action text-center">
      <button ejs-button cssClass="e-success" type="submit" class="invite-button">Invite Members</button>
    </div>
  </form>

</div>

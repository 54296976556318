import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { IncidentTypeModel } from "../domain/IncidentType";
import { v4 as uuidv4 } from 'uuid';
import { IncidentModel } from "../domain/Incident";
import { SuccessModel } from "../domain/Success";
import { ClientModel } from "../shared/customObjects/clientModel";
import { ClientContactModel } from "../shared/customObjects/clientContactModel";
import { InternalContactModel } from "../shared/customObjects/internalContactModel";
import { AdminCmsSettingsModel } from "../shared/customObjects/adminCmsSettingsModel";
import { protectedResources } from '../auth-config';
import URLUtils from "../shared/Utility/urlUtils";



@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  // ==================================================================
  // 
  // ==================================================================
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient
  ) {
    // constructor
  }

  url = protectedResources.organizationApi.endpoint;

  // url = this.config.apiOrganizationUrl;



  // ==================================================================
  // 
  // ==================================================================
  GetAdminCmsSettings(): Observable<any> {

    console.log("GetAdminCmsSettings()");

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.get<any>(
      this.url + "/api/Admin",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateAdminCMSSettings(adminCmsSettingsModel: AdminCmsSettingsModel): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body = {
      "fileNumberOption": adminCmsSettingsModel.fileNumberOption,
      "fileNumberStartingValue": adminCmsSettingsModel.fileNumberStartingValue,
      "digitalForensicsActivated": adminCmsSettingsModel.digitalForensicsCmsActivated,
      "videoForensicsActivated": adminCmsSettingsModel.videoForensicsCmsActivated,
      "privateInvestigatorActivated": adminCmsSettingsModel.privateInvesgigatorsCmsActivated,
      "cyberActivated": adminCmsSettingsModel.cyberCmsActivated,
      "osInActivated": adminCmsSettingsModel.osintCmsActivated,
      "workPlaceInvestigationsActivated": adminCmsSettingsModel.workplaceInvestigationsCmsActivated,
      "level": adminCmsSettingsModel.cmsType
    }

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Admin",
      body,
      httpsOptions
    );

  }





  // ==================================================================
  // 
  // ==================================================================
  GetClients(enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Client";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  GetClientByID(clientID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };


    let fullURL: string = this.url + "/api/Client/" + clientID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateClient(client: ClientModel): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };


    const body =
    {
      "id": client.id,
      "clientName": client.clientName,
      "suite": client.suite,
      "streetAddress": client.streetAddress,
      "city": client.city,
      "state": client.state,
      "country": client.country,
      "zip": client.zip,
      "phone": client.phone,
      "email": client.email,
      "websiteUrl": client.websiteUrl,
      "notes": client.notes,
      "googleURL": client.googleURL
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Client",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteClient(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Client/" + id,
      httpsOptions
    );
  }



  // ==================================================================
  // 
  // ==================================================================
  GetAllClientContacts(orginizationUnitId: string, enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {

    // const formData = new FormData();
    // formData.append('orginizationUnitId', orginizationUnitId);

    const httpsOptions = {
      // headers: new HttpHeaders({accept: "application/json "}),
      withCredentials: true,
    };

    console.log("API Call", this.url + "/api/ClientContact" +
      "?organizationUnitId=" + orginizationUnitId + "");

    let fullURL: string = this.url + "/api/ClientContact" + "?organizationUnitId=" + orginizationUnitId;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  GetClientContact(clientContactID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    console.log("clientContactID", clientContactID);

    let fullURL: string = this.url + "/api/ClientContact/" + clientContactID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.httpClient.get<any>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateClientContact(client: ClientContactModel): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    // const body = JSON.stringify(client);

    const body =
    {
      "id": client.id,
      "organizationUnitId": client.organizationUnitId,
      "firstName": client.firstName,
      "lastName": client.lastName,
      "email": client.email,
      "phones": [
        {
          "phoneNumber": client.cellPhone.phoneNumber,
          "phoneType": "Cell"
        },
        {
          "phoneNumber": client.workPhone.phoneNumber,
          "phoneType": "Work"
        }
      ]
    }

    console.log("body", body);
    console.log("body", body.toString());


    return this.httpClient.put<any>(
      this.url + "/api/ClientContact",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete 
  // ==================================================================
  DeleteClientContact(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/ClientContact/" + id,
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetAllInternalClientContacts(enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/InternalContact";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  GetInternalClientContacts(clientID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    console.log("clientID", clientID);


    let fullURL: string = this.url + "/api/InternalContact/" + clientID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateInternalClientContact(client: InternalContactModel): Observable<SuccessModel> {

    console.log("AddUpdateInternalClientContact", client)

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    // const body = JSON.stringify(client);

    const body =
    {
      "id": client.id,
      "organizationUnitId": client.organizationUnitId,
      "pinNumber": client.pinNumber,
      "firstName": client.firstName,
      "lastName": client.lastName,
      "email": client.email,
      "phones": [
        {
          "phoneNumber": client.phones[0].phoneNumber,
          "phoneType": "Cell"
        },
        {
          "phoneNumber": client.phones[1].phoneNumber,
          "phoneType": "Work"
        }
      ]
    };

    console.log("body - client", body);

    return this.httpClient.put<any>(
      this.url + "/api/InternalContact",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteInternalClientContact(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/InternalContact/" + id,
      httpsOptions
    );
  }



  // ==================================================================
  // 
  // ==================================================================
  GetOrgTeams(enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };


    let fullURL: string = this.url + "/api/DepartmentalUnit";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateOrgTeam(id: string, unitName: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "unitName": unitName
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/DepartmentalUnit",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Org Unit 
  // ==================================================================
  DeleteOrgTeam(orgID): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/DepartmentalUnit/" + orgID,
      httpsOptions
    );
  }






}

import { Inject, Injectable } from '@angular/core';
import { UserModel } from '../shared/customObjects/userModel';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { PGPEncryptionService } from './pgpencryption.service';
import { v4 as newGUID } from 'uuid';
export type PasswordType = 'Password' | 'PIN';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  // -----------------------------------------------------------------------------
  // UserProfileInitGuardGuard requires the following to ensure profile loaded
  // -----------------------------------------------------------------------------
  private _isInitialized = new BehaviorSubject<boolean>(false);
  public isInitialized = this._isInitialized.asObservable();

  public user: UserModel = new UserModel();

  public showNewAccountSetup: boolean = false;

  constructor(@Inject(LOCAL_STORAGE) private storage: Storage, private pgpEncryptionService: PGPEncryptionService) {
    console.log('UserProfileService --> Constructor()');
  }

  setUserProfile(user: any) {
    this.user.LoadFromApiResult(user);
    // --------------------------------------------------------------------------
    // Profile Loaded - Update Behavior Subject so that
    // individual pages that are protected by the UserProfileInitGuardGuard
    // can load
    // --------------------------------------------------------------------------
    this._isInitialized.next(true);
  }

  cacheUserProfile(KEK: string, type: PasswordType) {
    const contentEncryptionKey: string = newGUID();
    const encryptedCEK: string = this.pgpEncryptionService.encryptData(contentEncryptionKey, KEK);
    const encryptedDataJSON: string = this.pgpEncryptionService.encryptData(JSON.stringify(this.user), contentEncryptionKey);
    this.storage.setItem('userCached', JSON.stringify({ encryptedCEK, encryptedDataJSON, type }));
    return this.user.clientEncryptionKEK;
  }

  loadUserProfileFromCache(KEK: string) {
    try {
      const userCached = this.storage.getItem('userCached');
      const { encryptedCEK, encryptedDataJSON } = JSON.parse(userCached);

      const decryptedCEK: string = this.pgpEncryptionService.decryptData(encryptedCEK, KEK);
      const decryptedJSON: string = this.pgpEncryptionService.decryptData(encryptedDataJSON, decryptedCEK);
      const user = JSON.parse(decryptedJSON);
      return user;
    } catch (error) {
      return null;
    }
  }

  getPasswordType(): PasswordType | null {
    const userCached = this.storage.getItem('userCached');
    if(!userCached) {
      return null;
    }
    const { type } = JSON.parse(userCached);
    return type;
  }

  isCachedUser() {
    return this.storage.getItem('userCached');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmsStructureRoutingModule } from './cms-structure-routing.module';
import { CmsStructureComponent } from './cms-structure.component';
import { BaseDialogModule } from 'src/app/shared/base/dialog/dialog.module';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    CmsStructureComponent
  ],
  imports: [
    CommonModule,
    CmsStructureRoutingModule,
    BaseDialogModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

  ],
  exports: [
    CmsStructureComponent
  ]
})
export class CmsStructureModule { }

import { Injectable, ViewContainerRef } from '@angular/core';


export enum LazyLoadableComponents {
  UIExhibitComponent,
  DrawingPadComponent,
  UIClientComponent,
  NoteImagePreviewComponent,
  NotebookRenameComponent,
  LocationAndWeatherComponent,
  AiGenerateReportComponent,
  AiRecordAudioComponent,
  AiAnalyzeReportComponent,
  EditnotepopupComponent,
  MarkAsDeleteComponent,
  DeleteNotebookComponent,
  DownloadComponent,
  BookmarkComponent,
  PersonEntityV2Component,
  AdvancedComponent,
  AddQuickAttachmentComponent,
  NoteTemplatesComponent,
  UIProjectComponent,
  UIIncidentComponent,
  UIWorkRequestComponent,
  AuditComponent,
  DownloadConfirmationDialogComponent,
  LockScreenComponent,
  PreviousNoteVersionsComponent,
  SendFileRequestComponent,

  // Other components you want to lazy load
}



@Injectable({
  providedIn: 'root'
})


export class LazyLoadService {
  private componentRefs: any[] = [];


  constructor(

  ) { }


  // ===============================================================================
  // Place ALL Components I want to dynamically create in here...
  // and in the ENUM above
  // ===============================================================================
  readonly componentTypeMapping = {
    [LazyLoadableComponents.UIExhibitComponent]: () => import('../cms/projectDisplayBlocks/ui-exhibit/ui-exhibit.component'),
    [LazyLoadableComponents.DrawingPadComponent]: () => import('../drawing-pad/drawing-pad.component'),
    [LazyLoadableComponents.UIClientComponent]: () => import('../cms/projectDisplayBlocks/ui-client/ui-client.component'),
    [LazyLoadableComponents.NoteImagePreviewComponent]: () => import('../editnote/note-image-preview/note-image-preview.component'),
    [LazyLoadableComponents.NotebookRenameComponent]: () => import('../notebook-rename/notebook-rename.component'),
    [LazyLoadableComponents.LocationAndWeatherComponent]: () => import('../locationAndWeather/locationAndWeather.component'),
    [LazyLoadableComponents.AiGenerateReportComponent]: () => import('../ai/ai-generate-report/ai-generate-report.component'),
    [LazyLoadableComponents.AiRecordAudioComponent]: () => import('../ai/ai-record-audio/ai-record-audio.component'),
    [LazyLoadableComponents.AiAnalyzeReportComponent]: () => import('../ai/ai-analyze-report/ai-analyze-report.component'),
    [LazyLoadableComponents.EditnotepopupComponent]: () => import('../editnotepopup/editnotepopup.component'),
    [LazyLoadableComponents.MarkAsDeleteComponent]: () => import('../markasdelete/markasdelete.component'),
    [LazyLoadableComponents.DeleteNotebookComponent]: () => import('../delete-notebook/delete-notebook.component'),
    [LazyLoadableComponents.DownloadComponent]: () => import('../download/download.component'),
    [LazyLoadableComponents.BookmarkComponent]: () => import('../bookmark/bookmark.component'),
    [LazyLoadableComponents.PersonEntityV2Component]: () => import('../components/person-entity-v2/person-entity-v2.component'),
    [LazyLoadableComponents.AdvancedComponent]: () => import('../editnote/advanced/advanced.component'),
    [LazyLoadableComponents.AddQuickAttachmentComponent]: () => import('../notebook-cards/add-quick-attachment/add-quick-attachment.component'),
    [LazyLoadableComponents.NoteTemplatesComponent]: () => import('../editnote/note-templates/note-templates.component'),
    [LazyLoadableComponents.UIProjectComponent]: () => import('../cms/projectDisplayBlocks/ui-project/ui-project.component'),
    [LazyLoadableComponents.UIIncidentComponent]: () => import('../cms/projectDisplayBlocks/ui-incident/ui-incident.component'),
    [LazyLoadableComponents.UIWorkRequestComponent]: () => import('../cms/projectDisplayBlocks/ui-work-request/ui-work-request.component'),
    [LazyLoadableComponents.AuditComponent]: () => import('../editnote/audit/audit.component'),
    [LazyLoadableComponents.DownloadConfirmationDialogComponent]: () => import('../download/download-confirmation-dialog/download-confirmation-dialog.component'),
    [LazyLoadableComponents.LockScreenComponent]: () => import('../lock-screen/lock-screen.component'),
    [LazyLoadableComponents.PreviousNoteVersionsComponent]: () => import('../editnote/previous-note-versions/previous-note-versions.component'),
    [LazyLoadableComponents.SendFileRequestComponent]: () => import('../editnote/send-file-request/send-file-request.component'),


    

    // Add other mappings for other components
  };


  // ===============================================================================
  // Gets the Component (Lazy Loaded)
  //
  // NOTE: If you have multiple variations of the same component on a page that
  // you want to show at the same time (eg: AI Analysis), then set the instanceNum
  // to different values which will allow you to create multiple versions of the
  // component.
  // ===============================================================================
  async getLazyLoadComponentInstance(componentType: LazyLoadableComponents, container: ViewContainerRef, instanceNum: number = 0) {

    console.log("LazyLoadService --> getLazyLoadComponentInstance", componentType, container);
    const importComponent = this.componentTypeMapping[componentType];
    if (!importComponent) {
      throw new Error("Unsupported component type");
    }

    const componentModule = await importComponent();
    const componentClass = componentModule[Object.keys(componentModule)[0]]; // Get the first exported class from the module
    const existingComponent = this.componentRefs.find(c => c.instance instanceof componentClass && c.instanceNum === instanceNum);

    if (existingComponent) {
      // --------------
      // Existing
      // --------------
      console.log("LazyLoadService --> getLazyLoadComponentInstance() --> Getting Existing Component", existingComponent.instance);
      return existingComponent.instance;
    } else {
      // --------------
      // New
      // --------------
      console.log("getLazyLoadComponentInstance() --> CREATING New Component");
      const componentRef = container.createComponent(componentClass);
      this.componentRefs.push({
        instance: componentRef.instance,
        instanceNum: instanceNum // Store the instance number
      });

      console.log("LazyLoadService --> getLazyLoadComponentInstance() --> Returning New", componentRef.instance)

      return componentRef.instance;
    }
  }


  // ===================================================================
  // Hides the specified component
  // ASSUMPTION: that the component has a hide() method
  // ===================================================================
  async hideLazyLoadComponentInstanceIfExists(componentType: LazyLoadableComponents) {
    console.log("hideLazyLoadComponentInstance", componentType);
    // const componentInstance = await this.getLazyLoadComponentInstance(componentType, null) ;

    const importComponent = this.componentTypeMapping[componentType];
    if (!importComponent) {
      throw new Error("Unsupported component type");
    }

    const componentModule = await importComponent();
    const componentClass = componentModule[Object.keys(componentModule)[0]]; // Get the first exported class from the module
    const existingComponent = this.componentRefs.find(c => c.instance instanceof componentClass);

    if (existingComponent) {
      // ------------------------
      // Hide the component
      // ------------------------
      existingComponent.instance.hide();
    }
    else {
      // -----------------------------------------
      // Not created, so no need to hide
      // -----------------------------------------
      return;
    }



  }


  // ===============================================================================
  // Be sure to add the following into the pages ngOnDestroy()
  //
  // ngOnDestroy() {
  // ...
  // this.lazyLoadService.destroy();
  // ...
  // }
  //
  // NOTE: If getting errors in console for a specific component, make sure
  // it has the destroy() method in the component.
  //
  //   destroy(){
  //    // ------------------------------------------------------
  //    // Clean up code for this component if needed.
  //    // Added to avoid error in LazyLoad destroy method
  //    // ------------------------------------------------------
  //   }
  //
  // ===============================================================================
  destroy() {
    console.log("LazyLoadService --> destroy()");
    this.componentRefs.forEach(componentRef => {
      if (componentRef && !componentRef.destroyed) {
        try {
          componentRef.instance.destroy();
        }
        catch (ex) {
          // -------------------------------------------------------------------------------------
          // NOTE: NOT using console.error() as it is expected that this WILL
          // generate exceptions as most components do not have the destroy()
          // method as not needed in most circumstances. But log left for future testing.
          // -------------------------------------------------------------------------------------
          console.log("Component Destroy() Exception = ", componentRef, ex);
        }
      }
    });
    this.componentRefs = [];
  }


}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TforensicUserProfile } from '../domain/tforensicUserProfile';
import { Tforensic } from '../domain/tforensic';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { UpdateUserProfileModel } from '../domain/UpdateProfile';
import { protectedResources } from '../auth-config';
import { UserModel } from '../shared/customObjects/userModel';
import URLUtils from '../shared/Utility/urlUtils';


@Injectable({
  providedIn: 'root'
})
export class ForensicUserProfileService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {
    // constructor
  }

  url = protectedResources.apiBaseUrl.endpoint;

  getUserProfile(enableServiceWorkerCache: boolean, userID: string): Observable<TforensicUserProfile> {
    const httpOptions = {

      withCredentials: true
    };

    let fullURL: string = this.url + '/api/UserProfile';
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    console.log("getUserProfile()-url", fullURL);

    return this.http.get<TforensicUserProfile>(fullURL, httpOptions)

  }

 
  updateUserProfile(forensicUserProfile: UserModel): Observable<Tforensic> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json-patch+json ' }),
      withCredentials: true
    };
    console.log("url", this.url);

    return this.http.patch<Tforensic>(this.url + '/api/UserProfile',
      JSON.stringify({
        'legalName': forensicUserProfile.legalName,
        'displayName': forensicUserProfile.displayName,
        'usersEmail': forensicUserProfile.usersEmail,
        'timeZone': forensicUserProfile.timeZone,
        'agreeToTerms': forensicUserProfile.agreeToTerms,
        // 'passwordToProtectDownloadedFiles': forensicUserProfile.passwordToProtectDownloadedFiles,  // deprecated - no longer in use
        'accountPurchaseStatus': forensicUserProfile.purchaseStatus,
        'loginMessage': forensicUserProfile.loginMessage,
        'profileImage': forensicUserProfile.profileImage,
        'preferredLanguageISO639': forensicUserProfile.preferredLanguageISO639
      }), httpOptions);
  }


  setTimeZone(timeZone: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json-patch+json ' }),
      withCredentials: true
    };
    console.log("url", this.url);

    return this.http.patch<Tforensic>(this.url + '/api/UserProfile',
      JSON.stringify({
        'timeZone': timeZone,
      }), httpOptions);
  }

  agreeToTermsAndConditions() {

    const httpPostOptions = {
      withCredentials: true,
    };

    return this.http.post(
      this.url + '/api/UserProfile/AgreeToTermsAndConditions',
      httpPostOptions
    );


  }


  updateUserProfileImage(base64ProfileImage: string): Observable<Tforensic> {

    const httpPostOptions = {
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append("base64Image", base64ProfileImage);

    console.log("url", this.url);


    return this.http.patch<Tforensic>(
      this.url + '/api/UserProfile/UpdateProfileImage',
      formData,
      httpPostOptions
    );
  }

  updatePassword(password: string) {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("password", password);

    return this.http.post(
      this.url + '/api/UserManagement/UpdatePassword?password=' + password,
      formData,
      httpPostOptions
    );
  }


  UpdateFileDownloadPassword(password: string) {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("password", password);

    console.log("url", this.url);


    return this.http.post(
      this.url + '/api/UserProfile/UpdateFileDownloadPassword',
      formData,
      httpPostOptions
    );
  }




}


import { HttpErrorResponse } from '@angular/common/http';
// import { createDirectiveDefinitionMap } from '@angular/compiler/src/render3/partial/directive';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {  SortService, ResizeService, GroupService, ColumnMenuService, PageService, FilterService, ToolbarItems, EditService, ToolbarService, IEditCell, ExcelQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs, ExcelExportService, PdfExportService, GridComponent  } from '@syncfusion/ej2-angular-grids';
import { ContextMenuItem, GroupSettingsModel, FilterSettingsModel, EditSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentModel } from 'src/app/domain/Incident';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { EmitType} from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CaseManagementWorkRequestDocumentTypeAddComponent } from '../case-management-work-request-document-type-add/case-management-work-request-document-type-add.component';
import { CaseManagementWorkRequestStatusAddComponent } from '../case-management-work-request-status-add/case-management-work-request-status-add.component';



@Component({
  selector: 'app-case-management-work-request-status',
  templateUrl: './case-management-work-request-status.component.html',
  styleUrls: ['./case-management-work-request-status.component.scss'],
  providers: [SortService, GroupService, ColumnMenuService, PageService, EditService, ToolbarService,  ExcelExportService, PdfExportService]

})
export class CaseManagementWorkRequestStatusComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = true;
  public isModal : boolean = true;
  public showDeleteSpinner : boolean = false;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };

  

  // DataGrid Settings
  public pageSettings: Object;
  public customAttributes: Object;
  public resizeSettings = { mode: "Normal" };


  records : any[];

  // --------------------------------------------------------------------
  // Used to store full single record for menu clicks
  // and show display info
  // --------------------------------------------------------------------
  currRecordLabel : string = "";
  currRecID : string = "";
  

  @ViewChild('grid')
  public grid: GridComponent;
  
  @ViewChild("componentAdd")
  componentAdd : CaseManagementWorkRequestStatusAddComponent;


  @ViewChild("ejDialogMenu")
  ejDialogMenu : DialogComponent;

  @ViewChild("ejDialogDelete")
  ejDialogDelete: DialogComponent;


  currMenuIncident : IncidentModel = null;

  public filterSettings: FilterSettingsModel;
  public toolbar: ToolbarItems[];
  public sortOptions: object;


  filterBarTemplateForYear = {}
  
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.isLoading = false;
    this.LoadIncidentTypes();

    this.toolbar = ['ExcelExport', 'CsvExport', 'Search'];

    this.sortOptions = { columns: [{ field: 'value', direction: 'Ascending' }] };

    this.pageSettings = { pageSizes: true, pageSize: 5 };

  }


  toolbarClick(args: ClickEventArgs): void {

    switch (args.item.tooltipText) {
        case 'PDF Export':
            this.grid.pdfExport();
            break;
        case 'Excel Export':
            this.grid.excelExport();
            break;
        case 'CSV Export':
            this.grid.csvExport();
            break;
    }
}
  
  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;

    this.LoadIncidentTypes();
  }


  LoadIncidentTypes(){


    this.caseManagementService.GetWorkRequestStatus()
      .subscribe((result) => {

        console.log("result", result);

        this.records = result;

      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to obtain Work Request Status Types, please contact support@forensicnotes.com", "ERROR");
        });

  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
  }

  public onDialogClose(event: any) : void {
    this.isVisible = false;
  }

  OpenIndexMenu(){

    // Get Incident
  }

  Add(){
    this.componentAdd.SetupForNew();
    this.componentAdd.show();
  }


  refreshData(event){
    console.log("Refreshing Data");
    this.LoadIncidentTypes();
  }


  menuClick(event, record){

   
    this.currRecID = record.id;
    this.currRecordLabel = record.value;

    this.ejDialogMenu.show();
  }

  onOverlayClickOutsideMenu: EmitType<object> = () => {
    this.ejDialogMenu.hide();
  };


  Edit(){

    this.ejDialogMenu.hide();


    this.componentAdd.SetupForEditing(this.currRecID, this.currRecordLabel);
    this.componentAdd.show();
  }

  Delete(){
    this.ejDialogMenu.hide();
    this.ejDialogDelete.show();
  }

  
  CancelDelete(){
    // Close
    this.ejDialogDelete.hide();
  }

  DeleteConfirmed(){

    this.showDeleteSpinner = true;


    this.caseManagementService.DeleteWorkRequestStatus(this.currRecID)
    .subscribe((result) => {

      console.log("result", result);


      this.toastr.success("Work Request Status Deleted");
    this.showDeleteSpinner = false;

      this.ejDialogDelete.hide();
      this.refreshData(null);


    },
      (error: HttpErrorResponse) => {
        console.error("ERROR --->");
        console.error(error);
        this.toastr.error("Sorry, an error occurred attempting to Delete the Work Request Status, please contact support@forensicnotes.com", "ERROR");
      });


  }


}

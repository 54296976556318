import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'NL2br'
})

export class NL2brPipe implements PipeTransform {

  transform(value: string): string {
      return value.replace(/\n/g, '<br/>');
 }
}
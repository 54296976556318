import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementIncidentTypeRoutingModule } from './case-management-incident-type-routing.module';
import { CaseManagementIncidentTypeComponent } from './case-management-incident-type.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementIncidentTypeAddModule } from '../case-management-incident-type-add/case-management-incident-type-add.module';


@NgModule({
  declarations: [
    CaseManagementIncidentTypeComponent
  ],
  imports: [
    CommonModule,
    CaseManagementIncidentTypeRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementIncidentTypeAddModule
  ],
  providers: [
    PageService, 
    SortService, 
    FilterService, 
    GroupService,
  ],
  exports: [
    CaseManagementIncidentTypeComponent
  ]
})
export class CaseManagementIncidentTypeModule { }

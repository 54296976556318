import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginActivate } from '../service/LoginActivate';
import { BrandingSettingComponent } from './components/branding-setting/branding-setting.component';
import { CaseManagementComponent } from './components/case-management/case-management.component';
import { InvitedMemberComponent } from './components/invited-member/invited-member.component';
import { NotificationsSettingComponent } from './components/notifications-setting/notifications-setting.component';
import { PreferenceSettingComponent } from './components/preference-setting/preference-setting.component';
import { ProfileSettingComponent } from './components/profile-setting/profile-setting.component';
import { TeamSettingComponent } from './components/team-setting/team-setting.component';
import { TemplateSettingComponent } from './components/template-setting/template-setting.component';
import { SettingComponent } from './setting.component';


export const routes: Routes = [
  {
    path: '',
    component: SettingComponent,
    children: [
      { path: '', redirectTo: 'profile',  pathMatch: 'full' },
      {
        path: 'profile',
        component: ProfileSettingComponent,
        canActivate: [LoginActivate]
      },
      { path: 'preference', component: PreferenceSettingComponent },
      { path: 'branding', component: BrandingSettingComponent },
      { path: 'templates', component: TemplateSettingComponent },
      { path: 'team', component: TeamSettingComponent },
      { path: 'notification', component: NotificationsSettingComponent },
      { path: 'invite-member', component: InvitedMemberComponent },
    ],
  },
  { path: 'case-management', component: CaseManagementComponent },
  { path: 'case-management-incident-numbers', loadChildren: () => import('./components/case-management-incident-numbers/case-management-incident-numbers.module').then(m => m.CaseManagementIncidentNumbersModule) },
  { path: 'case-management-incident-numbers-addv2', loadChildren: () => import('./components/case-management-incident-numbers-addv2/case-management-incident-numbers-addv2.module').then(m => m.CaseManagementIncidentNumbersAddv2Module) },
  { path: 'case-management-incident-type', loadChildren: () => import('./components/case-management-incident-type/case-management-incident-type.module').then(m => m.CaseManagementIncidentTypeModule) },
  { path: 'case-management-incident-type-add', loadChildren: () => import('./components/case-management-incident-type-add/case-management-incident-type-add.module').then(m => m.CaseManagementIncidentTypeAddModule) },
  { path: 'case-management-org-team', loadChildren: () => import('./components/case-management-org-team/case-management-org-team.module').then(m => m.CaseManagementOrgTeamModule) },
  { path: 'case-management-org-team-add', loadChildren: () => import('./components/case-management-org-team-add/case-management-org-team-add.module').then(m => m.CaseManagementOrgTeamAddModule) },
  { path: 'case-management-bin-type', loadChildren: () => import('./components/case-management-bin-type/case-management-bin-type.module').then(m => m.CaseManagementBinTypeModule) },
  { path: 'case-management-received-method', loadChildren: () => import('./components/case-management-received-method/case-management-received-method.module').then(m => m.CaseManagementReceivedMethodModule) },
  { path: 'case-management-received-method-add', loadChildren: () => import('./components/case-management-received-method-add/case-management-received-method-add.module').then(m => m.CaseManagementReceivedMethodAddModule) },
  { path: 'case-management-work-request-document-type', loadChildren: () => import('./components/case-management-work-request-document-type/case-management-work-request-document-type.module').then(m => m.CaseManagementWorkRequestDocumentTypeModule) },
  { path: 'case-management-work-request-document-type-add', loadChildren: () => import('./components/case-management-work-request-document-type-add/case-management-work-request-document-type-add.module').then(m => m.CaseManagementWorkRequestDocumentTypeAddModule) },
  { path: 'case-management-work-request-status', loadChildren: () => import('./components/case-management-work-request-status/case-management-work-request-status.module').then(m => m.CaseManagementWorkRequestStatusModule) },
  { path: 'case-management-work-request-status-add', loadChildren: () => import('./components/case-management-work-request-status-add/case-management-work-request-status-add.module').then(m => m.CaseManagementWorkRequestStatusAddModule) },
  { path: 'case-management-work-request-exhibit-type', loadChildren: () => import('./components/case-management-work-request-exhibit-type/case-management-work-request-exhibit-type.module').then(m => m.CaseManagementWorkRequestExhibitTypeModule) },
  { path: 'case-management-work-request-exhibit-type-add', loadChildren: () => import('./components/case-management-work-request-exhibit-type-add/case-management-work-request-exhibit-type-add.module').then(m => m.CaseManagementWorkRequestExhibitTypeAddModule) },
  { path: 'case-management-bin-type-add', loadChildren: () => import('./components/case-management-bin-type-add/case-management-bin-type-add.module').then(m => m.CaseManagementBinTypeAddModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class BaseDialogComponent implements OnInit {

  public subscription: Subscription;


  public isVisible: boolean = false;
  public isLoading: boolean = true;
  public isSaveButtonDisabled: boolean = false;
  public showUpdatingSpinner: boolean = false;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';

  
  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };

  constructor(
    public readonly cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    
  }


  // ===================================================================
  // Check to see if Dialog already Open
  // allows the calling page to show message to user such as
  // 'Legal Analysis Dialog Already Open - Close Dialog and Try Again'
  //
  // Since we can only open the dialog in 1 instance on a page.
  // ===================================================================
  dialogIsOpen(){
    return this.isVisible;
  }


   // ===================================
    // SHOW
    // ===================================
    show() {
       console.log("SHOW - BaseDialogComponent");
      this.isVisible = true;
      this.cdRef.detectChanges();
      // this.isLoading = false;
    }
  
  
    // ===================================
    // HIDE
    // ===================================
    hide() {
      console.log("dialog Base hide()");
      this.isVisible = false;
      this.isLoading = true;
      this.cdRef.detectChanges();

    }
  
    // ===================================
    // 
    // ===================================
    public onDialogClose(event: any): void {
      console.log("dialog Base onDialogClose()");

      this.hide();
      this.cdRef.detectChanges();

    }
  
     // ===================================
    // 
    // ===================================
    Cancel() {
      console.log("Cancel clicked");
      this.hide();
      this.cdRef.detectChanges();

    }

    onOverlayClickOutsideMenu(){
      console.log("")
      this.hide();
      this.cdRef.detectChanges();

    }

    
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

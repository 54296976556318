export enum ExhibitType{
    Computer = "Computer",   // General for Laptop and Desktop
    Desktop = "Desktop",
    Cloud = "Cloud",
    Cell = "Cell",
    HDD = "HDD",
    Laptop = "Laptop",
    NAS = "NAS",
    Other = "Other",
    SD = "SD",
    SIM = "SIM",
    USB = "USB",
    Vehicle = "Vehicle",
    VideoOther = "VideoOther",
    VideoDVR = "VideoDVR",
    VideoTape = "VideoTape",
    VideoOpticalDisk = "VideoOpticalDisk",
    VideoElectronicFile = "VideoElectronicFile",
    VideoSD = "VideoSD",
    VideoHDD = "VideoHDD",
    VideoUSB = "VideoUSB"

}
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, timeout, catchError } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import { protectedResources } from '../auth-config';
import { AiAnalysisType } from '../domain/enumAiAnalysisType';
import { ApiResponsePromptPreviousNotes } from '../domain/ApiResponsePromptPreviousNotes';
import { ApiResponse } from '../domain/ApiResponseModel';
import { PersonThumbnailResult } from '../domain/person-thumbnail-result';


@Injectable({
  providedIn: 'root'
})
export class OpenAIService {


  public supportedLanguages = [
    { code: 'af', name: 'Afrikaans'},
    { code: 'ar', name: 'Arabic'},
    { code: 'hy', name: 'Armenian'},
    { code: 'az', name: 'Azerbaijani'},
    { code: 'be', name: 'Belarusian'},
    { code: 'bs', name: 'Bosnian'},
    { code: 'bg', name: 'Bulgarian'},
    { code: 'ca', name: 'Catalan'},
    { code: 'zh', name: 'Chinese'},
    { code: 'hr', name: 'Croatian'},
    { code: 'cs', name: 'Czech'},
    { code: 'da', name: 'Danish'},
    { code: 'nl', name: 'Dutch'},
    { code: 'en', name: 'English'},
    { code: 'et', name: 'Estonian'},
    { code: 'fi', name: 'Finnish'},
    { code: 'fr', name: 'French'},
    { code: 'gl', name: 'Galician'},
    { code: 'de', name: 'German'},
    { code: 'el', name: 'Greek'},
    { code: 'he', name: 'Hebrew'},
    { code: 'hi', name: 'Hindi'},
    { code: 'hu', name: 'Hungarian'},
    { code: 'is', name: 'Icelandic'},
    { code: 'id', name: 'Indonesian'},
    { code: 'it', name: 'Italian'},
    { code: 'ja', name: 'Japanese'},
    { code: 'kn', name: 'Kannada'},
    { code: 'kk', name: 'Kazakh'},
    { code: 'ko', name: 'Korean'},
    { code: 'lv', name: 'Latvian'},
    { code: 'lt', name: 'Lithuanian'},
    { code: 'mk', name: 'Macedonian'},
    { code: 'ms', name: 'Malay'},
    { code: 'mr', name: 'Marathi'},
    { code: 'mi', name: 'Maori'},
    { code: 'ne', name: 'Nepali'},
    { code: 'no', name: 'Norwegian'},
    { code: 'fa', name: 'Persian'},
    { code: 'pl', name: 'Polish'},
    { code: 'pt', name: 'Portuguese'},
    { code: 'ro', name: 'Romanian'},
    { code: 'ru', name: 'Russian'},
    { code: 'sr', name: 'Serbian'},
    { code: 'sk', name: 'Slovak'},
    { code: 'sl', name: 'Slovenian'},
    { code: 'es', name: 'Spanish'},
    { code: 'sw', name: 'Swahili'},
    { code: 'sv', name: 'Swedish'},
    { code: 'tl', name: 'Tagalog'},
    { code: 'ta', name: 'Tamil'},
    { code: 'th', name: 'Thai'},
    { code: 'tr', name: 'Turkish'},
    { code: 'uk', name: 'Ukrainian'},
    { code: 'ur', name: 'Urdu'},
    { code: 'vi', name: 'Vietnamese'},
    { code: 'cy', name: 'Welsh'}
  ];
  

  url = protectedResources.apiBaseUrl.endpoint;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }


  ProvideNoteImprovementSuggestions(notebookID: string, draftNoteText: string): Observable<any> {

    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("notebookId", notebookID);
    formData.append("newNoteText", draftNoteText);


    let fullURL: string = this.url + "/api/Ai/ProvideSuggestions";


    return this.http.post<any>(fullURL, formData, httpPostOptions);

  }



  GenerateSynopsisAndNarrativeForNotes(notebookID: string) {
    console.log("GenerateSynopsisAndNarrativeForNotes()", notebookID);


    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("notebookId", notebookID);

    let fullURL: string = this.url + "/api/Ai/GenerateSynopsisAndNarrativeForNotes";


    return this.http.post<any>(fullURL, formData, httpPostOptions);

  }


  GenerateLegalAnalysisAsProsecutor(report: string) {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("report", report);

    let fullURL: string = this.url + "/api/Ai/GenerateLegalAnalysisAsProsecutor";


    return this.http.post<any>(fullURL, formData, httpPostOptions);
  }


  GenerateLegalAnalysisAsDefense(report: string) {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("report", report);

    let fullURL: string = this.url + "/api/Ai/GenerateLegalAnalysisAsDefense";


    return this.http.post<any>(fullURL, formData, httpPostOptions);
  }


  GenerateReponseForPromptAndNotebookID(notebookID: string, aiAnalysisType: AiAnalysisType) {

    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("GenerateReponseForPromptAndNotebookID() --> AnalysisType & Temp", aiAnalysisType);

    const formData = new FormData();
    formData.append("notebookId", notebookID);
    formData.append("aiAnalysisType", aiAnalysisType.toString());

    let fullURL: string = this.url + "/api/Ai/GenerateReponseForPromptAndNotebookID";

    return this.http.post<any>(fullURL, formData, httpPostOptions);
  }


  GetNotesAsText(notebookID: string){
    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("GetNotesAsText()", notebookID);

    const formData = new FormData();
    formData.append("notebookId", notebookID);

    let fullURL: string = this.url + "/api/Ai/GetNotesAsText";

    return this.http.post<any>(fullURL, formData, httpPostOptions);
  }



  GenerateReponseForPromptAndText(textToAnalyze: string, aiAnalysisType: AiAnalysisType,
    questionFromUser: string = "") {

    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("GenerateReponseForPromptAndText() --> AnalysisType & Temp", aiAnalysisType);
    console.log("GenerateReponseForPromptAndText() --> Text", textToAnalyze);



    const formData = new FormData();
    formData.append("textToAnalyze", textToAnalyze);
    formData.append("aiAnalysisType", aiAnalysisType.toString());
    formData.append("questionFromUser", questionFromUser);

    let fullURL: string = this.url + "/api/Ai/GenerateReponseForPromptAndText";

    const customTimeout = 200 * 1000; // Set the desired timeout in milliseconds (200 seconds in this example)

    return this.http.post<any>(fullURL, formData, httpPostOptions).pipe(
      timeout(customTimeout),
      catchError((error) => {
        // Handle the error here
        console.error('Error occurred while fetching data:', error);
        throw error;
      })
    );



    // return this.http.post<any>(fullURL, formData, httpPostOptions);

  }


  CreateTranscription(audioFile: Blob, aiAnalysisType: AiAnalysisType, preferredLanguageISO639: string) {

    console.log("CreateTranscription", audioFile);

    const httpPostOptions = {
      withCredentials: true,
    };


    const customTimeout = 240000;  // 240 seconds

    const formData = new FormData();
    formData.append("audioFile", audioFile);
    formData.append("aiAnalysisType", aiAnalysisType.toString());
    // formData.append("preferredLanguageISO639", preferredLanguageISO639);


    let fullURL: string = this.url + "/api/Ai/CreateTranscription";

    return this.http.post<any>(fullURL, formData, httpPostOptions).pipe(
      timeout(customTimeout)
    );
  }




  GetThumbnailAndOCRforPersonDetails(fileId: string, enableServiceWorkerCache: boolean, userID: string) {
    const httpOptions = {
      withCredentials: true,
    };

    let fullURL: string = this.url +
      "/api/Attachments/GetThumbnailAndOCRforPersonDetails/" +
      fileId +
      "?imageOptions=250,250";

    return this.http.get<any>(fullURL, httpOptions);
  }

}

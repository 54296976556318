import { Inject, Injectable } from '@angular/core';
import { AuthTokenService } from './auth-token.service';
import { HubService } from './hub.service';
import { UserProfileService } from './user-profile.service';
import { ForensicUserProfileService } from './forensicUserProfile.service';
import { ClientEncryptionPasswordService } from './client-encryption-password.service';
import { LogoutService } from './logout.service';
import ErrorHandling from '../shared/Utility/ErrorHandling';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { NetworkConnectionService } from './network-connection.service';
import { debounceTime, filter, skip, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authTokenService: AuthTokenService,
    private hubService: HubService,
    private userProfileService: UserProfileService,
    private forensicUserProfileService: ForensicUserProfileService,
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    private logoutService: LogoutService,
    private errorHandling: ErrorHandling,
    private networkConnectionService: NetworkConnectionService
  ) { 
    this.initialize().then();
    this.networkConnectionService.networkStatus$.pipe(skip(1), debounceTime(1000)).subscribe((value) => {
      if (value) {
        this.initialize().then();
      }
    });
  }
  
  private initialize() {
    console.log('UserProfileService --> Initialize()');
    // AuthToken
    return this.authTokenService.RefreshToken().then(() => {
      console.log('AuthTokenService - RefreshToken() initialization complete');

      // UserProfile
      return this.getAndSetUserProfile().then(() => {
        console.log('UserProfileService - getUserProfile() initialization complete');

        // SignalR
        return this.hubService.setupConnection().then(() => {
          console.log('SignalRService --> SignalR Service - initialization complete');
        });
      });
    });
  }

  async getAndSetUserProfile() {
    console.log('UserProfileService --> getUserProfile()');
    try {
      // --------------------------------------------------------------------------------
      // IMPORTANT: Do NOT Return until Profile obtained to ensure app loads properly
      // --------------------------------------------------------------------------------
      const result = await this.forensicUserProfileService.getUserProfile(false, this.userProfileService.user.userID).toPromise();

      console.log('UserProfileService --> getUserProfile() -- Profile Obtained');

      // -----------------------
      // Load User Model
      // -----------------------
      this.userProfileService.setUserProfile(result.result);


      console.log('UserProfileService --> getUserProfile() -- User Profile', this.userProfileService.user);

      // ----------------------------------------
      // Set ClientSideEncryptionKEK
      // ----------------------------------------
      this.clientEncryptionPasswordService.setUserKEK(this.userProfileService.user.clientEncryptionKEK);

      console.log('UserProfileService --> getUserProfile() --> Returning');
    } catch (error) {
      console.error('++++++++++++++ ERROR +++++++++++++++++');
      console.error(error);
      console.error('++++++++++++++ ERROR (end of) +++++++++++++++++');
      if(error === 'Network is offline') {
        return;
      }
      if (error.status === 401) {
        console.log('401 Error');
        if (this.config.requireAuthentication == true) {
          this.logoutService.ForceLogin();
        }
        // console.error("else 1", error);
        this.errorHandling.LogErrorToServer('401 but not Auth', error);
      } else if (error.status === 403) {
        console.error('403 Error', error);
        this.errorHandling.LogErrorToServer('403 ERROR - OLD Account or Account Disabled', error);
        this.logoutService.logout();
        location.href = 'https://www.forensicnotes.com/account-disabled-403';
      } else if (error.status === 404) {
        console.log('404 Error');
        if (this.config.requireAuthentication == true) {
          this.logoutService.ForceLogin();
        }
        // console.error("else 2", error);
        this.errorHandling.LogErrorToServer('404 but not Auth - 2', error);
      } else if (error.status == 412) {
        console.error('412 Error');
        console.error(error);
        console.error(error.message);

        this.errorHandling.LogErrorToServer('412', error);
      } else {
        // ------------------------------------------------------------------------------------
        // Unknown Error - But likely Network ERROR
        // Send to a static error page.
        // ------------------------------------------------------------------------------------
        // console.error("Network Error", error);

        // location.href = "/assets/offline.html";

        this.logoutService.ForceLogin();
        return;
      }
    }
  }

}

import { TforensicNote } from 'src/app/domain/tforensicNote';
import { SyncServicesStatic } from './sync-services-static';
import { EMPTY, Observable, Observer, catchError, concat, endWith, forkJoin, map, of, retry, switchMap, tap, throwError } from 'rxjs';
import { PersonEntityModel } from 'src/app/shared/customObjects/personEntityModel';
import { v4 as newGUID } from 'uuid';
import { FileInfo, Uploader } from '@syncfusion/ej2-angular-inputs';
import { environment } from 'src/environments/environment';
import { protectedResources } from 'src/app/auth-config';
import { EventEmitter } from '@angular/core';
export enum NoteOfflineType {
  TEXT = 'TEXT',
  ATTACHMENT = 'ATTACHMENT',
  PERSON_ENTITY = 'PERSON_ENTITY',
  ADVANCED = 'ADVANCED',
}

export class NoteOfflineData extends TforensicNote {
  isOffline = true;
  static fromTforensicNote(note: TforensicNote): NoteOfflineData {
    return { ...note, isOffline: true };
  }
}

export interface FileData {
  name: string;
  size: number;
  data: ArrayBuffer;
}

export interface AdditionalData {
  files?: FileData[];
  person?: PersonEntityModel;
}

export interface SyncQueueData {
  id: string;
  type: string;
  data?: any;
}

export abstract class NoteOffline {
  noteId: string;
  notebookId: string;
  type: NoteOfflineType;
  data: TforensicNote;
  isSyncFail: boolean = false;
  saveUrl: string;
  abstract saveNoteToServer();
  abstract getDataToStore(): { json: string; additionalData?: AdditionalData };

  uploadFiles(_files: FileData[], onUpload?: any) {
    return new Observable((observer: Observer<any>) => {
      let numberNeedToComplete = 0;
      const files: FileInfo[] = _files.map(({ data, name, size }) => {
        return {
          name,
          rawFile: new File([data], name),
          size: size,
          status: '',
          type: '',
          statusCode: '1',
          validationMessages: { minSize: '', maxSize: '' },
        };
      });
      const uploader = new Uploader({
        asyncSettings: {
          saveUrl: `${this.saveUrl}`,
          chunkSize: environment.ChunkSize,
          retryCount: 3,
          retryAfterDelay: 1000,
        },
        autoUpload: false,
        uploading: onUpload ? onUpload.bind(this): undefined,
        chunkUploading: onUpload ? onUpload.bind(this): undefined,
        success: (args) => {
          console.log(`uploadFiles success ${this.noteId}`, args);
          numberNeedToComplete++;
          if(numberNeedToComplete === files.length) { // check all file uploaded
            uploader.destroy();
            observer.next({ success: true });
            observer.complete();
          }
        },
        failure: (args) => {
          console.log(`uploadFiles failure ${this.noteId}`, args);
          uploader.destroy();
          observer.next({ success: false });
          observer.error(`uploadFiles failure ${this.noteId}`);
        },
      });
      const uploaderWrapper = document.querySelector('#uploaderOfflineWrapper');
      if(!uploaderWrapper){
        throw new Error("#uploaderOffline not found");
      }
      const uploaderOffline = uploaderWrapper.querySelector("#uploaderOffline");
      if(!uploaderOffline) {
        const _uploaderOffline = document.createElement("input");
        _uploaderOffline.setAttribute("id", "uploaderOffline");
        _uploaderOffline.setAttribute("type", "file");
        uploaderWrapper.append(_uploaderOffline);
      }
      uploader.appendTo(`#uploaderOffline`);
      uploader.upload(files, true);
    });
  }

  static create(noteId: string, notebookId: string, type: NoteOfflineType, data: NoteOfflineData, additionalData?: AdditionalData) {
    const { files, person } = additionalData || {};
    switch (type) {
      case NoteOfflineType.TEXT:
        return new NoteTextOffline(noteId, notebookId, data);
      case NoteOfflineType.ATTACHMENT:
        return new NoteAttachmentOffline(noteId, notebookId, data, files[0]);
      case NoteOfflineType.PERSON_ENTITY:
        return new NotePersonEntityOffline(noteId, notebookId, data, person, files);
      case NoteOfflineType.ADVANCED:
        return new NoteTextAdvancedOffline(noteId, notebookId, data, files);

      default:
        throw new Error('Not Implemented');
        break;
    }
  }

  static fromJson({ noteId, notebookId, data, type, person, isSyncFail, syncQueueDatas, newNoteId }: any, additionalData?: AdditionalData): NoteOffline {
    return NoteOffline.create(
      noteId,
      notebookId,
      type,
      { ...data, isSyncFail, syncQueueDatas, newNoteId },
      {
        ...(additionalData || {}),
        person,
      }
    );
  }

  static async fileToBase64(file: File) {
    const { type } = file;
    if (!['image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(type)) {
      return null;
    }
    return this.arrayBufferToImage(await file.arrayBuffer());
  }

  static arrayBufferToImage(data: ArrayBuffer | Object) {
    if (!(data instanceof ArrayBuffer)) {
      return null;
    }

    const base64 = this.arrayBufferToBase64(data);

    return `data:image/png;base64, ${base64}`;
  }

  static arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    // const { byteLength = [] } = bytes;

    let binary = '';

    for (let i = 0; i < bytes?.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
  }

  constructor(noteId: string, notebookId: string, data: TforensicNote) {
    this.noteId = noteId;
    this.notebookId = notebookId;
    this.data = data;
  }

  updateNewNoteId(newNoteId: string) {
    this.noteId = newNoteId;
    this.data.noteId = newNoteId;
  }
}

export class NoteTextOffline extends NoteOffline {
  constructor(noteId: string, notebookId: string, data: TforensicNote) {
    super(noteId, notebookId, data);
    this.type = NoteOfflineType.TEXT;
  }

  saveNoteToServer() {
    const { noteId, noteContentType, dateDotNetUTCinTicks, noteText } = this.data;
    const _noteContentType = noteContentType ? noteContentType.toString() : '1';
    return SyncServicesStatic.forensicNoteService.CreateTextNoteInTicksAndTimestamp(this.notebookId, noteId, noteText, _noteContentType, dateDotNetUTCinTicks);
  }

  getDataToStore() {
    return {
      json: JSON.stringify(this),
    };
  }
}

export class NoteAttachmentOffline extends NoteOffline {
  file: FileData;
  constructor(noteId: string, notebookId: string, data: TforensicNote, file: FileData) {
    const { attachments } = data;
    if (!(attachments && attachments.length > 0)) {
      throw new Error('attachments is empty');
    }
    super(noteId, notebookId, data);
    this.file = file;
    this.type = NoteOfflineType.ATTACHMENT;
  }

  saveNoteToServer() {
    console.log('saveNoteToServer', this.data);
    const { noteId, noteDate } = this.data;
    this.saveUrl = `${protectedResources.apiBaseUrl.endpoint}/api/Notebooks/${this.notebookId}/Notes/CreateAttachmentNote_SyncFusionV3`;
    const uploadFiles$ = this.uploadFiles([this.file], this.onUpload(noteDate));
    return uploadFiles$.pipe(
      switchMap(() => SyncServicesStatic.forensicNoteService.timeStampNote(noteId, 'Note Created').pipe(
        map((res) => ({ success: true }))
      )),
    );
  }

  private onUpload(noteDate) {
    return (args: any) => {
      console.log(args);
      args.currentRequest.withCredentials = true;
  
      args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + SyncServicesStatic.authTokenService.token);
  
      args.customFormData = [{ 'noteID': this.noteId}, {'dateCreated': noteDate }];
    }
  }

  getDataToStore() {
    const { file, ...data } = this;
    return { json: JSON.stringify(data), additionalData: { files: [file] } };
  }

  static async getNoteHtml(file: File) {
    const base64 = await this.fileToBase64(file);
    let img = '';
    if(base64) {
      img = `
      <img src="${base64}" class="embeddedImages">
      `
    }
    return `
    ${img}
    <h2 class='hideFlexRowOnMobile'>File Upload</h2>
    <div style='display: flex;'>
      <div style='font-weight: bold; min-width: 140px; width:140px;' class='hideFlexRowOnMobile'>Filename:</div>
      <div
        style=' overflow-wrap: break-word;-ms-word-wrap: break-word; word-wrap: break-word; word-break: break-word; white-space: pre-wrap !important; vertical-align: top; '>${file.name}</div>
    </div>
    <div class='hideFlexRowOnMobile'>
      <div style='font-weight: bold; min-width: 140px; width:140px; display: flex;'>Size:</div>
      <div style=' overflow-wrap: break-word;-ms-word-wrap: break-word; word-wrap: break-word; word-break: break-word; white-space: pre-wrap !important; vertical-align: top; '>${file.size} KB</div>
    </div>
    <div class='showOnMobile smallgrey'>Additional values visible on desktop and in PDF Report</div>
    `;
  }

}

export class NotePersonEntityOffline extends NoteOffline {
  person: PersonEntityModel;
  files: FileData[];
  constructor(noteId: string, notebookId: string, data: TforensicNote & { newNoteId?: string }, person: PersonEntityModel, files: FileData[]) {
    super(noteId, notebookId, data);
    this.person = person;
    this.files = files;
    this.type = NoteOfflineType.PERSON_ENTITY;
  }

  saveNoteToServer() {
    console.log('saveNoteToServer', this);
    const { noteId, dateDotNetUTCinTicks } = this.data;

    return SyncServicesStatic.forensicNoteService.CreateAdvanceBlankNoteInTicks(this.notebookId, dateDotNetUTCinTicks, '8').pipe(
      switchMap(({ result: newNoteId }) => {
        return SyncServicesStatic.syncService.updateNoteIdToNewNoteId(noteId, newNoteId).pipe(
          switchMap(() => {
            return this.getSaveNoteRequests(this.noteId);
          })
        );
      })
    );
  }

  private getSaveNoteRequests(noteId: string) {
    this.saveUrl = `${protectedResources.apiBaseUrl.endpoint}/api/notes/${this.noteId}/AddAttachment_SyncFusion`;
    const fileUpload$ = this.uploadFiles(this.files, this.onUpload);
    return fileUpload$.pipe(
      switchMap(() => {
        this.person.notebookId = this.notebookId;
        this.person.associatedNoteID = this.noteId;
        this.person.id = this.noteId;
        return forkJoin([SyncServicesStatic.forensicNoteService.AddUpdatePersonNote(this.notebookId, noteId, '', this.person), SyncServicesStatic.caseManagementService.AddUpdatePerson(this.person)]);
      }),
      map(([personNote]) => {
        this.data.noteText = personNote.result.uIhtml;
        SyncServicesStatic.forensicNoteService.timeStampNote(noteId, 'Note Created').subscribe();
        return { success: true };
      }),
      catchError((err) => {
        console.error(err);
        return SyncServicesStatic.syncService.updateNoteIdToNewNoteId(this.noteId, newGUID()).pipe(
          switchMap(() => {
            return throwError(() => err);
          })
        );
      })
    );
  }

  private onUpload(args: any) {
    console.log(args);
    args.currentRequest.withCredentials = true;

    args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + SyncServicesStatic.authTokenService.token);
  }

  getDataToStore() {
    const { files, ...data } = this;
    return { json: JSON.stringify(data), additionalData: { files } };
  }

  static getNoteHtml() {
    return `<p style="padding:2px;text-align:center;font-weight:bold;font-size:large;color:red;">Your person enity note will be displayed after synced</p>`;
  }
}

export class NoteTextAdvancedOffline extends NoteOffline {
  files: FileData[];
  syncQueueDatas: SyncQueueData[] = null;

  constructor(noteId: string, notebookId: string, data: TforensicNote, files: FileData[]) {
    super(noteId, notebookId, data);
    this.files = files;
    this.type = NoteOfflineType.ADVANCED;
  }

  saveNoteToServer() {
    console.log('saveNoteToServer', this);
    const { noteId, dateDotNetUTCinTicks } = this.data;
    this.syncQueueDatas = this.getInitSyncQueueDatas();
    return SyncServicesStatic.forensicNoteService.CreateAdvanceBlankNoteInTicks(this.notebookId, dateDotNetUTCinTicks, '1').pipe(
      switchMap(({ result: newNoteId }) => {
        return SyncServicesStatic.syncService.updateNoteIdToNewNoteId(noteId, newNoteId).pipe(
          switchMap(() => {
            return this.getSaveNoteRequests();
          })
        );
      })
    );
  }

  private getInitSyncQueueDatas(): SyncQueueData[] {
    return [
      {
        id: newGUID(),
        type: 'addAttachmentToNote',
      },
      {
        id: newGUID(),
        type: 'UpdateNoteTextInTicks',
      },
    ];
  }

  private toRequests(syncQueueDatas: SyncQueueData) {
    const { type, data: syncData } = syncQueueDatas;
    switch (type) {
      case 'addAttachmentToNote':
        this.saveUrl = `${protectedResources.apiBaseUrl.endpoint}/api/notes/${this.noteId}/AddAttachment_SyncFusion`;
        return this.uploadFiles(this.files, this.onUpload);
      case 'UpdateNoteTextInTicks':
        return SyncServicesStatic.forensicNoteService.UpdateNoteTextInTicks(this.noteId, this.data.noteText, this.data.dateDotNetUTCinTicks);
      default:
        throw new Error('Not Implemented');
    }
  }

  private onUpload(args: any) {
    console.log(args);
    args.currentRequest.withCredentials = true;

    args.currentRequest.setRequestHeader('Authorization', 'Bearer ' + SyncServicesStatic.authTokenService.token);
  }

  private getSaveNoteRequests() {
    const requests$ = this.syncQueueDatas.map((syncData) => {
      return this.toRequests(syncData);
    });

    return concat(...requests$).pipe(
      endWith('DONE'),
      switchMap((res) => {
        if (res === 'DONE') {
          return of({});
        }
        return EMPTY;
      }),
      map((res) => {
        SyncServicesStatic.forensicNoteService.timeStampNote(this.noteId, 'Note Created').subscribe();
        return {
          success: true,
        };
      }),
      catchError((err) => {
        console.error(err);
        return SyncServicesStatic.syncService.updateNoteIdToNewNoteId(this.noteId, newGUID()).pipe(
          switchMap(() => {
            return throwError(() => err);
          })
        );
      })
    );
  }

  getDataToStore() {
    const { files, ...data } = this;
    return { json: JSON.stringify(data), additionalData: { files } };
  }
}

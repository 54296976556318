import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { TermsComponent } from './terms/terms.component';
import { TimezoneComponent } from './timezone/timezone.component';

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss']
})
export class AccountSetupComponent implements OnInit {


  @ViewChild("componentTerms")
  componentTerms: TermsComponent;

  
  @ViewChild("componentTimeZone")
  componentTimeZone: TimezoneComponent;

  isTermsSetup: boolean = false;
  isTimeZoneSetup: boolean = false;


  constructor(
    private appComponent: AppComponent,
  ) { }

  ngOnInit(): void {

  }


  LoadControl(isTermsSetup: boolean, isTimeZoneSetup: boolean) {

    this.isTermsSetup = isTermsSetup;
    this.isTimeZoneSetup = isTimeZoneSetup;

    if (this.isTermsSetup == false) {
      // Show TERMS First
      this.componentTerms.show();
    }
    else if(this.isTimeZoneSetup == false) {
      // Terms already setup, so show Timezone
      this.componentTimeZone.load();
      this.componentTimeZone.show();
    }
    else{
      // everything is set
      console.error("Account Setup Completed Already");
    }


  }


  TermsAccepted(){
    this.isTermsSetup = true;

    this.appComponent.user.agreeToTerms = true;

    if(this.isTimeZoneSetup == false){
      this.componentTimeZone.load();
      this.componentTimeZone.show();
    }
    else{
      // everything is set
      console.log("Account Setup Complete");
    }
  }

  TimeZoneSet(timeZone){
    console.log("Timezone parent");
    console.log("TimeZone Set", timeZone);

    this.isTimeZoneSetup = true;

    // ----------------------------------------------------
    // Update main User Profile -- Allow API
    // to properly setup the TimeZone and Offset values
    // by re-calling the getUserProfile
    // ----------------------------------------------------
    this.appComponent.CheckProfileAndSetup(false);

  }

}

import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, combineLatest, combineLatestAll, filter, firstValueFrom, map, merge, throwError, timeout } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import SecurityUtils from '../shared/Utility/securityUtils';
import { PasswordType, UserProfileService } from './user-profile.service';
import { NetworkConnectionService } from './network-connection.service';

@Injectable({
  providedIn: 'root',
})
export class ClientEncryptionPasswordService {
  private customKEKSource = new BehaviorSubject<string>(null);
  public customKEK$ = this.customKEKSource.asObservable();
  // ===========================================================================================
  // The Key Encryption Key KEK is retrieved from the server upon login from the Users Profile
  // It is stored in this class for use to encrypt and decrypt the Content Encryption Key (CEK)
  // If the Application is Offline and app has refreshed, then notes can't be decrypted
  // until app goes back online.
  // Under most situations, the users won't face this issue.
  // ===========================================================================================

  private KEKSource = new BehaviorSubject<string>(null);
  public KEK$ = this.KEKSource.asObservable();
  // =============================================================
  //
  // =============================================================

  constructor(
    @Inject(LOCAL_STORAGE) private storage: Storage,
    private securityUtils: SecurityUtils,
    private userProfileService: UserProfileService,
    private networkConnectionService: NetworkConnectionService
  ) {
    // Constructor
  }

  getKEKObs() {
    if (this.checkUseCustomKEK()) {
      if (this.KEKSource.value) {
        return this.KEK$;
      }
      return this.customKEK$;
    }
    if (this.securityUtils.isServiceWorkerCacheEnabled() && this.userProfileService.isCachedUser()) {
      return merge(this.KEK$, this.customKEK$).pipe(
        filter((e) => !!e),
      );
    }
    return this.KEK$;
  }

  checkUseCustomKEK() {
    return !this.networkConnectionService.isOnline
      && this.securityUtils.isServiceWorkerCacheEnabled()
      && this.userProfileService.isCachedUser()
  }

  getKEKPromise() {
    return firstValueFrom(this.getKEKObs().pipe(filter((e) => !!e)));
  }

  getKEK() {
    return this.KEKSource.value || this.customKEKSource.value;
  }

  // =============================================================
  //
  // =============================================================
  setUserKEK(contentEncryptionKey: string) {
    console.log('INDEX-DB --> KEK Set = ' + contentEncryptionKey);
    this.KEKSource.next(contentEncryptionKey);
  }

  getUserKEK() {
    return this.KEKSource.value;
  }

  // =============================================================
  //
  // =============================================================
  getPassword() {
    return this.getKEK();
    // if (this.KEKSource.value) {
    //   console.log('INDEX-DB --> KEK Set = ', this.KEKSource.value);
    //   return this.KEKSource.value;
    // }
  }

  // =============================================================
  //
  // =============================================================
  clearUserKEK() {
    this.KEKSource.next(null);
  }

  setCustomKEK(password: string) {
    const isCachedUser = this.userProfileService.isCachedUser();
    const hash = this.hashPassword(password);
    if (!isCachedUser) {
      return false;
    }

    const user = this.userProfileService.loadUserProfileFromCache(hash);
    if (user) {
      if (!this.userProfileService.user.userID) {
        this.userProfileService.setUserProfile(user);
      }
      this.customKEKSource.next(user.clientEncryptionKEK);
      return true;
    }
    return false;
  }

  setupCustomKEK(password: string, type: PasswordType) {
    const hash = this.hashPassword(password);
    const clientEncryptionKEK = this.userProfileService.cacheUserProfile(hash, type);
    this.customKEKSource.next(clientEncryptionKEK);
  }
  // this func to get KEK Setup in APP
  // Which will contain value after user fill in password
  getCustomKEKFromApp() {
    return this.customKEKSource.getValue();
  }

  clearCustomKEK() {
    this.storage.removeItem('userCached');
    this.customKEKSource.next(null);
  }

  // async updateAllTableWithNewKEK(oldKEK: string, newKEK: string) {
  //   const allEncryptedTables = ['notebookInfo', 'notebookListInfo', 'Notes', 'offlineNoteBooks', 'offlineNotes'];
  //   for await (const table of allEncryptedTables) {
  //     await this.updateKEKForTable(table, oldKEK, newKEK);
  //   }
  // }

  // private async updateKEKForTable(table: string, oldKEK: string, newKEK: string) {
  //   const tx = this.indexDBService.indexDB.transaction(table, 'readwrite');
  //   const store = tx.objectStore(table);
  //   const cursorRequest = store.openCursor() as unknown as IDBRequest<IDBCursorWithValue | null>;

  //   cursorRequest.onsuccess = async (event) => {
  //     const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
  //     if (cursor) {
  //       const { encryptedCEK, encryptedDataJSON, ...data } = cursor.value;
  //       const decryptedCEK: string = this.pgpEncryptionService.decryptData(encryptedCEK, oldKEK);
  //       const decryptedJSON: string = this.pgpEncryptionService.decryptData(encryptedDataJSON, decryptedCEK);

  //       const newEncryptedCEK: string = this.pgpEncryptionService.encryptData(decryptedCEK, newKEK);
  //       const newEncryptedDataJSON: string = this.pgpEncryptionService.encryptData(decryptedJSON, newEncryptedCEK);

  //       const updateRequest = await cursor.update({ ...data, encryptedCEK: newEncryptedCEK, encryptedDataJSON: newEncryptedDataJSON });
  //       updateRequest.onsuccess = function () {
  //         cursor.continue();
  //       };
  //       updateRequest.onerror = function (event) {
  //         console.error('Error updating data:', event);
  //       };
  //     } else {
  //       // Complete the transaction
  //       console.log('All data updated successfully.');
  //     }
  //   };

  //   cursorRequest.onerror = (event) => {
  //     console.error('Cursor request failed', cursorRequest.error);
  //   };
  // }

  private hashPassword(password: string) {
    return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
  }
}

import { Component, OnInit, ViewChild, Input, Inject, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ForensicUserProfileService } from '../service/forensicUserProfile.service';
import { TforensicUserProfile } from '../domain/tforensicUserProfile';
// import { DownloadingComponent } from '../downloading/downloading.component';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ToastrService } from "ngx-toastr";
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { Clipboard } from '@angular/cdk/clipboard';
// import { ConsoleLogger } from 'DELETE node_modules/@angular/compiler-cli/ngcc';
import { protectedResources } from '../auth-config';
import { BrandingService } from '../service/branding.service';
import { timeout } from 'rxjs';
import { DownloadService } from '../service/download.service';
import { BaseDialogComponent } from '../shared/base/dialog/dialog.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class DownloadComponent extends BaseDialogComponent implements OnInit {

  url = protectedResources.apiBaseUrl.endpoint;

  @ViewChild("ejDialogInfo")
  ejDialogInfo: DialogComponent;


  @Input() notebookID: string;
  @Input() currentpassword: string = "";

  noteID: string = "";


  isNotebook: boolean = false;     // Used to check if this form is for Notebook or Note
  noteOrNotebookText: string = "Notebook";  // This will either be Notebook or Note for display UI (default Notebook)

  private originalPassword: string = "";
  radioselectedvalue: string;

  PDForZIP: string = "";  // PDF or Zip Archive
  downloadingNote: boolean = false;
  downloadingNotePDF: boolean = false;
  downloadingNotebook: boolean = false;

  @ViewChild("downloadPasswordEntered")
  downloadPasswordEntered: ElementRef;



  // =====================================================================
  // CONSTRUCTOR
  // =====================================================================
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private forensicUserProfileService: ForensicUserProfileService,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private brandingService: BrandingService,
    private downloadService: DownloadService,
    public readonly cdRef: ChangeDetectorRef,
    public appComponent: AppComponent

  ) {
    super(cdRef);
  }

  // =====================================================================
  // OnInit
  // =====================================================================
  ngOnInit() {
    this.radioselectedvalue = "yes";
  }

  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
    this.cdRef.detectChanges();
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.cdRef.detectChanges();

  }


  // =====================================================================
  // Loads Control for NOTE
  // =====================================================================
  LoadControlForNote(noteID: string, password: string, showCloseButton: boolean = true) {

    console.log("LoadControlForNote called...");

    this.isNotebook = false;
    this.noteOrNotebookText = "Note";


    this.noteID = noteID;

    this.currentpassword = password;
    this.originalPassword = password;

    console.log("pass", this.currentpassword);

    this.show();

    this.cdRef.detectChanges();
  }


  // =====================================================================
  // Loads Control for NoteBOOK
  // =====================================================================
  LoadControlForNotebook(notebookID: string, password: string, numberOfNotes: number) {

    this.isNotebook = true;
    this.noteOrNotebookText = "Notebook";

    if (numberOfNotes == 0) {
      // No Last Note Date, Therefore, No Notes to Download
      this.toastr.info("You must enter a note before you can download a Notebook",
        "No Notes to Download");
      return;
    }

    this.notebookID = notebookID;
    this.currentpassword = password;
    this.originalPassword = password;

    console.log("pass", this.currentpassword);
    this.show();
  }

  onOverlayClickOutsideMenu() {

    // Hide main menu
    super.onOverlayClickOutsideMenu();

    // Make sure info dialog closed
    this.ejDialogInfo.hide();
  }


  DownloadNoteOrNotebook(password: string, packageType: string) {

    let filetype: string = "";


    // Display changed depending on if Note or NoteBOOK
    if(this.isNotebook){
      this.downloadingNotebook = true;
    }
    else{
      // Note
      this.downloadingNote = true;
    }

    if (packageType == "0") {
      // ZIP Archive -- Only ZIP can be password protected (at this time)
      // Need to make sure password set if selected to use one.
      // ----------------------------------------------------------
      filetype = "application/zip";

      this.PDForZIP = "ZIP Archive";

      if (password.length <= 0 && this.radioselectedvalue == "yes") {
        this.toastr.warning("Password Required for Full-Disclosure");
        this.downloadPasswordEntered.nativeElement.placeholder = "Enter password here...";
        return;
      }
      // Else... Continue...

    }
    else if (packageType == "1") {
      filetype = "application/pdf";

      this.PDForZIP = "PDF";

      if(this.isNotebook == false){
        // -----------------------------
        // Note...AND... PDF
        // -----------------------------
        this.downloadingNotePDF = true;
      }


      // No password check required.... Continue...
    }


    // ---------------------------------------
    // Did user change password?
    // If yes, then update User Profile
    // ---------------------------------------
    console.log("updating password?");
    if (this.originalPassword != password) {
      this.updateProfilePassword(password);
    }

    // ------------------------------------------------------------------------
    // Use Password?
    // Note: This MUST be AFTER the above UpdateProfilePassword Method call!
    // ------------------------------------------------------------------------
    if (this.radioselectedvalue == "no") {
      password = "";    // set to blank and no password will be set on ZIP.
    }

    // -------------------------------------------------------------------
    // Hide THIS Dialog - No longer required and we want to stop
    // double clicking.
    // -------------------------------------------------------------------
    this.hide();

      // Show Please Wait Message to User
    this.ejDialogInfo.show();

    if (this.isNotebook) {

      // ----------------------------------------
      // Show NoteBOOK
      // ----------------------------------------
      this.DownloadForensicNotebook(filetype, password, packageType);
    }
    else {
      // -------------------------------------------
      // Download Note
      // -------------------------------------------
      this.DownloadNote(filetype, password, packageType);
    }

    this.cdRef.detectChanges();

  }


  // ==========================================================
  // GENERATE Download Notebook Request
  // ==========================================================
  DownloadForensicNotebook(filetype: string, password: string, packageType: string) {

    console.log("DownloadForensicNotebook called...");

    this.downloadService
      .GenerateDownloadNotebook(this.notebookID, filetype, password, packageType, this.appComponent.user.userID)
      .subscribe((result) => {

        console.log("DownloadForensicNotebook-Result", result);

        // this.downLoadFile(result, filetype);

      },
        (error) => {
          this.toastr.error("Unknown Error - Please contact support if you continue to experience this problem.")
          console.error(error);
        });

  }

  // ==========================================================
  // Download Note
  // ==========================================================
  DownloadNote(filetype: string, password: string, packageType: string) {

    this.downloadService
      .DownloadNote(this.noteID, filetype, password, packageType, this.appComponent.user.userID)
      .subscribe((result) => {
        this.downLoadFile(result, filetype)
      },
        (error) => {
          this.toastr.error("Unknown Error - Please contact support if you continue to experience this problem.")
          console.error(error);
        });
  }


  // ==========================================================
  // Download Forensic Notebook File
  // Called by DownloadForensicNotebook Method above only!
  // ==========================================================
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);

    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }

    this.cdRef.detectChanges();


  }


  // =============================
  // Update Password
  // =============================
  updateProfilePassword(password) {

    console.log("Updating password yes");


    this.forensicUserProfileService
      .UpdateFileDownloadPassword(password)
      .subscribe((result) => {

        // Password Updated on Server.
        // No Need to update user.
        console.log("Zip Password Updated");
        this.cdRef.detectChanges();


      },
        (error) => {
          console.error(error);
        });

  }

  // =============================
  // Show Hide Password
  // =============================
  showHidePassword(a) {

    var attribute = this.downloadPasswordEntered.nativeElement.getAttribute("type");

    if (attribute == "password") {
      this.downloadPasswordEntered.nativeElement.setAttribute('type', 'text');
      a.target.classList.add('active');
    }
    else {
      this.downloadPasswordEntered.nativeElement.setAttribute('type', 'password');
      a.target.classList.remove('active');
    }


  }

  // =============================
  // Set Value
  // =============================
  Setradio(selectedvalue: string) {
    this.radioselectedvalue = selectedvalue;

  }

  // =============================
  // COPY Password to Clipboard
  // =============================
  CopyInput(inputElement) {
    this.clipboard.copy(this.downloadPasswordEntered.nativeElement.value);
    this.toastr.success("Password Copied to Clipboard");
  }



  onDialogInfoClose(event) {
    console.log("onDialogInfoClose");
    this.ResetForm();
  }

  
  // ------------------------------------------------------------------------------------------------
  // Reset Form
  //
  // NOTE: Do NOT Reset until AFTER Info Dialog Closed as these values are required for dialog
  // ------------------------------------------------------------------------------------------------
  ResetForm(){

    this.currentpassword = "";

    this.PDForZIP = "";  // PDF or Zip Archive
    this.downloadingNote = false;
    this.downloadingNotePDF = false;
    this.downloadingNotebook = false;

    this.cdRef.detectChanges();

  }

}





import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DownloadComponent } from './download.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatIconModule } from '@angular/material/icon';
import { DownloadConfirmationDialogComponent } from './download-confirmation-dialog/download-confirmation-dialog.component';
import { SharedModule } from '../shared/shared.module';

// export const routes = [
//   { path: '', component: DownloadComponent }
// ];

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    MatIconModule,
    DialogModule,
    SharedModule,
    // RouterModule.forChild(routes),
  ],
  exports: [
    DownloadComponent,
    DownloadConfirmationDialogComponent
  ],
  declarations: [
    DownloadComponent,
    DownloadConfirmationDialogComponent
  ]
})
export class DownloadModule { }

import { Injectable, NgZone } from '@angular/core';
import { IndexDBService } from '../indexDB/index-db.service';
import { CmsLevelValues } from 'src/app/shared/customObjects/cmsLevelValues';
import { v4 as newGUID } from 'uuid';
import { BehaviorSubject, Subject, catchError, combineLatest, filter, forkJoin, from, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { NotebookOffline } from './models/notebooks-offline';
import { NotesOfflineService } from './notes-offline.service';
import { NotebooksOfflineService } from './notebooks-offline.service';
import { UserProfileService } from '../user-profile.service';
import { SyncQueueService } from './sync-queue.service';
import { ToastrService } from 'ngx-toastr';
import ErrorHandling from 'src/app/shared/Utility/ErrorHandling';
import { ForensicNoteBookService } from '../forensicNoteBook.service';
import { CaseManagementNavigationService } from '../case-management-navigation.service';
import { TforensicNoteBook } from 'src/app/domain/tforensicNoteBook';
import { SyncQueueItem } from './models/sync';
import { NoteOffline, NoteOfflineData, NoteOfflineType } from './models/note-offline';
import { TforensicNote } from 'src/app/domain/tforensicNote';
import { SyncServicesStatic } from './models/sync-services-static';
import { ForensicNoteService } from '../forensicNote.service';
import { NetworkConnectionService } from '../network-connection.service';
import { PersonEntityModel } from 'src/app/shared/customObjects/personEntityModel';
import { CaseManagementService } from '../case-management.service';
import { AuthTokenService } from '../auth-token.service';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  readonly CREATE_NOTE_FAIL_MESSAGE = '<p>Notebook NOT Created</p><p>Please try again</p> <p>If you continue to experience issues, please email support@forensicnotes.com</p>';
  private _notebooks = new Map<string, NotebookOffline>();
  private _notes = new Map<string, NoteOffline>();
  private _notebookMapWithNotes = new Map<string, Set<string>>();

  private _noteBooksSrouce$ = new BehaviorSubject<NotebookOffline[]>(null);
  public noteBooks$ = this._noteBooksSrouce$.asObservable();

  private _notesSrouce$ = new BehaviorSubject<NoteOffline[]>([]);
  public notes$ = this._notesSrouce$.asObservable();

  private syncedNotebooksSrouce = new Subject<{ oldNotebookId: string, newNotebookId: string }>();
  public syncedNotebooks$ = this.syncedNotebooksSrouce.asObservable();

  private syncedNotesSrouce = new Subject<{ note: NoteOffline; syncedAll: boolean }>();
  public syncedNotes$ = this.syncedNotesSrouce.asObservable();
  constructor(
    private indexDBService: IndexDBService,
    private notebooksOfflineService: NotebooksOfflineService,
    private notesOfflineService: NotesOfflineService,
    private userProfileService: UserProfileService,
    private syncQueueService: SyncQueueService,
    private forensicNoteBookService: ForensicNoteBookService,
    private caseManagementNavigationService: CaseManagementNavigationService,
    private toastr: ToastrService,
    private errorHandling: ErrorHandling,
    private forensicNoteService: ForensicNoteService,
    private networkConnectionService: NetworkConnectionService,
    private caseManagementService: CaseManagementService,
    private authTokenService: AuthTokenService,
    private ngZone: NgZone,
  ) {
    SyncServicesStatic.forensicNoteService = this.forensicNoteService;
    SyncServicesStatic.caseManagementService = this.caseManagementService;
    SyncServicesStatic.authTokenService = this.authTokenService;
    SyncServicesStatic.syncService = this;

    
    this.initNoteBookOffline().then();
    this.syncedNotes$.subscribe(({syncedAll})=> {
      if(syncedAll) {
        this.toastr.success('All notes has been synced', 'SYNC');
      }
    })
  }

  async initNoteBookOffline() {
    return this.ngZone.runOutsideAngular(async () => {
      forkJoin([from(this.notebooksOfflineService.getAllNotebookOfflines()), from(this.notesOfflineService.getAllNoteOfflines())]).subscribe(
        ([notebooks, notes]) => {
          console.log('getAllNotebookOfflines', notebooks);
          console.log('getAllNoteOfflines', notes);

          notebooks.forEach((notebook) => {
            this._notebooks.set(notebook.id, notebook);
          });
          notes.forEach((note) => {
            const { noteId, notebookId } = note;
            this._notes.set(noteId, note);
            if (!this._notebookMapWithNotes.has(notebookId)) {
              const noteIdsSet = new Set<string>();
              this._notebookMapWithNotes.set(notebookId, noteIdsSet);
            }
            this._notebookMapWithNotes.get(notebookId).add(note.noteId);
          });
          this._initNotebooksSyncQueue();
          this._initNotesSyncQueueNotBelongToNoteBookOffline();
          this.notebookChanged();
          this.noteChanged();
        },
        (err) => {
          console.log('getAllNotebookOfflines ==> Error', err);
        }
      );
    });
  }

  createNotebookOffline(name: string, _cmsLevelValues?: CmsLevelValues, cmsType?: number) {
    if (!this.indexDBService.indexDB) {
      console.error('INDEX-DB --> this.indexDBService.indexDB not set');
      throw new Error('IndexDB not Initialize');
    }

    const id = newGUID();
    const cmsLevelValues = _cmsLevelValues ? _cmsLevelValues.toJson() : undefined;
    const notebook: NotebookOffline = {
      id,
      name,
      cmsLevelValues,
      cmsType,
      created: new Date().getTime(),
    };

    return from(this.notebooksOfflineService.addOrUpdateNoteBookToIndexDB(notebook)).pipe(
      tap(() => {
        const queueItem = this._getNotebookSyncQueue(notebook);
        this.syncQueueService.enqueue([queueItem]);
        this.toastr.success('Notebook will be synced when the network is back to online', 'Notebook is created offline', { enableHtml: true });
        this._notebooks.set(id, notebook);
        this.notebookChanged();
      }),
      map(() => ({notebookId: id}))
    );
  }

  addNoteToNoteBookOffline(notebookId: string, type: NoteOfflineType, forensicNote: TforensicNote, additionalData?: {files?: File[], person?: PersonEntityModel}, showToastr: boolean = true) {
    if (!this.indexDBService.indexDB) {
      console.error('INDEX-DB --> this.indexDBService.indexDB not set');
      throw new Error('IndexDB not Initialize');
    }
    const noteData = NoteOfflineData.fromTforensicNote(forensicNote);
    const note$ = from(this.notesOfflineService.createNoteOffline(notebookId, type, noteData, additionalData));
    return note$.pipe(
      switchMap((note)=> {
        return from(this.notesOfflineService.addOrUpdateNoteToIndexDB(notebookId, note.noteId, note)).pipe(
          tap(() => {
            this._notes.set(note.noteId, note);
            if (!this._notebookMapWithNotes.has(notebookId)) {
              const noteIdsSet = new Set<string>();
              this._notebookMapWithNotes.set(notebookId, noteIdsSet);
            }
            this._notebookMapWithNotes.get(notebookId).add(note.noteId);
    
            this.noteChanged();
            // If not adding note to NoteBook offline, add note to queue
            // If adding note to Notebook offline, need to wait to sync NoteBook Offline success then add note offline to queue
            if (!this._notebooks.has(notebookId)) {
              const queueItem = this._getNoteSyncQueue(note);
              this.syncQueueService.enqueue([queueItem]);
              if(showToastr) {
                this.toastr.success('Note will be synced when the network is back to online', 'Note is added offline', { enableHtml: true });
              }
            }
          })
        );
      })
    )
  }

  getOfflineNotebook(id: string) {
    return this.noteBooks$.pipe(
      filter(e=>!!e),
      map(notebooks=> (notebooks||[]).find(e=>e.id === id)),
      take(1),
      map((notebook)=> {
        if(!notebook) {
          return undefined;
        }
        const numberOfNotesOffline = this._notebookMapWithNotes.get(notebook.id) ? Array.from(this._notebookMapWithNotes.get(notebook.id).values()).length: 0
        return {
          ...notebook,
          numberOfNotesOffline
        }
      })
    )
  }

  updateNoteIdToNewNoteId(oldNoteId: string, newNoteId: string) {
    const note = this._notes.get(oldNoteId);
    if(!note) {
      return of();
    }
    note.updateNewNoteId(newNoteId);
    this._notes.delete(oldNoteId);
    this._notes.set(newNoteId, note);
    const notebookMapWithNotes =  this._notebookMapWithNotes.get(note.notebookId);
    if(notebookMapWithNotes) {
      notebookMapWithNotes.delete(oldNoteId);
      notebookMapWithNotes.add(newNoteId);
    }
    const updateIndexDB$ = from(this.notesOfflineService.addOrUpdateNoteToIndexDB(note.notebookId, oldNoteId, note));
    return updateIndexDB$;
  }

  updateNoteIntoIndexDB(noteId: string) {
    const note = this._notes.get(noteId);
    if(!note) {
      return of();
    }
    const updateIndexDB$ = from(this.notesOfflineService.addOrUpdateNoteToIndexDB(note.notebookId, noteId, note));
    return updateIndexDB$;
  }

  retrySyncAgain(noteId: string) {
    const note = this._notes.get(noteId);
    if(!note) {
      return;
    }
    note.isSyncFail = false;
    this.noteChanged();
    const queueItem = this._getNoteSyncQueue(note);
    this.syncQueueService.enqueue([queueItem]);
  }

  private _initNotebooksSyncQueue() {
    const queueItems = this._getNoteBooksSyncQueues();
    this.syncQueueService.enqueue(queueItems);
  }

  // init Notes Sync Queue for Notes not belong to offline notebook
  private _initNotesSyncQueueNotBelongToNoteBookOffline() {
    const queues: SyncQueueItem[] = [];
    this._notebookMapWithNotes.forEach((noteIds, notebookId) => {
      if (!this._notebooks.has(notebookId)) {
        noteIds.forEach((noteId) => {
          const note = this._notes.get(noteId);
          const queue = this._getNoteSyncQueue(note);
          queues.push(queue);
        });
      }
    });
    this.syncQueueService.enqueue(queues);
  }

  private _getNoteBooksSyncQueues() {
    const notebooks = Array.from(this._notebooks.values());
    const queueItems = notebooks.map((notebook) => {
      return this._getNotebookSyncQueue(notebook);
    });
    return queueItems;
  }

  private _getNotebookSyncQueue(notebook: NotebookOffline): SyncQueueItem {
    const { id, name, cmsLevelValues } = notebook;
    const forensicNoteBook = new TforensicNoteBook();
    forensicNoteBook.title = name;
    const request$ = this.forensicNoteBookService.createforensicnotebook(forensicNoteBook);
    const onSuccess = (res) => {
      const { success, result } = res;
      const notebookId = result;
      if (!success) {
        // should notice to User
        console.log('[SYNC] Error createforensicnotebook', res);
        this.toastr.error(this.CREATE_NOTE_FAIL_MESSAGE, 'SYNC ERROR', { enableHtml: true });
        return;
      }
      if (cmsLevelValues) {
        const cmsLevelSyncQueue = this._addNotebookToCurrentCMSLevelSyncQueue(notebookId, notebook);
        this.syncQueueService.enqueue([cmsLevelSyncQueue]);
      } else {
        this.toastr.success(`Notebook ${notebook.name} synced successful`, 'Sync');
        this.syncedNotebooksSrouce.next({ oldNotebookId: id, newNotebookId: notebookId });
      }
      this.notebooksOfflineService.removeNotebookOfflineFromStore(id).then();
     

       // update all notes belong to notebook offline from offline notebookId to new notebookId from server
      const noteIdsUpdated = this._updateAllOfflineNotesToNewNotebookId(id, notebookId);
      
      if(noteIdsUpdated.length) {
        const needUpdateNotebookIdToNotes$ = this._updateAllOfflineNotesToNewNotebookIdIndexDB(notebookId);
        forkJoin(needUpdateNotebookIdToNotes$).subscribe(()=> {
          const noteSyncQueues = this._getListNoteSyncQueueByNotebookIdOffline(notebookId);
          if (noteSyncQueues.length > 0) {
            this.syncQueueService.enqueue(noteSyncQueues);
          }
          this._notebooks.delete(id);
          this.notebookChanged();
        })
        this.notebookChanged();
      }else {
        this._notebooks.delete(id);
        this.notebookChanged();
      }
    
    };
    return { request$, onSuccess };
  }

  private _addNotebookToCurrentCMSLevelSyncQueue(notebookId: string, notebook: NotebookOffline): SyncQueueItem {
    const { cmsLevelValues, cmsType, id } = notebook;
    const _cmsLevelValues = CmsLevelValues.fromJson(cmsLevelValues);

    const request$ = this.caseManagementNavigationService.AddCMSNotebook(cmsType, _cmsLevelValues, notebookId, this.userProfileService.user.userID);
    const onSuccess = (res) => {
      console.log('[SYNC] AddNotebookToCurrentCMSLevel-results', res);
      this.toastr.success(`Notebook ${notebook.name} added to Current CMS Level`, 'Sync');
      this.syncedNotebooksSrouce.next({ oldNotebookId: id, newNotebookId: notebookId });
    };
    const onError = (error) => {
      this.toastr.error(error.message, 'SYNC ERROR');
      this.errorHandling.LogErrorToServer('NEW NOTEBOOK Caused CMS Project Error', error);
    };
    return { request$, onSuccess, onError };
  }

  private _updateAllOfflineNotesToNewNotebookId(oldNotebookId: string, newnotebookId: string) {
    const isNeedToUpdate = this._notebookMapWithNotes.has(oldNotebookId);
    if(!isNeedToUpdate) {
      return [];
    }
    const noteIds = Array.from(this._notebookMapWithNotes.get(oldNotebookId).values());
    noteIds.forEach((noteId) => {
      const note = this._notes.get(noteId);
      if(!note) {
        // should not go here
        throw new Error("Note not existing");
      };
      note.notebookId = newnotebookId;
    });
    console.log("verify notes has been update notebookId",  Array.from(this._notes.values()));

    const noteIdsSet = this._notebookMapWithNotes.get(oldNotebookId);
    this._notebookMapWithNotes.set(newnotebookId, noteIdsSet);
    this._notebookMapWithNotes.delete(oldNotebookId);
    return noteIds
  }

  private _updateAllOfflineNotesToNewNotebookIdIndexDB(newNotebookId: string) {
    const noteIds = Array.from(this._notebookMapWithNotes.get(newNotebookId).values());
    const addOrUpdateAllNoteToIndexDB$ = noteIds.map((noteId)=> {
      const note = this._notes.get(noteId);
      return from(this.notesOfflineService.addOrUpdateNoteToIndexDB(note.notebookId, note.noteId, note));
    })
    return addOrUpdateAllNoteToIndexDB$;
  }

  private _getListNoteSyncQueueByNotebookIdOffline(notebookId: string): SyncQueueItem[] {
    if (!this._notebookMapWithNotes.has(notebookId)) {
      return [];
    }
    const queues: SyncQueueItem[] = [];
    this._notebookMapWithNotes.get(notebookId).forEach((noteId) => {
      const note = this._notes.get(noteId);
      const queue = this._getNoteSyncQueue(note);
      queues.push(queue);
    });
    return queues;
  }

  private _getNoteSyncQueue(note: NoteOffline): SyncQueueItem {
    const request$ = note.saveNoteToServer();
    const onSuccess = (res) => {
      const { success, result } = res;
      if (!success) {
        // should notice to User
        console.log('[SYNC] Error createforensicnote', res);
        this.toastr.error('Note Failed to Timestamp - Please contact support', 'SYNC ERROR');
        return;
      }
      this.notesOfflineService.removeNoteOfflineFromStore(note.noteId).then();
      this._notes.delete(note.noteId);
      this._notebookMapWithNotes.get(note.notebookId).delete(note.noteId);
      this.noteChanged();
      this.syncedNotesSrouce.next({ note, syncedAll: Array.from(this._notes.values()).length === 0 });
    };
    const onError = (error) => {
      const _note = this._notes.get(note.noteId);
      _note.isSyncFail = true;
      this.noteChanged();
      if(this.networkConnectionService.isOnline) {
        this.toastr.error(error.message, 'SYNC ERROR');
        this.errorHandling.LogErrorToServer('Add SYNC NOTE Caused Error', error);
      }else {
        this.retrySyncAgain(note.noteId);
      }
    };
    return { request$, onSuccess, onError };
  }

  private notebookChanged() {
    const notebooks = Array.from(this._notebooks.values()).sort((a, b) => {
      if (a.created < b.created) {
        return 1;
      }
      if (a.created > b.created) {
        return -1;
      }
      return 0;
    });
    this._noteBooksSrouce$.next([...notebooks]);
  }

  private noteChanged() {
    const notes = Array.from(this._notes.values());
    this._notesSrouce$.next([...notes]);
  }
}

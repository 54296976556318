import { TforensicNotebookNoteListAttachment } from './tforensicNotebookNoteListAttachment';
import { TforensicDateString } from './tforensicDateString';
import { tforensicnoteBookmarkDetails } from './tforensicnoteBookmarkDetails';
import stringUtils from '../shared/Utility/stringUtils';

// ==========================================================
// Class to assist with Display of Witness Request Info
// ==========================================================
export class requestSubmission{
  submittedNoteDate_String: TforensicDateString;
  numberOfFiles: number;
  noteID: string;
}


// ==========================================================
// Forensic Note Object class
// ==========================================================
export class TforensicNote {
  associatedToParentId: string = "";
  noteId: string = "";
  nodeGroupID: string = "";                 // Notebook ID
  nodeGroupTitle: string = "";              // Notebook Title
  timestampDate: string = "";
  timestampDate_String: string = "";
  noteDate: any;
  noteDateUTCOriginal: any;
  isConvertedToLocal: Boolean = false; // Set to true once noteDateUTCOriginal is set
  versionNumber: string = "";
  versionTimestamped: boolean;
  showTimestampButton: boolean = false;  // Angular App Use Only -  New Notes entered are shown, but not timestamped. We do NOT want to show Timestamp button to user as timestamp process in process.
  versionMarkedAsDeleted: boolean;
  mostRecentVersionComments: string = "";
  mostRecentNoteIDInChain: string = "";
  noteText: string = "";
  noteTextSummary: string = "";             // Used for Filter display to show part of note when filtered out - Client Side use ONLY!
  noteTitle: string = "";
  noteContentType: number = 1;
  //  attachments: any[];
  attachments: TforensicNotebookNoteListAttachment[];

  fullCount: any;
  success: boolean;
  result: TforensicNote[];
  noteDate_String: TforensicDateString;
  dateDotNetUTCinTicks: number = 0;
  isBookmarked: boolean = false;
  bookmark: tforensicnoteBookmarkDetails = null; // NULL if NOT Set.
  isVisible: boolean = true;                    // Angular App Use Only -  if false, then this note will be hidden in the UI (Search / Filter)
  isVisiblePreSearchTerm: boolean = true;       // Angular App Use Only -  if false, then this note will be hidden in the UI (Search / Filter) - BEFORE Search Term entered - to allow us to easily revert for search/Filter
  isShow: boolean = true;
  // --------------------------------------------------------------------------------
  // Following are for Display Purposes on EditNote page to avoid method calls
  // --------------------------------------------------------------------------------
  downloadNoteShow: boolean = true;
  downloadNoteEnabled: boolean = true;
  viewPrevNoteShow: boolean = true;
  viewPrevNoteEnabled: boolean = true;
  editNoteShow: boolean = true;
  editNoteEnabled: boolean = true
  editImageShow: boolean = true;
  editImageEnabled: boolean = true

  // Client-Side Helpers
  hash: string = "";
  timestampDotNetTicks: number = 0;

  // Mark Note as Deleted - Timestamped Notes
  deleteMarkNoteShow: boolean = true;
  deleteMarkNoteEnabled: boolean = true

  // Delete - ONLY for NonTimestamped
  deleteNoteShow: boolean = false;

  // Bookmark
  bookmarkNoteShow: boolean = true;
  bookmarkNoteEnabled: boolean = true

  // Person Entity
  personEntityShow: boolean = false;

  // isNewNote
  // With Forensic Witness (File Request) the Notes are created by the Worker app
  // As a result, it does not have access to the actual Azure services to look up the note
  // so we can check for isNewNote and if Yes, then download the note from the server.
  isNewNote: boolean = false;

  // show/hide timestamp spinner
  isTimestampInProgress: boolean = false;
  ngClass_getNoteCSS: any = "";
  ngStyle_getDateTitleClass: any = "";

  // -------------------------------------------------------------------
  // Witness Request Display Assistant
  // Will ONLY be used for Original File Request Note to assist with
  // display its 0 to Many children notes
  // -------------------------------------------------------------------
  requestSubmissions: requestSubmission[] = [];


  // ========================================================================
  // Converts the API Results to a TforensicNote Array of Objects
  // ========================================================================
  public static SetupNoteObjectInitialValuesFromArray(notesArr: any[]) : TforensicNote[]{

    console.log("SetupNoteObjectInitialValuesFromArray - IndexDB", notesArr)

    let currObj: TforensicNote;

    let noteArray: TforensicNote[] = [];

      
    for (var i = 0; i < notesArr.length; i++) {
      console.log("Checking Notes #" + i);

      currObj = new TforensicNote();
      Object.assign(currObj, notesArr[i]);

      // currObj = notesArr[i];

      currObj = this.SetupNoteObjectInitialValues(currObj);

      // // ----------------------------
      // // File Request Submission?
      // // ----------------------------
      // if(currObj.noteContentType == 9){
      //   // ----------------------------------------------------------
      //   // Submitted Files - Update Parent Original File Request)
      //   // ----------------------------------------------------------

      //   console.log("PRequest --> currObj", currObj);

      //   let reqSub: requestSubmission = new requestSubmission();

      //   reqSub.noteID = currObj.noteId;
      //   // -------------------------------------------------------
      //   // remove 2 FN generated to get actual number of 
      //   // attachments submitted by witness
      //   // -------------------------------------------------------
      //   reqSub.numberOfFiles = currObj.attachments.length - 2;  
      //   reqSub.submittedNoteDate_String = currObj.noteDate_String;
        
      //   // --------------------------------------------------------------------
      //   // Need to find the parent to push the submission into that Note obj
      //   // NOTE: We do NOT need to load the entire Note Object Array (notesArr)
      //   // but instead the current notes that have been processed ABOVE this
      //   // latest note. Since the notes are loaded in order, then we only have
      //   // to look at the previous ones that have already been processed!
      //   // --------------------------------------------------------------------
      //   this.updateParentNoteWithWitnessRequestSubmission(noteArray, currObj.associatedToParentId, reqSub);
      // }



      noteArray.push(currObj);

      // console.log("Current Note --> AFTER Setup", currObj);

      // console.log("currObj.mostRecentNoteIDInChain", currObj.mostRecentNoteIDInChain);
    }

    // this.checkNotesForWitnessRequestSubmissions(noteArray);

    return noteArray;

  }

  public static checkNotesForWitnessRequestSubmissions(notesArray: TforensicNote[]){
    for (var i = 0; i < notesArray.length; i++) {

      let currObj = new TforensicNote();
      Object.assign(currObj, notesArray[i]);

      // ----------------------------
      // File Request Submission?
      // ----------------------------
      if(currObj.noteContentType == 9){
        // ----------------------------------------------------------
        // Submitted Files - Update Parent Original File Request)
        // ----------------------------------------------------------

        console.log("PRequest --> currObj", currObj);

        let reqSub: requestSubmission = new requestSubmission();

        reqSub.noteID = currObj.noteId;
        // -------------------------------------------------------
        // remove 2 FN generated to get actual number of 
        // attachments submitted by witness
        // -------------------------------------------------------
        reqSub.numberOfFiles = currObj.attachments.length - 2;  
        reqSub.submittedNoteDate_String = currObj.noteDate_String;
        
        // --------------------------------------------------------------------
        // Need to find the parent to push the submission into that Note obj
        // NOTE: We do NOT need to load the entire Note Object Array (notesArr)
        // but instead the current notes that have been processed ABOVE this
        // latest note. Since the notes are loaded in order, then we only have
        // to look at the previous ones that have already been processed!
        // --------------------------------------------------------------------
        this.updateParentNoteWithWitnessRequestSubmission(notesArray, currObj.associatedToParentId, reqSub);
      }
    }
  }


  // ============================================================================
  // Helper Method for Updating Original File Request with links to submissions
  // ============================================================================
  private static updateParentNoteWithWitnessRequestSubmission(notesArray: TforensicNote[], noteIdToFind: string, reqSub: requestSubmission){

    for (var i = 0; i < notesArray.length; i++) {
      // console.log("PRequest --> Checking Notes #" + i, notesArray[i]);
      // console.log("PRequest --> Looking for NoteID = ", noteIdToFind, notesArray[i].noteId);

      if(notesArray[i].noteId == noteIdToFind){
        // found it
        notesArray[i].requestSubmissions.push(reqSub);
        return;
      }
    }
      // if we get here, then we did NOT find the note as expected.
      console.error("updateParentNoteWithWitnessRequestSubmission() failed");
  }



  // ===========================================================
  // Sets up the Note for Proper Display - CSS - Styles
  // NOTE: If you do NOT do this, then the values do NOT get
  // properly set (I don't think)
  // ===========================================================
  public static SetupNoteObjectInitialValues(currObj: TforensicNote): TforensicNote {

    console.log("SetupNoteObjectInitialValues -- TforensicNote = ", currObj);
    console.log("SetupNoteObjectInitialValues -- Timestamped = " + currObj.versionTimestamped);

    currObj.isVisible = true;
    currObj.isShow = true;

    //currObj.ngStyle_getDateTitleClass = this.getDateTitleClass(currObj);


    // Menu Options
    currObj.downloadNoteShow = true;
    currObj.downloadNoteEnabled = true;
    currObj.viewPrevNoteShow = true;
    currObj.viewPrevNoteEnabled = true;
    currObj.editNoteShow = true;
    currObj.editNoteEnabled = true;
    currObj.editImageShow = true;
    currObj.editImageEnabled = true;
    currObj.deleteMarkNoteShow = true;
    currObj.deleteMarkNoteEnabled = true;
    currObj.deleteNoteShow = false;
    currObj.bookmarkNoteShow = true;
    currObj.bookmarkNoteEnabled = true;

    // Timestamp Spinner - By default, hide
    currObj.isTimestampInProgress = false;

    currObj.attachments.forEach((attachment: TforensicNotebookNoteListAttachment) => {
      console.log("attachment", attachment);
      console.log("attachment", attachment.attachmentName);

      attachment.extension = stringUtils.getExtensionOnly(attachment.attachmentName);
      attachment.filenameNoExtension = stringUtils.getFileNameNoExtension(attachment.attachmentName);
    });


    console.log("SetupNoteObjectInitialValues... LEAVING", currObj);

    return currObj;
  }

}

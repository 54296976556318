import stringUtils from "../shared/Utility/stringUtils";

export class IncidentModel {
    id: string = "";
    yearOfIncident: string = "";
    incidentNumber: string = "";
    incidentTypeId: string = "";
    incidentTypeDisplay: string = "";
    notes: string = "";
    projectId: string = "";
    projectTitle: string = "";
    requestingUnitid: string = "";

    // Derived
    requestingUnitName: string = "";
    calc_IncidentNumberWithIncidentType: string = "";

    // UI Helpers
    isEdit: boolean = false;    // true if object was edit (updated)


    LoadFromAPIResult(result: any){
        console.log("LoadFromAPIResult", result);

        this.id = result.base.id;
        this.yearOfIncident = result.base.yearOfIncident;
        this.incidentNumber = result.base.incidentNumber;
        this.incidentTypeId = result.base.incidentTypeId;
        this.notes = stringUtils.replaceNewLineWithBR(result.base.notes);
        this.projectId = result.base.projectId;
        this.requestingUnitid = result.base.requestingUnitid;

        // Derived
        this.incidentTypeDisplay = result.derived.incidentTypeLabel;
        this.requestingUnitName = result.derived.requestingUnitid;
        this.projectTitle = result.derived.projectTitle;
        this.requestingUnitid = result.derived.requestingUnitid;

    }



} 

    
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { AutoCompleteAllModule, AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentTypeModel } from 'src/app/domain/IncidentType';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-case-management-received-method-add',
  templateUrl: './case-management-received-method-add.component.html',
  styleUrls: ['./case-management-received-method-add.component.scss']
})
export class CaseManagementReceivedMethodAddComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = true;

  public isEditMode : boolean = false;

  public showUpdatingSpinner: boolean = false;

  @Output() newEvent = new EventEmitter<boolean>();

  updateNewEvent(value: boolean) {
    this.newEvent.emit(value);
  }


  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';
  // public dialogDragTarget = '.setting-page';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };

  btnText: string = "Add";          // Can be either Add or Update

  textBoxValue: string = "";
  ID: string = "";


  // ===================================
  // CONSTRUCTOR
  // ===================================
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }




  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
  }

  public onDialogClose(event: any): void {
    this.hide();
  }

  SetupForNew() {
    this.isLoading = false;
    this.btnText = "Add";
  }

  SetupForEditing(id : string, value : string) {
    this.isLoading = true;
    this.isEditMode = true;
    this.btnText = "Update";

    this.ID = id;
    this.textBoxValue = value;

    this.isLoading = false;
  }

  AddUpdate() {

    if(this.isEditMode){
     // textBoxValue already set
    }
    else{
      // New Record
      this.ID = uuidv4();
    }

    // Show updating Spinner
    this.showUpdatingSpinner = true;


    this.caseManagementService.AddUpdateExhibitReceivingMethod(this.ID, this.textBoxValue)
      .subscribe((result) => {

        this.showUpdatingSpinner = false;

        console.log("result", result);

        // Assume success as result.success returns false?
        if(this.isEditMode){
        this.toastr.success("Exhibit Receiving Method Updated");
        }
        else{
          // added
          this.toastr.success("Exhibit Receiving Method Added");
        }

        // --------------------------------------------------------------------------------------
        // Make sure the parent calling this updates it data to show the new Incident #.
        // --------------------------------------------------------------------------------------
        this.updateNewEvent(true);

        // Reset Values
        this.textBoxValue = "";

        this.hide();
      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to update Exhibit Receiving Method, please contact support@forensicnotes.com", "ERROR");
        });
  }

  Cancel(){
     // Reset Values
     this.textBoxValue = "";

     this.hide();
  }

}

import { Injectable } from '@angular/core';
import { AdditionalData, FileData, NoteOffline, NoteOfflineData, NoteOfflineType } from './models/note-offline';
import { IndexDBService } from '../indexDB/index-db.service';
import { SyncQueueService } from './sync-queue.service';
import { ToastrService } from 'ngx-toastr';
import { UserProfileService } from '../user-profile.service';
import ErrorHandling from 'src/app/shared/Utility/ErrorHandling';
import { SyncQueueItem } from './models/sync';
import { v4 as newGUID } from 'uuid';
import { PGPEncryptionService } from '../pgpencryption.service';
import { ClientEncryptionPasswordService } from '../client-encryption-password.service';
import { PersonEntityModel } from 'src/app/shared/customObjects/personEntityModel';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotesOfflineService {
  readonly indexDBStoreName = this.indexDBService.OBJECT_STORE_NAME_OFFLINE_NOTES;

  constructor(private indexDBService: IndexDBService, private pgpEncryptionService: PGPEncryptionService, private clientEncryptionPasswordService: ClientEncryptionPasswordService) {}

  async createNoteOffline(notebookId: string, type: NoteOfflineType, noteData: NoteOfflineData, additionalData?: {files?: File[], person?: PersonEntityModel}): Promise<NoteOffline> {
    if (!this.indexDBService.indexDB) {
      console.error('INDEX-DB --> this.indexDBService.indexDB not set');
      throw new Error('IndexDB not Initialize');
    }
    const { noteId } = noteData;
    const { files, person } = additionalData || {};
    const _additionalData: AdditionalData = {};
    if(!!files) {
      const _fileData: FileData[] = await Promise.all(files.map(async (item) => {
        const { name, size } = item;
        const data = await item.arrayBuffer();
        return {name, size, data}
      }));
      _additionalData['files'] = _fileData;
    }
    if(!!person) {
      _additionalData['person'] = person;
    }
    return NoteOffline.create(noteId, notebookId, type, noteData, _additionalData);
  }

  async getAllNoteOfflines() {
    try {
      const _notes: NoteOffline[] = [];
      const KEK$ = this.clientEncryptionPasswordService.getKEKObs().pipe(filter(e=>!!e));
      const KEK = await firstValueFrom(KEK$);
      const tx = this.indexDBService.indexDB.transaction(this.indexDBStoreName, 'readwrite');
      const store = tx.objectStore(this.indexDBStoreName);
      const index = store.index('noteId');
      const records = (await index.getAll()) as any[];

      if (!records || records.length === 0) {
        return [];
      }
      for await (const record of records) {
        const { id, encryptedCEK, encryptedDataJSON, additionalData } = record;

        // Decrypted CEK
        const decryptedCEK: string = this.pgpEncryptionService.decryptData(encryptedCEK, KEK);

        // Decrypt JSON with CEK
        const decryptedJSON: string = this.pgpEncryptionService.decryptData(encryptedDataJSON, decryptedCEK);
        const jsonData = JSON.parse(decryptedJSON);
        
        const note = NoteOffline.fromJson(jsonData, additionalData);
        _notes.push(note);
      }
      return _notes;
    } catch (error) {
      console.error(error);
      throw new Error('Can not get notes offline');
    }
  }

  async addOrUpdateNoteToIndexDB(notebookId: string, noteId: string, note: NoteOffline) {
    if (!notebookId) {
      throw new Error('NotebookId is Empty');
    }
    try {
      const KEK = await this.clientEncryptionPasswordService.getKEKPromise();
      const { json, additionalData } = note.getDataToStore();
      const contentEncryptionKey: string = newGUID();
      const encryptedCEK: string = this.pgpEncryptionService.encryptData(contentEncryptionKey, KEK);
      const encryptedDataJSON: string = this.pgpEncryptionService.encryptData(json, contentEncryptionKey);

      const isExisting = await this.indexDBService.indexDB.get(this.indexDBService.OBJECT_STORE_NAME_OFFLINE_NOTES, noteId);
   
      if (isExisting) {
        // not hanlding now
        await this.removeNoteOfflineFromStore(noteId);
      }

      await this.indexDBService.indexDB.add(this.indexDBStoreName, {
        id: note.noteId, // should use note.noteId to update current noteId for note, instead of using oldNoteId
        notebookId,
        encryptedCEK,
        encryptedDataJSON,
        additionalData
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async removeNoteOfflineFromStore(id: string) {
    const tx = this.indexDBService.indexDB.transaction(this.indexDBStoreName, 'readwrite');
    const store = tx.objectStore(this.indexDBStoreName);
    await store.delete(id);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementBinTypeRoutingModule } from './case-management-bin-type-routing.module';
import { CaseManagementBinTypeComponent } from './case-management-bin-type.component';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementBinTypeAddModule } from '../case-management-bin-type-add/case-management-bin-type-add.module';



@NgModule({
  declarations: [
    CaseManagementBinTypeComponent
  ],
  imports: [
    CommonModule,
    CaseManagementBinTypeRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementBinTypeAddModule
  ],
  exports: [
    CaseManagementBinTypeComponent
  ]
})
export class CaseManagementBinTypeModule { }

import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { skip, take } from "rxjs/operators";
import { AppComponent } from "src/app/app.component";
import { NetworkConnectionService } from "src/app/service/network-connection.service";
import { ForensicProjectServiceV2 } from "src/app/service/project-v2.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProjectLevelNaming {

    // ------------------------------------------------------------------------------------
    // DEFAULT Values - So UI has something to display if network slow or non-existent
    // ------------------------------------------------------------------------------------
    masterName: string = "";
    level1Name: string = "";
    level2Name: string = "";
    level3Name: string = "Department";
    level4Name: string = "Work Request";
    level5Name: string = "Exhibit";
    level6Name: string = "Exhibit Detail";

    // Show Notebooks Tab in UI?
    level1AllowNotebooks: boolean = true;
    level2AllowNotebooks: boolean = true;
    level3AllowNotebooks: boolean = true;
    level4AllowNotebooks: boolean = true;
    level5AllowNotebooks: boolean = true;
    level6AllowNotebooks: boolean = true;


    // Show Resources Tab in UI?
    level1AllowResources: boolean = true;
    level2AllowResources: boolean = true;
    level3AllowResources: boolean = true;
    level4AllowResources: boolean = true;
    level5AllowResources: boolean = true;
    level6AllowResources: boolean = true;

    // Show Diary Tasks Tab in UI?
    level1AllowDiaryTasks: boolean = true;
    level2AllowDiaryTasks: boolean = true;
    level3AllowDiaryTasks: boolean = true;
    level4AllowDiaryTasks: boolean = true;
    level5AllowDiaryTasks: boolean = true;
    level6AllowDiaryTasks: boolean = true;

    // Show Final Reports Tab in UI?
    level1AllowFinalReports: boolean = true;
    level2AllowFinalReports: boolean = true;
    level3AllowFinalReports: boolean = true;
    level4AllowFinalReports: boolean = true;
    level5AllowFinalReports: boolean = true;
    level6AllowFinalReports: boolean = true;


    constructor(
        private forensicProjectService: ForensicProjectServiceV2,
        private toastr: ToastrService,
        // private appComponent: AppComponent,  // Can't inject as this class is injected into it!
    ) {

        console.log("default constructor for ProjectLevelNaming()");
    }


    ProjectLevelNaming() {

        this.forensicProjectService
            .getProjectLevels()
            .subscribe((result) => {

                console.log("ProjectsLevels", result);

                this.LoadResultsFromAPI(result);

            },
                (error) => {

                    console.error(error);

                    this.masterName = "(reload app)";
                    this.level1Name = "(reload app)";
                    this.level2Name = "(reload app)";
                    this.level3Name = "(reload app)";
                    this.level4Name = "(reload app)";
                    this.level5Name = "(reload app)";
                    this.level6Name = "(reload app)";

                    // ---------------------------------------------------
                    // Reload of App should get the values properly set.
                    // ---------------------------------------------------
                    this.toastr.info("App Loading Error...<br><a href='/'>Click here to Reload</a>", "ACTION REQUIRED", { enableHtml: true });
                });

    }

    // ============================================================
    //
    // ============================================================
    LoadResultsFromAPI(result: any) {

        console.log("LoadResultsFromAPI - ProjectLevelNaming");

        let projectLevels: string[] = result.result;

        // Currently, we expect a set amount of levels

        let currLevel: number = 0;

        // Master - Side Menu
        this.masterName = projectLevels[currLevel]["levelLabel"];



        // Level 1
        currLevel = 1;
        this.level1Name = projectLevels[currLevel]["levelLabel"];
        this.level1AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level1AllowResources = projectLevels[currLevel]["allowResources"];
        this.level1AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level1AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        // Level 2
        currLevel = 2;
        this.level2Name = projectLevels[2]["levelLabel"];
        this.level2AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level2AllowResources = projectLevels[currLevel]["allowResources"];
        this.level2AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level2AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        // Level 3
        currLevel = 3;
        this.level3Name = projectLevels[3]["levelLabel"];
        this.level3AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level3AllowResources = projectLevels[currLevel]["allowResources"];
        this.level3AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level3AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        // Level 4
        currLevel = 4;
        this.level4Name = projectLevels[4]["levelLabel"];
        this.level4AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level4AllowResources = projectLevels[currLevel]["allowResources"];
        this.level4AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level4AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        // Level 5
        currLevel = 5;
        this.level5Name = projectLevels[5]["levelLabel"];
        this.level5AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level5AllowResources = projectLevels[currLevel]["allowResources"];
        this.level5AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level5AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        // Level 6
        currLevel = 6;
        this.level6Name = projectLevels[6]["levelLabel"];
        this.level6AllowNotebooks = projectLevels[currLevel]["allowNotebooks"];
        this.level6AllowResources = projectLevels[currLevel]["allowResources"];
        this.level6AllowDiaryTasks = projectLevels[currLevel]["allowDiaryTasks"];
        this.level6AllowFinalReports = projectLevels[currLevel]["allowFinalReports"];

        console.log("level6", this.level6Name);
    }

    // ============================================================
    //
    // ============================================================
    SetupDefaultLEO(projectLevelNaming: ProjectLevelNaming): ProjectLevelNaming {
        projectLevelNaming.masterName = "Project";
        projectLevelNaming.level1Name = "Project";
        projectLevelNaming.level2Name = "Incident #";
        projectLevelNaming.level3Name = "Section";
        projectLevelNaming.level4Name = "Work Request";
        projectLevelNaming.level5Name = "Exhibit";
        projectLevelNaming.level6Name = "Exhibit Detail";

        return projectLevelNaming;
    }

    // ============================================================
    //
    // ============================================================
    SetupDefaultPrivateIndustry(projectLevelNaming: ProjectLevelNaming): ProjectLevelNaming {
        projectLevelNaming.masterName = "Case";
        projectLevelNaming.level1Name = "Client";
        projectLevelNaming.level2Name = "File #";
        projectLevelNaming.level3Name = "Department";
        projectLevelNaming.level4Name = "Work Request";
        projectLevelNaming.level5Name = "Exhibit";
        projectLevelNaming.level6Name = "Exhibit Detail";

        return projectLevelNaming;
    }


    // ============================================================
    //
    // ============================================================
    showNotebookTab(projectLevel: number): boolean {

        console.log("showNotebookTab", projectLevel);

        switch (projectLevel) {
            case 1:
                return this.level1AllowNotebooks;
            case 2:
                return this.level2AllowNotebooks;
            case 3:
                return this.level3AllowNotebooks;
            case 4:
                return this.level4AllowNotebooks;
            case 5:
                return this.level5AllowNotebooks;
            case 6:
                return this.level5AllowNotebooks;
            default:
                return false;
        }



        return false;
    }

    showResourceTab(projectLevel: number): boolean {

        console.log("showResourceTab", projectLevel);

        switch (projectLevel) {
            case 1:
                return this.level1AllowResources;
            case 2:
                return this.level2AllowResources;
            case 3:
                return this.level3AllowResources;
            case 4:
                return this.level4AllowResources;
            case 5:
                return this.level5AllowResources;
            case 6:
                return this.level6AllowResources;
            default:
                return false;
        }
        return false;
    }

    showDiaryTasksTab(projectLevel: number): boolean {

        console.log("showResourceTab", projectLevel);

        switch (projectLevel) {
            case 1:
                return this.level1AllowDiaryTasks;
            case 2:
                return this.level2AllowDiaryTasks;
            case 3:
                return this.level3AllowDiaryTasks;
            case 4:
                return this.level4AllowDiaryTasks;
            case 5:
                return this.level5AllowDiaryTasks;
            case 6:
                return this.level6AllowDiaryTasks;
            default:
                return false;
        }
        return false;
    }

    showFinalReportTab(projectLevel: number): boolean {

        console.log("showFinalReportTab", projectLevel);

        switch (projectLevel) {
            case 1:
                return this.level1AllowFinalReports;
            case 2:
                return this.level2AllowFinalReports;
            case 3:
                return this.level3AllowFinalReports;
            case 4:
                return this.level4AllowFinalReports;
            case 5:
                return this.level5AllowFinalReports;
            case 6:
                return this.level6AllowFinalReports;
            default:
                return false;
        }
        return false;
    }

}


export class CmsLevelValues {

    currFilterLevel: number = 0;
    lv1ClientProjectID: string = "";
    lv2IncidentFileID: string = "";
    lv3DeptSectionID: string = "";
    lv4WorkRequestID: string = "";
    lv5ExhibitsWorkRequestTaskID: string = "";
    lv6ExhibitID: string = "";

    Load(currFilterLevel: number, lv1ClientProjectID: string, lv2IncidentFileID: string, lv3DeptSectionID: string,
        lv4WorkRequestID: string, lv5ExhibitsWorkRequestTaskID: string, lv6ExhibitID: string) {

        if (!currFilterLevel) {
            // -----------------------------------
            // We are at the BASE CMS Display
            // No values to load, use defaults.
            // -----------------------------------
            return;
        }


        this.currFilterLevel = currFilterLevel;
        this.lv1ClientProjectID = lv1ClientProjectID;
        this.lv2IncidentFileID = lv2IncidentFileID;
        this.lv3DeptSectionID = lv3DeptSectionID;
        this.lv4WorkRequestID = lv4WorkRequestID;
        this.lv5ExhibitsWorkRequestTaskID = lv5ExhibitsWorkRequestTaskID;
        this.lv6ExhibitID = lv6ExhibitID;


        // For Testing...
        console.log("currFilterLevel", this.currFilterLevel);
        console.log("lv1ClientProjectID", this.lv1ClientProjectID);
        console.log("lv2IncidentFileID", this.lv2IncidentFileID);
        console.log("lv3DeptSectionID", this.lv3DeptSectionID);
        console.log("lv4WorkRequestID", this.lv4WorkRequestID);
        console.log("lv5ExhibitsWorkRequestTaskID", this.lv5ExhibitsWorkRequestTaskID);
        console.log("lv6ExhibitID", this.lv6ExhibitID);
    }


    // Return the correct Level ID
    public getCurrentLevelID() : string {
        switch(this.currFilterLevel){

            // NOTE: NOT TESTED!!!!!

            case 0:
                return "";
            case 1:
                return this.lv1ClientProjectID;
            case 2:
                return this.lv2IncidentFileID;
            case 3:
                return this.lv3DeptSectionID;
            case 4:
                return this.lv4WorkRequestID;
            case 5:
                return this.lv5ExhibitsWorkRequestTaskID;
            case 6:
                return this.lv6ExhibitID;
            default:
                return "NOT SET";

        }
    }

    toJson() {
        return {
            currFilterLevel: this.currFilterLevel,
            lv1ClientProjectID: this.lv1ClientProjectID,
            lv2IncidentFileID: this.lv2IncidentFileID,
            lv3DeptSectionID: this.lv3DeptSectionID,
            lv4WorkRequestID: this.lv4WorkRequestID,
            lv5ExhibitsWorkRequestTaskID: this.lv5ExhibitsWorkRequestTaskID,
            lv6ExhibitID: this.lv6ExhibitID
        }
    }

    static fromJson(data: any) {
        const { currFilterLevel, lv1ClientProjectID, lv2IncidentFileID, lv3DeptSectionID, lv4WorkRequestID, lv5ExhibitsWorkRequestTaskID, lv6ExhibitID } = data; 
        const cmsLevelValues = new CmsLevelValues()
        cmsLevelValues.Load(currFilterLevel, lv1ClientProjectID, lv2IncidentFileID, lv3DeptSectionID, lv4WorkRequestID, lv5ExhibitsWorkRequestTaskID, lv6ExhibitID);
        return cmsLevelValues
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotebookRenameComponent } from './notebook-rename.component';

const routes: Routes = [{ path: '', component: NotebookRenameComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotebookRenameRoutingModule { }

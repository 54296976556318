import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementIncidentTypeAddRoutingModule } from './case-management-incident-type-add-routing.module';
import { CaseManagementIncidentTypeAddComponent } from './case-management-incident-type-add.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    CaseManagementIncidentTypeAddComponent
  ],
  imports: [
    CommonModule,
    CaseManagementIncidentTypeAddRoutingModule,
    DialogModule,
    SharedModule,
  ],
  exports:[
    CaseManagementIncidentTypeAddComponent
  ]
})
export class CaseManagementIncidentTypeAddModule { }

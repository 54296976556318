import { BrandingService } from '../../../service/branding.service';
import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  Inject,
  Input,
} from '@angular/core';
import { Dialog, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
import { environment } from './../../../../environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForensicUserProfileService } from '../../../service/forensicUserProfile.service';
import { UpdateBrandModel } from '../../../domain/UpdateBrand';
import { APP_CONFIG, AppConfig } from '../../../app-config.module';
import { ToastrService } from 'ngx-toastr';
import { protectedResources } from '../../../auth-config';
import { AuthTokenService } from 'src/app/service/auth-token.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-branding-setting',
  templateUrl: './branding-setting.component.html',
  styleUrls: ['./branding-setting.component.scss'],
  providers: [DatePipe,
  ],
})
export class BrandingSettingComponent implements OnInit {

  urlBase = protectedResources.apiBaseUrl.endpoint;


  public imageSettings: object = {
    saveFormat: 'Base64',
  };

  @ViewChild("ejDialogNotebookName")
  ejDialogNotebookName: Dialog;

  @ViewChild("ejDialogOwnerName")
  ejDialogOwnerName: Dialog;

  @ViewChild("ejDialogDates")
  ejDialogDates: Dialog;

  @ViewChild("ejDialogTimeZone")
  ejDialogTimeZone: Dialog;

  @ViewChild('ejDialogOrganization', { static: true })
  ejDialogOrganization: DialogComponent;
  @ViewChild('containerOrg', { static: true, read: ElementRef })
  containerOrg: ElementRef;

  updateBrand: UpdateBrandModel = new UpdateBrandModel();

  @Input()
  isComponentVisible: boolean = false;

  public inlineMode: object = { enable: true, onSelection: true };

  public format: Object = {
    width: 'auto'
  };
  public fontFamily: Object = {
    width: 'auto'
  };


  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'FontColor',
      'BackgroundColor',
      'OrderedList',
      'UnorderedList',
      '|',
      'Undo',
      'Redo',
    ],
    // items: ['Bold', 'Italic', 'Underline','FontColor', 'BackgroundColor', 'OrderedList', 'UnorderedList','|','Image', '|', 'Undo', 'Redo']
  };


  isInitialPageLoad: boolean = true;


  // organizationName: string;
  // logoPath: string;
  // bannerPath: string;
  // path: string;
  // newnotebookDisclaimer: string = "";
  todate: any;
  fromdate: any;
  showLogo: boolean = false;
  showbanner: boolean = false;
  isGlobalAdmin: boolean;
  bannerUploded: boolean = false;

  constructor(
    private _userProfileService: ForensicUserProfileService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private _brandingService: BrandingService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private toastr: ToastrService,
    private authTokenService: AuthTokenService,
    public appComponent: AppComponent,

  ) {
    this.todate = new Date();
    this.fromdate = new Date();
    this.fromdate = this.fromdate.setMonth(this.fromdate.getMonth() - 1);
    this.todate = this.datePipe.transform(this.todate, 'yyyy-MM-dd @ hh:mm');
    this.fromdate = this.datePipe.transform(
      this.fromdate,
      'yyyy-MM-dd @ hh:mm'
    );



  }


  ngOnInit() {
    console.log("Calling getUserProfile");


  }

  LoadControl(){
    this.isComponentVisible = true;
    this.authTokenService.refreshAuthTokenIfExpired();
    this.ReloadBrandingInfo();

  }

  // private RefreshAuthToek(){
  //   // ---------------------------------------------------------
  //   // Check if Token expired
  //   // ---------------------------------------------------------
  //   if (this.authTokenService.isTokenExpired()) {
  //     // ---------------------------------------------------------
  //     // Token is expired - Uploads will not be allowed as
  //     // it won't refresh the token automatically.
  //     // ---------------------------------------------------------
  //     this.authTokenService.RefreshToken();
  //     this.toastr.info(this.authTokenService.getTryAgainRefreshTokenMsg(), "WARNING",
  //       { enableHtml: true });

  //     console.error("Login Token Expired - Attempting to Refresh");
  //     return;
  //   }
  // }


  private ReloadBrandingInfo() {
    this._brandingService.GetBrandingDetails(this.appComponent.securityUtils.isServiceWorkerCacheEnabled(), this.appComponent.user.userID).subscribe((r) => {
      console.log('ReloadBrandingInfo: ', r);
      if (r.success == true) {
         console.log("Calling GetBrandingDetails obtained");

        this.updateBrand.organizationName = r.result.organizationName;
        if (
          r.result.bannerPath == '' &&
          r.result.logoPath == '' &&
          r.result.organizationName == ''
        ) {
          // ----------------------
          // Show DEFAULT FN Logo
          // ----------------------
          console.log("ngOnInit() - Showing FN Logo");
          // this.path =
          //   'https://fnpublic.blob.core.windows.net/banners/Forensic_Notes_Logo.png';

            this.updateBrand.logoPath =
            'https://fnpublic.blob.core.windows.net/banners/Forensic_Notes_Logo.png';
        }
        else if (r.result.bannerPath != '') {
          // ------------------------------------------------
          // Show Banner - Overrides other values if set
          // ------------------------------------------------
          console.log("ngOnInit() - Showing Banner");
          // this.path = r.result.bannerPath;
          this.updateBrand.bannerPath = r.result.bannerPath;
          this.showbanner = true;
          this.showLogo = false;

          // -------------------------
          // Sets Banner for Popup
          // -------------------------
          this.updateBrand.bannerPath = r.result.bannerPath;
          // this.bannerPath = r.result.bannerPath;

        }
        else {
          // -------------------------
          // Show Logo with Text
          // -------------------------
          console.log("ngOnInit() - Showing Logo", r.result);

          // this.path = r.result.logoPath;
          this.updateBrand.logoPath = r.result.logoPath;
          this.showLogo = true;
          this.showbanner = false;

          console.log("Logo", this.updateBrand.logoPath);

          // -------------------------
          // Sets Logo for Popup
          // -------------------------
          // this.logoPath = r.result.logoPath;
          this.updateBrand.logoPath = r.result.logoPath;
        }


        // Set Disclaimer Text
        this.updateBrand.notebookDisclaimer = r.result.notebookDisclaimer;
        // this.newnotebookDisclaimer = r.result.notebookDisclaimer;
        console.log("Disclaimer Text = ", this.updateBrand.notebookDisclaimer);

        // Everything should now be loaded - Show page.
        this.isInitialPageLoad = false;

      }
      else {
        this.toastr.error("Unknown Error - Please contact support if you continue to experience this problem.")
      }
    },
      (error) => {
        this.toastr.error("Unknown Error - Please contact support if you continue to experience this problem.")
        console.error(error);

        this.updateBrand.logoPath =
          'https://fnpublic.blob.core.windows.net/banners/Forensic_Notes_Logo.png';

      });




  }



  public pathLogo = {
    saveUrl: this.urlBase + '/api/AccountAdmin/SaveLogo',
  };

  public pathBanner: Object = {
    saveUrl: this.urlBase + '/api/AccountAdmin/SaveBanner',
  };

  public addHeaders(args: any) {
    args.currentRequest.withCredentials = true;

    console.log("addHeaders --> token", this.authTokenService.token);

    args.currentRequest.setRequestHeader(
      'Authorization',
      'Bearer ' + this.authTokenService.token
    );

  }
  public onUploadSuccessLogo(args: any): void {

    console.log("onUploadSuccessLogo", args);

    // ---------------------------------------------------------------
    // Logo is Being Set...so remove value for Banner
    // IMPORTANT NOTE:
    // - Logo path SET by Upload Method
    // -- DO NOT SET HERE
    // --- We are ONLY removing the BannerPath by setting it to ""
    // so we do NOT WANT TO modify the actual updateBrand object!
    // ---------------------------------------------------------------
    let updateBrandTmp = new UpdateBrandModel();
    updateBrandTmp.logoPath = undefined;  // Do NOT UPDATE THIS VALUE with PATCH
    updateBrandTmp.bannerPath = '';
    updateBrandTmp.organizationName = this.updateBrand.organizationName;

    console.log("onUploadSuccessLogo - updateBrandTmp", updateBrandTmp);


    this._brandingService.UpdateBrandingDetails(updateBrandTmp).subscribe((r) => {
      console.log('onUploadSuccessLogo', r);
      // this.updateBrand.bannerPath = "";
      this.ReloadBrandingInfo();
    });

  }

  public onUploadFailureLogo(args: any): void {
    console.error("Logo Upload Failed", args);
    this.toastr.error("Please ensure the image you selected is a square logo.<br>Rectangular images are not accepted.", "Failed to Upload Logo", {enableHtml: true});


  }

  public onUploadSuccessBanner(args: any): void {

    // ---------------------------------------------------------------
    // Banner is Being Set...so remove values for OrgName and Logo
    // IMPORTANT NOTE:
    // - Banner path SET by UPLOAD Method
    // -- DO NOT SET HERE
    // --- We are ONLY removing the LogoPath by setting it to ""
    // so we do NOT WANT TO modify the actual updateBrand object!
    // ---------------------------------------------------------------
    let updateBrandTmp = new UpdateBrandModel();
    updateBrandTmp.organizationName = "";     // set blank as not required for Banner
    updateBrandTmp.logoPath = "";             // set blank as not required for Banner
    updateBrandTmp.bannerPath = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD

    console.log("onUploadSuccessBanner - updateBrandTmp", updateBrandTmp);

    this._brandingService.UpdateBrandingDetails(updateBrandTmp).subscribe((r) => {
      console.log("onUploadSuccessBanner", r);
      // ---------------------------------------------------------------
      // Banner is Set...so remove values for OrgName and Logo
      // ---------------------------------------------------------------
      // this.logoPath = "";
      this.updateBrand.logoPath = "";
      this.updateBrand.organizationName = "";

      this.ReloadBrandingInfo();

    });
  }

  public onUploadFailureBanner(args: any): void {
    this.toastr.error("Failed to Upload Banner");
  }

  public dropEleLogo: HTMLElement;
  public dropEleBanner: HTMLElement;


  // =================================
  // Update Org Name
  // =================================
  UpdateOrgName() {

    let updateBrandTmp = new UpdateBrandModel();
    updateBrandTmp.organizationName = this.updateBrand.organizationName;
    updateBrandTmp.logoPath = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD
    updateBrandTmp.bannerPath = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD
    updateBrandTmp.notebookDisclaimer =  undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD

    if (updateBrandTmp != null) {
      this._brandingService
        .UpdateBrandingDetails(updateBrandTmp)
        .subscribe((r) => {
          console.log('UpdateOrgName: ', r);
          if (r.success == true) {
            this.ReloadBrandingInfo();
            this.ejDialogOrganization.hide();
          }
        });
    }
  }


  public updateNotice() {
    let updateBrandTmp = new UpdateBrandModel();
    // updateBrandTmp.organizationName = this.organizationName;
    // updateBrandTmp.logoPath = this.logoPath;
    // updateBrandTmp.bannerPath = this.bannerPath;
    // updateBrandTmp.notebookDisclaimer = disclaimer;

    updateBrandTmp.notebookDisclaimer = this.updateBrand.notebookDisclaimer;
    updateBrandTmp.bannerPath = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD
    updateBrandTmp.logoPath = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD
    updateBrandTmp.organizationName = undefined;    // PATCH UPDATE - DO NOT UPDATE THIS FIELD


    console.log("updateBrandTmp",updateBrandTmp);

    if (updateBrandTmp != null) {
      this._brandingService
        .UpdateBrandingDetails(updateBrandTmp)
        .subscribe((r) => {
          if (r.success == true) {
            // this.ngOnInit();
            this.toastr.success("Disclaimer/Notice Updated");
          }
        },
          (error) => {
            console.error(error);

            if (this.updateBrand.notebookDisclaimer.indexOf("<img") > -1) {
              // Image included in Disclaimer... Currently not supported till we start saving to SQL Server.
              this.toastr.error("Image detected. We do not currently support images within the disclaimer area. Please contact support if this is a requirement."
                , "IMAGE ERROR");
            }
            else {
              this.toastr.error("Unknown error - Please contact support if this problem continues", "ERROR - Saving Disclaimer");
            }
          });
    }
  }

  public openOrgDialog = function (event: any): void {
    // Call the show method to open the Dialog
    this.ejDialogOrganization.show();
  };


  // ====================
  // Hide ALL Menus
  // ====================
  public onOverlayClick: EmitType<object> = () => {
    this.ejDialogOrganization.hide();
    this.ejDialogNotebookName.hide();
    this.ejDialogOwnerName.hide();
    this.ejDialogDates.hide();
    this.ejDialogTimeZone.hide();

  };

  closeOrg() {
    this.ejDialogOrganization.hide();
    document.body.classList.remove('k');
  }

}

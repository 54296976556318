import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotebookRenameComponent } from './notebook-rename.component';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatFormField } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NotebookRenameRoutingModule } from './notebook-rename-routing.module';

@NgModule({
  declarations: [NotebookRenameComponent],
  imports: [
    NotebookRenameRoutingModule,
    CommonModule,
    DialogModule,
    MatInputModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule, 
  ],
  exports: [
    NotebookRenameComponent
  ]
})
export class NotebookRenameModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';  

import { Tforensic } from '../domain/tforensic';
import { protectedResources } from '../auth-config';

import { APP_CONFIG,AppConfig } from '../app-config.module';

@Injectable({
  providedIn: 'root'
})

export class ForensicLoggerService   {
 
  
  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }
 
  url = protectedResources.apiBaseUrl.endpoint;

  LogErrorToServer(exception: string, stackTrace: string): Observable<any> {
    var httpOptions = {

      withCredentials: true
    };
    var formData = new FormData();
    formData.append('exception', exception);
    formData.append('stackTrace', stackTrace);


   
    return this.httpClient.post<any>(this.url + '/api/Logger/LogError', formData,httpOptions)

  }

  LogMessageToServer(message: string, source: string): Observable<any> {

    console.log("message", message);
    console.log("source", source);


    var formData = new FormData();
    formData.append('message', message);
    formData.append('source', source);

    var httpPostOptions =
    {
      withCredentials: true
    }

   
    console.log("Sending Message to Server", this.url + '/api/Logger/LogMessage');
    console.log("Sending Message to Server - FormData", formData);



    return this.httpClient.post<any>(this.url + '/api/Logger/LogMessage',
    formData, httpPostOptions
  );


  }

}

<div class="breadCrumbRow" [ngClass]="{'hidden' : hideBreadCrumb}">
    <div class="row">
      <!-- HOME -->
      <div>
        <a routerLink="/" class="breadcrumb hand" title="Go back to Home">
            <mat-icon aria-hidden="false" aria-label="Home Icon" class="material-icons md-24">home</mat-icon>
        </a>
      </div>
      <!-- My / Team Notebooks -->
      <div *ngIf="showNotebookLevel">
        <a routerLink="/notebooks/my-notebooks" class="breadcrumb hand" title="Go back to My Notebooks" *ngIf="showMyNotebookLevel">
          &nbsp;>&nbsp;<mat-icon aria-hidden="false" aria-label="My Notebooks Icon" class="material-icons md-24">person</mat-icon>
        </a>
        <a routerLink="/notebooks/team-notebooks" class="breadcrumb hand" title="Go back to Team Notebooks" *ngIf="showTeamNotebookLevel">
          &nbsp;>&nbsp;<mat-icon aria-hidden="false" aria-label="Team Notebooks Icon" class="material-icons md-24">groups</mat-icon>
      </a>
      </div>
      <!-- CMS Levels -->
      <div *ngIf="showLevel1">
        <a routerLink="/cms" class="breadcrumb">&nbsp;>&nbsp;{{customProjectName}}s</a>
       </div>
      <div *ngIf="showLevel1">
       <a routerLink="/cms" class="breadcrumb">&nbsp;><span 
          [ngClass]="{hideOnMobile: cssHideOnMobilelv1}">&nbsp;{{level1Name}}</span></a>
      </div>
      <div *ngIf="showLevel2">
       <a routerLink="/cms/1/{{level2ProjectID}}"  class="breadcrumb">&nbsp;><span 
        [ngClass]="{hideOnMobile: cssHideOnMobilelv2}">&nbsp;{{level2Name}}</span></a>
      </div>
      <div *ngIf="showLevel3">
        <a routerLink="/cms/2/{{level2ProjectID}}/{{level3ProjectID}}"  class="breadcrumb">&nbsp;><span 
          [ngClass]="{hideOnMobile: cssHideOnMobilelv3}">&nbsp;{{level3Name}}</span></a>
      </div>
      <div *ngIf="showLevel4">
        <a routerLink="/cms/3/{{level2ProjectID}}/{{level3ProjectID}}/{{level4ProjectID}}"  class="breadcrumb">&nbsp;><span 
          [ngClass]="{hideOnMobile: cssHideOnMobilelv4}">&nbsp;{{level4Name}}</span></a>
      </div>
      <div *ngIf="showLevel5">
        <a routerLink="/cms/4/{{level2ProjectID}}/{{level3ProjectID}}/{{level4ProjectID}}/{{level5ProjectID}}" class="breadcrumb"><span 
          [ngClass]="{hideOnMobile: cssHideOnMobilelv5}">&nbsp;>&nbsp;{{level5Name}}</span></a>
      </div>
      <div *ngIf="showLevel6">
        <a routerLink="/cms/5/{{level2ProjectID}}/{{level3ProjectID}}/{{level4ProjectID}}/{{level5ProjectID}}/{{level6ProjectID}}" 
          class="breadcrumb">&nbsp;>&nbsp;{{level6Name}}</a>
      </div>
      <div *ngIf="showSectionPage">
        <span class="breadcrumb">&nbsp;>&nbsp;{{sectionName}}</span>
       </div>
       <div *ngIf="showNotebook">
        <span class="breadcrumb" >&nbsp;>&nbsp;{{notebookName}}</span>
      </div>
    </div>
</div>
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfig, APP_CONFIG } from '../app-config.module';

@Injectable()
export class Insights {
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: this.appConfig.insightsInstrumentationKey,
            // instrumentationKey: '2b1aff0d-4879-4ef9-b3e4-1ea719c1f2ac',
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
        },
    });

    // constructor(private router: Router, private appConfig: AppConfig) {  // Get ... NullInjectorError: NullInjectorError: No provider for AppConfig!
    constructor(private router: Router,
        @Inject(APP_CONFIG) private appConfig: AppConfig
        ) {
        this.appInsights.loadAppInsights();

        console.log("InsightsKey", this.appInsights.config.instrumentationKey);
    }

    // expose methods that can be used in components and services
    trackEvent(name: string): void {
        this.appInsights.trackEvent({ name });
    }

    trackTrace(message: string): void {
        this.appInsights.trackTrace({ message });
    }
}
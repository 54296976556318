import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { AutoCompleteAllModule, AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { AppComponent } from 'src/app/app.component';
import { IncidentModel } from 'src/app/domain/Incident';
import { IncidentTypeModel } from 'src/app/domain/IncidentType';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { CmsNavigationModel } from 'src/app/shared/customObjects/cmsNavigationModel';
import NumberUtils from 'src/app/shared/Utility/NumberUtils';
import { v4 as uuidv4 } from 'uuid';
import { CaseManagementIncidentTypeComponent } from '../case-management-incident-type/case-management-incident-type.component';


@Component({
  selector: 'app-case-management-incident-numbers-addv2',
  templateUrl: './case-management-incident-numbers-addv2.component.html',
  styleUrls: ['./case-management-incident-numbers-addv2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CaseManagementIncidentNumbersAddv2Component implements OnInit {


  public isVisible: boolean = false;
  public isLoading: boolean = true;

  public isEditMode: boolean = false;

  public showUpdatingSpinner: boolean = false;
  public noIncidentTypeRecords: boolean = false; // if false, show message to add incident types

  @Output() newIncidentNumberEvent = new EventEmitter<IncidentModel>();

  updateNewIncidentNumberEvent(incidentNumberObj: IncidentModel) {
    this.newIncidentNumberEvent.emit(incidentNumberObj);
  }

  @ViewChild("componentEditIncidentTypes")
  componentEditIncidentTypes: CaseManagementIncidentTypeComponent;



  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';
  // public dialogDragTarget = '.setting-page';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };


  @ViewChild("ddIncidentType")
  ddIncidentType: AutoCompleteComponent;


  // maps the Category column to groupBy fields
  public yearGroupFields: Object = { value: 'Year' };
  // set the placeholder to the AutoComplete input
  public yearPlaceHolderText: string = 'e.g. 2022';
  public recordsYear: { [key: string]: Object }[] = [
    { Year: '2022', id: '2022' },
    { Year: '2021', id: '2021' },
    { Year: '2020', id: '2020' },
    { Year: '2019', id: '2019' },
  ];


  // public recordsIncidentTypes: IncidentTypeModel;
  public recordsIncidentTypes: any[];

  public incidentGroupFields: Object = { id: 'id', value: 'value' };
  // set the placeholder to the AutoComplete input
  public incidentPlaceHolderText: string = 'e.g. Theft';
  // public recordsIncidentTypes: { [key: string]: Object }[] = [
  //   { Year: '2022', Id: '2022' },
  // ];


  btnText: string = "Add";          // Can be either Add or Update

  private incidentObj: IncidentModel;
  year: string = "";
  incidentNumber: string = "";
  incidentTypeID: string = "";

  // isSubmitDisabled : boolean = false;
  selectedIncidentTypeID: string = '';
  selectedYear: string = '';


  // To Pre-Select value in dropdown
  updateIncidentID: string = "";
  incidentTypePreSelectValue: string = "";

  // -----------------------------------------------------
  // If LEO = Incident, if Private Invest = File
  // -----------------------------------------------------
  incidentOrFileNameDisplayed: string = "";


  // ===================================
  // CONSTRUCTOR
  // ===================================
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private toastr: ToastrService,
    public appComponent: AppComponent,
    public readonly cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {

    // Load onInit so that they are loaded for Editing
    // so that we can set the value right away and not wait.
    // this.LoadIncidentTypes();

    // PreSelect the current year.
    this.selectedYear = new Date().getFullYear().toString();
  }




  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
    this.cdRef.detectChanges();
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
    this.cdRef.detectChanges();

  }

  public onDialogClose(event: any): void {
    // this.isVisible = false;
    this.hide();
  }

  cmsNavigation: CmsNavigationModel = null;
  // SetupForNewIncident(incidentOrFileNameDisplayed: string = "Incident") {
  SetupForNewIncident(cmsNavigation: CmsNavigationModel, incidentOrFileNameDisplayed: string = "Incident") {

    console.log("SetupForNewIncident");

    this.isEditMode = false;


    this.cmsNavigation = cmsNavigation;

    if (!this.cmsNavigation) {
      console.log("CMSNavigation is NULL - Incident will NOT be associated with CMS Project");
    }


    this.incidentObj = new IncidentModel();
    this.LoadIncidentTypes();

    this.isLoading = false;
    this.btnText = "Add";

    this.incidentOrFileNameDisplayed = incidentOrFileNameDisplayed;


  }

  SetupForEditingByID(incidentFileOr: string, incidentID: string) {

    console.log("SetupForEditingByID");

    this.isEditMode = true;


    this.caseManagementService
      .GetIncidentRecordByID(this.appComponent.user.userID, incidentID)
      .subscribe((result) => {

        console.log("GetIncidentRecordByID Object", result);

        let incident: IncidentModel = new IncidentModel();

        incident.LoadFromAPIResult(result);

        this.SetupForEditing(incidentFileOr, incident);
      },
        (error) => {
          // ---------------------
          // Failed to get notes.
          // ---------------------
          console.error(error);
          this.toastr.error(error, "Error");
          return;
        });
  }

  SetupForEditing(incidentFileOr: string = "Incident", incident: IncidentModel) {

    console.log("SetupForEditing");


    this.isLoading = true;
    this.isEditMode = true;

    this.incidentOrFileNameDisplayed = incidentFileOr;



    this.btnText = "Update";

    this.incidentObj = incident;

    console.log("incidentObj", incident);


    this.year = incident.yearOfIncident;
    this.incidentNumber = incident.incidentNumber;

    // Make sure OnSelect values are set.
    // This value will change if user adjusts the values in UI.
    this.updateIncidentID = incident.id;
    // this.selectedYear = incident.yearOfIncident;
    this.selectedIncidentTypeID = incident.incidentTypeId;

    this.selectedYear = incident.yearOfIncident;

    this.LoadIncidentTypes(incident.incidentTypeDisplay);

    this.cdRef.detectChanges();

  }

  onSelectIncidentType(args) {
    this.selectedIncidentTypeID = args.itemData.id;
  }

  onSelectYear(args) {
    this.selectedYear = args.itemData.id;
  }




  CreateUpdateIncidentNumber(txtYear, incidentTypeID) {


    console.log("txtYear", txtYear);
    console.log("txtIncidentNumber", this.incidentNumber);
    console.log("incidentTypeID", incidentTypeID);
    console.log("incidentTypeID", this.selectedIncidentTypeID);
    console.log("selectedYear", this.selectedYear);

    console.log("txtIncidentNumber-BEFORE", this.incidentNumber);


    if(this.appComponent.adminCmsSettingsModel.fileNumberOption == 'Numeric'){
      // ---------------------------------------------------------------------------
      // Numeric Value expected...make sure in proper format and padding with 0's
      // ---------------------------------------------------------------------------
      while (this.incidentNumber.length < 7) {
        this.incidentNumber = "0" + this.incidentNumber;
      }
    }
    else if(this.appComponent.adminCmsSettingsModel.fileNumberOption == 'AutoIncrement'){
      // --------------------------------
      // Make sure incident is AUTO
      // API will know what to do!
      // --------------------------------
      this.incidentNumber = "0";
    }
    else if(this.appComponent.adminCmsSettingsModel.fileNumberOption == 'FreeFormText'){
      // FreeForm... Leave as-is
    }
    else{
      // Error - Unknown
      console.error("Incident Number Format Unknown", this.appComponent.adminCmsSettingsModel.fileNumberOption);
      this.toastr.error("Unknown Format Requested - Contact Support");
      return;
    }



    console.log("txtIncidentNumber-AFTER", this.incidentNumber);


    // this.incidentObj = new IncidentModel();

    if (this.isEditMode) {
      // Editing Existing Record
      this.incidentObj.id = this.updateIncidentID;
      this.incidentObj.isEdit = true;
    }
    else {
      // New Incident Number Record
      this.incidentObj.id = uuidv4();
      this.incidentObj.isEdit = false;
    }

    this.incidentObj.yearOfIncident = this.selectedYear;
    this.incidentObj.incidentNumber = this.incidentNumber;
    this.incidentObj.incidentTypeId = this.selectedIncidentTypeID;

    if (this.cmsNavigation) {
      // ---------------------------------------------------------------------------------------------------
      // CMSNavigation valid object, so we are associating this Incident with a specific CMS Project.
      // ---------------------------------------------------------------------------------------------------
      if (this.cmsNavigation.requestingUnitId) {
        this.incidentObj.requestingUnitid = this.cmsNavigation.requestingUnitId;
      }

      if (this.cmsNavigation.projectId) {
        this.incidentObj.projectId = this.cmsNavigation.projectId;
      }
    }

    // NOTE: Notes bound to object already.

    console.log("Incident", this.incidentObj);


    // Show updating Spinner
    this.showUpdatingSpinner = true;


    this.caseManagementService.AddUpdateIncident(this.incidentObj)
      .subscribe((result) => {

        this.showUpdatingSpinner = false;

        console.log("result", result);

        // Assume success as result.success returns false?
        if (this.isEditMode) {
          this.toastr.success("Incident Number Updated");
        }
        else {
          // New Incident Number Record
          this.toastr.success("Incident Number Added");
        }

        console.log("recordsIncidentTypes", this.recordsIncidentTypes);

        
        if(this.appComponent.adminCmsSettingsModel.fileNumberOption == 'AutoIncrement'){
          // If Auto-Increment, then value unknown until now... Get Number from result
          this.incidentObj.incidentNumber = result.base.incidentNumber;
        }

        // --------------------------------------------------------------------------------------
        // Make sure the parent calling this updates it data to show the new Incident #.
        // --------------------------------------------------------------------------------------
        this.updateNewIncidentNumberEvent(this.incidentObj);

        // Reset Values... Except for Year
        this.incidentNumber = "";
        this.recordsIncidentTypes = null;

        this.hide();
      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to obtain Incident Types, please contact support@forensicnotes.com", "ERROR");
        });


  }

  CreateIncidentType() {
    // TODO - Pass recordsIncidentTypes to CreateIncidentType so that this auto-refreshes.

    // for now... fake it!

    let recordsIncidentTypesTemp: IncidentTypeModel = new IncidentTypeModel();

    let incidentType: string = "Test";

    recordsIncidentTypesTemp.id = "2";
    recordsIncidentTypesTemp.value = incidentType;

    let recArr: any = [];

    recArr.push(recordsIncidentTypesTemp)

    console.log(recArr);

    this.recordsIncidentTypes = recArr;

    console.log(this.recordsIncidentTypes);

    this.incidentPlaceHolderText = incidentType;

    // https://www.syncfusion.com/forums/139746/automatically-selected-value-in-the-autocomplete
    // let obj =  document.getElementById('ddIncidentType').ej2_instances[0]; 
    // this.ddIncidentType.value = "2";

    this.toastr.success("Incident Type '" + incidentType + "' added. Select from list to continue.");
    this.noIncidentTypeRecords = false;
  }


  LoadIncidentTypes(preSelectValue: string = "") {

    console.log("LoadINicdentTypes()");

    this.caseManagementService.GetIncidentTypes()
      .subscribe((result) => {

        this.isLoading = false;

        console.log("result", result);

        // if (result.success == true) {
        this.recordsIncidentTypes = result;

        console.log("this.recordsIncidentTypes", this.recordsIncidentTypes);

        console.log("result.result.length", result.length);

        this.incidentPlaceHolderText = "eg: " + result[0].value;

        // console.log("this.ddIncidentType.value", this.ddIncidentType.value);

        // this.ddIncidentType.value = "ef18ec24-054c-4d8f-90e6-078679f47a15";
        // console.log("this.ddIncidentType.value", this.ddIncidentType.value);

        if (preSelectValue != "") {

          this.incidentTypePreSelectValue = preSelectValue;
        }


        if (this.recordsIncidentTypes.length > 0) {
          this.noIncidentTypeRecords = false;
        }
        else {
          // No Records found - Show message to user.
          this.noIncidentTypeRecords = true;
        }

        console.log("recordsIncidentTypes", this.recordsIncidentTypes);

        this.cdRef.detectChanges();

        // }
        // else {
        //   this.toastr.error("Sorry, an unknown error occurred attempting to obtain Incident Types, please contact support@forensicnotes.com", "ERROR");
        // }
      },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to obtain Incident Types, please contact support@forensicnotes.com", "ERROR");
        });

  }

  // =========================================================
  // Only allow Numeric Values for Incident Number
  // =========================================================
  allowNumericDigitsOnlyOnKeyUp(e) {
    NumberUtils.allowNumericDigitsOnlyOnKeyUp(e, this.toastr);
  }


  Cancel() {
    // Reset Values
    this.incidentNumber = "";
    this.recordsIncidentTypes = null;
    this.hide();
  }


  AddNewIncidentType() {
    this.componentEditIncidentTypes.show();
  }

  RefreshIncidentList() {
    this.LoadIncidentTypes();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadCrumbTrailComponent } from './bread-crumb-trail.component';
import { MatIconModule } from '@angular/material/icon';
import { NotebookRenameModule } from '../notebook-rename/notebook-rename.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    BreadCrumbTrailComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NotebookRenameModule,
    RouterModule
  ],
  exports: [
    BreadCrumbTrailComponent
  ]
})
export class BreadCrumbTrailModule { }

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { IncidentTypeModel } from "../domain/IncidentType";
import { v4 as uuidv4 } from 'uuid';
import { IncidentModel } from "../domain/Incident";
import { SuccessModel } from "../domain/Success";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { WorkRequestModel } from "../shared/customObjects/workRequestModel";
import { ExhibitVideoModel } from "../shared/customObjects/exhibitVideoModel";
import { WorkRequestTaskModel } from "../shared/customObjects/workRequestTaskModel";
import { ExhibitComputerModel } from "../shared/customObjects/exhibitComputerModel";
import { ExhibitCloudModel } from "../shared/customObjects/exhibitCloudModel";
import { ExhibitModel } from "../shared/customObjects/exhibitModel";
import { ExhibitNasModel } from "../shared/customObjects/exhibitNasModel";
import { ExhibitHddModel } from "../shared/customObjects/exhibitHddModel";
import { ExhibitUSBorSDModel } from "../shared/customObjects/exhibitUSBorSDModel";
import { protectedResources } from '../auth-config';
import { CmsLevelValues } from "../shared/customObjects/cmsLevelValues";
import URLUtils from "../shared/Utility/urlUtils";
import { AppComponent } from "../app.component";



@Injectable({
  providedIn: 'root'
})
export class CaseManagementNavigationService {



  url = protectedResources.caseManagementApi.endpoint;

  // Queries

  PRIVATE_QUERY: string = "?query=Client&query=Incident&query=AssignedToUnit&query=WorkRequest&query=WorkRequestTask&query=Exhibit";
  LEO_QUERY: string = "?query=Project&query=Incident&query=AssignedToUnit&query=WorkRequest&query=WorkRequestTask&query=Exhibit";



  // ==================================================================
  // 
  // ==================================================================
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient,
    // private appComponent: AppComponent,
  ) {
    // constructor

    console.log("URL", this.url);
  }


  // -------------------------------------
  // CMS Type
  // 0 = not set
  // 1 = Law Enforcement
  // 2 = Private Industry
  // -------------------------------------


  private getQuery(cmsType: number): string {
    if (cmsType == 1) {
      return this.LEO_QUERY;
    }
    else if (cmsType == 2) {
      return this.PRIVATE_QUERY;
    }
    else {
      // Error..this should not have been called.
      console.error("Call to Navigation API Error - CMS Type = " + cmsType);
      return "";
    }

  }

  // ==================================================================
  // 
  // ==================================================================
  GetNavigation(cmsType: number, cmsLevelValues: CmsLevelValues, enableServiceWorkerCache: boolean, userID: string): Observable<any> {

    console.log("currFilterLevel", cmsLevelValues.currFilterLevel);


    let query: string = this.getQuery(cmsType);
    let filterString: string = "";


    switch (cmsLevelValues.currFilterLevel) {
      case 0:
        filterString = "";  // no filter
        break;
      case 1:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID;
        break;
      case 2:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID;
        break;
      case 3:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID;
        break;
      case 4:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID;
        break;
      case 5:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID + "/" + cmsLevelValues.lv5ExhibitsWorkRequestTaskID;
        break;
      case 6:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID + "/" + cmsLevelValues.lv5ExhibitsWorkRequestTaskID
          + "/" + cmsLevelValues.lv6ExhibitID;
        break;
      default:
        console.error("Unknown CMS Level", cmsLevelValues.currFilterLevel);
        return;


    }

    console.log("query", query);
    console.log("filterString, filterString");

    let fullURLv1 = this.url + "/api/Navigation" + filterString + query;

    console.log("fullURL", fullURLv1);

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = fullURLv1;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.httpClient.get<any>(
      fullURL,
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddCMSNotebook(cmsType: number, cmsLevelValues: CmsLevelValues, notebookID: string, ownedByID: string): Observable<any> {

    console.log("currFilterLevel", cmsLevelValues.currFilterLevel);


    if (ownedByID == "00000000-0000-0000-0000-000000000000") {
      // --------------------------------------------------------------------------------------
      // Found Bug Issue
      // BUG: Sometimes we are added notebooks to a Project with the owner as 000000
      // which causes issues. Do NOT allow this.
      // Notify use in Logs to help correct this.
      // --------------------------------------------------------------------------------------

      const customError = new HttpErrorResponse({
        status: 512, // You can specify the appropriate HTTP status code
        statusText: 'Error',
        error: 'OwnedByID is invalid (00000000-0000-0000-0000-000000000000). Cannot proceed with adding the notebook to the project.',
      });
 
      return throwError(() => customError); // Return an error observable using a function
    }

    // Else... Add

    let query: string = this.getQuery(cmsType);
    let filterString: string = "";


    switch (cmsLevelValues.currFilterLevel) {
      case 0:
        filterString = "";  // no filter
        break;
      case 1:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID;
        break;
      case 2:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID;
        break;
      case 3:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID;
        break;
      case 4:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID;
        break;
      case 5:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID + "/" + cmsLevelValues.lv5ExhibitsWorkRequestTaskID;
        break;
      case 6:
        filterString = "/" + cmsLevelValues.lv1ClientProjectID + "/" + cmsLevelValues.lv2IncidentFileID + "/"
          + cmsLevelValues.lv3DeptSectionID + "/" + cmsLevelValues.lv4WorkRequestID + "/" + cmsLevelValues.lv5ExhibitsWorkRequestTaskID
          + "/" + cmsLevelValues.lv6ExhibitID;
        break;
      default:
        console.error("Unknown CMS Level", cmsLevelValues.currFilterLevel);
        return;


    }

    console.log("query", query);
    console.log("filterString", filterString);

    let fullURL = this.url + "/api/Navigation/Notebook" + filterString + query;

    console.log("fullURL", fullURL);

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    const body = {
      "notebookId": notebookID,
      "ownedBy": ownedByID
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      fullURL,
      body,
      httpsOptions
    );
  }

  RemoveCMSNotebook(notebookdID: string): Observable<any> {

    const httpPostOptions = {
      withCredentials: true,
      headers: new HttpHeaders({ accept: "text/plain" })
    };

    return this.http.delete<any>(
      this.url + "/api/Navigation/Notebook/" + notebookdID,
      httpPostOptions
    );

  }

}

<div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
<div *ngIf="!isLoading">

    <div [ngClass]="borderClass">
        <app-bread-crumb-trail #componentBreadCrumb  sectionName="{{customProjectNaming.level2Name}} Numbers" *ngIf="showInDialog == false"></app-bread-crumb-trail>
      
    <div class="buttonSectionOneColRight">
       
    </div>

    <div class="headerRow">
        <div class="">
            <h1>{{customProjectNaming.level2Name}} Numbers</h1>
        </div>
        <div class="headerRowButtonRight">
            <button class="SiteColor" #btnCreateChild (click)="AddIncidentNumber($event)">Add</button>
        </div>
    </div>


    <ejs-grid #grid [dataSource]='recordsIncidentNumbers' allowPaging='true' [pageSettings]='pageSettings'
        [enableHover]="false" allowSorting="true" allowResizing="false" enableAdaptiveUI='true' showColumnMenu='true'
        [sortSettings]='sortOptions' (toolbarClick)='toolbarClick($event)' [allowExcelExport]='true'
        [allowPdfExport]='true' [toolbar]='toolbar' clipMode="EllipsisWithTooltip">
        <e-columns>
            <e-column field='base.yearOfIncident' headerText='Year' width='60' textAlign='right'></e-column>
            <e-column field='base.incidentNumber' headerText='{{customProjectNaming.level2Name}} #'
                width='85' textAlign='left'></e-column>
            <e-column field='derived.incidentTypeLabel' headerText='Type' textAlign='left'
                hideAtMedia='(min-width: 600px)'></e-column>
            <e-column width="25" allowEdit="false" textAlign='right'>
                <ng-template #template let-recordsIncidentNumbers>
                    <div class="noteOptionsSection hand" (click)="menuClick($event, recordsIncidentNumbers)">
                        <span class="material-icons md-36 iconBlue" title="Menu Options">more_vert</span>
                    </div>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>

    </div>
 
</div>

<app-case-management-incident-numbers-addv2 #componentAddIncidentNumber
(newIncidentNumberEvent)="refreshData($event)">
</app-case-management-incident-numbers-addv2>

<!-- Note Menu Dialog -->
<ejs-dialog id="ejDialogMenu" #ejDialogMenu isModal="true" (overlayClick)="onOverlayClickOutsideMenu()"
showCloseIcon="true" width="350px" [visible]="false" header="{{currRecIncidentNumber}} Options"
[animationSettings]='animationSettings' [target]="dialogDragTarget" [allowDragging]='allowDialogDragging'
[enableResize]='false' isModal="true" [ngClass]="{'hidden' : isLoading}">
<ng-template #content>
    <button mat-button class="mat-icon-menu-button" (click)="EditIncidentNumber()" title="Edit">
        <mat-icon>draw</mat-icon>
        Edit
    </button>
    <button mat-button class="mat-icon-menu-button" (click)="DeleteIncident()" title="Delete">
        <mat-icon>delete_outline</mat-icon>
        Delete
    </button>
    <hr>
    <div class="sectionGeekStats">
        <span class="titleGeekStats">Incident Number ID:</span>{{currRecID}}<br>
        <span class="titleGeekStats">Incident Number:</span>{{currRecIncidentNumber}}
    </div>
</ng-template>
</ejs-dialog>

<ejs-dialog id="ejDialogDelete" #ejDialogDelete showCloseIcon='false' header='Delete Incident' width="450"
[visible]="false" [animationSettings]='animationSettings' [target]="dialogDragTarget"
[allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
<div>
    <p>Are you sure you want to delete this incident?</p>
    <div class="buttonSectionTwoCol">
        <button class="cancel" (click)="CancelDelete()">Cancel</button>
        <button class="delete" (click)="DeleteIncidentConfirmed()"><img
                src="/assets/images/progress-spinner.gif" class="button-spinner"
                *ngIf="showDeleteSpinner" />Delete Incident</button>
        <!-- </div> -->
    </div>
</div>
</ejs-dialog>

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.scss']
})
export class AssetManagementComponent implements OnInit {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private toastr: ToastrService,
    public adminCmsSettingsModel: AdminCmsSettingsModel
  ) { }

  ngOnInit(): void {
  }

}

<ejs-dialog id="ejDialogAddEdit" #ejDialogAddEdit showCloseIcon='true' header='{{btnText}} BIN Type'
    (beforeClose)="onDialogClose($event)" width="375" [visible]="isVisible" [animationSettings]='animationSettings'
    [target]="dialogDragTarget" [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
    <div>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">
            <div class="center">
                
                    <mat-form-field appearance="outline" class="">
                        <mat-label>BIN Type</mat-label>
                        <input matInput placeholder="eg: ..." minlength="1"
                            maxlength="30"
                            [(ngModel)]="binType" />
                    </mat-form-field>
            </div>

            <div style="padding: 10px;"></div>
            <div class="buttonSection center">
                <button ejs-button class="cancel" type="button" (click)="Cancel()">Cancel</button>
                <button class="success" id="btnSubmit" #btnSubmit
                    [disabled]="binType == ''"
                    (click)="AddUpdate()"><img
                        src="/assets/images/progress-spinner.gif" class="button-spinner"
                        *ngIf="showUpdatingSpinner" />{{btnText}} BIN Type</button>
            </div>
            
        </div>
    </div>
</ejs-dialog>
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { TforensicNoteBook } from '../domain/tforensicNoteBook';
import { ForensicNoteBookService } from '../service/forensicNoteBook.service';


@Component({
  selector: 'app-notebook-rename',
  templateUrl: './notebook-rename.component.html',
  styleUrls: ['./notebook-rename.component.scss']
})
export class NotebookRenameComponent implements OnInit {


  @ViewChild("ejDialogRenameNotebook")
  ejDialogRenameNotebook: DialogComponent;

  currNotebookObj : TforensicNoteBook = null;

  @Input() notebookID: string;

  @Input() notebookName: string;

  @Output()
  newNotebookNameEvent = new EventEmitter<string>();

  updateParentWithNewNotebookName(value: string){
    this.newNotebookNameEvent.emit(value);
  }


  isVisible: boolean = false;
  showClosebutton: boolean = true;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';

  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };
  

  constructor(
    private forensicNoteBookService: ForensicNoteBookService,
    private toastr: ToastrService,
    private appComponent: AppComponent,

  ) { }

  ngOnInit(): void {

  }

  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
  }

  LoadControl(notebook : TforensicNoteBook){
    console.log("LoadControlFromObj", notebook);

    this.currNotebookObj = notebook;

    this.notebookID = notebook.notebookID;
    this.notebookName = notebook.title;


  }

  LoadControlByVal(notebookID: string, notebookName: string){
    console.log("LoadControlByVal", notebookID);


    this.notebookID = notebookID;
    this.notebookName = notebookName;


  }


  public RenameNotebook(newNotebookName){


    if(newNotebookName.length == 0){
      this.toastr.warning("Notebook Name must be provided");
      return;
    }

    if(newNotebookName.length > 51){
      this.toastr.warning("Notebook Name must be 50 characters or less");
      return;
    }

    this.forensicNoteBookService
    .updateNoteBookNameByID(this.notebookID, newNotebookName)
    .subscribe((result) => {
      if (result.success == true) {
        // SUCCESS - Nothing else to do.

        this.updateParentWithNewNotebookName(newNotebookName);

        // this.forensicNoteBookService
        //   .getNotebookInformation(this.notebookID, this.appComponent.securityUtils.isServiceWorkerCacheEnabled(), this.appComponent.user.userID)
        //   .subscribe((r) => {
        //     this.newNotebookName = newNotebookName;
        //   });
      } 
      else {
        this.toastr.error("Failed to update notebook name.");
      }
    });

    // Close immediately as the response takes longer than the SignalR update
    // this.isVisible = false; 

    if(this.currNotebookObj != null){
      // update object so UI updates right away.
      this.currNotebookObj.title = newNotebookName;
    }

    this.hide();

  }


  // =====================================================================
  // Close Dialog if Click Outside
  // =====================================================================
  public onOverlay: EmitType<object> = () => {
    this.isVisible = false;
  };

  public onDialogClose(event: any) : void {
   this.isVisible = false;
  }

}

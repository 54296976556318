import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileOptionsRoutingModule } from './file-options-routing.module';
import { FileOptionsComponent } from './file-options.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    FileOptionsComponent
  ],
  imports: [
    CommonModule,
    FileOptionsRoutingModule,
    DialogModule,
    SharedModule,
  ],
  exports: [
    FileOptionsComponent
  ]
})
export class FileOptionsModule { }

<ejs-dialog showCloseIcon='true' header='Terms & Conditions' (beforeClose)="onDialogClose($event)" width="550"
    [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
    [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
    <ng-template #content>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">
            <!-- Content -->
            <p>Your setup process is almost complete.</p>
            <p>Please read and accept the following Terms &amp; Conditions to continue:</p>
            <ul>
                <li><a href="https://www.forensicnotes.com/terms-and-conditions/" target="_blank"
                        rel="noopener">Forensic Notes Terms &amp; Conditions</a></li>
                <li><a href="https://www.iubenda.com/privacy-policy/7787168" target="_blank" rel="noopener">Forensic
                        Notes Privacy Policy</a></li>
                <li><a href="https://www.digistamp.com/toolkitDoc/Java/DigistampLicense.html" target="_blank"
                        rel="noopener">DigitStamp End-User License Agreement</a></li>
            </ul>
            <p><br />DigiStamp is an independent and trusted Timestamping Authority (TSA).</p>
            <div class="buttonSectionOneCol">
                <button class="success" id="btnSubmit" #btnSubmit 
                (click)="AcceptTerms()">I ACCEPT ALL TERMS & CONDITIONS</button>
            </div>
            <!-- (end of) Content-->
        </div>
    </ng-template>
</ejs-dialog>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseManagementReceivedMethodAddComponent } from './case-management-received-method-add.component';

const routes: Routes = [{ path: '', component: CaseManagementReceivedMethodAddComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseManagementReceivedMethodAddRoutingModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TforensicTimesZone } from '../domain/tforensicTimesZone';
import { map } from 'rxjs/operators';
import { APP_CONFIG,AppConfig } from '../app-config.module';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  url = protectedResources.apiBaseUrl.endpoint;

  getAllTimezones(): Observable<TforensicTimesZone> {
    const httpOptions = {
      withCredentials: true
    };
    return this.http.get<TforensicTimesZone>(this.url + '/api/TimeZones', httpOptions);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
// import { createDirectiveDefinitionMap } from '@angular/compiler/src/render3/partial/directive';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SortService, ResizeService, GroupService, ColumnMenuService, PageService, FilterService, ToolbarItems, EditService, ToolbarService, IEditCell, ExcelQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs, ExcelExportService, PdfExportService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { CaseManagementIncidentNumbersAddv2Component } from '../case-management-incident-numbers-addv2/case-management-incident-numbers-addv2.component';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { IncidentNumbersComponent } from 'src/app/incident-numbers/incident-numbers.component';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';

@Component({
  selector: 'app-case-management-incident-numbers',
  templateUrl: './case-management-incident-numbers.component.html',
  styleUrls: ['./case-management-incident-numbers.component.scss'],

})
export class CaseManagementIncidentNumbersComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = true;
  public isModal: boolean = true;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };


  @ViewChild("componentIncidentNumbers")
  componentIncidentNumbers: IncidentNumbersComponent;


  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    public customProjectNaming: ProjectLevelNaming,
  ) { }

  ngOnInit(): void {
    // -------------------------------------------------
    // Nothing to load... so hide the loading gif!
    // -------------------------------------------------
    this.isLoading = false;
  }




  // ===================================
  // SHOW
  // ===================================
  show() {

    console.log("Showing Incident Number Dialog")
    this.isVisible = true;
    this.isLoading = false;

    this.componentIncidentNumbers.ShowInDialog();
  }




  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    // this.isLoading = true;
  }

  public onDialogClose(event: any): void {
    this.isVisible = false;
  }





}

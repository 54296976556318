import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseManagementIncidentNumbersAddv2Component } from './case-management-incident-numbers-addv2.component';

const routes: Routes = [{ path: '', component: CaseManagementIncidentNumbersAddv2Component }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseManagementIncidentNumbersAddv2RoutingModule { }

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, map, merge, of, Observable, BehaviorSubject, withLatestFrom, share, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkConnectionService {
  isOnline: boolean = false;
  networkStatus$: Observable<boolean>;
  networkControl = new BehaviorSubject<boolean>(true);
  constructor(
    private toastr: ToastrService,
  ) {
    this.checkNetworkStatus();
    this.networkStatus$.subscribe((status) => {
      this.isOnline = status;
    })
  }

  checkNetworkStatus() {
    this.isOnline = navigator.onLine;
    this.networkStatus$ = merge(
      of(null).pipe(map(e=>"INIT")),
      this.networkControl.asObservable().pipe(map(e=>"networkControl")),
      fromEvent(window, 'online').pipe(map(e=>"ONLINE")),
      fromEvent(window, 'offline').pipe(map(e=>"ONLINE")),
    ).pipe(
      withLatestFrom(this.networkControl.asObservable()),
      map(([_, network])=> {
        if(!network) {
          return false;
        }
        return !!(navigator.onLine);
      }),
      distinctUntilChanged()
    );
  }

  setAppOffline() {
    if(this.networkControl.value === false) {
      return;
    }
    this.toastr.info("Your Connection is not Stable, App is now in Offline Mode. Please check your connection and refresh the page.", "Offline Mode")
    this.networkControl.next(false);
  }

  setAppOnline() {
    if(this.networkControl.value === true) {
      return;
    }
    if(navigator.onLine) {
      this.toastr.info("App is back to Online.", "Online Mode")
      this.networkControl.next(true);
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TforensicNoteBook } from '../domain/tforensicNoteBook';
import { TforensicProject } from '../domain/tforensicProject';
import { PopupSettingsModel } from '@syncfusion/ej2-inplace-editor/src/inplace-editor/base/models-model';
import { TextBoxModel } from '@syncfusion/ej2-inputs';
import { ForensicNoteBookService } from '../service/forensicNoteBook.service';
import { ToastrService } from 'ngx-toastr';
import { InPlaceEditorComponent } from '@syncfusion/ej2-angular-inplace-editor';
import { NotebookRenameComponent } from '../notebook-rename/notebook-rename.component';
import { ProjectLevelNaming } from '../shared/customObjects/ProjectLevelNaming';
import { Console } from 'console';
import { CmsNavigationModel } from '../shared/customObjects/cmsNavigationModel';
import { CmsLevelValues } from '../shared/customObjects/cmsLevelValues';
import { ExhibitType } from '../shared/customObjects/ExhibitType';
import { ExhibitModel } from '../shared/customObjects/exhibitModel';
import ExhibitHelper from '../shared/Utility/ExhibitHelper';


@Component({
  selector: 'app-bread-crumb-trail',
  templateUrl: './bread-crumb-trail.component.html',
  styleUrls: ['./bread-crumb-trail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class BreadCrumbTrailComponent implements OnInit {


  hideBreadCrumb: boolean = true;

  // @Input()
  // project: TforensicProject = null;

  @Input()
  useLoadControlMethods: boolean = false;

  @Input()
  customProjectNaming: ProjectLevelNaming = null;

  @Input()
  notebookID: string = null;

  @Input()
  notebookName: string = null;

  cmsNavigation: CmsNavigationModel = null;

  // -----------------------------------------------------------------------------
  // Main Pages
  // eg: Projects, My Notebooks, Team Notebooks
  // Valid Values: 
  // - Projects
  // - My Notebooks
  // - Team Notebooks
  // -- Really whatever you want since it just shows this text with no link
  // -----------------------------------------------------------------------------
  @Input()
  set sectionName(sectionName: string) {
    this._sectionName = sectionName;
    this.showSectionPage = true;
  }
  get sectionName(): string {
    return this._sectionName;
  }

  showSectionPage: boolean = false;

  @ViewChild('inplaceNotebookName')
  public inplaceNotebookName: InPlaceEditorComponent;


  private isHome: boolean = false; // home page

  private _sectionName: string = "- not set -";

  // Project Levels
  public customProjectName: string = "Project";
  public showNotebookLevel: boolean = false;
  public showMyNotebookLevel: boolean = false;
  public showTeamNotebookLevel: boolean = false;
  public showLevel1: boolean = false;
  public showLevel2: boolean = false;
  public showLevel3: boolean = false;
  public showLevel4: boolean = false;
  public showLevel5: boolean = false;
  public showLevel6: boolean = false;


  // ---------------------------------------------------------------
  // Sets to hide the breadcrum on Mobile due to space issues.
  // ---------------------------------------------------------------
  public cssHideOnMobilelv1: boolean = true;
  public cssHideOnMobilelv2: boolean = true;
  public cssHideOnMobilelv3: boolean = true;
  public cssHideOnMobilelv4: boolean = true;
  public cssHideOnMobilelv5: boolean = true;

  // lv5 not required as the last one would always show if visible.
  // If a lower level the last one, then ensure that one always shows by setting it to false.



  public showNotebook: boolean = false;

  public level1Name: string = "Level 1";
  public level1ProjectID: string = "0";

  public level2Name: string = "Level 2";
  public level2ProjectID: string = "0";

  public level3Name: string = "Level 3";
  public level3ProjectID: string = "0";

  public level4Name: string = "Level 4";
  public level4ProjectID: string = "0";

  public level5Name: string = "Level 5";
  public level5ProjectID: string = "0";

  public level6Name: string = "Level 6";
  public level6ProjectID: string = "0";




  public notebookNamePopSettings: PopupSettingsModel;
  // public overviewModelName: TextBoxModel = {
  //   placeholder: 'Enter Notebook Name'
  // };
  public settingsName: PopupSettingsModel = {
    title: 'Enter Notebook Name'
  };

  @ViewChild("renameNotebook")
  renameNotebook: NotebookRenameComponent;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private forensicNoteBookService: ForensicNoteBookService,
    private toastr: ToastrService,
    public readonly cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.ShowConsoleMsg("ngOnInit() - breadcrumb");

    if (this.useLoadControlMethods) {
      // Do not load on PageOnInit
      return;
    }

    this.ShowConsoleMsgObj("notebookID", this.notebookID);
    this.ShowConsoleMsgObj("notebookName", this.notebookName);
    this.ShowConsoleMsgObj("sectionName", this.sectionName);


    this.setupHomePage();

    this.ShowConsoleMsg("isHome = " + this.isHome);

    if (this.isHome == false) {
      this.setupBreadCrumbForNotebook();
      // this.setupBreadCrumbForProject();
      this.setupMainPages();
    }

    console.log("Breadcrumb", this.showNotebook, this.showMyNotebookLevel, this.showTeamNotebookLevel);

    this.show();

    this.ShowConsoleMsg("ngOnInit complete");

  }

  LoadControlForMyTeamNotebooks(isOwned: boolean){

    console.log("Breadcrumb - isOwned = ", isOwned);

    // -----------------------------------
    // Show the Notebook Icon section
    // then determine which icon
    // -----------------------------------
    this.showNotebookLevel = true;

    if(isOwned){
      // My Notebooks
      this.showMyNotebookLevel = true;
    }
    else{
      this.showTeamNotebookLevel = true;
    }

    console.log("Breadcrumb", this.showNotebook, this.showMyNotebookLevel, this.showTeamNotebookLevel);

    this.cdRef.detectChanges();
  }

  LoadControlForCMS(cmsNavigation: CmsNavigationModel, customProjectNaming: ProjectLevelNaming, sectionName: string) {
    this.cmsNavigation = cmsNavigation;
    this.sectionName = sectionName;
    this.customProjectNaming = customProjectNaming;

    console.log("cmsNavigation - BreadCrumb", this.cmsNavigation);
    console.log("customProjectNaming - BreadCrumb", this.customProjectNaming);

    this.customProjectName = this.customProjectNaming.masterName;
    this.showSectionPage = false;
    this.showNotebook = false;

    this.showLevel1 = false;
    this.showLevel2 = false;
    this.showLevel3 = false;
    this.showLevel4 = false;
    this.showLevel5 = false;
    this.showLevel6 = false;

    this.cssHideOnMobilelv1 = false;
    this.cssHideOnMobilelv2 = false;
    this.cssHideOnMobilelv3 = false;
    this.cssHideOnMobilelv4 = false;
    this.cssHideOnMobilelv5 = false;

    console.log("this.cmsNavigation.currentLevel", this.cmsNavigation.currentLevel);

    // --------------------------------------------------------------------------------
    // IMPORTANT NOTE: 
    // No 'break;' added as the code is meant to flow through to the next one if
    // the value above is valid. So if 5, do everything below...
    // --------------------------------------------------------------------------------
    switch (this.cmsNavigation.currentLevel) {
      case 6:
        let exhibitType: ExhibitType = ExhibitModel.ConvertStringToExhibitTypeObject(this.cmsNavigation.breadCrumbTrail[4].subItemType);

        this.level6Name = ExhibitHelper.fixDisplayByReplacingExhibitTypeWithProperName(this.cmsNavigation.breadCrumbTrail[4].label, this.cmsNavigation.breadCrumbTrail[4].subItemType, exhibitType);
        this.level6ProjectID = this.cmsNavigation.breadCrumbTrail[4].id;
        this.showLevel6 = true;
      case 5:
        this.level5Name = "Work Request " + this.cmsNavigation.breadCrumbTrail[3].label;
        this.level5ProjectID = this.cmsNavigation.breadCrumbTrail[3].id;
        this.showLevel5 = true;

        if(this.showLevel6){
          this.cssHideOnMobilelv1 = true;
          this.cssHideOnMobilelv2 = true;
          this.cssHideOnMobilelv3 = true;
          this.cssHideOnMobilelv4 = true;
          this.cssHideOnMobilelv5 = true;
        }

      case 4:
        this.level4Name = this.cmsNavigation.breadCrumbTrail[2].label;
        this.level4ProjectID = this.cmsNavigation.breadCrumbTrail[2].id;
        this.showLevel4 = true;

        if(this.showLevel5){
          this.cssHideOnMobilelv1 = true;
          this.cssHideOnMobilelv2 = true;
          this.cssHideOnMobilelv3 = true;
          this.cssHideOnMobilelv4 = true;
        }

      case 3:
        this.level3Name = this.cmsNavigation.breadCrumbTrail[1].label;
        this.level3ProjectID = this.cmsNavigation.breadCrumbTrail[1].id;
        this.showLevel3 = true;

        if(this.showLevel4){
          this.cssHideOnMobilelv1 = true;
          this.cssHideOnMobilelv2 = true;
          this.cssHideOnMobilelv3 = true;
        }

      case 2:
        this.level2Name = this.cmsNavigation.breadCrumbTrail[0].label;
        this.level2ProjectID = this.cmsNavigation.breadCrumbTrail[0].id;
        this.showLevel2 = true;

        if(this.showLevel3){
          this.cssHideOnMobilelv1 = true;
          this.cssHideOnMobilelv2 = true;
        }

      case 1:
        this.level1Name = this.customProjectNaming.level1Name + 's';
        this.showLevel1 = true;

        if(this.showLevel2){
          this.cssHideOnMobilelv1 = true;
        }

    }

    this.show();

  }

  show() {
    this.hideBreadCrumb = false;
    this.cdRef.detectChanges();
  }



  // ==============================================================================
  // Console Log
  // Can easily comment out below to turn off all consoles output
  // ==============================================================================
  ShowConsoleMsg(msg: string) {
    console.log("bread-crumb", msg);
  }

  ShowConsoleMsgObj(msg: string, AnyObj: any) {
    console.log("bread-crumb" + " _ " + msg, AnyObj);
  }


  private setupHomePage() {

    console.log("Breadcrumb - SetupHomePage()");
    if (this.notebookID == null && this.cmsNavigation == null
      && this.notebookName == null && this.sectionName == null) {
      // Show Home setup (home icon only)


      // My and Team Notebooks
      this.showNotebookLevel = false;
      this.showMyNotebookLevel = false;
      this.showTeamNotebookLevel = false;

      // CMS Levels
      this.showLevel1 = false;
      this.showLevel2 = false;
      this.showLevel3 = false;
      this.showLevel4 = false;
      this.showLevel5 = false;
      this.showNotebook = false;
      this.showSectionPage = false;

      this.isHome = true;
    }
  }


  private setupBreadCrumbForNotebook() {

    if (this.notebookID != null) {
      // ----------------------------
      // Notebook info set
      // ----------------------------
      this.ShowConsoleMsg("Showing Note Crumb");
      this.showNotebook = true;
    }

    this.cdRef.detectChanges();

  }

  setupMainPages() {
    if (this.showSectionPage == false) {
      // Not set
      return;
    }
    // else, we are on a main page/section

    this.showSectionPage = true;

    this.cdRef.detectChanges();

  }


  // ========================================================================
  // RENAME NOTEBOOK
  // ========================================================================
  RenameNotebook() {
    this.renameNotebook.isVisible = true;
    this.cdRef.detectChanges();

  }
}

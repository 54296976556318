import {
  Component,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';

import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
// import { UserProfileService } from '../service/user-profile.service';
import { ForensicUserProfileService } from '../service/forensicUserProfile.service';

import { APP_CONFIG, AppConfig } from '../app-config.module';
import { NotificationService } from '../service/notification.service';
// import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons';
import { isIPv4 } from 'net';
import { MsalService } from '@azure/msal-angular';
import { UserModel } from '../shared/customObjects/userModel';
import { ProfileSettingComponent } from './components/profile-setting/profile-setting.component';
import { BrandingSettingComponent } from './components/branding-setting/branding-setting.component';
import { CaseManagementComponent } from './components/case-management/case-management.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {

  allNotification: any[] = [];
  allUnreadNotification: any[] = [];
  unreadNotification: any[] = [];
  allUnreadAndreadNotification: any[] = [];
  SetInterval: any;
  StopInterval: any;
  index: number;
  p: number = 1;
  u: number = 1;
  nCount: number = 0;
  StartStopInterVal: boolean = false;
  interval: any;


  @ViewChild("componentProfileSettings")
  componentProfileSettings: ProfileSettingComponent;


  @ViewChild('componentBranding')
  componentBranding: BrandingSettingComponent;


  @ViewChild("componentCaseManagement")
  componentCaseManagement: CaseManagementComponent;





  public pageChanged(args: any) {
    this.index = 0;
    this.p = args;
    this.index = (args - 1) * 3;
  }
  // profile: string = this.config.profile;
  AdvanceMode: string = this.config.AdvanceMode;

  public isVisible: boolean = false;
  public isLoading: boolean = false;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';

  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };


  isMobile: boolean = false;

  public UnReadpageChanged(args: any) {
    this.index = 0;
    this.u = args;
    this.index = (args - 1) * 3;
  }

  IfLogin: boolean = false;

  unReadNotificationIDs: any[] = [];

  user: UserModel = new UserModel();



  constructor(
    public _userProfileService: ForensicUserProfileService,
    private _notificationService: NotificationService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: MsalService,
    private appComponent: AppComponent

  ) {
    // console.log('Setting Constructor called');
  }

  @ViewChild('ejDialogadminMenu', { static: true })
  ejDialogadminMenu: DialogComponent;
  public targetElementadminMenu: HTMLElement;

  @ViewChild('ejDialogNotification', { static: true })
  ejDialogNotification: DialogComponent;
  public targetElementNotification: HTMLElement;

  title = 'New-Repo';
  componentName = 'profile';

  ngOnInit() {
    
  }

  LoadControl(user: UserModel){
    console.log('LoadControl - Settings');

    this.user = user;

    this.IfLogin = false;

    document.getElementById('Refresh').classList.add('fa-spin');

    // ----------------------------
    // Notifications - Get All
    // ----------------------------
    this._notificationService.GetAllNotification().subscribe((r) => {
      this.allNotification = r.result;
      this.allUnreadNotification = [];
      for (var i = 0; i < r.result.length; i++) {
        if (r.result[i].dateTimeNotificationRead == undefined) {
          this.nCount++;
          this.unReadNotificationIDs.push(r.result[i].notificationID);
          this.allUnreadNotification.push(r.result[i]);
        }
      }
      document.getElementById('Refresh').classList.remove('fa-spin');
    });
  }


    // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
  }

  public onDialogClose(event: any): void {
    // this.isVisible = false;
    this.hide();
  }

  // ==================================================================
  //
  // ==================================================================
  viewAllNotification() {
    document.getElementById('Allnotification').style.display = 'block';
    document.getElementById('ViewAllNotification').style.display = 'none';
  }

  // ==================================================================
  //
  // ==================================================================
  UnreadNotification() {
    if (this.nCount === 0) {
      document.getElementById('Allnotification').style.display = 'none';
      document.getElementById('ViewAllNotification').style.display = 'block';
    } else {
      this.allNotification = this.allUnreadNotification;
    }
  }

  // ==================================================================
  //
  // ==================================================================
  readAllNotification() {
    for (var i = 0; i < this.unReadNotificationIDs.length; i++) {
      this._notificationService
        .MarkAsReadNotification(this.unReadNotificationIDs[i])
        .subscribe((r) => {
          this.refreshnotification();
        });
    }
  }

  // ==================================================================
  //
  // ==================================================================
  refreshnotification() {
    document.getElementById('Refresh').classList.add('fa-spin');
    this._notificationService.GetAllNotification().subscribe((r) => {
      this.allNotification = r.result;
      this.nCount = 0;
      this.allUnreadNotification = [];
      this.unReadNotificationIDs = [];
      for (var i = 0; i < r.result.length; i++) {
        if (r.result[i].dateTimeNotificationRead == undefined) {
          this.nCount++;
          this.unReadNotificationIDs.push(r.result[i].notificationID);
          this.allUnreadNotification.push(r.result[i]);
        }
      }
      document.getElementById('Refresh').classList.remove('fa-spin');
    });
  }

  // ==================================================================
  //
  // ==================================================================
  Userlogout() {
    // this.IfLogin = false;
    // window.location.href =
    //   this.config.loginUrl +
    //   '/Account/logout?returnUrl=' +
    //   document.location.href;

      this.authService.logoutRedirect({
        postLogoutRedirectUri: this.config.loginUrl
      });
  }

  // ==================================================================
  //
  // ==================================================================
  UserProfileEdit() {
    location.href = this.config.loginUrl + '/profile/edit';
  }

  // ==================================================================
  //
  // ==================================================================
  headerm(a) {
    var header = a.target;
    header.classList.toggle('show-dropdowne');

    var d = document.getElementsByClassName('b-icon notification-show')[0];
    if (d != undefined) {
      d.classList.remove('notification-show');
    }
  }

  // ==================================================================
  //
  // ==================================================================
  notification(a) {
    var ss = a.target;
    ss.classList.toggle('notification-show');

    //a.target.classList.toggle('notification-show');
    var c = document.getElementsByClassName('dots headermenu-show')[0];
    if (c != undefined) {
      c.classList.remove('headermenu-show');
    }
  }

  // ==================================================================
  //
  // ==================================================================
  adminMenu(event: any) {
    this.ejDialogadminMenu.show();
  }

  // ==================================================================
  //
  // ==================================================================
  public onOverlayClickadminMenu: EmitType<object> = () => {
    this.ejDialogadminMenu.hide();
  };

  // ==================================================================
  //
  // ==================================================================
  Notification(event: any) {
    this.ejDialogNotification.show();
  }

  // ==================================================================
  //
  // ==================================================================
  public onOverlayClickNotification: EmitType<object> = () => {
    this.ejDialogNotification.hide();
  };

  // ==================================================================
  //
  // ==================================================================
  MarkAsReadOrUnread(nId: number) {
    var getDiv = document.getElementById('ntf_' + nId);
    if (getDiv.style.opacity == '0.5') {
      this._notificationService.MarkAsUnReadNotification(nId).subscribe((r) => {
        if (r.success == true) {
          this.refreshnotification();
        }
      });
    } else {
      this._notificationService.MarkAsReadNotification(nId).subscribe((r) => {
        if (r.success == true) {
          this.refreshnotification();
        }
      });
    }
  }

  // ==================================================================
  //
  // ==================================================================
  addTeam() {
    document.body.classList.add('team');
  }



  onChangeComponent(component) {
    console.log("component", component);
    this.componentName = component;

    switch(this.componentName){
      case "branding":
        this.componentBranding.LoadControl();
        break;
      case "casemanagement":
      this.componentCaseManagement.LoadControl(this.appComponent.user.userID);
      break;
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { AppComponent } from 'src/app/app.component';
import { FileOptionsComponent } from 'src/app/components/settings/file-options/file-options.component';
import { OrganizationService } from 'src/app/service/organization.service';
import { ForensicProjectServiceV2 } from 'src/app/service/project-v2.service';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';
import { threadId } from 'worker_threads';
import { CaseManagementBinTypeComponent } from '../case-management-bin-type/case-management-bin-type.component';
import { CaseManagementIncidentNumbersComponent } from '../case-management-incident-numbers/case-management-incident-numbers.component';
import { CaseManagementIncidentTypeComponent } from '../case-management-incident-type/case-management-incident-type.component';
import { CaseManagementOrgTeamComponent } from '../case-management-org-team/case-management-org-team.component';
import { CaseManagementReceivedMethodComponent } from '../case-management-received-method/case-management-received-method.component';
import { CaseManagementWorkRequestDocumentTypeComponent } from '../case-management-work-request-document-type/case-management-work-request-document-type.component';
import { CaseManagementWorkRequestExhibitTypeComponent } from '../case-management-work-request-exhibit-type/case-management-work-request-exhibit-type.component';
import { CaseManagementWorkRequestStatusComponent } from '../case-management-work-request-status/case-management-work-request-status.component';
import { CmsStructureComponent } from '../cms-structure/cms-structure.component';

@Component({
  selector: 'app-case-management',
  templateUrl: './case-management.component.html',
  styleUrls: ['./case-management.component.scss']
})
export class CaseManagementComponent implements OnInit {

  userID: string = "not set";

  @ViewChild('componentIncidentNumbers')
  componentIncidentNumbers: CaseManagementIncidentNumbersComponent;

  @ViewChild('componentFileFormatOptions')
  componentFileFormatOptions: FileOptionsComponent;
  

  @ViewChild('componentCaseManagementStructure')
  componentCaseManagementStructure: CmsStructureComponent;

 

  @ViewChild('componentOrgTeam')
  componentOrgTeam: CaseManagementOrgTeamComponent;

  @ViewChild('componentIncidentType')
  componentIncidentType: CaseManagementIncidentTypeComponent;

  @ViewChild('componentBINType')
  componentBINType: CaseManagementBinTypeComponent;


  @ViewChild('componentReceivedMethod')
  componentReceivedMethod: CaseManagementReceivedMethodComponent;

  @ViewChild('componentWorkRequestDocumentType')
  componentWorkRequestDocumentType: CaseManagementWorkRequestDocumentTypeComponent;

  @ViewChild('componentWorkRequestStatus')
  componentWorkRequestStatus: CaseManagementWorkRequestStatusComponent;

  @ViewChild('componentWorkRequestExhibitType')
  componentWorkRequestExhibitType: CaseManagementWorkRequestExhibitTypeComponent;

  isLoading: boolean = true;

  // Level Settings
  showPrivateIndustrySettings: boolean = true;
  showLawEnforcementSettings: boolean = true;


  public cmsTypeOptions: Object[] = [{ value: '0', text: 'CMS Disabled' }, { value: '1', text: 'Law Enforcement' }, { value: '2', text: 'Private Industry' }];
  public cmsType: string = "1";

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    private forensicProjectService: ForensicProjectServiceV2,
    public customProjectNaming: ProjectLevelNaming,
    public appComponent: AppComponent,


  ) { }

  ngOnInit(): void {
    // ngOnInit

  }

  ngAfterViewInit() {

    // -------------------------------------------------------------------------------------------
    // IMPORTANT NOTE: The injected class can take a bit to load from the DB...
    // to ensure the RadioButton is properly selected, need to make sure we wait till the 
    // CMS level is returned and then convert to STRING for comparing. 
    // Using the adminCmsSettingsModel.cmsType for the NgModel does not work 
    // and no radio button is ever pre-selected.
    // Once properly set, then show the page
    // -------------------------------------------------------------------------------------------
    setTimeout(() => {
      console.log("Loading CMS Admin Settings");

      this.cmsType = this.adminCmsSettingsModel.cmsType.toString();
      console.log("CMS LEVEL", this.cmsType);

      // this.levelStr = this.adminCmsSettingsModel.cmsType.toString();

      // console.log("levelStr", this.levelStr);
      // ----------------------------------------------------------
      // Show the page now that it is setup with proper values.
      // ----------------------------------------------------------
      this.isLoading = false;
    }, 500);
  }

  LoadControl(userID: string){
    this.userID = userID;
  }

  clickLevel(level: number) {
    console.log("clickLevel", level);
    this.adminCmsSettingsModel.cmsType = level;
    this.adminCmsSettingsModel.cmsTypeStr = level.toString();
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);

    if (level == 1) {
      // -------------------------------
      // Setup Custom Naming for LEO
      // -------------------------------
      this.customProjectNaming = this.customProjectNaming.SetupDefaultLEO(this.customProjectNaming);
    }
    else if (level == 2) {
      // -------------------------------
      // Setup Custom Naming for Private
      // -------------------------------
      this.customProjectNaming = this.customProjectNaming.SetupDefaultPrivateIndustry(this.customProjectNaming);
    }

    this.forensicProjectService.UpdateAllProjectLevels(this.customProjectNaming)
      .subscribe((result) => {
        console.log("Custom Levels Saved", result.result);
      },
        (error) => {
          console.error(error);
          this.toastr.error(error, "ERROR")
        });

  }


  EnableDigitalForensicsCMSClick(checkbox: MatCheckboxChange) {
    console.log("event click", checkbox);

    this.adminCmsSettingsModel.digitalForensicsCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }

  EnableVideoForensicsCMSClick(checkbox: MatCheckboxChange) {
    this.adminCmsSettingsModel.videoForensicsCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }


  EnablePrivateInvestigatorsCMSClick(checkbox: MatCheckboxChange) {
    this.adminCmsSettingsModel.privateInvesgigatorsCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }

  EnableCyberCMSClick(checkbox: MatCheckboxChange) {
    this.adminCmsSettingsModel.cyberCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }

  EnableOsintCMSClick(checkbox: MatCheckboxChange) {
    this.adminCmsSettingsModel.osintCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }

  EnableWorkplaceCMSClick(checkbox: MatCheckboxChange) {
    this.adminCmsSettingsModel.workplaceInvestigationsCmsActivated = checkbox.checked;
    this.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.adminCmsSettingsModel);
  }


  EditCmsStructure(){
    console.log("EditCmsStructure()");

    this.componentCaseManagementStructure.LoadControl(this.userID);
    this.componentCaseManagementStructure.show();
  }

}

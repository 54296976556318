import {PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import stringUtils from '../shared/Utility/stringUtils';

@Pipe({ name: 'safeHtml' })
export class ParsesafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: any, args?: any): any {
    
    console.log("safeHtml transform", value);

    // console.log("safeHtml transform - replaced ", value.replace(/\n/g, "---"));
    // console.log("safeHtml transform - replaced br", stringUtils.replaceAll(value, "<br />", "--"));

    // -----------------------------------------------------------------------------------------------
    // BUG - With <br /> tags above <table...> 
    // Adds a bunch of BR tags.
    // See: https://forensicnotes.atlassian.net/wiki/spaces/DEV/pages/2010218515/SafeHtml+BUG
    // -----------------------------------------------------------------------------------------------

    let newVal: string = stringUtils.FixBrBug(value);

    console.log("safeHtml transform - newVal", newVal);

    
    let returnVal = this.sanitizer.bypassSecurityTrustHtml(newVal);
    console.log("safeHtml transform - returnVal", returnVal);
    return returnVal;
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountSetupRoutingModule } from './account-setup-routing.module';
import { AccountSetupComponent } from './account-setup.component';
import { TermsModule } from './terms/terms.module';
import { TimezoneModule } from './timezone/timezone.module';


@NgModule({
  declarations: [
    AccountSetupComponent
  ],
  imports: [
    CommonModule,
    AccountSetupRoutingModule,
    TermsModule,
    TimezoneModule
  ],
  exports: [
    AccountSetupComponent
  ]
})
export class AccountSetupModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementWorkRequestExhibitTypeAddRoutingModule } from './case-management-work-request-exhibit-type-add-routing.module';
import { CaseManagementWorkRequestExhibitTypeAddComponent } from './case-management-work-request-exhibit-type-add.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CaseManagementWorkRequestExhibitTypeAddComponent
  ],
  imports: [
    CommonModule,
    CaseManagementWorkRequestExhibitTypeAddRoutingModule,
    DialogModule,
    SharedModule,
  ],
  exports: [
    CaseManagementWorkRequestExhibitTypeAddComponent
  ]
})
export class CaseManagementWorkRequestExhibitTypeAddModule { }

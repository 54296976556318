import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { AutoCompleteAllModule, AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentTypeModel } from 'src/app/domain/IncidentType';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { OrganizationService } from 'src/app/service/organization.service';
import { v4 as newGUID } from 'uuid';

@Component({
  selector: 'app-case-management-org-team-add',
  templateUrl: './case-management-org-team-add.component.html',
  styleUrls: ['./case-management-org-team-add.component.scss']
})
export class CaseManagementOrgTeamAddComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = true;

  public isEditMode : boolean = false;

  public showUpdatingSpinner: boolean = false;

  @Output() newOrgTeamEvent = new EventEmitter<boolean>();

  updateNewOrgUnitTypeEvent(value: boolean) {
    this.newOrgTeamEvent.emit(value);
  }


  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';
  // public dialogDragTarget = '.setting-page';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };

  btnText: string = "Add";          // Can be either Add or Update

  orgName: string = "";
  orgID: string = "";


  // ===================================
  // CONSTRUCTOR
  // ===================================
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }




  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
  }

  public onDialogClose(event: any): void {
    this.hide();
  }

  SetupForNew() {
    this.isLoading = false;
    this.btnText = "Add";
  }

  SetupForEditing(orgID : string, orgName : string) {
    this.isLoading = true;
    this.isEditMode = true;
    this.btnText = "Update";

    this.orgID = orgID;
    this.orgName = orgName;

    this.isLoading = false;
  }

  AddUpdateOrgTeam() {

    if(this.isEditMode){
     // orgID already set
    }
    else{
      // New Number Record
      this.orgID = newGUID();
    }

    // Show updating Spinner
    this.showUpdatingSpinner = true;


    this.organizationService.AddUpdateOrgTeam(this.orgID, this.orgName)
      .subscribe((result) => {

        this.showUpdatingSpinner = false;

        console.log("result", result);

        // Assume success as result.success returns false?
        if(this.isEditMode){
        this.toastr.success("Org Unit / Team Updated");
        }
        else{
          // added
          this.toastr.success("Org Unit / Team Added");
        }

        // --------------------------------------------------------------------------------------
        // Make sure the parent calling this updates it data to show the new Incident #.
        // --------------------------------------------------------------------------------------
        this.updateNewOrgUnitTypeEvent(true);

        // Reset Values
        this.orgName = "";

        this.hide();
      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to obtain Org Unit / Team, please contact support@forensicnotes.com", "ERROR");
        });
  }

  Cancel(){
     // Reset Values
     this.orgName = "";

     this.hide();
  }

}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// --------------------------------------------
// Registering Syncfusion license key
// --------------------------------------------
registerLicense('NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVnW3lccHBWRWRUVUJ2');

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
};


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}


// ---------------------------------------------------------------------
// console.log <-- HIDE This on Production
// ---------------------------------------------------------------------
if (environment.hideConsoleLog) {
  // check if window exists, if you render backend window will not be available 
  if (window) {
    window.console.log = function () { };
  }
} 
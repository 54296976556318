import { HttpErrorResponse } from '@angular/common/http';
// import { createDirectiveDefinitionMap } from '@angular/compiler/src/render3/partial/directive';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { SortService, ResizeService, GroupService, ColumnMenuService, PageService, FilterService, ToolbarItems, EditService, ToolbarService, IEditCell, ExcelQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs, ExcelExportService, PdfExportService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ContextMenuItem, GroupSettingsModel, FilterSettingsModel, EditSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentModel } from 'src/app/domain/Incident';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { EmitType } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CaseManagementIncidentTypeAddComponent } from '../case-management-incident-type-add/case-management-incident-type-add.component';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';

@Component({
  selector: 'app-case-management-incident-type',
  templateUrl: './case-management-incident-type.component.html',
  styleUrls: ['./case-management-incident-type.component.scss'],
  providers: [SortService, GroupService, ColumnMenuService, PageService, EditService, ToolbarService, ExcelExportService, PdfExportService],
  changeDetection: ChangeDetectionStrategy.OnPush,


})
export class CaseManagementIncidentTypeComponent implements OnInit {

  @Output() itemsUpdated = new EventEmitter<boolean>();

  updateParent() {
    this.itemsUpdated.emit(true);
  }

  public isVisible: boolean = false;
  public isLoading: boolean = true;
  public isModal: boolean = true;
  public showDeleteSpinner: boolean = false;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };



  // DataGrid Settings
  public pageSettings: Object;
  public customAttributes: Object;
  public resizeSettings = { mode: "Normal" };


  records: any[];

  // --------------------------------------------------------------------
  // Used to store full single record for menu clicks
  // and show display info
  // --------------------------------------------------------------------
  currRecordLabel: string = "";
  currRecID: string = "";


  @ViewChild('grid')
  public grid: GridComponent;

  @ViewChild("componentAdd")
  componentAdd: CaseManagementIncidentTypeAddComponent;


  @ViewChild("ejDialogMenu")
  ejDialogMenu: DialogComponent;

  @ViewChild("ejDialogDelete")
  ejDialogDelete: DialogComponent;


  currMenuIncident: IncidentModel = null;

  public filterSettings: FilterSettingsModel;
  public toolbar: ToolbarItems[];
  public sortOptions: object;


  filterBarTemplateForYear = {}

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private toastr: ToastrService,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    public customProjectNaming: ProjectLevelNaming,
    public readonly cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {

    this.isLoading = false;
    // this.LoadIncidentTypes();

    this.toolbar = ['ExcelExport', 'CsvExport', 'Search'];

    this.sortOptions = { columns: [{ field: 'value', direction: 'Ascending' }] };

    this.pageSettings = { pageSizes: true, pageSize: 5 };

  }


  toolbarClick(args: ClickEventArgs): void {

    switch (args.item.tooltipText) {
      case 'PDF Export':
        this.grid.pdfExport();
        break;
      case 'Excel Export':
        this.grid.excelExport();
        break;
      case 'CSV Export':
        this.grid.csvExport();
        break;
    }
  }

  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;

    this.LoadIncidentTypes();
    this.cdRef.detectChanges();
  }


  LoadIncidentTypes() {


    this.caseManagementService.GetIncidentTypes()
      .subscribe((result) => {

        console.log("result", result);

        this.records = result;
    this.cdRef.detectChanges();


      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to obtain " + this.customProjectNaming.level2Name + "  Types, please contact support@forensicnotes.com", "ERROR");
        });

  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
    this.cdRef.detectChanges();

  }

  public onDialogClose(event: any): void {
    this.isVisible = false;
  }

  OpenIndexMenu() {

    // Get Incident
  }

  AddIncidentType() {
    this.componentAdd.SetupForNewIncident();
    this.componentAdd.show();
  }


  refreshData(event) {
    console.log("Refreshing Data");
    this.LoadIncidentTypes();

    // ---------------------------------------------------------
    // Update Parent so that it knows to refresh its list
    // ---------------------------------------------------------
    this.updateParent();
  }


  menuClick(event, record) {


    this.currRecID = record.id;
    this.currRecordLabel = record.value;

    this.ejDialogMenu.show();
  }

  public onOverlayClickOutsideMenu: EmitType<object> = () => {
    this.ejDialogMenu.hide();
  };


  EditIncidentType() {

    this.ejDialogMenu.hide();


    this.componentAdd.SetupForEditing(this.currRecID, this.currRecordLabel);
    this.componentAdd.show();
  }

  DeleteIncidentType() {
    this.ejDialogMenu.hide();
    this.ejDialogDelete.show();
  }


  CancelDelete() {
    // Close
    this.ejDialogDelete.hide();
  }

  DeleteConfirmed() {

    this.showDeleteSpinner = true;


    this.caseManagementService.DeleteIncidentType(this.currRecID)
      .subscribe((result) => {

        console.log("result", result);


        this.toastr.success(this.customProjectNaming.level2Name + " Type Deleted");
        this.showDeleteSpinner = false;

        this.ejDialogDelete.hide();
        this.refreshData(null);


      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to Delete " + this.customProjectNaming.level2Name + "  Type, please contact support@forensicnotes.com", "ERROR");
        });


  }


}

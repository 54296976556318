<ejs-dialog #ejDialogSettings [showCloseIcon]="false"
  [header]="state === 'FillPassword' ? 'Client-Side Encryption Password': 'Password is not Correct'"
  [visible]="isVisible" [animationSettings]="animationSettings" [enableResize]='false' [isModal]="true"
  (beforeClose)="validation($event)">
  <ng-template #content>
    <div class="app-encryption-password">
      <p>&nbsp;</p>
      <ng-container *ngIf="!isPasswordSetup;else AlreadySetupPasswordTemplate">
        <mat-radio-group [value]="type" color="primary" (change)="onTypeChange($event)">
          <mat-radio-button class="radio-spacing" [value]="'PIN'">
            <b>PIN:</b> Quick & Easy - Moderately Secure
          </mat-radio-button>
          <mat-radio-button class="radio-spacing" [value]="'Password'">
            <b>Password:</b> Secure (use Complex Password)
          </mat-radio-button>
        </mat-radio-group>
        <ng-container *ngIf="type === 'Password'">
          <ng-container [formGroup]="setupPassword">
            <mat-form-field appearance="outline">
              <mat-label>Enter your password</mat-label>
              <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
              <button tabindex="-1" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="setupPassword.get('password')?.errors">
                {{setupPassword.controls.password.errors | pipeFunction: decorateMessageError}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Confirm your password</mat-label>
              <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="confirmPassword">
              <button tabindex="-1" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="setupPassword.get('confirmPassword')?.errors">
                {{setupPassword.controls.confirmPassword.errors | pipeFunction: decorateMessageError}}</mat-error>
            </mat-form-field>
          </ng-container>
          <div class="buttonSectionOneColRight">
            <button [disabled]="setupPassword.invalid" class="success" id="btnSubmit" #btnSubmit
              (click)="onSetupPassword()">Save</button>
          </div>
        </ng-container>
        <ng-container *ngIf="type === 'PIN'">
          <mat-tab-group [selectedIndex]="selectedIndex">
            <mat-tab>
              <ng-container *ngIf="selectedIndex === 0">
                <app-pin-numpad (onChange)="onChangeNumpadSetupPassword($event, 'password')"></app-pin-numpad>
                <div class="buttonSectionOneColRight">
                  <button [disabled]="setupPassword.get('password').invalid" class="success" (click)="selectedIndex = 1">Confirm</button>
                </div>
              </ng-container>
            </mat-tab>
            <mat-tab>
              <ng-container *ngIf="selectedIndex === 1">
                <ng-container *ngIf="!showError">
                  <label>Please confirm your PIN</label>
                </ng-container>
                <label *ngIf="showError" class="error">PIN is not match</label>
                <app-pin-numpad (onChange)="onChangeNumpadSetupPassword($event, 'confirmPassword')"></app-pin-numpad>
                <div class="button-group">
                  <button class="cancel" (click)="onBack()">Back</button>
                  <button [disabled]="setupPassword.get('confirmPassword').value?.length < 6" class="success" id="btnSubmit" #btnSubmit
                    (click)="onSetupPIN()">Save</button>
                </div>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
      </ng-container>
    </div>
 
  </ng-template>
</ejs-dialog>

<ng-template #DeletePasswordConfirmationTemplate>
  <p>You have entered an incorrect {{type}}. This {{type}} is used to encrypt and decrypt notes on your device.
  </p>
  <p>Options:</p>
  <ul>
    <li>Re-enter {{type}}: Try entering your {{type}} again.</li>
    <li>Delete Local Cache: Remove all unsynced notes stored on this device. This action cannot be undone.
      ({{numberOffline.notes + numberOffline.notebooks}} Notes/ Notebooks will be lost).</li>
  </ul>
  <div class="buttonSectionOneColRight">
    <button ejs-button class="cancel" type="button" (click)="onDeleteCancel()">Re-Enter {{type}}</button>
    <button class="delete" id="btnSubmit" #btnSubmit (click)="onDeleteOldEncriptionPassword()">Delete Local
      Cache</button>
  </div>
</ng-template>

<ng-template #AlreadySetupPasswordTemplate>
  <ng-container *ngIf="state === 'FillPassword'; else DeletePasswordConfirmationTemplate">
    <ng-container *ngIf="type === 'Password'">
      <ng-container *ngTemplateOutlet="VerifyPassword"></ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'PIN'">
      <ng-container *ngTemplateOutlet="VerifyPIN"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #VerifyPassword>
  <mat-form-field appearance="outline">
    <mat-label>Enter your password</mat-label>
    <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordControl">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hidePassword">
      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="passwordControl.errors && passwordControl.errors.minlength">Minimum
      {{passwordControl.errors.minlength.requiredLength}} Characters</mat-error>
  </mat-form-field>
  <div class="buttonSectionOneColRight">
    <button [disabled]="passwordControl.invalid" class="success" id="btnSubmit" #btnSubmit
      (click)="onSavePassword()">Submit</button>
  </div>
</ng-template>
<ng-template #VerifyPIN>
  <app-pin-numpad (onChange)="onChangeNumpad($event)"></app-pin-numpad>
  <div class="buttonSectionOneColRight">
    <button [disabled]="passwordControl.invalid" class="success" id="btnSubmit" #btnSubmit
      (click)="onSavePassword()">Submit</button>
  </div>
</ng-template>
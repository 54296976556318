import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { IncidentTypeModel } from "../domain/IncidentType";
import { v4 as uuidv4 } from 'uuid';
import { IncidentModel } from "../domain/Incident";
import { SuccessModel } from "../domain/Success";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { WorkRequestModel } from "../shared/customObjects/workRequestModel";
import { ExhibitVideoModel } from "../shared/customObjects/exhibitVideoModel";
import { WorkRequestTaskModel } from "../shared/customObjects/workRequestTaskModel";
import { ExhibitComputerModel } from "../shared/customObjects/exhibitComputerModel";
import { ExhibitCloudModel } from "../shared/customObjects/exhibitCloudModel";
import { ExhibitModel } from "../shared/customObjects/exhibitModel";
import { ExhibitNasModel } from "../shared/customObjects/exhibitNasModel";
import { ExhibitHddModel } from "../shared/customObjects/exhibitHddModel";
import { ExhibitUSBorSDModel } from "../shared/customObjects/exhibitUSBorSDModel";
import { protectedResources } from '../auth-config';
import { ProjectSimpleModel } from "../shared/customObjects/projectSimpleModel";
import { PersonEntityModel } from "../shared/customObjects/personEntityModel";
import URLUtils from "../shared/Utility/urlUtils";
import { AnyARecord } from "dns";



@Injectable({
  providedIn: 'root'
})
export class CaseManagementService {

  // ==================================================================
  // 
  // ==================================================================
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient
  ) {
    // constructor

    console.log("URL", this.url);
  }

  url = protectedResources.caseManagementApi.endpoint;



  // ==================================================================
  // 
  // ==================================================================
  CreateProjectSimple(projectSimple: ProjectSimpleModel) {

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": projectSimple.id,
      "title": projectSimple.title,
      "synopsis": projectSimple.synopsis,
      "isInActive": projectSimple.isInActive,
    };

    console.log("body", body);


    return this.httpClient.put<any>(
      this.url + "/api/Project",
      body,
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetProjects(enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Project";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  getProjectById(projectID: string, enableServiceWorkerCache: boolean, userID: string) {
    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Project/" + projectID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }

  // ==================================================================
  //  Delete Incident Type
  // ==================================================================
  DeleteProject(projectID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Project/" + projectID,
      httpsOptions
    );
  }




  // ==================================================================
  // 
  // ==================================================================
  GetIncidentYears(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Incident/Years",
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetIncidentTypes(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    console.log("url-GetIncidentTypes", this.url);

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/IncidentType",
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateIncidentTypes(incidentTypeID: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": incidentTypeID,
      "value": value
    };

    console.log("body", body);


    return this.httpClient.put<any>(
      this.url + "/api/Lookups/IncidentType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Incident Type
  // ==================================================================
  DeleteIncidentType(incidentTypeID): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/IncidentType/" + incidentTypeID,
      httpsOptions
    );
  }

  // ==================================================================
  //  Get Incident Records
  // ==================================================================
  GetIncidentRecords(enableServiceWorkerCache: boolean, userID: string, isNotInProject: boolean, year: string = "", incidentTypeID: string = ""): Observable<any[]> {


    const formData = new FormData();
    formData.append("year", year);
    formData.append("incidentTypeID", incidentTypeID);

    const httpParams = new HttpParams()
      .set("year", year)
      .set("incidentTypeID", incidentTypeID)
      .set("IsNotInProject", isNotInProject.toString());

    // console.info(httpParams.toString());


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
      params: httpParams
    };

    let fullURL: string = this.url + "/api/Incident";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }


  // ==================================================================
  //  Get Incident by ID
  // ==================================================================
  GetIncidentRecordByID(userID: string, incidentID: string): Observable<any> {

    console.log("GetIncidentRecordByID(..) --> incidentID = " + incidentID);


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Incident/" + incidentID;
    fullURL = URLUtils.setURLCachingParam(fullURL, false, userID);

    console.log("fullURL = " + fullURL);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateIncident(incident: IncidentModel): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": incident.id,
      "yearOfIncident": incident.yearOfIncident,
      "incidentNumber": incident.incidentNumber,
      "incidentTypeId": incident.incidentTypeId,
      "notes": incident.notes,
      "projectId": incident.projectId,
      "requestingUnitid": incident.requestingUnitid
    };

    console.log("body", body);


    return this.httpClient.put<any>(
      this.url + "/api/Incident",
      body,
      httpsOptions
    );

  }




  // ==================================================================
  //  Delete Incident Records
  // ==================================================================
  DeleteIncident(incidentID): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Incident/" + incidentID,
      httpsOptions
    );
  }





  // ==================================================================
  // 
  // ==================================================================
  GetBinTypes(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/BinType",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateBinType(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "value": value
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/BinType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteBinType(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/BinType/" + id,
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetExhibitReceivingMethod(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/ReceivedMethod",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateExhibitReceivingMethod(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "value": value
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/ReceivedMethod",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteExhibitReceivingMethod(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/ReceivedMethod/" + id,
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  GetWorkRequestExhibitType(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/MediaType",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateWorkRequestExhibitType(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body = [
      {
        "id": id,
        "value": value
      }];

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/MediaType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteWorkRequestExhibitType(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/MediaType/" + id,
      httpsOptions
    );
  }



  // ==================================================================
  // 
  // ==================================================================
  GetWorkRequests(enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/WorkRequest";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  GetWorkRequestByID(workRequestID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/WorkRequest/" + workRequestID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateWorkRequest(workRequest: WorkRequestModel): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    //       "cmsType": workRequest.cmsType,
    // "cmsType": workRequest.cmsType,

    const body =
    {
      "id": workRequest.id,
      "workRequestType": workRequest.workRequestType.replace(" ", ""),  // remove spaces - forensicsType requires a single word name
      "incidentId": workRequest.incidentId,
      "projectId": workRequest.projectId,
      "requestingUnitTypeId": workRequest.requestingUnitTypeId,
      "requestingMemberId": workRequest.requestingMemberId,
      "primaryContactId": workRequest.primaryContactId,
      "address": workRequest.address,
      "victimInformation": workRequest.victimInformation,
      "suspectInformation": workRequest.suspectInformation,
      "specialInstructions": workRequest.specialInstructions
    };

    let fullURL: string = this.url + "/api/WorkRequest/" + workRequest.forensicsType.replace(" ", "");  // remove spaces - forensicsType requires a single word name 


    console.log("body", body);
    console.log("fullURL", fullURL);


    return this.httpClient.put<any>(
      fullURL,
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Work Request 
  // ==================================================================
  DeleteWorkRequest(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/WorkRequest/" + id,
      httpsOptions
    );
  }






  // ==================================================================
  // 
  // ==================================================================
  GetWorkRequestStatus(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/WorkRequestStatus",
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateWorkRequestStatus(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "value": value
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/WorkRequestStatus",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteWorkRequestStatus(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/WorkRequestStatus/" + id,
      httpsOptions
    );
  }



  // ==================================================================
  // 
  // ==================================================================
  GetFormTypes(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/FormType",
      httpsOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  AddUpdateFormType(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "value": value
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/FormType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete Bin Type 
  // ==================================================================
  DeleteFormType(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    console.log("Deleting ID = " + id);

    return this.httpClient.delete<any[]>(
      this.url + "/api/FormType/" + id,
      httpsOptions
    );
  }




  // ==================================================================
  // 
  // ==================================================================
  GetMediaTypes(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/MediaType",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateMediaType(id: string, value: string): Observable<SuccessModel> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": id,
      "value": value
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/MediaType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete
  // ==================================================================
  DeleteMediaType(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/MediaType/" + id,
      httpsOptions
    );
  }






  // ==================================================================
  // Only pass in the parameters you want to FILTER for
  // ==================================================================
  GetWorkRequestTasks(statusId: string, workRequestID: string, exhibitId: string,
    assignedToId: string, requestingUnitId: string, incidentId: string, enableServiceWorkerCache: boolean, userID: string): Observable<any[]> {

    let urlParams: string = "";


    if (statusId) {
      urlParams += "statusId=" + statusId + "&";
    }

    if (workRequestID) {
      urlParams += "workRequestId=" + workRequestID + "&";
    }

    if (exhibitId) {
      urlParams += "exhibitId=" + exhibitId + "&";
    }

    if (assignedToId) {
      urlParams += "assignedToId=" + assignedToId + "&";
    }

    if (requestingUnitId) {
      urlParams += "requestingUnitId=" + requestingUnitId + "&";
    }

    if (incidentId) {
      urlParams += "incidentId=" + incidentId + "&";
    }

    console.log("urlParams", urlParams);

    let fullURLv1: string = this.url + "/api/WorkRequestTask?" + urlParams;

    console.log("fullURLv1", fullURLv1);



    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = fullURLv1;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.httpClient.get<any[]>(
      fullURL,
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateWorkRequestTask(workRequestTask: WorkRequestTaskModel): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": workRequestTask.id,
      "workRequestID": workRequestTask.workRequestID,
      "exhibitID": workRequestTask.exhibitID,
      "priority": workRequestTask.priority,
      "statusID": workRequestTask.statusID,
      "assignedToID": workRequestTask.assignedToID,
      "statusComments": workRequestTask.statusComments
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/WorkRequestTask",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Get by ID
  // ==================================================================
  GetWorkRequestTask(id: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/WorkRequestTask/" + id;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }

  // ==================================================================
  //  Delete
  // ==================================================================
  DeleteWorkRequestTask(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/WorkRequestTask/" + id,
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  GetExhibitDispositionType(): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    // NON SENSITIVE INFORMATION - AlWAYS CACHE

    return this.httpClient.get<any[]>(
      this.url + "/api/Lookups/ExhibitDispositionType",
      httpsOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  AddUpdateExhibitDispositionType(workRequestTask: WorkRequestTaskModel): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json-patch+json " }),
      withCredentials: true,
    };

    const body =
    {
      "id": workRequestTask.id,
      "workRequestID": workRequestTask.workRequestID,
      "exhibitID": workRequestTask.exhibitID,
      "priority": workRequestTask.priority,
      "statusID": workRequestTask.statusID,
      "assignedToID": workRequestTask.assignedToID,
      "statusComments": workRequestTask.statusComments
    };

    console.log("body", body);

    return this.httpClient.put<any>(
      this.url + "/api/Lookups/ExhibitDispositionType",
      body,
      httpsOptions
    );

  }

  // ==================================================================
  //  Delete
  // ==================================================================
  DeleteExhibitDispositionType(id): Observable<any[]> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    return this.httpClient.delete<any[]>(
      this.url + "/api/Lookups/ExhibitDispositionType/" + id,
      httpsOptions
    );
  }






  AddUpdatePerson(
    person: PersonEntityModel
  ): Observable<any> {
    const httpPostOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json ",
      }),
      withCredentials: true,
    };


    const entity =
    {
      "personType": person.personType,
      "id": person.id,
      "notebookId": person.notebookId,
      "identification": person.identification,
      "firstName": person.firstName,
      "lastName": person.lastName,
      "dob": person.dob,
      // "dobYear": person,
      // "dobMonth": person,
      // "dobDay": person,
      "estimatedAge": person.estimatedAge,
      "streetName": person.streetName,
      "streetNumber": person.streetNumber,
      "city": person.city,
      "state": person.state,
      "country": person.country,
      "zipCode": person.zipCode,
      "contactMethod": person.contactMethod,
      "cellPhone": person.cellPhone,
      "cellPhoneCountryCode": person.cellPhoneCountryCode,
      "homePhone": person.homePhone,
      "homePhoneCountryCode": person.homePhoneCountryCode,
      "email": person.email,
      "notes": person.notes,
      "images": [],
      "associatedNoteID": person.associatedNoteID,

    };


    console.log("body", entity);


    return this.http.put<any>(
      this.url + "/api/Person/" + person.personType,
      entity,
      httpPostOptions
    );
  }

  // ==================================================================
  // 
  // ==================================================================
  GetPersonEntities(enableServiceWorkerCache: boolean, userID: string, query: string = ""): Observable<any[]> {

    if (query) {
      // Query set, so append ? to make proper format
      query = "?" + query;
    }

    console.log("GetPersonEntities", query);

    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Person" + query;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any[]>(fullURL, httpsOptions);
  }


  // ==================================================================
  // 
  // ==================================================================
  GetPersonByID(id: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };

    let fullURL: string = this.url + "/api/Person/" + id;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }

  // ==================================================================
  // 
  // ==================================================================
  GetPersonByNoteID(noteID: string, enableServiceWorkerCache: boolean, userID: string): Observable<any> {


    const httpsOptions = {
      headers: new HttpHeaders({ accept: "application/json " }),
      withCredentials: true,
    };


    let fullURL: string = this.url + "/api/Person/ByNote/" + noteID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    return this.httpClient.get<any>(fullURL, httpsOptions);
  }


   // ==================================================================
  // 
  // ==================================================================
  SendFileWitnessRequest(witnessId : string, contactMethod: string, commentsToWitness: string, personContentNoteId: string): Observable<any> {

    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("witnessId ", witnessId );
    formData.append("contactMethod", contactMethod);
    formData.append("commentsToWitness", commentsToWitness);
    formData.append("personContentNoteId", personContentNoteId);

    console.log("SendFileWitnessRequest - contactMethod", contactMethod);


    return this.http.post<any>(
      this.url + "/api/WitnessRequest/" + witnessId,
      formData,
      httpPostOptions
    );



  }









}


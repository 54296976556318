import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementIncidentNumbersRoutingModule } from './case-management-incident-numbers-routing.module';
import { CaseManagementIncidentNumbersComponent } from './case-management-incident-numbers.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';
import { IncidentNumbersModule } from 'src/app/incident-numbers/incident-numbers.module';

@NgModule({
  declarations: [
    CaseManagementIncidentNumbersComponent,

  ],
  imports: [
    CommonModule,
    CaseManagementIncidentNumbersRoutingModule,
    SharedModule,
    DialogModule,
    // GridModule,
    // AutoCompleteModule,
    // CaseManagementIncidentNumbersAddv2Module,
    IncidentNumbersModule,
  ],
  exports:
  [
    CaseManagementIncidentNumbersComponent
  ]
})
export class CaseManagementIncidentNumbersModule { }

<ejs-dialog id="ejDialogDownload" #ejDialogDownload (overlayClick)="onOverlayClickOutsideMenu()"
  (beforeClose)="onDialogClose($event)" [visible]="isVisible" header="Download {{noteOrNotebookText}}"
  [animationSettings]='animationSettings' [target]="dialogDragTarget" [allowDragging]='allowDialogDragging'
  [enableResize]='false' isModal="true" [showCloseIcon]='true' [width]="360" [zIndex]="99999">
  <ng-template #content>
    <div class="heading">Forensic {{noteOrNotebookText}} (PDF Only)</div>
    <button class="success" (click)="DownloadNoteOrNotebook(downloadPasswordEntered.value, '1')">Download PDF
      Only</button>
    <br>
    <hr />
    <br>
    <div class="heading">Forensic {{noteOrNotebookText}} (Full-Disclosure)</div>
    <p>
      ZIP Archive Includes:
    </p>
    <ul>
      <li>Attachments<br>
        (images, videos, documents, etc.)</li>
      <li>Forensics Notes<br>
        (including previous versions)</li>
    </ul>

    <p class="subheading">
      Password Protect ZIP Archive?
    </p>

    <div class="radio-btn" style="min-height:25px">
      <label>
        <input type="radio" value="no" name="question" (change)="Setradio('no');">
        <span class="cricle-r"></span>No
      </label>
    </div>
    <div class="radio-btn" style="min-height:25px">
      <label>
        <input type="radio" value="yes" [checked]='true' name="question" (change)="Setradio('yes');">
        <span class="cricle-r"></span>Yes - Password Protected
      </label>
    </div>
    <div class="input-box row">
      <input type="password" #downloadPasswordEntered id="downloadPasswordEntered" value="{{currentpassword}}">

      <div class="popup-l-icon">
        <div class="tooltip icon-b1" (click)="CopyInput(downloadPasswordEntered)">
          <span class="copyd-icon"></span>
          <span class="tooltiptext" id="myTooltip">Copy</span>
        </div>
        <div class="display-inline icon-b1 tooltip" (click)="showHidePassword($event)">
          <span class="eye-icon"></span>
          <span class="tooltiptext" id="myTooltip">Show/Hide Password</span>
        </div>
      </div>
    </div>
    <button class="success" (click)="DownloadNoteOrNotebook(downloadPasswordEntered.value, '0')">Download
      Full-Disclosure Package</button>
  </ng-template>
</ejs-dialog>

<!-- Info Dialog to user -->
<ejs-dialog id="ejDialogInfo" #ejDialogInfo (overlayClick)="onOverlayClickOutsideMenu()" [visible]="false"
  header="Download Forensic {{noteOrNotebookText}}" [showCloseIcon]='true' (beforeClose)="onDialogInfoClose($event)"
  [width]="450" [animationSettings]='animationSettings' [target]="dialogDragTarget"
  [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true" [zIndex]="10000">
  <ng-template #content>
    <!-- Note -->
    <p *ngIf="downloadingNote">Your Note {{PDForZIP}} is currently being downloaded.</p>
    <p *ngIf="downloadingNotePDF">Depending on your browser settings, your note will either be in your download folder or open within your browser.</p>
    <!-- Notebook -->
    <p *ngIf="downloadingNotebook">Your Notebook {{PDForZIP}} is currently being generated.</p>
    <p *ngIf="downloadingNotebook">When ready, a Dialog window will appear allowing you to download the generated {{PDForZIP}} by clicking the 'Download {{PDForZIP}}' button.
    </p>
    <!-- general info -->
    <p class="specialNote">You can close this window at any time.<br>
      The Download process will remain active in the background.</p>
  </ng-template>
</ejs-dialog>
<ejs-dialog id="ejDialog" #ejDialog showCloseIcon='true' header='{{customProjectNaming.level2Name}} Types' 
[width]="400"
    (beforeClose)="onDialogClose($event)"
  [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
  [allowDragging]='allowDialogDragging' isModal="isModal">
  <ng-template #content>
    <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
    <div *ngIf="!isLoading">

      <div class="buttonSectionOneColRight">
        <button class="SiteColor" #btnCreateChild (click)="AddIncidentType($event)">Add {{customProjectNaming.level2Name}} Type</button>
      </div>


      <ejs-grid #grid [dataSource]='records' allowPaging='true' [pageSettings]='pageSettings'
        [enableHover]="false" allowSorting="true" allowResizing="false" enableAdaptiveUI='true' showColumnMenu='true'
        allowFiltering='true' [filterSettings]='filterSettings' 
        [sortSettings]='sortOptions' 
        (toolbarClick)='toolbarClick($event)'
        [allowExcelExport]='true' [allowPdfExport]='true'
        [toolbar]='toolbar' 
        clipMode="EllipsisWithTooltip">
        <e-columns>
          <e-column field='value' width="50"  headerText='Type' textAlign='left'></e-column>
          
          <e-column width="25" allowEdit="false" textAlign='right'>
            <ng-template #template let-records>
              <div class="noteOptionsSection hand" (click)="menuClick($event, records)">
                <span class="material-icons md-36 iconBlue" title="Menu Options">more_vert</span>
              </div>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>


    </div>
  </ng-template>
</ejs-dialog>


<app-case-management-incident-type-add #componentAdd (newIncidentTypeEvent)="refreshData($event)">
</app-case-management-incident-type-add>


<!-- Note Menu Dialog -->
<ejs-dialog id="ejDialogMenu" #ejDialogMenu isModal="true" (overlayClick)="onOverlayClickOutsideMenu()"
  showCloseIcon="true" width="350px" [visible]="false" header="{{currRecordLabel}} Options" [animationSettings]='animationSettings'
  [target]="dialogDragTarget" [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true"
  [ngClass]="{'hidden' : isLoading}">
  <ng-template #content>
    <button mat-button class="mat-icon-menu-button" (click)="EditIncidentType()"
      title="Edit">
      <mat-icon>draw</mat-icon>
      Edit
    </button>
    <button mat-button class="mat-icon-menu-button" (click)="DeleteIncidentType()"
      title="Delete">
      <mat-icon>delete_outline</mat-icon>
      Delete
    </button>
    <hr>
    <div class="sectionGeekStats">
      <span class="titleGeekStats">{{customProjectNaming.level2Name}} Type ID:</span>{{currRecID}}<br>
      <span class="titleGeekStats">{{customProjectNaming.level2Name}} Type:</span>{{currRecordLabel}}
    </div>
  </ng-template>
</ejs-dialog>

<ejs-dialog id="ejDialogDelete" #ejDialogDelete showCloseIcon='false' 
  header='Delete {{customProjectNaming.level2Name}} Type'
   width="450"
   [visible]="false"
   [animationSettings]='animationSettings'
    [target]="dialogDragTarget"  [allowDragging]='allowDialogDragging' 
    [enableResize]='false' isModal="true"
   >
   <ng-template #content>
       <p>Are you sure you want to delete this {{customProjectNaming.level2Name}} type?</p>
       <div class="buttonSectionTwoCol">
        <button class="cancel" (click)="CancelDelete()">Cancel</button>
        <button class="delete"
        (click)="DeleteConfirmed()"
        ><img src="/assets/images/progress-spinner.gif" class="button-spinner"
        *ngIf="showDeleteSpinner" />Delete {{customProjectNaming.level2Name}} Type</button>
      <!-- </div> -->
    </div>
  </ng-template>
</ejs-dialog>
// DEPRECATED
export class TforensicNoteBook {
  notebookID: string;
  title: string;
  numberOfNotes: number;
  numberOfNotesIncludingNonTimestamped: number;
  dateOfLastNote: any;
  dateNotebookCreated: any;
  noteBookCreatedBy: string;
  noteBookCreatedByID: string;
  noteBookCreatedByProfileImage: string;
  result: any[];
  statusCode: any;
  isOwned: string;
  success: boolean;
  isMarkedAsDeleted: boolean = false;
  projectID: string = "";
  isInProject: boolean = false;
  noteVersioningAllowed: boolean = true;

}


import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, tap, throwError, timeout } from 'rxjs';
import { NetworkConnectionService } from './network-connection.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  countRequests = 0; // support for bring app online
  constructor(private networkConnectionService: NetworkConnectionService,  private toastr: ToastrService,) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!!['assets', 'tinymce'].find(e=> req.url.includes(e))) {
      return next.handle(req);
    }
    // exclude requests
    if(['Attachments/GetImage'].find(e=>req.url.includes(e))) {
      return next.handle(req);
    }
    const start = performance.now();
    return next.handle(req).pipe(
      tap(()=> {
        const end = performance.now();
        const responseTime = end - start;
        if(!this.networkConnectionService.isOnline && responseTime < 1000) {
          this.countRequests += 1;
          if(this.countRequests > 5) {
            this.networkConnectionService.setAppOnline();
          }
        }
      }),
      timeout(30000),
      catchError((error: Error) => {
        if (error.name === 'TimeoutError') {
          this.countRequests = 0;
          if(this.networkConnectionService.isOnline) {
            this.networkConnectionService.setAppOffline();
          }
          return throwError(() => 'Network is offline'); // Handle timeout error
        }
        return throwError(() => error); // Forward other errors
      })
    );
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Button } from '@syncfusion/ej2-angular-buttons';
import { AutoCompleteAllModule, AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentTypeModel } from 'src/app/domain/IncidentType';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-case-management-incident-type-add',
  templateUrl: './case-management-incident-type-add.component.html',
  styleUrls: ['./case-management-incident-type-add.component.scss']
})
export class CaseManagementIncidentTypeAddComponent implements OnInit {

  public isVisible: boolean = false;
  public isLoading: boolean = true;

  public isEditMode : boolean = false;

  public showUpdatingSpinner: boolean = false;

  @Output() newIncidentTypeEvent = new EventEmitter<boolean>();

  updateNewIncidentTypeEvent(value: boolean) {
    this.newIncidentTypeEvent.emit(value);
  }


  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';
  // public dialogDragTarget = '.setting-page';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };

  btnText: string = "Add";          // Can be either Add or Update

  incidentType: string = "";
  incidentTypeID: string = "";


  // ===================================
  // CONSTRUCTOR
  // ===================================
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private caseManagementService: CaseManagementService,
    private toastr: ToastrService,
    public adminCmsSettingsModel: AdminCmsSettingsModel,
    public customProjectNaming: ProjectLevelNaming,

  ) { }

  ngOnInit(): void {

  }




  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
  }


  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.isLoading = true;
  }

  public onDialogClose(event: any): void {
    this.hide();
  }

  SetupForNewIncident() {
    this.isLoading = false;
    this.isEditMode = false;
    this.btnText = "Add";
  }

  SetupForEditing(incidentTypeID : string, incidentLabel : string) {
    this.isLoading = true;
    this.isEditMode = true;
    this.btnText = "Update";

    this.incidentTypeID = incidentTypeID;
    this.incidentType = incidentLabel;

    this.isLoading = false;
  }

  AddUpdateIncidentType() {

    console.log("AddUpdateIncidentType -- Edit?", this.isEditMode);

    if(this.isEditMode){
     // incidentTypeID already set
    }
    else{
      // New Incident Number Record
      this.incidentTypeID = uuidv4();
    }

    // Show updating Spinner
    this.showUpdatingSpinner = true;


    this.caseManagementService.AddUpdateIncidentTypes(this.incidentTypeID, this.incidentType)
      .subscribe((result) => {

        this.showUpdatingSpinner = false;

        console.log("result", result);

        // Assume success as result.success returns false?
        if(this.isEditMode){
        this.toastr.success(this.customProjectNaming.level2Name + " Type Updated");
        }
        else{
          // New Incident Type Record
          this.toastr.success(this.customProjectNaming.level2Name + " Type Added");
        }

        // --------------------------------------------------------------------------------------
        // Make sure the parent calling this updates it data to show the new Incident #.
        // --------------------------------------------------------------------------------------
        this.updateNewIncidentTypeEvent(true);

        // Reset Values
        this.incidentType = "";

        this.hide();
      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Sorry, an error occurred attempting to update " + this.customProjectNaming.level2Name + " Types, please contact support@forensicnotes.com", "ERROR");
        });


  }

  
  Cancel(){
    // Reset Values
    this.incidentType = "";

    this.hide();
 }

}

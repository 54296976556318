import { HttpErrorResponse } from '@angular/common/http';
// import { createDirectiveDefinitionMap } from '@angular/compiler/src/render3/partial/directive';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SortService, ResizeService, GroupService, ColumnMenuService, PageService, FilterService, ToolbarItems, EditService, ToolbarService, IEditCell, ExcelQueryCellInfoEventArgs, PdfQueryCellInfoEventArgs, ExcelExportService, PdfExportService, GridComponent  } from '@syncfusion/ej2-angular-grids';
import { ContextMenuItem, GroupSettingsModel, FilterSettingsModel, EditSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { IncidentModel } from 'src/app/domain/Incident';
import { CaseManagementService } from 'src/app/service/case-management.service';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { EmitType} from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { CaseManagementIncidentNumbersAddv2Component } from '../setting/components/case-management-incident-numbers-addv2/case-management-incident-numbers-addv2.component';
import { ProjectLevelNaming } from '../shared/customObjects/ProjectLevelNaming';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-incident-numbers',
  templateUrl: './incident-numbers.component.html',
  styleUrls: ['./incident-numbers.component.scss'],
  providers: [SortService, GroupService, ColumnMenuService, PageService, EditService, ToolbarService,  ExcelExportService, PdfExportService]
})
export class IncidentNumbersComponent implements OnInit {


  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.dialogDrag-section';


  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };


  
  public isLoading: boolean = true;
  public showDeleteSpinner : boolean = false;

// DataGrid Settings
public pageSettings: Object;
public customAttributes: Object;
public resizeSettings = { mode: "Normal" };


recordsIncidentNumbers : any[];

// --------------------------------------------------------------------
// Used to store full single record for menu clicks
// and show display info
// --------------------------------------------------------------------
currRecordIncidentNumber : IncidentModel = new IncidentModel(); 
currRecID : string = "";
currRecIncidentNumber : string = "";


@ViewChild('grid')
public grid: GridComponent;

@ViewChild("componentAddIncidentNumber")
componentAddIncidentNumber : CaseManagementIncidentNumbersAddv2Component;


@ViewChild("ejDialogMenu")
ejDialogMenu : DialogComponent;

@ViewChild("ejDialogDelete")
ejDialogDelete: DialogComponent;


currMenuIncident : IncidentModel = null;

public filterSettings: FilterSettingsModel;
public toolbar: ToolbarItems[];
public sortOptions: object;


filterBarTemplateForYear = {}

borderClass: string = "content mat-elevation-z8 maxHeightIfSet maxWidthIfSet";  // set to '' to hide border
showInDialog: boolean = false;  // set to true to hide Breadcrumb

constructor(
  @Inject(APP_CONFIG) private config: AppConfig,
  private caseManagementService: CaseManagementService,
  private toastr: ToastrService,
  public adminCmsSettingsModel: AdminCmsSettingsModel,
  public customProjectNaming: ProjectLevelNaming,
  private appComponent: AppComponent,

) { }

ngOnInit(): void {

  console.log("ngOnInit-LoadIncidentNumbers")

  this.toolbar = ['ExcelExport', 'CsvExport', 'Search'];

  this.sortOptions = { columns: [{ field: 'base.yearOfIncident', direction: 'Descending' }, { field: 'base.incidentNumber', direction: 'Descending' }] };

  this.pageSettings = { pageSizes: true, pageSize: 5 };


}

AfterViewInit() {

  // --------------------------------------------------
  // Only Load Once the Page is displayed to user
  // --------------------------------------------------
  setTimeout(() => {
    console.log("AfterViewInit - Loading Incident Numbers");
    this.LoadIncidentNumbers();
  }, 5);
}

toolbarClick(args: ClickEventArgs): void {
  console.log("toolbar click", args);
  console.log("toolbar click", args.item);
  console.log("toolbar click", args.item.tooltipText);


  switch (args.item.tooltipText) {
      case 'PDF Export':
          this.grid.pdfExport();
          break;
      case 'Excel Export':
          this.grid.excelExport();
          break;
      case 'CSV Export':
          this.grid.csvExport();
          break;
  }
}

ShowInDialog(){
  this.showInDialog = true;
  this.borderClass = "";
  this.isLoading = false;
}

LoadIncidentNumbers(){


  this.caseManagementService.GetIncidentRecords(this.appComponent.securityUtils.isServiceWorkerCacheEnabled(), this.appComponent.user.userID, false)
    .subscribe((result) => {

      console.log("result-LoadIncidentNumbers", result);

      this.recordsIncidentNumbers = result;

      this.isLoading = false;

    },
      (error: HttpErrorResponse) => {
        console.error("ERROR --->");
        console.error(error);
        this.toastr.error("Sorry, an error occurred attempting to obtain Incident Records, please contact support@forensicnotes.com", "ERROR");
      });

}


OpenIndexMenu(){

  // Get Incident
}

AddIncidentNumber(){
  this.componentAddIncidentNumber.SetupForNewIncident(null, this.customProjectNaming.level2Name);
  this.componentAddIncidentNumber.show();
}


refreshData(event){
  console.log("Refreshing Data");
  this.LoadIncidentNumbers();
}


menuClick(event, record){

  this.currRecordIncidentNumber.id = record.base.id;
  this.currRecordIncidentNumber.incidentNumber = record.base.incidentNumber;
  this.currRecordIncidentNumber.yearOfIncident = record.base.yearOfIncident;
  this.currRecordIncidentNumber.incidentTypeId = record.base.incidentTypeId;
  this.currRecordIncidentNumber.incidentTypeDisplay = record.derived.incidentTypeLabel;


  // Setup Display values in Menu
  this.currRecID = record.base.id;
  this.currRecIncidentNumber = record.base.yearOfIncident + "-" + record.base.incidentNumber

  this.ejDialogMenu.show();

  console.log("currRecordIncidentNumber", record);


}

public onOverlayClickOutsideMenu: EmitType<object> = () => {
  this.ejDialogMenu.hide();
};


EditIncidentNumber(){

  this.ejDialogMenu.hide();

  console.log("incidentObj", this.currRecordIncidentNumber);

  this.componentAddIncidentNumber.SetupForEditing(this.customProjectNaming.level2Name, this.currRecordIncidentNumber);
  this.componentAddIncidentNumber.show();
}

DeleteIncident(){
  this.ejDialogMenu.hide();
  this.ejDialogDelete.show();
}


CancelDelete(){
  // Close
  this.ejDialogDelete.hide();
}

DeleteIncidentConfirmed(){

  this.showDeleteSpinner = true;


  this.caseManagementService.DeleteIncident(this.currRecordIncidentNumber.id)
  .subscribe((result) => {

    console.log("result", result);


    this.toastr.success("Incident Deleted");
  this.showDeleteSpinner = false;

    this.ejDialogDelete.hide();
    this.refreshData(null);


  },
    (error: HttpErrorResponse) => {
      console.error("ERROR --->");
      console.error(error);
      this.toastr.error("Sorry, an error occurred attempting to Delete Incident, please contact support@forensicnotes.com", "ERROR");
    });


}



}

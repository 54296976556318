import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementIncidentNumbersAddv2RoutingModule } from './case-management-incident-numbers-addv2-routing.module';
import { CaseManagementIncidentNumbersAddv2Component } from './case-management-incident-numbers-addv2.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups'
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementIncidentTypeModule } from '../case-management-incident-type/case-management-incident-type.module';


@NgModule({
  declarations: [
    CaseManagementIncidentNumbersAddv2Component
  ],
  imports: [
    CommonModule,
    CaseManagementIncidentNumbersAddv2RoutingModule,
    DialogModule,
    SharedModule,
    CaseManagementIncidentTypeModule
  ],
  exports:[
    CaseManagementIncidentNumbersAddv2Component
  ]
})
export class CaseManagementIncidentNumbersAddv2Module { }

<ejs-dialog id="ejDialogAddIncident" #ejDialogAddIncident showCloseIcon='true'
    header='{{btnText}} {{incidentOrFileNameDisplayed}} Number' (beforeClose)="onDialogClose($event)" width="375"
    [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
    [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
    <ng-template #content>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">
            <div class="rowFull alignBottom">
                <div class="column25">
                    <p class="matLabelWithNoOutline">Year</p>
                    <ejs-autocomplete id='ddYear' #ddYear [showPopupButton]='true' [dataSource]='recordsYear'
                        [placeholder]='yearPlaceHolderText' [fields]='yearGroupFields' [highlight]='true'
                        (select)="onSelectYear($event)" [value]="selectedYear"></ejs-autocomplete>
                </div>
                <div class="hyphen">-</div>
                <div class="column75">
                    <!-- Auto-Increment -->
                    <div class="autoIncrement"
                        *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'AutoIncrement'">(Auto-Increment)</div>
                    <!-- Numeric -->
                    <mat-form-field appearance="outline" class=""
                        *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'Numeric'">
                        <mat-label>{{incidentOrFileNameDisplayed}} Number</mat-label>
                        <input #txtIncidentNumber matInput placeholder="eg: 1234567 or 0001234" minlength="1"
                            maxlength="7" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"
                            [(ngModel)]="incidentNumber" />
                    </mat-form-field>

                    <!-- FreeForm Text -->
                    <mat-form-field appearance="outline" class=""
                        *ngIf="appComponent.adminCmsSettingsModel.fileNumberOption == 'FreeFormText'">
                        <mat-label>{{incidentOrFileNameDisplayed}} Number</mat-label>
                        <input matInput placeholder="eg: 1234567 or CN-XYZ123" minlength="1" maxlength="10"
                            [(ngModel)]="incidentNumber" />
                    </mat-form-field>
                </div>
            </div>

            <p class="matLabelWithNoOutline">{{incidentOrFileNameDisplayed}} Type</p>


            <div>
                <div class="iconsNextToEachOther">
                    <ejs-autocomplete id='ddIncidentType' #ddIncidentType [showPopupButton]='true'
                        [dataSource]='recordsIncidentTypes' [placeholder]='incidentPlaceHolderText'
                        [fields]='incidentGroupFields' [highlight]='true' (select)="onSelectIncidentType($event)"
                        [value]="incidentTypePreSelectValue"></ejs-autocomplete>
                    <!-- Edit Option - ONLY FOR Group ADMINS -->
                    <span class="material-icons iconBlue iconPaddingLeft superCenter hand"
                        title="Edit {{incidentOrFileNameDisplayed}} Types" *ngIf="appComponent.user.isGroupAdmin"
                        (click)="AddNewIncidentType()">edit</span>
                </div>
            </div>

            <mat-form-field appearance="outline" class="form-fullWidth">
                <mat-label>{{incidentOrFileNameDisplayed}} Synopsis</mat-label>
                <textarea [(ngModel)]="incidentObj.notes" matInput cdkTextareaAutosize
                    placeholder="Enter synopsis of {{incidentOrFileNameDisplayed}} ..." cdkAutosizeMinRows="6"
                    class="form-width-medium"></textarea>

            </mat-form-field>
            <div style="padding: 10px;"></div>
            <div class="buttonSection center" *ngIf="noIncidentTypeRecords == false">
                <button ejs-button class="cancel" type="button" (click)="Cancel()">Cancel</button>
                <button class="success" id="btnSubmit" #btnSubmit
                    [disabled]="selectedYear == '' || selectedIncidentTypeID == '' || (incidentNumber == '' && appComponent.adminCmsSettingsModel.fileNumberOption != 'AutoIncrement')"
                    (click)="CreateUpdateIncidentNumber(ddYear.value, ddIncidentType)"><img
                        src="/assets/images/progress-spinner.gif" class="button-spinner"
                        *ngIf="showUpdatingSpinner" />{{btnText}} {{incidentOrFileNameDisplayed}} Number</button>
            </div>
            <div *ngIf="noIncidentTypeRecords" class="setupRequiredInfoBox">
                <h3>Creating your first {{incidentOrFileNameDisplayed}} Number?</h3>
                <p>You need to add an {{incidentOrFileNameDisplayed}} Type (eg: Theft, Robbery, IP Theft, etc.) first.
                </p>
                <button class="success" (click)="CreateIncidentType()">Create {{incidentOrFileNameDisplayed}}
                    Type</button>
            </div>

        </div>
    </ng-template>
</ejs-dialog>

<app-case-management-incident-type #componentEditIncidentTypes (itemsUpdated)="RefreshIncidentList()">
</app-case-management-incident-type>
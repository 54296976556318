import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-pin-numpad',
  templateUrl: './pin-numpad.component.html',
  styleUrls: ['./pin-numpad.component.scss'],
  imports: [CommonModule, MatIconModule],
  standalone: true,
})
export class PinNumpadComponent {

  @Input() length = 6;
  @Output() onChange = new EventEmitter<string>();
  inputs: string[]
  position: number = 0;
  ngOnInit() {
    this.inputs = Array.from({ length: this.length }).map(e=>'');
  }
  enter(n: number) {
    if (this.position >= this.length) {
      return;
    }
    this.inputs[this.position] = n.toString();
    this.position++;
    this.emitChange();
  }
  back() {
    if (this.position > 0) {
      this.position--;
      this.inputs[this.position] = '';
      this.emitChange();
    }
  }
  emitChange() {
    const value = this.inputs.join('')
    this.onChange.emit(value);
  }
}

<div class="d-flex">
  <div>
    <table>
      <tr>
        <ng-container *ngFor="let input of inputs">
          <td><input type="password" maxlength="1" required readonly placeholder="_" size="1" [value]="input" class="passwordTxt"></td>
        </ng-container>
      </tr>
    </table>
  </div>
  <div>
    <table>
      <tr>
        <td class="numpad" (click)="enter(1)">1</td>
        <td class="numpad" (click)="enter(2)">2</td>
        <td class="numpad" (click)="enter(3)">3</td>
      </tr>
      <tr>
        <td class="numpad" (click)="enter(4)">4</td>
        <td class="numpad" (click)="enter(5)">5</td>
        <td class="numpad" (click)="enter(6)">6</td>
      </tr>
      <tr>
        <td class="numpad" (click)="enter(7)">7</td>
        <td class="numpad" (click)="enter(8)">8</td>
        <td class="numpad" (click)="enter(9)">9</td>
      </tr>
      <tr>
        <td></td>
        <td class="numpad" (click)="enter(0)">0</td>
        <td><button class="cancel" (click)="back()"><mat-icon>clear</mat-icon></button></td>
      </tr>
    </table>
  </div>
</div>
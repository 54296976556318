import { Injectable , Inject } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 
import { Observable } from 'rxjs';  
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { NotificationModel } from '../domain/tforensicNotification';
import { NotificationListModel } from '../domain/tforensicNotificationList';
import { protectedResources } from '../auth-config';
import URLUtils from '../shared/Utility/urlUtils';

@Injectable({
  providedIn: 'root'
})
export class ForensicNotificationServics {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig, private httpClient: HttpClient) { }

  url = protectedResources.apiBaseUrl.endpoint;

  GetAllNotification(numberToDisplay : number,
    enableServiceWorkerCache: boolean, userID: string
    ): Observable<NotificationListModel>{
    const httpPostOptions =
    {
      withCredentials: true
    }

    let fullURL: string = this.url + '/api/Notifications?startIndex=0&numberToDisplay=' + numberToDisplay;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.httpClient.get<NotificationListModel>(fullURL,httpPostOptions);
  }

  GetAllNotificationCategories(): Observable<NotificationModel> {
    const httpPostOptions =
    {
      withCredentials: true
    };
    return this.httpClient.get<NotificationModel>(this.url + '/api/Notifications/Categories?audience=2', httpPostOptions);
  }

  GetAllnotificationByOpenNitification(startindex: number): Observable<NotificationListModel> {
    const httpPostOptions = { withCredentials: true };
    return this.httpClient.get<NotificationListModel>
    (this.url + '/api/Notifications?startIndex=' + startindex + '&numberToDisplay=3', httpPostOptions);
  }

  MarkAsReadNotification(nID: number): Observable<NotificationListModel> {
    const httpOptions = {
      headers: new HttpHeaders({'accept': 'text/plain' }),
      withCredentials: true
    };
    return this.httpClient.patch<NotificationListModel>(this.url + '/api/Notifications/' + nID + '/MarkAsread', httpOptions);
  }

  MarkAsUnReadNotification(nID: number): Observable<NotificationListModel> {
    const httpOptions = {
      headers: new HttpHeaders({ 'accept': 'text/plain' }),
      withCredentials: true
    };
    return this.httpClient.patch<NotificationListModel>(this.url + '/api/Notifications/' + nID + '/MarkAsUnread', httpOptions);
  }

}

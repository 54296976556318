import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseManagementOrgTeamAddComponent } from './case-management-org-team-add.component';

const routes: Routes = [{ path: '', component: CaseManagementOrgTeamAddComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseManagementOrgTeamAddRoutingModule { }

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ForensicUserProfileService } from 'src/app/service/forensicUserProfile.service';
import { BaseDialogComponent } from 'src/app/shared/base/dialog/dialog.component';

@Component({
  selector: 'app-setup-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})

// ===========================================================
// Extends Base Dialog Component in Shared/Base folder.
// ===========================================================
export class TermsComponent extends BaseDialogComponent implements OnInit {


  @Output() termsAccepted = new EventEmitter<boolean>();

  updateParentTermsAccepted() {
    this.termsAccepted.emit(true);
  }

  isTermsAccepted: boolean = false;



  constructor(
    private forensicUserProfileService: ForensicUserProfileService,
    private toastr: ToastrService,
    public readonly cdRef: ChangeDetectorRef

  ) {
    super(cdRef);
  }

  ngOnInit(): void {

    // -------------------------------------------------
    // Nothing to load... so hide the loading gif!
    // -------------------------------------------------
    this.isLoading = false;
  }

  AcceptTerms() {
    console.log("Accept Clicked");


    this.forensicUserProfileService.agreeToTermsAndConditions()
      .subscribe((result) => {

        console.log("Terms Accepted");
        this.isTermsAccepted = true;
        this.updateParentTermsAccepted();
        this.hide();


      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Failed to Save... please contact support", "ERROR");
        });


  }

  onDialogClose(event) {

    if (this.isTermsAccepted == false) {
      // Do not allow Dialog to Close with X button
      this.toastr.warning("Sorry, but you must accept all terms and conditions." +
      "<br><br>NOTE: Security of your information is our #1 Priority and we will never sell your information to others." +
      "<br><br>If you have any questions, please contact support@forensicnotes.com", "TERMS & CONDITIONS", 
      {enableHtml: true});
      event.cancel = true;    // To cancel closing of the dialog
    }
    // else.. allow close if terms accepted
  }


}

import { Component, OnInit } from '@angular/core';
import { TimeZoneService } from '../../../service/time-zone.service';
// import { UserProfileService } from '../../../service/user-profile.service';
import { ForensicUserProfileService } from '../../../service/forensicUserProfile.service';
import { TforensicUserProfile } from '../../../domain/tforensicUserProfile';

import { UpdateUserProfileModel } from '../../../domain/UpdateProfile';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-preference-setting',
  templateUrl: './preference-setting.component.html',
  styleUrls: ['./preference-setting.component.css'],
})
export class PreferenceSettingComponent implements OnInit {
  public data: { [key: string]: Object }[] = [
    { Id: '1', Value: '5' },
    { Id: '2', Value: '10' },
    { Id: '3', Value: '15' },
    { Id: '4', Value: '20' },
    { Id: '5', Value: '25' },
  ];

  public fields: Object = { text: 'Value', value: 'Id' };
  //set the placeholder to DropDownList input
  public text: string = 'Select';
  displayName: string;
  usersEmail: string;
  TimeZone: string;
  agreeToTerms: boolean;
  passwordToProtectDownloadedFiles: string;
  accountPurchaseStatus: string;
  notebookPageSize: number;
  loginMessage: string;
  alwaysStayOnCurrentNoteOnTimestamp: boolean;
  editorToolbarMode: number;
  timeStampShortCut: number;
  numberOfUnreadAlerts: number;
  etModeValue: number;
  tsshortValue: number;
  successmsgp: string;
  timestampSetting: boolean;
  radioSelected: number = 0;
  toolbarModes: any;
  shortcutKeys: any;
  Opentoolbar: boolean;
  Closetoolbar: boolean;
  timestap: boolean;
  noshortcut: boolean;

  constructor(
    private _userProfileService: ForensicUserProfileService,
    private appComponent: AppComponent) {}

  advancedApp() {
    document.getElementById('simple-content').classList.add('active');
    document.getElementById('advance-content').classList.remove('active');
    document.getElementById('simpleApp').classList.remove('simple-active');
    document.getElementById('advancedApp').classList.add('advance-active');
  }
  simpleApp() {
    document.getElementById('advance-content').classList.add('active');
    document.getElementById('simple-content').classList.remove('active');
    document.getElementById('advancedApp').classList.remove('advance-active');
    document.getElementById('simpleApp').classList.add('simple-active');
  }
  preferenceUpdate() {
    if (this.etModeValue == undefined) {
      this.etModeValue = this.editorToolbarMode;
    }
    if (this.tsshortValue == undefined) {
      this.tsshortValue = this.timeStampShortCut;
    }
    if (this.timestampSetting == undefined) {
      this.timestampSetting = this.alwaysStayOnCurrentNoteOnTimestamp;
    }
    var userProfile = new UpdateUserProfileModel();
    userProfile.displayName = this.displayName;
    userProfile.usersEmail = this.usersEmail;
    userProfile.timeZone = this.TimeZone;
    userProfile.agreeToTerms = this.agreeToTerms;
    userProfile.passwordToProtectDownloadedFiles = this.passwordToProtectDownloadedFiles;
    userProfile.accountPurchaseStatus = this.accountPurchaseStatus;
    userProfile.notebookPageSize = this.notebookPageSize;
    userProfile.loginMessage = this.loginMessage;
    userProfile.alwaysStayOnCurrentNoteOnTimestamp = this.timestampSetting;
    userProfile.editorToolbarMode = this.etModeValue;
    userProfile.timeStampShortCut = this.tsshortValue;
    userProfile.numberOfUnreadAlerts = this.numberOfUnreadAlerts;

    console.error("CODE COMMENTED OUT");

    // this._userProfileService.updateUserProfile(userProfile).subscribe((r) => {
    //   if (r.success == true) {
    //     document.getElementById('successfullp').style.display = 'block';
    //     document.getElementById('successfullp').style.color = '#055cc3';
    //     this.successmsgp = 'Saved Successfully';
    //     setTimeout(() => {
    //       this.successmsgp = '';
    //     }, 5000);
    //   } else {
    //     document.getElementById('successfullp').style.display = 'block';
    //     document.getElementById('successfullp').style.color = 'red';
    //     this.successmsgp = 'Failed To Update';
    //     setTimeout(() => {
    //       this.successmsgp = '';
    //     }, 5000);
    //   }
    // });
  }
  etMode(val: any) {
    this.etModeValue = val;
  }
  tsshort(val: any) {
    this.tsshortValue = val;
  }
  timestamp(args: any) {

    // TODO - May have to check if checked. TEST THIS!

    this.timestampSetting = args;
  }
  ngOnInit() {

    console.log("getUserProfile()");


    this._userProfileService.getUserProfile(this.appComponent.securityUtils.isServiceWorkerCacheEnabled(), this.appComponent.user.userID).subscribe((r) => {
      if (r.success == true) {
        this.displayName = r.result.displayName;
        this.usersEmail = r.result.usersEmail;
        this.TimeZone = r.result.timeZone;
        this.agreeToTerms = r.result.agreeToTerms;
        this.passwordToProtectDownloadedFiles =
          r.result.passwordToProtectDownloadedFiles;
        this.accountPurchaseStatus = r.result.accountPurchaseStatus;
        this.notebookPageSize = r.result.notebookPageSize;
        this.loginMessage = r.result.loginMessage;
        this.alwaysStayOnCurrentNoteOnTimestamp =
          r.result.alwaysStayOnCurrentNoteOnTimestamp;
        this.editorToolbarMode = r.result.editorToolbarMode;
        this.timeStampShortCut = r.result.timeStampShortCut;
        this.numberOfUnreadAlerts = r.result.numberOfUnreadAlerts;
        if (this.editorToolbarMode == 0) {
          this.Opentoolbar = true;
        } else if (this.editorToolbarMode == 1) {
          this.Closetoolbar = true;
        }

        if (this.timeStampShortCut == 0) {
          this.timestap = true;
        } else if (this.timeStampShortCut == 1) {
          this.noshortcut = true;
        }

        this.toolbarModes = [
          {
            name: 'Toolbar should open automatically when I edit a note.',
            checked: this.Opentoolbar,
            value: '0',
          },
          {
            name:
              'Toolbar should stay closed and only open when I click “Show Toolbar”.',
            checked: this.Closetoolbar,
            value: '1',
          },
        ];
        this.shortcutKeys = [
          {
            name: '(Alt + S) will timestamp the current note.',
            checked: this.timestap,
            value: '0',
          },
          {
            name: 'No shortcut.',
            checked: this.noshortcut,
            value: '1',
          },
        ];
      }
    });

    document.getElementById('advance-content').classList.add('active');
    document.getElementById('simpleApp').classList.add('simple-active');

    document.body.classList.add('preference');
    document.body.classList.remove('branding');
    document.body.classList.remove('profile');
    document.body.classList.remove('template');
    document.body.classList.remove('team');
    document.body.classList.remove('forensicWitness');
    document.body.classList.remove('teamuser');
    document.body.classList.remove('notifications');
  }
}

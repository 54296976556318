import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { defaultIfEmpty } from "rxjs";
import { OrganizationService } from "src/app/service/organization.service";


@Injectable({
    providedIn: 'root'
})
export class AdminCmsSettingsModel {

    fileNumberOption: string = "";
    fileNumberStartingValue: number = 0;

    digitalForensicsCmsActivated: boolean = false;
    videoForensicsCmsActivated: boolean = false;
    privateInvesgigatorsCmsActivated: boolean = false;
    cyberCmsActivated: boolean = false;
    osintCmsActivated: boolean = false;
    workplaceInvestigationsCmsActivated: boolean = false;

    // -------------------------
    // CMS Type
    // 0 = not set
    // 1 = Law Enforcement
    // 2 = Private Industry
    // -------------------------
    cmsType: number = -1;               // NOT SET if -1
    cmsTypeStr: string = this.cmsType.toString();
    isLoaded: boolean = false;          // false until this object is loaded from server.

    // // --------------------------------------------------------------
    // // Law Enforcement uses different terms than Private Industry
    // // example: In Law Enforcement, the term "Incidents" is common
    // // but in Private Industry, this would be a File Number
    // // so depending on the level, the following will be the name
    // // used within the app for several of the dialogs.
    // // --------------------------------------------------------------
    // incidentOrFileNameDisplayed: string = "Incident";


    numberOfSystemsEnabled: number = 0;
    systemsEnabled: string = "";


    constructor(
        private organizationService: OrganizationService,
        private toastr: ToastrService,

    ) 
    {

        console.log("default constructor for AdminCmsSettingsModel()");
    }

    LoadSettingsFromResult(result: any) {
        // ----------------------------------------------------------------
        // NOTE: Make sure right side matches API names
        // The API Names are Different (thank you Darrel! :) --> NOT hahha
        // ----------------------------------------------------------------

        console.log("LoadSettingsFromResult", result); 

        this.fileNumberOption = result.base.fileNumberOption;
        this.fileNumberStartingValue = result.base.fileNumberStartingValue;

        this.digitalForensicsCmsActivated = result.base.digitalForensicsActivated;
        this.videoForensicsCmsActivated = result.base.videoForensicsActivated;
        this.privateInvesgigatorsCmsActivated = result.base.privateInvestigatorActivated;
        this.cyberCmsActivated = result.base.cyberActivated;
        this.osintCmsActivated = result.base.osintCmsActivated;
        this.workplaceInvestigationsCmsActivated = result.base.workPlaceInvestigationsActivated;

        this.cmsType = +result.base.level;
        this.cmsTypeStr = result.base.level;


        console.log("this.digitalForensicsCmsActivated", this.digitalForensicsCmsActivated);
        console.log("this.cmsType", this.cmsType);
        console.log("result.base.level", result.base.level);

        // IMPORTANT as required by other pages to ensure this is fully loaded.
        this.isLoaded = true;
    }


    GetSettings() {

        this.organizationService
            .GetAdminCmsSettings()
            .subscribe((result) => {

                console.log("GetSettings CMS Settings", result);

                this.LoadSettingsFromResult(result);

            },
                (error) => {

                    console.error(error);
                });

    }


    AddUpdateAdminCMSSettings(adminCmsSettingsModel: AdminCmsSettingsModel) {

        console.log("UpdateDigitalForensicsActivated", adminCmsSettingsModel);


        this.organizationService.AddUpdateAdminCMSSettings(adminCmsSettingsModel)
            .subscribe((result) => {
                this.toastr.success("Settings Updated");

                // Make sure this object is up to date.

                this.fileNumberOption = adminCmsSettingsModel.fileNumberOption;
                this.fileNumberStartingValue = adminCmsSettingsModel.fileNumberStartingValue;

                this.digitalForensicsCmsActivated = adminCmsSettingsModel.digitalForensicsCmsActivated;
                this.videoForensicsCmsActivated = adminCmsSettingsModel.videoForensicsCmsActivated;
                this.privateInvesgigatorsCmsActivated = adminCmsSettingsModel.privateInvesgigatorsCmsActivated;
                this.cyberCmsActivated = adminCmsSettingsModel.cyberCmsActivated;
                this.osintCmsActivated = adminCmsSettingsModel.osintCmsActivated;
                this.workplaceInvestigationsCmsActivated = adminCmsSettingsModel.workplaceInvestigationsCmsActivated;

                this.cmsType = adminCmsSettingsModel.cmsType;

            },
                (error) => {
                    this.toastr.error("Unable to update CMS Setting");
                    console.error(error);
                });

    }

}



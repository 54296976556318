import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParsesafeHtmlPipe } from './ParsesafeHtml.pipe';
import { SafePipe } from './safe.pipe';
import { ReplacePipe } from './replace.pipe';
import { NL2brPipe } from './replaceNewLineWithBR.pipe';



@NgModule({
  declarations: [
    ParsesafeHtmlPipe,
    SafePipe,
    ReplacePipe,
    NL2brPipe,
  ],
  imports: [

  ],
  exports:[
    ParsesafeHtmlPipe,
    SafePipe,
    ReplacePipe,
    NL2brPipe
  ]
})
export class CustomPipesModule { }
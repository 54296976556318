import { RouterModule } from '@angular/router';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule} from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { HammerModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
// import { AgmCoreModule } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, } from '@angular/core';
import { LoginActivate } from '../service/LoginActivate';
// import { UploaderModule} from '@syncfusion/ej2-angular-inputs';
import { MatTableModule } from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';

// CUSTOM Components Used in other Custom Components

import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutoCompleteModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import {MatRadioModule} from '@angular/material/radio';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MatSelectModule } from '@angular/material/select';
import { HtmlDirective } from './directives/html.directive';
import { DownloadFileDirective } from './directives/download-file-directive.directive';
// import { NgxPaginationModule } from 'ngx-pagination';
import { PipeFunction } from '../CustomPipes/pipe-function.pipe';
import { PinNumpadComponent } from './components/pin-numpad/pin-numpad.component';

@NgModule({
  declarations: [
    HtmlDirective,
    DownloadFileDirective,
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    FormsModule,
    Angulartics2Module.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBQKty2hx8SNecUR2vej8tHLQRkwgizgpQ',
    //   libraries: ['places'],
    //   language: 'en',
    // }),

    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    DialogModule,
    MatDialogModule,
    ProgressButtonModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    ProgressButtonModule,
    AutoCompleteModule,
    MatCardModule,
    MatRadioModule,
    DatePickerModule,
    ComboBoxModule,
    ReactiveFormsModule,
    NumericTextBoxModule,
    RadioButtonModule,
    CheckBoxModule,
    MatSelectModule,
    MatTabsModule,
    // NgxPaginationModule,
    PipeFunction,
    PinNumpadComponent,
  ],
  providers: 
  [
    HttpClientModule,
    CookieService,
    LoginActivate,
  ],
  exports: [
    HammerModule,  
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatError,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,

    MatCardModule,
    DialogModule,
    MatDialogModule,
    ProgressButtonModule,
    // UploaderModule,
    MatTableModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    ProgressButtonModule,
    AutoCompleteModule,
    MatCardModule,
    MatRadioModule,
    DatePickerModule,
    ComboBoxModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NumericTextBoxModule,
    RadioButtonModule,
    CheckBoxModule,
    MatSelectModule,
    HtmlDirective,
    DownloadFileDirective,
    MatTabsModule,
    // NgxPaginationModule,
    PipeFunction,
    PinNumpadComponent,
  ]
})
export class SharedModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';



const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'notebooks',
    loadChildren: () => import('./notebook-cards/notebook-cards.module').then((m) => m.NotebookCardsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'report-a-bug',
    loadChildren: () => import('./report-abug/report-abug.module').then((m) => m.ReportABugModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'change-temp-password',
    loadChildren: () => import('./temp-password/temp-password.module').then((m) => m.TempPasswordModule),
    canActivate: [MsalGuard],
  },

  {
    path: 'notebook/:id/:numberOfNotes/:isCached',
    loadChildren: () => import('./editnote/editnote.module').then((m) => m.EditnoteModule),
    canActivate: [MsalGuard]
  },
  // {
  //   path: 'notebookDisplay/:id',
  //   loadChildren: () =>
  //     import('./notebook-display/notebook-display.module').then((m) => m.NotebookDisplayModule),
  //
  // },

  // {
  //   path: 'lazy',
  //   loadChildren: () => import('./lazy-load/lazy-load.module').then(m => m.LazyLoadModule)
  // },

  { path: 'errorPage', loadChildren: () => import('./error-page/error-page.module').then((m) => m.ErrorPageModule), canActivate: [MsalGuard] },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsModule), canActivate: [MsalGuard] },
  { path: 'asset-management', loadChildren: () => import('./asset-management/asset-management.module').then((m) => m.AssetManagementModule), canActivate: [MsalGuard] },
  { path: 'entities', loadChildren: () => import('./entities/entities.module').then((m) => m.EntitiesModule), canActivate: [MsalGuard] },
  { path: 'rawNotebookDownload', loadChildren: () => import('./tools/raw-notebook-download/raw-notebook-download.module').then((m) => m.RawNotebookDownloadModule), canActivate: [MsalGuard] },
  { path: 'clientSideCaching', loadChildren: () => import('./setting/turn-on-offline/turn-on-offline.module').then((m) => m.TurnOnOfflineModule), canActivate: [MsalGuard] },
  { path: 'notCached', loadChildren: () => import('./not-cached/not-cached.module').then((m) => m.NotCachedModule), canActivate: [MsalGuard] },
  { path: 'clearCache', loadChildren: () => import('./setting/clear-cache/clear-cache.module').then((m) => m.ClearCacheModule), canActivate: [MsalGuard] },
  { path: 'account-setup-timezone', loadChildren: () => import('./account-setup/timezone/timezone.module').then((m) => m.TimezoneModule), canActivate: [MsalGuard] },
  { path: 'account-setup', loadChildren: () => import('./account-setup/account-setup.module').then((m) => m.AccountSetupModule), canActivate: [MsalGuard] },
  // CMS Pages
  { path: 'cms', loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule), canActivate: [MsalGuard] },
  { path: 'cms/:currFilterLv/:lv1', loadChildren: () => import('./cms/pages/lv1-incident-file/lv1-incident-file.module').then((m) => m.Lv1IncidentFileModule), canActivate: [MsalGuard] },
  { path: 'cms/:currFilterLv/:lv1/:lv2', loadChildren: () => import('./cms/pages/lv2-sect-dept/lv2-sect-dept.module').then((m) => m.Lv2SectDeptModule), canActivate: [MsalGuard] },
  { path: 'cms/:currFilterLv/:lv1/:lv2/:lv3', loadChildren: () => import('./cms/pages/lv3-work-requests/lv3-work-requests.module').then((m) => m.Lv3WorkRequestsModule), canActivate: [MsalGuard] },
  {
    path: 'cms/:currFilterLv/:lv1/:lv2/:lv3/:lv4',
    loadChildren: () => import('./cms/pages/lv4-work-request-tasks/lv4-work-request-tasks.module').then((m) => m.Lv4WorkRequestTasksModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'cms/:currFilterLv/:lv1/:lv2/:lv3/:lv4/:lv5',
    loadChildren: () => import('./cms/pages/lv5-exhibit-details/lv5-exhibit-details.module').then((m) => m.Lv5ExhibitDetailsModule),
    canActivate: [MsalGuard],
  },
  { path: 'notebookAudit', loadChildren: () => import('./editnote/audit/audit.module').then((m) => m.AuditModule) },
  { path: 'notebookImagePreview', loadChildren: () => import('./editnote/note-image-preview/note-image-preview.module').then((m) => m.NoteImagePreviewModule) },

  // ===============================================
  // VALID PAGES - KEEP FOR FUTURE TESTING!!!!
  // ===============================================
  // { path: 'notebookDisplay', loadChildren: () => import('./notebook-display/notebook-display.module').then(m => m.NotebookDisplayModule) },

  // { path: 'incidents', loadChildren: () => import('./setting/components/case-management-incident-numbers/case-management-incident-numbers.module').then(m => m.CaseManagementIncidentNumbersModule), canActivate: [MsalGuard] },
  // { path: 'work-requests', loadChildren: () => import('./work-requests/work-requests.module').then(m => m.WorkRequestsModule), canActivate: [MsalGuard] },
  // { path: 'project-dialogs', loadChildren: () => import('./project-dialogs/project-dialogs.module').then(m => m.ProjectDialogsModule), canActivate: [MsalGuard] },
  // { path: 'case-management-simple-work-request-step1', loadChildren: () => import('./case-management-simple/work-request-step1/work-request-step1.module').then(m => m.WorkRequestStep1Module), canActivate: [MsalGuard] },
  // { path: 'case-management-leo-work-request-step1', loadChildren: () => import('./case-management-leo/leo-work-request-step1/leo-work-request-step1.module').then(m => m.LeoWorkRequestStep1Module), canActivate: [MsalGuard] },
  // { path: 'case-management-leo-work-request-exhibits-video-add', loadChildren: () => import('./case-management-leo/work-request-exhibits-video-add/work-request-exhibits-video-add.module').then(m => m.WorkRequestExhibitsVideoAddModule), canActivate: [MsalGuard] },

  // { path: 'case-management-incidents-view', loadChildren: () => import('./case-management/incidents-view/incidents-view.module').then(m => m.IncidentsViewModule), canActivate: [MsalGuard] },
  // { path: 'cms-client-project', loadChildren: () => import('./cms-client-project/cms-client-project.module').then(m => m.CmsClientProjectModule), canActivate: [MsalGuard] },

  // { path: 'case-management-exhibits-step-add', loadChildren: () => import('./case-management/work-request-exhibits-step-add/work-request-exhibits-step-add.module').then(m => m.WorkRequestExhibitsStepAddModule), canActivate: [MsalGuard] },
  // { path: 'case-management-exhibit-continuity', loadChildren: () => import('./case-management/evidence-continuity/evidence-continuity.module').then(m => m.EvidenceContinuityModule), canActivate: [MsalGuard] },
  // { path: 'digital-forensics-select-exhibit-type', loadChildren: () => import('./digital-forensics/select-exhibit-type/select-exhibit-type.module').then(m => m.SelectExhibitTypeModule), canActivate: [MsalGuard] },
  // { path: 'digital-forensics-exhibit', loadChildren: () => import('./digital-forensics/exhibit/exhibit.module').then(m => m.ExhibitModule), canActivate: [MsalGuard] },
  // { path: 'digital-forensics-select-exhibit-type-child', loadChildren: () => import('./digital-forensics/select-exhibit-type-child/select-exhibit-type-child.module').then(m => m.SelectExhibitTypeChildModule), canActivate: [MsalGuard] },
  // { path: 'digital-forensics-exhibit-child', loadChildren: () => import('./digital-forensics/exhibit-child/exhibit-child.module').then(m => m.ExhibitChildModule), canActivate: [MsalGuard] },
  // { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [MsalGuard] },
  // CMS Setup
  // { path: 'cms/setup', loadChildren: () => import('./cms/setup/setup.module').then(m => m.SetupModule), canActivate: [MsalGuard] },
  // CMS Page Base Class - NOT Called Directly, but REQUIRED to avoid errors! See: https://forensicnotes.atlassian.net/wiki/spaces/DEV/pages/1997078529/Not+a+known+element+-+BUT+No+Errors+on+the+Page
  // { path: 'cmsMain', loadChildren: () => import('./cms/pages/pages.module').then(m => m.PagesModule) },
  // { path: 'cmsBlocks/projectDisplayBlocks/level1', loadChildren: () => import('./cms/projectDisplayBlocks/level1/level1.module').then(m => m.Level1Module), canActivate: [MsalGuard] },
  // { path: 'cms/projectDisplayBlocks/filterLevel0', loadChildren: () => import('./cms/projectDisplayBlocks/filter-level0/filter-level0.module').then(m => m.FilterLevel0Module) },
  // { path: 'cms/projectDisplayBlocks/UI-Client', loadChildren: () => import('./cms/projectDisplayBlocks/ui-client/ui-client.module').then(m => m.UIClientModule) },
  // { path: 'cms/projectDisplayBlocks/filterLevel1', loadChildren: () => import('./cms/projectDisplayBlocks/filter-level1/filter-level1.module').then(m => m.FilterLevel1Module) },
  // { path: 'cms/projectDisplayBlocks/UI-Exhibit', loadChildren: () => import('./cms/projectDisplayBlocks/ui-exhibit/ui-exhibit.module').then(m => m.UIExhibitModule) },
  // { path: 'cms/projectDisplayBlocks/UI-Incident', loadChildren: () => import('./cms/projectDisplayBlocks/ui-incident/ui-incident.module').then(m => m.UIIncidentModule) },
  // { path: 'cms/projectDisplayBlocks/UI-Project', loadChildren: () => import('./cms/projectDisplayBlocks/ui-project/ui-project.module').then(m => m.UIProjectModule) },
  // { path: 'cms/projectDisplayBlocks/UI-WorkRequest', loadChildren: () => import('./cms/projectDisplayBlocks/ui-work-request/ui-work-request.module').then(m => m.UIWorkRequestModule) },
  // { path: 'cms/notebooks/selectNotebooks', loadChildren: () => import('./cms/notebooks/select-notebooks/select-notebooks.module').then(m => m.SelectNotebooksModule) },
  // { path: 'cms/projectDisplayBlocks/filterLevel2', loadChildren: () => import('./cms/projectDisplayBlocks/filter-level2/filter-level2.module').then(m => m.FilterLevel2Module) },
  // { path: 'cms/projectDisplayBlocks/filterLevel3', loadChildren: () => import('./cms/projectDisplayBlocks/filter-level3/filter-level3.module').then(m => m.FilterLevel3Module) },
  // { path: 'cms/projectDisplayBlocks/filterLevel4', loadChildren: () => import('./cms/projectDisplayBlocks/filter-level4/filter-level4.module').then(m => m.FilterLevel4Module) },
  // { path: 'cms/createProject', loadChildren: () => import('./cms/create-project/create-project.module').then(m => m.CreateProjectModule) },
  // Contacts
  // { path: 'contacts-external', loadChildren: () => import('./contacts/external/external.module').then(m => m.ExternalModule) },
  // { path: 'contacts-external-add-client', loadChildren: () => import('./contacts/external/add-client/add-client.module').then(m => m.AddExternalClientModule) },
  // { path: 'contacts-external-add-contact', loadChildren: () => import('./contacts/external/add-contact/add-contact.module').then(m => m.AddExternalContactModule) },
  // { path: 'contacts-external-contact-view', loadChildren: () => import('./contacts/external/view-contact/view-contact.module').then(m => m.ViewContactModule) },
  // { path: 'contacts-internal', loadChildren: () => import('./contacts/internal/internal.module').then(m => m.InternalModule) },
  // { path: 'contacts-internal-add', loadChildren: () => import('./contacts/internal/add-contact/add-contact.module').then(m => m.AddContactModule) },
  // { path: 'asset-management-software', loadChildren: () => import('./asset-management/software/software.module').then(m => m.SoftwareModule) },
  // { path: 'asset-management-software-vendor-add', loadChildren: () => import('./asset-management/software/vendor-add/vendor-add.module').then(m => m.VendorAddModule) },
  // { path: 'asset-management-software-add', loadChildren: () => import('./asset-management/software/software-add/software-add.module').then(m => m.SoftwareAddModule) },
  // { path: 'asset-management-software-type-add', loadChildren: () => import('./asset-management/software/type-add/type-add.module').then(m => m.TypeAddModule) },
  // { path: 'notebook-display-advanced-editor', loadChildren: () => import('./notebook-display/advanced-editor/advanced-editor.module').then(m => m.AdvancedEditorModule) },
  // { path: 'notebook-display-template-ui', loadChildren: () => import('./notebook-display/template-ui/template-ui.module').then(m => m.TemplateUIModule) },
  // { path: 'notebook-display-audit-log', loadChildren: () => import('./notebook-display/audit-log/audit-log.module').then(m => m.AuditLogModule) },
  // { path: 'incident-numbers', loadChildren: () => import('./incident-numbers/incident-numbers.module').then(m => m.IncidentNumbersModule) },
  { path: 'account-setup-terms', loadChildren: () => import('./account-setup/terms/terms.module').then((m) => m.TermsModule) },
  { path: 'AccountSetupV2', loadChildren: () => import('./account-setup-v2/account-setup-v2.module').then((m) => m.AccountSetupV2Module) },
  { path: 'editNoteAdvanced', loadChildren: () => import('./editnote/advanced/advanced.module').then((m) => m.AdvancedModule) },
  { path: 'editNoteTemplates', loadChildren: () => import('./editnote/note-templates/note-templates.module').then((m) => m.NoteTemplatesModule) },
  { path: 'addQuickNote', loadChildren: () => import('./notebook-cards/add-quick-note/add-quick-note.module').then((m) => m.AddQuickNoteModule) },
  { path: 'addQuickAttachment', loadChildren: () => import('./notebook-cards/add-quick-attachment/add-quick-attachment.module').then((m) => m.AddQuickAttachmentModule) },
  { path: 'uploadTest', loadChildren: () => import('./upload-test/upload-test.module').then((m) => m.UploadTestModule) },
  { path: 'aiNoteSuggestion', loadChildren: () => import('./ai/ai-note-suggestion/ai-note-suggestion.module').then((m) => m.AiNoteSuggestionModule) },
  { path: 'aiGenerateReport', loadChildren: () => import('./ai/ai-generate-report/ai-generate-report.module').then((m) => m.AiGenerateReportModule) },
  { path: 'ai-tools', loadChildren: () => import('./ai/ai-tools-dashboard/ai-tools-dashboard.module').then((m) => m.AiToolsDashboardModule) },
  { path: 'ai-analyze-report', loadChildren: () => import('./ai/ai-analyze-report/ai-analyze-report.module').then((m) => m.AiAnalyzeReportModule) },
  { path: 'lockScreen', loadChildren: () => import('./lock-screen/lock-screen.module').then((m) => m.LockScreenModule) },
  // { path: 'ai-record-audio', loadChildren: () => import('./ai/ai-record-audio/ai-record-audio.module').then(m => m.AiRecordAudioModule) },
  // { path: 'cmsSettings', loadChildren: () => import('./setting/components/cms-structure/cms-structure.module').then(m => m.CmsStructureModule) },
  // { path: 'shared-base-dialog', loadChildren: () => import('./shared/base/dialog/dialog.module').then(m => m.BaseDialogModule) },
  // { path: 'fileOptions', loadChildren: () => import('./components/settings/file-options/file-options.module').then(m => m.FileOptionsModule) },
  // { path: 'testAssetSoftwareDetails', loadChildren: () => import('./asset-management/software/detailed/detailed.module').then(m => m.DetailedModule) },
  // { path: 'testAssetSoftwareDetailsAddUpdate', loadChildren: () => import('./asset-management/software/details-add-update/details-add-update.module').then(m => m.DetailsAddUpdateModule) },
  // { path: 'entityPerson', loadChildren: () => import('./entities/person/person.module').then(m => m.PersonModule) },
  // { path: 'entityPersonDetails', loadChildren: () => import('./entities/person-details/person-details.module').then(m => m.PersonDetailsModule) },
  // { path: 'addDongleInfo', loadChildren: () => import('./asset-management/software/add-update-dongle/add-update-dongle.module').then(m => m.AddUpdateDongleModule) },

  { path: 'add-note-via-share', loadChildren: () => import('./notebook-cards/add-note-via-share/add-note-via-share.module').then((m) => m.AddNoteViaShareModule), data: { notShowLayout: true } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { AuthTokenService } from "../../auth-token.service";
import { CaseManagementService } from "../../case-management.service";
import { ForensicNoteService } from "../../forensicNote.service";
import { SyncService } from "../sync.service";

export class SyncServicesStatic {
  static forensicNoteService: ForensicNoteService;
  static syncService: SyncService;
  static caseManagementService: CaseManagementService;
  static authTokenService: AuthTokenService
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum DialogType {
  EncryptionPasswordComponent,
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private openSrouce = new Subject<{type: DialogType; data: any}>();
  open$ = this.openSrouce.asObservable();
  constructor() { }

  openDialog(type: DialogType, data: any) {
    this.openSrouce.next({type, data});
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ForensicLoggerService } from 'src/app/service/forensicLogger.service';
import { UserModel } from '../customObjects/userModel';
import { NetworkConnectionService } from 'src/app/service/network-connection.service';
import { UserProfileService } from 'src/app/service/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export default class ErrorHandling {
  constructor(
    private toastr: ToastrService, 
    public logger: ForensicLoggerService, 
    private userProfileService: UserProfileService,
    private networkConnectionService: NetworkConnectionService,
  ) {
    // Default Constructor
  }

  // ==================================================================================
  // Log Error to Server and to Console.
  // eg: this.errorHandling.LogErrorToServer(exception, stackTrace, this.user)
  // ==================================================================================
  LogErrorToServer(addtionalInfo: string, error: HttpErrorResponse) {
    // ------------
    // OFFLINE?
    // ------------
    if (!(navigator && navigator.onLine)) {
      console.error('OFFLINE - Error NOT Logged to Server');
      return;
    }

    // -------------------------------------------------------------------
    // Error assumed to be caused due to being offline, so do NOT SEND.
    // -------------------------------------------------------------------

    // Output to Console
    if (error) {
      console.error('LogErrorToServer ', addtionalInfo + '\nERROR: ' + error.message);
    } else {
      console.error('LogErrorToServer ', addtionalInfo + '\nERROR: NULL');
    }

    // Get More Detailed Information for our Logs to Assist
    let userDetails: string = '';
    if (this.userProfileService.user.usersEmail) {
      userDetails = '<br> ++++++ USER DETAILS +++++++<br> email: ' + this.userProfileService.user.usersEmail;

      // TODO - Add further details like Mobile, Desktop, Installed, Size, etc.
    }

    try {
      this.logger.LogErrorToServer(addtionalInfo, error + userDetails).subscribe(
        (result) => {
          console.log('Error Logged to Server', result);
        },
        (error) => {
          console.error(error);
          if (!(this.networkConnectionService.isOnline)) {
            return;
          }
          this.toastr.error('Unable to send error to server. Please advise support@forensicnotes.com if this issue continues', 'ERROR');
        }
      );
    } catch (exception) {
      // Expected if offline... so ignore
      console.log('EXCEPTION trying to send error to server. Network offline? ', exception);
    }
  }

  // ==============================================================
  // Log Error to Server and to Console.
  // ==============================================================
  LogMessageToServer(message: string, source: string) {
    // ------------
    // OFFLINE?
    // ------------
    if (!(navigator && navigator.onLine)) {
      // console.error("OFFLINE - Message NOT Logged to Server");
      return;
    }

    this.logger.LogMessageToServer(message, source).subscribe(
      (result) => {
        // console.log("M", result);
        // No need to put anything in console.
      },
      (error) => {
        console.error(error);
        // this.toastr.error("Unable to send error to server. Please advise support@forensicnotes.com if this issue continues", "ERROR");
      }
    );
  }

  ShowUnknownErrorPopup() {
    this.toastr.error('Unknown SignalR Error : Please contact support', 'ERROR');
  }
}

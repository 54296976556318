<ejs-dialog header="Download Forensic Notebook" showCloseIcon="true" #ejDialogConfirmDownload
  (overlayClick)="onOverlayClickOutsideMenu()" 
  (beforeClose)="onDialogClose($event)"
  width="450px" [visible]="isVisible"
  [animationSettings]='animationSettings' [target]="dialogDragTarget"
  [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true"
  [zIndex]="110090">
  <div>
    <div class="section">
    <div *ngIf="downloadNotebookName != ''"><span class="titleInline">{{downloadNotebookName}}</span><br></div>
    <span class="titleInline">NotebookID:</span><span class="details">{{downloadNotebookbookID}}</span><br>
    <span class="titleInline">File Size:</span><span class="details">{{downloadFileSizeDisplayMB}}</span><br>
    <div *ngIf="showSizeWarning">
      <p class="note">NOTE: This file could take several minutes to download depending on your internet download speed.</p>
    </div>
    <p>&nbsp;</p>
  </div>
    <div class="centerDiv"><button  [disabled]="isDisabled" downloadFile="{{downloadURL}}" 
        fileName="{{fileName}}" (click)="onClickDownload()" title="Click to download" 
        class="success"><img
        src="/assets/images/progress-spinner.gif" class="button-spinner"
        *ngIf="isSpinnerActive" />{{downloadButtonLabel}}</button>
        <div *ngIf="showDownloadProgressNote" class="downloadInfo">
            * Download Time will depend on size of the download<br>and your internet download speed.<br>
            Once complete, the download will show<br>in your browser download area.
        </div>
    </div>
  </div>
</ejs-dialog>
/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */


import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { env } from 'process';
import { environment } from './../environments/environment';

// const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
let isIE = false;
if(typeof window !=="undefined"){
  isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
}



export const b2cPolicies = {
  names: {
    signUpSignIn: environment.signUpSignIn,
    editProfile: environment.editProfile
  },
  authorities: {
    signUpSignIn: {
      authority: environment.authorityLogin,
    },
    editProfile: {
      authority: environment.authorityEdit
    }
  },
  authorityDomain: environment.authorityDomain
};


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientID, // 'ad345a26-da2a-407b-b9a3-2a803597c6d0', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    iframeHashTimeout: 10000        // Stop Error: https://kksimplifies.com/msal-broswer-browserautherror/
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  assetsApi: {
    endpoint: environment.assetsApiEndPoint,
    scopes: [environment.scopes],
  },
  caseManagementApi: {
    endpoint: environment.caseManagementApiEndpoint,
    scopes: [environment.scopes]
  },
  organizationApi: {
    endpoint: environment.organizationApiEndpoint,
    scopes: [environment.scopes]
  },
  apiBaseUrl: {
    endpoint: environment.apiBaseUrlEndpoint,
    scopes: [environment.scopes]
  },
  apiSignalRSocketUrl: {
    endpoint: environment.apiSignalRSocketApiEndPoint,
    scopes: [environment.scopes]
  },
  


}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: []
};
<ejs-dialog id="ejDialogRenameNotebook" #ejDialogDownload
  isModal="true"
  (overlayClick)="onOverlay()" 
  [visible]="isVisible"
  header="Rename Notebook"
  [showCloseIcon]='showClosebutton'
  width="350px"
  (beforeClose)="onDialogClose($event)"
  [animationSettings]='animationSettings'
  [target]="dialogDragTarget"  [allowDragging]='allowDialogDragging' 
  >
  <div class="center">
      <mat-form-field appearance="outline" class="notebookNameInput">
        <mat-label>Notebook Name</mat-label>
        <input #txtNotebookName matInput 
        placeholder="Enter notebook name..." 
        minlength="1"
        maxlength="50"
        [value]="notebookName"
        (keyup.enter)="RenameNotebook(txtNotebookName.value)"
        />
          <mat-error *ngIf="txtNotebookName.invalid">required</mat-error>
      </mat-form-field>
    <div class="buttonSection center">
      <button ejs-button class="cancel" type="button" (click)="isVisible=false">Cancel</button>
      <button class="success" (click)="RenameNotebook(txtNotebookName.value)">Rename Notebook</button>
    </div>
   </div>
</ejs-dialog>


import { Injectable } from '@angular/core';
import { IndexDBService } from '../indexDB/index-db.service';
import { PGPEncryptionService } from '../pgpencryption.service';
import { v4 as newGUID } from 'uuid';
import { ClientEncryptionPasswordService } from '../client-encryption-password.service';
import { NotebookOffline } from './models/notebooks-offline';
import { ToastrService } from 'ngx-toastr';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotebooksOfflineService {
  readonly indexDBStoreName = this.indexDBService.OBJECT_STORE_NAME_OFFLINE_NOTEBOOKS;
  constructor(
    private indexDBService: IndexDBService,
    private pgpEncryptionService: PGPEncryptionService,
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    private toastr: ToastrService
  ) {}

  async getAllNotebookOfflines() {
    try {
      const KEK = await this.clientEncryptionPasswordService.getKEKPromise();
      const _noteBooks: NotebookOffline[] = [];
      const tx = this.indexDBService.indexDB.transaction(this.indexDBStoreName, 'readonly');
      const store = tx.objectStore(this.indexDBStoreName);
      const index = store.index('notebookId');
      const records = (await index.getAll()) as any[];
      if (!records || records.length === 0) {
        return [];
      }
      for await (const record of records) {
        const { id, encryptedCEK, encryptedDataJSON } = record;

        // Decrypted CEK
        const decryptedCEK: string = this.pgpEncryptionService.decryptData(encryptedCEK, KEK);

        // Decrypt JSON with CEK
        const decryptedJSON: string = this.pgpEncryptionService.decryptData(encryptedDataJSON, decryptedCEK);
        const notebook = JSON.parse(decryptedJSON);
        _noteBooks.push(notebook);
      }
      return _noteBooks;
    } catch (error) {
      console.error(error);
      throw new Error('Can not get notebooks offline');
    }
  }

  async addOrUpdateNoteBookToIndexDB(notebook: NotebookOffline) {
    const { id } = notebook;
    if(!id) {
      throw new Error("NotebookId is Empty");
    }
    const dataJson = JSON.stringify(notebook);

    const KEK = await this.clientEncryptionPasswordService.getKEKPromise();
    const contentEncryptionKey: string = newGUID();
    const encryptedCEK: string = this.pgpEncryptionService.encryptData(contentEncryptionKey, KEK);
    const encryptedDataJSON: string = this.pgpEncryptionService.encryptData(dataJson, contentEncryptionKey);

    const isExisting = await this.indexDBService.indexDB.get(this.indexDBStoreName, id);

    if (isExisting) {
      // not hanlding now
      await this.indexDBService.indexDB.put(this.indexDBStoreName, {
        id,
        encryptedCEK,
        encryptedDataJSON,
      });
      this.toastr.success('Notebook is updated', 'Notebook will be synced when the network is back to online', { enableHtml: true });
      return;
    }

    await this.indexDBService.indexDB.add(this.indexDBStoreName, {
      id,
      encryptedCEK,
      encryptedDataJSON,
    });
  }

  async removeNotebookOfflineFromStore(id: string) {
    try {
      const tx = this.indexDBService.indexDB.transaction(this.indexDBStoreName, 'readwrite');
      const store = tx.objectStore(this.indexDBStoreName);
      await store.delete(id);
    } catch (error) {
      console.error(error);
    }
  }
}

import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { AppErrorEvent } from '../shared/customObjects/appErrorEvent';
import { ClientEncryptionPasswordService } from './client-encryption-password.service';

@Injectable({
  providedIn: 'root'
})
export class PGPEncryptionService {


  constructor(
    private router: Router,
  ) {
  }


  // =====================================================================
  //
  // =====================================================================
  encryptData(dataStr: string, contentEncryptionKey: string, optionID: string = "") {


    console.log("clientEncryptionPasswordService --> PASSWORD ", contentEncryptionKey)

    let bytes = new TextEncoder().encode(dataStr);
    let inputIsValid = new TextDecoder().decode(bytes).length == dataStr.length;
    if (!inputIsValid) {
      console.error("Input is not a valid UTF-8 encoded string", dataStr);
      return;
    }

    var ciphertext = CryptoJS.AES.encrypt(dataStr, contentEncryptionKey).toString();

    return ciphertext;

  }

  // =====================================================================
  //
  // =====================================================================
  decryptData(ciphertext: string, contentEncryptionKey: string, optionID: string = "") {

    console.log("INDEX-DB --> decryptData --> ciphertext = ", ciphertext);
    const bytes = CryptoJS.AES.decrypt(ciphertext, contentEncryptionKey);
    console.log("INDEX-DB --> decryptData --> bytes = ", bytes);

    // Check for invalid UTF-8 sequences
    try {
      let decryptedString: string = bytes.toString(CryptoJS.enc.Utf8);
      let bytes2 = new TextEncoder().encode(decryptedString);
      let inputIsValid = new TextDecoder().decode(bytes2).length == decryptedString.length;
      if (!inputIsValid) {
        console.error("INDEX-DB --> Input is not a valid UTF-8 encoded string")
        return;
      }

      return decryptedString;
    }
    catch (exception) {
      console.error("INDEX-DB --> decryptData() error --> OptionID = " + optionID, exception)
    }

  }






}

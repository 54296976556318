import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SettingComponent } from './setting.component';

import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileSettingComponent } from './components/profile-setting/profile-setting.component';
import { PreferenceSettingComponent } from './components/preference-setting/preference-setting.component';
import { BrandingSettingComponent } from './components/branding-setting/branding-setting.component';
import { TemplateSettingComponent } from './components/template-setting/template-setting.component';
import { TeamSettingComponent } from './components/team-setting/team-setting.component';
import { LoginActivate } from '../service/LoginActivate';
import { NotificationsSettingComponent } from './components/notifications-setting/notifications-setting.component';
import { InvitedMemberComponent } from './components/invited-member/invited-member.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { SettingRoutingModule } from './setting-routing.module';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MatTableModule } from '@angular/material/table';
import { CaseManagementComponent } from './components/case-management/case-management.component';
import { CaseManagementIncidentNumbersComponent } from './components/case-management-incident-numbers/case-management-incident-numbers.component';
import { CaseManagementRoutingModule } from './components/case-management/case-management-routing.module';
import { CaseManagementIncidentNumbersModule } from './components/case-management-incident-numbers/case-management-incident-numbers.module';
import { CaseManagementOrgTeamComponent } from './components/case-management-org-team/case-management-org-team.component';
import { CaseManagementOrgTeamModule } from './components/case-management-org-team/case-management-org-team.module';
import { CaseManagementIncidentTypeModule } from './components/case-management-incident-type/case-management-incident-type.module';
import { CaseManagementBinTypeModule } from './components/case-management-bin-type/case-management-bin-type.module';
import { CaseManagementReceivedMethodModule } from './components/case-management-received-method/case-management-received-method.module';
import { CaseManagementWorkRequestDocumentTypeModule } from './components/case-management-work-request-document-type/case-management-work-request-document-type.module';
import { CaseManagementWorkRequestStatusModule } from './components/case-management-work-request-status/case-management-work-request-status.module';
import { CaseManagementWorkRequestExhibitTypeModule } from './components/case-management-work-request-exhibit-type/case-management-work-request-exhibit-type.module';
import { AssetManagementModule } from './components/asset-management/asset-management.module';
import { FileOptionsModule } from '../components/settings/file-options/file-options.module';
import { CmsStructureModule } from './components/cms-structure/cms-structure.module';
import { SafePipe } from '../CustomPipes/safe.pipe';
import { CustomPipesModule } from '../CustomPipes/custom-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SettingRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    FontAwesomeModule,
    EditorModule,
    MatMenuModule,
    MatButtonModule,
    UploaderModule,
    Ng2TelInputModule,
    MatTableModule,
    CaseManagementRoutingModule,
    CaseManagementIncidentNumbersModule,
    CaseManagementOrgTeamModule,
    CaseManagementIncidentTypeModule,
    CaseManagementBinTypeModule,
    CaseManagementReceivedMethodModule,
    CaseManagementWorkRequestDocumentTypeModule,
    CaseManagementWorkRequestStatusModule,
    CaseManagementWorkRequestExhibitTypeModule,
    AssetManagementModule,
    FileOptionsModule,
    CmsStructureModule,
    CustomPipesModule,
    // NgxIntlTelInputModule 
  ],
  exports: [
    ProfileSettingComponent,
    PreferenceSettingComponent,
    BrandingSettingComponent,
    TemplateSettingComponent,
    TeamSettingComponent,
    NotificationsSettingComponent,
    InvitedMemberComponent,
    CaseManagementComponent,
    
  ],
  declarations: [
    ProfileSettingComponent,
    PreferenceSettingComponent,
    BrandingSettingComponent,
    TemplateSettingComponent,
    TeamSettingComponent,
    NotificationsSettingComponent,
    InvitedMemberComponent,
    CaseManagementComponent,
    
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    SafePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SettingModule {}

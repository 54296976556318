import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { AppComponent } from 'src/app/app.component';
import { BaseDialogComponent } from 'src/app/shared/base/dialog/dialog.component';
import { AdminCmsSettingsModel } from 'src/app/shared/customObjects/adminCmsSettingsModel';
import { ProjectLevelNaming } from 'src/app/shared/customObjects/ProjectLevelNaming';
import NumberUtils from 'src/app/shared/Utility/NumberUtils';

@Component({
  selector: 'app-file-format-options',
  templateUrl: './file-options.component.html',
  styleUrls: ['./file-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class FileOptionsComponent  extends BaseDialogComponent implements OnInit {


  // option: string = "numeric";
  // showAutoIncrement: boolean = false;
  // autoIncrementStartingValue: string = "0000001";


  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public toastr: ToastrService,
    public customProjectNaming: ProjectLevelNaming,
    public appComponent: AppComponent,
    public readonly cdRef: ChangeDetectorRef

  ) { 
    super(cdRef);
  }

  ngOnInit(): void {
    // this.isVisible = true;

    console.log("ngOnInit - File Options Settings", this.appComponent.adminCmsSettingsModel);

        // -------------------------------------------------
    // Nothing to load... so hide the loading gif!
    // -------------------------------------------------
    this.isLoading = false;
  }

  click(itemClicked: string){
    console.log("ItemClicked", itemClicked);
  }

    // =========================================================
  // Only allow Numeric Values for Incident Number
  // =========================================================
  allowNumericDigitsOnlyOnKeyUp(e) {
    NumberUtils.allowNumericDigitsOnlyOnKeyUp(e, this.toastr);
  }


  Cancel(){
    this.hide();
  }

  Save(){

    this.showUpdatingSpinner = true;

    console.log("option", this.appComponent.adminCmsSettingsModel.fileNumberOption);
    console.log("autoIncrementStartingValue", this.appComponent.adminCmsSettingsModel.fileNumberStartingValue);

    // -------------------------------------------------------------------------------------
    // NOTE: We are ASSUMING Success with Save. If failure, then message shown to user.
    // -------------------------------------------------------------------------------------
    this.appComponent.adminCmsSettingsModel.AddUpdateAdminCMSSettings(this.appComponent.adminCmsSettingsModel);
    this.showUpdatingSpinner = false;
    this.hide();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimezoneRoutingModule } from './timezone-routing.module';
import { TimezoneComponent } from './timezone.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { BaseDialogModule } from 'src/app/shared/base/dialog/dialog.module';

@NgModule({
  declarations: [
    TimezoneComponent
  ],
  imports: [
    CommonModule,
    TimezoneRoutingModule,
    BaseDialogModule,
    DialogModule
  ],
  exports: [
    TimezoneComponent
  ]
})
export class TimezoneModule { }

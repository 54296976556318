<ejs-dialog showCloseIcon='true' header='DIALOG TITLE' (beforeClose)="onDialogClose($event)" width="550"
    [visible]="isVisible" [animationSettings]='animationSettings' [target]="dialogDragTarget"
    [allowDragging]='allowDialogDragging' [enableResize]='false' isModal="true">
    <div>
        <div *ngIf="isLoading"><img class="loading-img" src="/assets/images/loading.gif"></div>
        <div *ngIf="!isLoading">
            <!-- Content -->
            COPY HTML AND REPLACE THIS WITH CONTENT
            ALSO BE SURE TO REPLACE TITLE, WIDTH, Etc.
             <!-- (end of) Content-->
        </div>
    </div>
</ejs-dialog>   
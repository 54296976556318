import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementWorkRequestStatusRoutingModule } from './case-management-work-request-status-routing.module';
import { CaseManagementWorkRequestStatusComponent } from './case-management-work-request-status.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from 'src/app/shared/shared.module';
import { CaseManagementWorkRequestStatusAddModule } from '../case-management-work-request-status-add/case-management-work-request-status-add.module';


@NgModule({
  declarations: [
    CaseManagementWorkRequestStatusComponent
  ],
  imports: [
    CommonModule,
    CaseManagementWorkRequestStatusRoutingModule,
    SharedModule,
    DialogModule,
    GridModule,
    AutoCompleteModule,
    CaseManagementWorkRequestStatusAddModule
  ],
  exports: [
    CaseManagementWorkRequestStatusComponent
  ]
})
export class CaseManagementWorkRequestStatusModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { Observable, of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { protectedResources } from '../auth-config';

@Injectable()
export class LoginActivate implements CanActivate  {

  urlBase = protectedResources.apiBaseUrl.endpoint;
  
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }
  flag: boolean = true;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    
    if(!window.location.href.includes("printPDF") || !window.location.href.includes("printnotePDF")){
      const httpPostOptions =
      {
        headers:
          new HttpHeaders(
            {
              "Content-Type": "application/json"
            }),
        withCredentials: true,

      
        params: {
          "startIndex": "0",
          "numberToDisplay": "3",
          "searchText": "",
          "ownerType": "0"
        }
      }
      return this.http.get(this.urlBase + '/api/Notebooks/List', httpPostOptions).pipe(
        map(res => {
        return true;
        }),
        catchError((err) => {
        
          if (this.config.requireAuthentication == true) {
            this.flag = false;

            
          
            location.href = this.config.loginUrl + '/Account/BeginSignOn?returnUrl=' + document.location.href;
            return of(false);
        
          }
          else{
            throw Error("Unknown Error in canActivate --> Error = " + err);
          }
          
        })
      );
    }
    else{
      throw Error("Unknown Error in canActivate");
    }
    

  }
}

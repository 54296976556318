import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaseManagementWorkRequestDocumentTypeAddRoutingModule } from './case-management-work-request-document-type-add-routing.module';
import { CaseManagementWorkRequestDocumentTypeAddComponent } from './case-management-work-request-document-type-add.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CaseManagementWorkRequestDocumentTypeAddComponent
  ],
  imports: [
    CommonModule,
    CaseManagementWorkRequestDocumentTypeAddRoutingModule,
    DialogModule,
    SharedModule,
  ],
  exports: [
    CaseManagementWorkRequestDocumentTypeAddComponent
  ]
})
export class CaseManagementWorkRequestDocumentTypeAddModule { }
